import { getPerformance } from "firebase/performance";
import { cloneDeep } from "lodash";

import type { Geo } from "../misc/Geo";
import { FeatureType, MapDoc } from "../misc/Types";

export const performance = window.location.hostname === "localhost"
  ? null
  : getPerformance();


export interface ClientDoc {
  readonly id: string;
  readonly clientColor: string;
  readonly clientDisplayName: string;
}

export type BaseStatus = Record<string, unknown>;

export interface StationStatus extends BaseStatus {
  id: string;
  name: string;
  lastUpdated: number;
  client?: string;
}

export interface TracklineInfo extends BaseStatus {
  earliestPointEpoch: number;
  lastPointsRemoved: number;
  lastSimplifyEpoch: number;
  totalPointsRemoved: number;
}

export interface TracklineStatus extends BaseStatus {
  info: TracklineInfo;
  lastUpdate: Geo.TracklinePoint;
}

export type ThirdPartyStatus = StationStatus | Record<string, StationStatus>;


export type MapSourcesByClient = Record<FeatureType, true | string[]>;

const EMPTY_MAP_SOURCES_BY_CLIENT: Record<FeatureType, string[]> = {
  [FeatureType.BearingLines]: [],
  [FeatureType.Buoys]: [],
  [FeatureType.Ellipses]: [],
  [FeatureType.FishingGear]: [],
  [FeatureType.Gliders]: [],
  [FeatureType.LeaseAreas]: [],
  [FeatureType.Sma]: [],
  [FeatureType.Sightings]: [],
  [FeatureType.SlowZones]: [],
  [FeatureType.Stations]: [],
};


const ADMIN_MAP_SOURCES_BY_CLIENT: MapSourcesByClient = {
  [FeatureType.BearingLines]: true,
  [FeatureType.Buoys]: true,
  [FeatureType.Ellipses]: true,
  [FeatureType.FishingGear]: true,
  [FeatureType.Gliders]: true,
  [FeatureType.LeaseAreas]: true,
  [FeatureType.Sma]: true,
  [FeatureType.Sightings]: true,
  [FeatureType.SlowZones]: true,
  [FeatureType.Stations]: true,
};



export function extractMapSourcesByClient(mapDocument: MapDoc): MapSourcesByClient {
  // fast path for admins
  if (typeof mapDocument.sources === "boolean" && mapDocument.sources) {
    return {
      ...ADMIN_MAP_SOURCES_BY_CLIENT,
      // while there's no issue with admins seeing all lease areas, we want to restrict them to the 
      // lease areas configured for the map, to know it's working properly for non-admin users.
      [FeatureType.LeaseAreas]: mapDocument.leaseAreas ?? [],
    };
  }

  const sources = cloneDeep(EMPTY_MAP_SOURCES_BY_CLIENT);
  
  for (const [clientId, clientSrc] of Object.entries(mapDocument.sources)) {
    if (typeof clientSrc === "boolean" && clientSrc) {
      // add the client to all sources if 'true'
      for (const dt in sources) {
        sources[dt].push(clientId);
      }
    } else if (Array.isArray(clientSrc)) {
      // otherwise, only add them to the data types specified
      for (const dt of clientSrc) {
        Array.isArray(sources[dt]) && sources[dt].push(clientId);
      }
    }
  }
  
  return {
    ...sources,
    [FeatureType.LeaseAreas]: mapDocument.leaseAreas ?? [],
  };
}
