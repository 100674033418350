import { LayerGroup } from "../LayerGroup";
import { Layer, LayerConfig, LayerType, MiscLayer } from "../LayerTypes";


// import all the layer groups, split up to avoid a huge 1000 line file like before
import { ACOUSTIC_GROUP } from "./acoustic";
import { LEASE_AREA_LAYER_GROUP, SLOW_ZONE_LAYER_GROUP, SMA_LAYER_GROUP } from "./polygons";
import { FISHING_GEAR_GROUP, BUOY_LAYER_GROUP } from "./buoysFishingGear";
import { GLIDER_LAYER_GROUP } from "./glider";
import { STATION_LAYER_GROUP } from "./stations";
import { MARINE_SIGHTING_LAYER_GROUP, BIRD_SIGHTING_LAYER_GROUP } from "./sightings";
import { FeatureType } from "../../misc/Types";

/**
 * Defines the layer insertion order, which defines the z index 
 * of each layer. (0 is the bottom most layer, and higher numbers are drawn on top). 
 * 
 * Defining this here instead of each layer config defining its own z index helps
 * ensure we keep the numbers sequential + without duplicates  
 */
const LAYER_TO_ORDER: Record<Layer, number> = {
  [FeatureType.Sma]: 0,
  [FeatureType.LeaseAreas]: 1,
  [FeatureType.SlowZones]: 2,
  [FeatureType.Ellipses]: 3,
  [MiscLayer.EllipseOutlines]: 4,
  [FeatureType.BearingLines]: 5,
  [MiscLayer.BearingLineOutlines]: 6,
  [MiscLayer.GliderTracklines]: 7,
  [FeatureType.Buoys]: 8,
  [FeatureType.Gliders]: 9,
  [MiscLayer.StationTracklines]: 10,
  [FeatureType.FishingGear]: 11,
  [FeatureType.Sightings]: 12,
  [MiscLayer.BirdSightings]: 13,
  [MiscLayer.SightingsWithImages]: 14,
  [MiscLayer.BirdSightingsWithImages]: 15,
  [MiscLayer.PriorityBirdSightings]: 16,
  [MiscLayer.PrioritySightings]: 17,
  [MiscLayer.PriorityBirdSightingsWithImages]: 18,
  [MiscLayer.PrioritySightingsWithImages]: 19,
  [FeatureType.Stations]: 20,
};


/** 
 * Builds all of the source/layer/etc containers/indexes/etc. Does so 
 * at runtime to ensure everything works.
 */
function build() { 
  const LAYER_GROUPS: LayerGroup[] = [
    ACOUSTIC_GROUP,
    LEASE_AREA_LAYER_GROUP,
    SMA_LAYER_GROUP,
    SLOW_ZONE_LAYER_GROUP,
    FISHING_GEAR_GROUP,
    BUOY_LAYER_GROUP,
    GLIDER_LAYER_GROUP,
    STATION_LAYER_GROUP, 
    MARINE_SIGHTING_LAYER_GROUP, 
    BIRD_SIGHTING_LAYER_GROUP
  ];

  const layerOrder: Layer[] = [];
  const layers = {}; // : Record<Layer, LayerConfig<LayerType.Any>> = {};
  const layerToGroup = {}; // : Record<Layer, LayerGroup> = {};
    
  const sourceToLayers: Record<FeatureType, Layer[]> = {
    [FeatureType.BearingLines]: [],
    [FeatureType.Buoys]: [],
    [FeatureType.Ellipses]: [],
    [FeatureType.FishingGear]: [],
    [FeatureType.Gliders]: [],
    [FeatureType.LeaseAreas]: [],
    [FeatureType.Sightings]: [],
    [FeatureType.SlowZones]: [],
    [FeatureType.Sma]: [],
    [FeatureType.Stations]: [],
  };


  for (const group of LAYER_GROUPS) {
    for (const layer of group.iterLayers()) {
      layerToGroup[layer.layerId] = group;

      const zIndex = LAYER_TO_ORDER[layer.layerId];

      if (layerOrder[zIndex]) {
        throw new Error(`Duplicate zIndex found for layers ${layer.layerId} and ${layerOrder[zIndex]}`);
      }

      layerOrder[zIndex] = layer.layerId;
            
      sourceToLayers[layer.sourceId].push(layer.layerId);
            
      if (layer.layerId in layers) {
        throw new Error(`found duplicate layer: ${layer.layerId}`);
      }

      layers[layer.layerId] = layer;
    }
  }

  const errors = layerOrder.map((layer, index) => {
    if (layer === undefined) {
      return new Error(`empty z index ${index}`);
    } else {
      return null;
    }
  }).filter(err => err instanceof Error);

  if (errors.length) {
    throw errors;
  } 
    
  return { 
    LAYER_GROUPS,
    LAYERS: layers as Record<Layer, LayerConfig<LayerType.Any>>,
    LAYER_ORDER: layerOrder as Layer[],
    LAYER_TO_GROUP: layerToGroup as Record<Layer, LayerGroup>,
    LAYER_TO_ORDER,
    SOURCE_TO_LAYERS: sourceToLayers,
  };
} 

export default build();



