import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import TopNav from "../../components/nav/TopNav";
import ManageExistingUsers from "../../components/admin/ManageExistingUsers";
import CreateUsers from "../../components/admin/CreateUsers";

import { MapsAPI } from "../../firebase/API";
import type { MapDoc } from "../../misc/Types";

import { useStateContext } from "../../provider/StateProvider";

import isEmpty from "lodash/isEmpty";

const UserManagement = (): JSX.Element => {
  const { user } = useStateContext();

  document.title = "Mysticetus - Users";

  const [maps, setMaps] = useState<Record<string, MapDoc>>({});

  useEffect(() => {
    if (isEmpty(maps)) {
      MapsAPI.getAll().then(mapRecord => setMaps(mapRecord));
    }

  }, [maps]);

  return (
    <>
      <TopNav title='User Management'/>
      <Container className='pad-sides mt-4'>
        <Tabs defaultActiveKey="add-users">
          <Tab eventKey="add-users" title='Add new users'>
            <Container className='rounded-border no-top-border small-top-pad'>
              {
                !isEmpty(maps) && user && user.email
                  ? <CreateUsers currentUserEmail={user.email} maps={maps}/>
                  : <Spinner animation="border" role="status" variant='primary'>
                    <span className="sr-only">Loading...</span>
                  </Spinner>
              }
            </Container>
          </Tab>
          <Tab eventKey="manage-existing-users" title="Manage existing users">
            <Container className='rounded-border no-top-border small-top-pad'>
              {
                !isEmpty(maps) && user && user.email
                  ? <ManageExistingUsers currentUserEmail={user.email} maps={maps}/>
                  : <Spinner animation="border" role="status" variant='primary'>
                    <span className="sr-only">Loading...</span>
                  </Spinner>
              }
            </Container>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default UserManagement;
