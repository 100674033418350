import React, { useState } from "react";
import { Card, Form, Modal } from "react-bootstrap";

import Alert, { AlertProps } from "../misc/Alert";
import LoadingButton from "../misc/LoadingButton";

import PasswordInput from "./PasswordInput";

import FirebaseAuth from "../../firebase/FirebaseAuth";

import { useStateContext, useStateDispatch } from "../../provider/StateProvider";


interface GoogleError {
  code: string,
  message: string,
}

function isGoogleError(x: unknown, specificCode?: string): x is GoogleError {
  if (!(x instanceof Error)) {
    return false;
  }

  const casted = x as unknown as Record<string, unknown>;

  if (typeof casted.code !== "string" || typeof casted.message !== "string") {
    return false;
  }

  console.log({ specificCode, type: typeof specificCode });
  if (typeof specificCode === "string") {
    console.log({ code: casted.code, specificCode, equal: casted.code === specificCode });

    return casted.code === specificCode;
  }

  return true;
}


export interface UpdatePasswordProps {
  className?: string;
}

function UpdatePassword(props: UpdatePasswordProps): JSX.Element {
  const { user, config } = useStateContext();
  const dispatch = useStateDispatch();

  const [basePassword, setBasePassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [alertProps, setAlertProps] = useState<AlertProps | null>(null);
  const [relogError, setRelogError] = useState<string | null>(null);


  const [showSignIn, setShowSignIn] = useState<boolean>(false);

  const handleUpdateSuccess = () => {
    setBasePassword("");
    setConfirmPassword("");

    setAlertProps({
      show: true,
      fade: 200,
      variant: "success",
      text: "Password update successful"
    });
  };

  const handleUpdateError = (error: unknown) => {
    if (isGoogleError(error, "auth/requires-recent-login")) {
      setShowSignIn(true);
      return;
    }

    console.error(error);

    let message: string | null = null;

    if (error instanceof Error || isGoogleError(error)) {
      message = error.message;
    } else if (typeof error === "string") {
      message = error;
    } else {
      message = `Encountered an unknown error: '${error}'`;
    }

    setAlertProps({
      show: true,
      variant: "danger",
      text: message,
    });
  };

  const updatePassword = (e?: React.FormEvent<HTMLElement>) => {
    e?.preventDefault();

    setAlertProps(null);
    setIsLoading(true);

    FirebaseAuth.updatePassword(dispatch, basePassword)
      .then(() => handleUpdateSuccess())
      .catch(error => handleUpdateError(error))
      .finally(() => setIsLoading(false));
  };

  const relogin = (e?: React.FormEvent<HTMLElement>) => {
    e?.preventDefault();

    let email: string;
    if (typeof user?.email === "string") {
      email = user.email;
    } else if (typeof config?.userDoc.email === "string") {
      email = config.userDoc.email;
    } else {
      setRelogError(`
        Unexpected error encountered.
        Please fully sign out and then sign back in and retry.
        If the issue still persists, please email info@mysticetus.com for assistance
      `);
      return;
    }

    setIsLoading(true);
    FirebaseAuth.signInWithEmailPass(dispatch, email, currentPassword)
      .then(() => updatePassword())
      .catch(handleUpdateError)
      .finally(() => {
        setIsLoading(false);
        setShowSignIn(false);
      });
  };

  return (
    <Card className={props.className ?? ""}>
      <Card.Header>
        Change your password
      </Card.Header>
      <Form onSubmit={updatePassword}>
        <Card.Body className="px-4">
          <PasswordInput
            value={basePassword}
            confirmValue={confirmPassword}
            disabled={isLoading}
            onChange={(pass) => setBasePassword(pass)}
            onConfirmChange={(pass) => setConfirmPassword(pass)}
            size="sm"
            className="w-auto"/>
        </Card.Body>
        <Card.Footer className="w-100">
          <div className="d-flex flex-row-reverse justify-content-start align-items-center align-self-center">
            <LoadingButton
              isLoading={isLoading}
              type="submit"
              disabled={basePassword.length < 5 || basePassword !== confirmPassword}
              className="">
              Submit
            </LoadingButton>
            {
              alertProps !== null
                ? <Alert {...alertProps} className="p-2 w-auto mr-auto"/>
                : null
            }
          </div>
        </Card.Footer>
      </Form>
      <Modal show={showSignIn}>
        <Form onSubmit={relogin}>
          <Modal.Header>
            <Modal.Title>
              Please confirm your current password to continue
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PasswordInput
              value={currentPassword}
              disabled={isLoading}
              onChange={(pass) => setCurrentPassword(pass)}
              size="sm"
              className="w-auto"/>
          </Modal.Body>
          <Modal.Footer className="flex-row">
            <LoadingButton
              type="submit"
              disabled={currentPassword.length < 5}
              isLoading={isLoading}>
              Confirm
            </LoadingButton>
            <Alert show={typeof relogError === "string"} variant="danger">
              {relogError}
            </Alert>
          </Modal.Footer>
        </Form>
      </Modal>
    </Card>
  );
}


export default UpdatePassword;
