import React, { useState, useEffect, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

import { validate } from "email-validator";

import { UsersAPI } from "../../firebase/API";
import { SetState } from "../../misc/Types";

export const isEmail = validate;


export interface MultiEmailLabelProps {
  email: string;
  setValidated: (valid: boolean) => void;
  removeOnClick: () => void;
}


export interface MultiEmailInputProps {
  setEmails: SetState<string[]>;
  emails: string[];
  submitLoading: boolean;
  setEmailsValid: SetState<boolean>;
  placeholder?: string;
  className?: string;
  focus?: boolean;
}

const MultiEmailLabel = (props: React.PropsWithoutRef<MultiEmailLabelProps>) => {
  const { email, setValidated, removeOnClick } = props;
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    UsersAPI.doesUserExist(email).then(exists => {
      setIsValid(!exists);
      setValidated(!exists);
      setIsLoading(false);
    });
  }, [email]);

  const classes = [
    "d-inline-flex",
    "flex-row",
    "mr-2",
    "justify-content-start",
    "align-items-center",
    "w-auto",
    !isLoading && !isValid ? "danger" : "",
  ];

  return (
    <div data-tag className={classes.join(" ")}>
      {
        isLoading
          ? <Spinner size='sm' animation="border" className="mr-2" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          : null
      }
      <span className="align-middle">
        {
          isLoading
            ? email
            : isValid
              ? email
              : `${email} already exists, please remove`
        }
      </span>
      <button type="button" data-tag-handle className="close ml-2 align-middle" aria-label="Close"
        onClick={removeOnClick}>
        <span aria-hidden="true" className="text-dark w-auto h-auto">
          &times;
        </span>
      </button>
    </div>
  );
};


const MultiEmailInput = (props: React.PropsWithoutRef<MultiEmailInputProps>): JSX.Element => {
  const { setEmails, emails, setEmailsValid } = props;

  const [inputValue, setInputValue] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(props.focus ?? false);
  const [invalidEmailAlert, setInvalidEmailAlert] = useState<string|null>(null);
  const [validatedEmails, setValidatedEmails] = useState<Record<string, boolean>>({});

  const emailInputRef = useRef<HTMLInputElement>(null);

  const tryAddEmail = (newEmail: string): void => {
    if (isEmail(newEmail)) {
      if (emails.includes(newEmail)) {
        setInvalidEmailAlert(`${newEmail} has already been entered`);
      } else {
        setEmails(existing => [...existing, newEmail]);
        setInputValue("");
      }
    } else {
      setInvalidEmailAlert(`'${newEmail}' is an invalid email`);
    }
  };

  const removeEmail = (removeEmail: string, isDisabled?: boolean): void => {
    if (isDisabled) return;

    const emailCopy: string[] = [];

    for (const existingEmail of emails) {
      if (existingEmail !== removeEmail) {
        emailCopy.push(existingEmail);
      }
    }

    setEmails(emailCopy);
  };

  const handleOnKeydown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    switch (e.key) {
      case "Enter":
      case "NumpadEnter":
      case "Tab":
        e.preventDefault();
        if (e.currentTarget.value.length > 0) {
          tryAddEmail(e.currentTarget.value);
        }
        break;
      case "Backspace":
        if (!e.currentTarget.value) {
          removeEmail(emails[emails.length - 1], false);
        }
        break;
    }
  };

  const handleOnKeyup = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    switch (e.key) {
      case "NumpadEnter":
      case "Tab":
        if (e.currentTarget.value.length > 0) {
          tryAddEmail(e.currentTarget.value);
        }
        break;
    }
  };

  const handleOnChange = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
  };

  const handleOnBlur = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setFocused(false);

    if (e.currentTarget.value.length > 0) {
      tryAddEmail(e.currentTarget.value);
    }
  };

  const handleOnFocus = (): void => {
    setFocused(true);

    if (inputValue.length > 0 && invalidEmailAlert) {
      setInvalidEmailAlert(null);
    }
  };

  const handleMainDivFocus = (): void => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  };

  useEffect(() => {

    if (!invalidEmailAlert) {
      for (const email of emails) {
        if (!validatedEmails[email]) {
          setEmailsValid(false);
          return;
        }
      }

      setEmailsValid(emails.length > 0);
    } else {
      setEmailsValid(false);
    }
  }, [emails, invalidEmailAlert, validatedEmails]);

  const divClass = [
    props.className,
    "multi-email-input",
    focused ? "focused" : "",
    (inputValue.length === 0 && emails.length === 0) ? "empty" : "",
  ];

  return (
    <>
      <div className={divClass.join(" ")} onClick={handleMainDivFocus}>
        <span data-placeholder>
          {props.placeholder ?? "Email Addresses"}
        </span>
        {
          emails.map((email: string, idx: number) => {
            const setValidated = (valid: boolean) => {
              setValidatedEmails(curr => {
                return { ...curr, [email]: valid };
              });
            };

            return (
              <MultiEmailLabel key={idx} email={email}
                setValidated={setValidated}
                removeOnClick={() => removeEmail(email)}/>
            );
          })
        }
        <input ref={emailInputRef} className="" type="text"
          value={inputValue}
          disabled={props.submitLoading}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onKeyDown={handleOnKeydown}
          onKeyUp={handleOnKeyup}/>
      </div>
      <Alert className='small-alert'
        show={invalidEmailAlert !== null}
        variant='danger'>
        {invalidEmailAlert}
      </Alert>
    </>
  );
};

export default MultiEmailInput;
