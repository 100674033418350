import React, { useState, useEffect } from "react";

import { isEmail } from "../../components/misc/MultiEmailInput";

import { Alert, Form, Modal, Row } from "react-bootstrap";

import PasswordInput from "./PasswordInput";
import LoadingButton from "../../components/misc/LoadingButton";

import { useStateDispatch } from "../../provider/StateProvider";

import FirebaseAuth from "../../firebase/FirebaseAuth";
import { PendingUsersAPI } from "../../firebase/API";

import * as Sentry from "@sentry/react";
import { isNonEmptyRecord } from "../../misc/Utils";
import { NavigateFunction } from "react-router-dom";

const mysticetusEmail = "info@mysticetus.com";


export interface SetPasswordProps {
  show: boolean;
  close: () => void;
  nav: NavigateFunction;
}

enum AuthError {
  InvalidActionCode,
  ExpiredActionCode,
}


function getAuthError(rawError: unknown): null | AuthError {
  if (!isNonEmptyRecord(rawError) || typeof rawError.code !== "string") {
    return null;
  } 

  switch (rawError.code) {
    case "auth/expired-action-code":
      return AuthError.ExpiredActionCode;
    case "auth/invalid-action-code":
      return AuthError.InvalidActionCode;
    default:
      return null;
  }
}

const SetPassword = (props: React.PropsWithoutRef<SetPasswordProps>): JSX.Element => {
  const { show, close, nav } = props;

  const dispatch = useStateDispatch();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [authError, setAuthError] = useState<string|null>(null);
  const [respMessage, setRespMessage] = useState<string|null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [newLinkRequest, setNewLinkRequest] = useState<boolean>(false);

  document.title = "Mysticetus";


  const submitNewPassword = (): Promise<void> => {
    return FirebaseAuth.signInWithEmailLink(dispatch, email, window.location.href)
      .then(user => {
        if (!user) {
          throw new Error("User was not found");
        }

        return FirebaseAuth.updatePassword(dispatch, password);
      })
      .then(() => {
        setRespMessage("Successfully changed password, returning to the home page...");
        PendingUsersAPI.deleteAllDocsWithEmail(email);
        setTimeout(() => close(), 2000);
      })
      .catch(error => {
        // handle specific error codes from firebase
        switch (getAuthError(error)) {
          case AuthError.ExpiredActionCode:
            setNewLinkRequest(true);
            return;
          // this case likely comes from users trying to re-use the sign in link from the 
          // invite, even though its a one time thing. Redirect to home to force them to log in normally
          case AuthError.InvalidActionCode:
            setRespMessage("This account has already set an initial password, ");
            nav("/");
            close();
            return;
        }

        // if we didnt get a known code/error, log it
        Sentry.captureException(error, this);
        console.error(error);
        throw error;
      });
  };

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();

    setSubmitLoading(true);

    const action = newLinkRequest
      ? PendingUsersAPI.tryRequestNewSignInLink(email)
      : submitNewPassword();

    action.catch(error => {
      if (newLinkRequest) {
        setNewLinkRequest(false);
      }

      setAuthError(error.message);
    }).finally(() => setSubmitLoading(false));
  };

  const emailOnUnfocus = (): void => {
    if (!isEmail(email)) {
      setAuthError("Email is not valid");
    }
  };

  useEffect(() => {
    const validLink = FirebaseAuth.checkEmailSignInLink(window.location.href);

    if (!validLink) {
      setAuthError("Sign in link is not valid");
      return;
    }
  }, []);

  useEffect(() => {

    const validPassword = password.length > 6 && password === repeatPassword;

    const enableSubmit = isEmail(email) && (validPassword || newLinkRequest);

    // Only set a new state if we need to change it, to prevent
    // extra renders
    if (enableSubmit !== submitEnabled) {
      setSubmitEnabled(enableSubmit);
    }

  }, [email, password, repeatPassword]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <h1>Set a password</h1>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>
              Email
            </Form.Label>
            <Form.Control size="sm" type="text" autoComplete="username"
              onChange={(e) => setEmail(e.target.value)}
              placeholder='email' value={email}
              onBlur={emailOnUnfocus}
              onFocus={() => setAuthError(null)}/>
          </Form.Group>

          {
            !newLinkRequest
              ? <PasswordInput value={password}
                onChange={setPassword}
                confirmValue={repeatPassword}
                onConfirmChange={setRepeatPassword}
                disabled={submitLoading}
                autoComplete={false}/>
              : null
          }
          {
            authError && authError.length > 0
              ? <Alert className='p-1' variant="danger">
                { authError }
                {
                  newLinkRequest
                    ? null
                    : <>
                      <br/>
                      {"For assistance, please email "}
                      <a href={`mailto:${mysticetusEmail}`}>
                        {mysticetusEmail}
                      </a>
                    </>
                }
              </Alert>
              : null
          }
          {
            respMessage && respMessage.length > 0
              ? <Alert className='p-1' variant="success">
                { respMessage }
              </Alert>
              : null
          }
          <Row className='no-margins justify-right'>
            <LoadingButton disabled={submitLoading || !submitEnabled}
              isLoading={submitLoading} variant="primary"
              type="submit" >
              {
                !newLinkRequest
                  ? "Submit"
                  : "Request new sign-up link"
              }
            </LoadingButton>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SetPassword;
