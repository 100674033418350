import { FeatureType } from "../../misc/Types";
import { LayerGroup, TrackedFeatureLayerGroup } from "../LayerGroup";
import { BUILTIN_ICON, LayerConfig, LayerParamOptions, LayerType, MiscLayer } from "../LayerTypes";
import { fillInDefaultOptions } from "./common";



const GLIDER_LAYER: LayerConfig<LayerType.Symbol> = {
  layerId: FeatureType.Gliders,
  sourceId: FeatureType.Gliders,
  hasAgeDecay: false,
  addIcon: BUILTIN_ICON,
  factory: function (opts?: LayerParamOptions): LayerType.Symbol {
    const { visible } = fillInDefaultOptions(opts);

    return {
      id: FeatureType.Gliders,
      type: "symbol",
      source: FeatureType.Gliders,
      filter: [
        "all",
        ["==", ["get", "dataType"], "glider"],
        ["==", ["geometry-type"], "Point"],
      ],
      layout: {
        "visibility": visible ? "visible" : "none",
        "icon-image": [
          "concat",
          [
            "case",
            [
              "all",
              ["has", "recentDetections"],
              ["to-boolean", ["get", "recentDetections"]],
            ],
            "detection",
            "base",
          ],
          "GliderIcon",
          [
            "case",
            [">=", ["get", "heading"], 180], "Left",
            ["<", ["get", "heading"], 180], "Right",
            "Right"
          ]
        ],
        "icon-size": 0.45,
        "icon-rotate": ["get", "heading"],
        "icon-allow-overlap": true
      }
    };
  }
};

const GLIDER_TRACKLINE_LAYER: LayerConfig<LayerType.Line> = {
  layerId: MiscLayer.GliderTracklines,
  sourceId: FeatureType.Gliders,
  factory: function (opts?: LayerParamOptions): LayerType.Line {
    const { visible } = fillInDefaultOptions(opts);

    return {
      id: MiscLayer.GliderTracklines,
      type: "line",
      source: FeatureType.Gliders,
      filter: [
        "all",
        ["==", ["get", "dataType"], "glider"],
        ["==", ["geometry-type"], "LineString"],
      ],
      layout: {
        "visibility": visible ? "visible" : "none",
      },
      paint: {
        "line-color": "#FFDD00",
        // Data-driven line-gradient not supported by mapbox yet.
        // Once they implement this, we can re-add this
        "line-gradient": [
          "interpolate",
          ["linear"],
          ["line-progress"],
          0,
          ["to-color", "#000000"],
          1,
          ["to-color", "#FFDD00"]
        ]
      }
    };
  }
};



export const GLIDER_LAYER_GROUP: LayerGroup = new TrackedFeatureLayerGroup({
  name: "gliders",
  featureLayer: GLIDER_LAYER,
  tracklineLayer: GLIDER_TRACKLINE_LAYER, 
});




