import { Geo, isTracklineRecord } from "./Geo";
import sortedIndexBy from "lodash/sortedIndexBy";
import round from "lodash/round";
import remove from "lodash/remove";
import { inPlaceSort } from "fast-sort";

import type { TimeDelta } from "../misc/TimeDelta";
import { FeatureId } from "./Types";


class TracklineContainer {
  public id: FeatureId;

  private _points: Geo.TracklinePoint[] = [];

  private _epochs: Record<number, boolean> = {};

  constructor(id: FeatureId, initPoints?: Geo.TracklinePoint[] | Record<string, Geo.TracklinePoint>) {
    this.id = id;

    if (initPoints) {
      if (isTracklineRecord(initPoints)) {
        this._points = Object.values(initPoints);
      } else if (Array.isArray(initPoints)) {
        this._points = initPoints;
      }

      this._points.forEach(point => {
        point.epoch = round(point.epoch, -1);
      });

      remove(this._points, (point => {
        if (this._epochs[point.epoch]) {
          return true;
        } else {
          this._epochs[point.epoch] = true;
        }
      }));

      // verify whatever we have passed in is sorted
      inPlaceSort(this._points).asc(point => point.epoch);
    }
  }

  public get length(): number { 
    return this._points.length;
  }

  public addNewPoint = (newPoint: Geo.TracklinePoint, checkBoundsFirst = true): void => {
    // If requested (and by default) check if the new point is older or newer
    // than what we have currently. Most points we recieve here should
    // fall in this category, so we can skip calculating the right index
    // if the new point falls outside the bounds of our current set of points.
    if (newPoint.point.length === 3) {
      newPoint.point.pop();
    }

    newPoint.epoch = round(newPoint.epoch, -1);

    if (this._epochs[newPoint.epoch]) {
      return;
    }

    // Compute the index to insert at,
    const insertIdx = this.computeInsertInfo(newPoint, checkBoundsFirst)[0];

    // If the point at that epoch already exists, just update the value
    // without inserting as a new point.
    if (this._points[insertIdx] &&
        this._points[insertIdx].epoch === newPoint.epoch) {
      this._points[insertIdx] = newPoint;
    } else {
      this._points.splice(insertIdx, 0, newPoint);
    }
  };

  public addManyPoints = (newPoints: Geo.TracklinePoint[] | Record<string, Geo.TracklinePoint>): void => {
    if (!Array.isArray(newPoints)) {
      newPoints = Object.values(newPoints);
    }

    if (newPoints.length === 0) return;

    // Remove any altitude points, since we don't need/want them to take
    // up more room than needed
    for (let idx = 0; idx < newPoints.length; idx++) {
      if (newPoints[idx].point.length === 3) {
        newPoints[idx].point.pop();
      }

      newPoints[idx].epoch = round(newPoints[idx].epoch, -1);
    }

    remove(newPoints, (point => {
      if (this._epochs[point.epoch]) {
        return true;
      } else {
        this._epochs[point.epoch] = true;
      }
    }));

    const allPoints = [...this._points, ...newPoints];

    inPlaceSort(allPoints).asc(point => point.epoch);

    this._points = allPoints;
  };

  private computeInsertInfo = (newPointOrEpoch: number | Geo.TracklinePoint, checkBoundsFirst = true): [number, boolean] => {
    // If we have no points, immediately return 0.
    if (this._points.length === 0) return [0, false];

    const newEpoch = typeof newPointOrEpoch === "number"
      ? newPointOrEpoch
      : newPointOrEpoch.epoch;

    if (checkBoundsFirst) {
      // Check if the new point is newer
      const lastEpoch = this.getNewestPoint() ?.epoch;
      if (lastEpoch && lastEpoch < newEpoch) {
        return [this._points.length, lastEpoch === newEpoch];
      }

      // Check if the new point is older
      const firstEpoch = this.getOldestPoint() ?.epoch;
      if (firstEpoch && newEpoch < firstEpoch) {
        return [0, firstEpoch === newEpoch];
      }
    }

    const insertIdx = sortedIndexBy<{ epoch: number }>(this._points, { epoch: newEpoch }, (t) => t.epoch);

    return [insertIdx, this._points[insertIdx].epoch === newEpoch];
  };

  public getRawPoints = (): Geo.TracklinePoint[] => {
    return this._points;
  };

  public getOldestPoint = (): null | Geo.TracklinePoint => {
    if (this._points.length === 0) { 
      return null;
    }

    return this._points[0];
  };

  public getNewestPoint = (): null | Geo.TracklinePoint => {
    if (this._points.length === 0) { 
      return null;
    }

    return this._points.at(-1) ?? null;
  };

  public getLineStringCoords = (td: TimeDelta): Geo.Coordinate[] => {
    // immediately return an empty array if we have no points
    if (this._points.length === 0) {
      return [];
    }

    const now = Date.now() / 1000;
    const oldEpochCutoff = now - td.old.delta;
    const newEpochCutoff = now - (td.new?.delta ?? 0);


    // if our cutoff is newer than our newest point, return
    // an empty array of points, since we don't have anything newer.
    const newestEpoch = this.getNewestPoint()?.epoch;

    // If our newest point is older than our cutoff, return an empty array
    if (newestEpoch && newestEpoch < oldEpochCutoff) {
      return [];
    }

    // Start with a null value, that way we know to return
    // an empty array if the cutoff if newer than our points.
    let startIdx: number | null = null;

    // if the cutoff is earlier than our current earliest point,
    // we know we're including every point, so start our index at 0.
    // Otherwise, compute it, and start from there. That will
    const oldestEpoch = this.getOldestPoint()?.epoch;

    if (oldestEpoch && oldEpochCutoff < oldestEpoch) {
      startIdx = 0;
    } else {
      const insertIndex = this.computeInsertInfo(oldEpochCutoff)[0];

      if (insertIndex) {
        startIdx = insertIndex;
      }
    }

    // If we couldnt compute an index, return an empty array,
    // since this means the cutoff is newer than our newest point.
    if (startIdx === null) { 
      return [];
    }

    // Create a container for our points.
    const lineString: Geo.Coordinate[] = [];

    // Iterate from the start index, and insert each point as we go.
    for (let idx = startIdx; idx < this._points.length; idx++) {
      const pt = this._points[idx];

      // once we hit one newer than our cutoff, break. Since these are ordered we know we can bail.
      if (pt.epoch > newEpochCutoff) {
        break;
      }

      lineString.push(this._points[idx].point);
    }

    return lineString;
  };
}

export default TracklineContainer;
