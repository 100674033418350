import { FeatureType, StatusDataType } from "./Types";
import { Geo } from "./Geo";
import { isNonEmptyRecord } from "./Utils";

const OkIcon = new URL("/public/static/icons/GreenGlobeOn.png", import.meta.url);
const PartialIcon = new URL("/public/static/icons/AmberGlobeOn.png", import.meta.url);
const ErrIcon = new URL("/public/static/icons/RedGlobeOn.png", import.meta.url);

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace StatusIcon {
  export const Type = {
    Error: "error",
    Ok: "ok",
    Partial: "partial",
  } as const;

  export type Icon<S extends keyof typeof Type> = {
    status: typeof Type[S];
    src: string;
  }

  export const Error = {
    status: Type.Error,
    src: ErrIcon,
  };

  export const Ok = {
    status: Type.Ok,
    src: OkIcon,
  };

  export const Partial = {
    status: Type.Partial,
    src: PartialIcon,
  };

  export type Union = typeof Error | typeof Ok | typeof Partial;
}

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace StatusTree {
  export const Stations = FeatureType.Stations;
  export type Stations = typeof Stations;

  export type StationTree = StatusRecord<Stations>;

  export const ThirdParty = "thirdparty";
  export type ThirdParty = typeof ThirdParty;

  export type ThirdPartyTree = {
    [source: string]: Status.ThirdParty | StatusRecord<ThirdParty>;
  }

  export const Tracklines = "tracklines";
  export type Tracklines = typeof Tracklines;

  export type TracklineTree = StatusRecord<Tracklines>;

  export type Union = Stations | ThirdParty | Tracklines;


  type StatusRecord<S extends StatusTree.Union> = (
    S extends StatusTree.Stations
    ? Record<string, Status.Station>
    : S extends StatusTree.ThirdParty
    ? Record<string, Status.ThirdParty>
    : S extends StatusTree.Tracklines
    ? Record<string, Status.Trackline>
    : never
  );
}


/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace Status {
  export interface Base {
    lastUpdated: number;
    id: string;
    name: string;
    statusText?: string;
    error?: string;
    errorCount?: number;
    firstEncountered?: number;
  }

  export interface MystiStatus {
    name: string;
    status: string;
    updateTime: string;
  }

  export interface Station extends Base {
    client: string;
    status?: MystiStatus[];
    appVersion?: string;
    machineName?: null | string;
    vesselActivity?: string;
    marineTrafficLink?: string;
  }

  export interface ThirdParty extends Base {
    source: string;
  }

  export type TracklineInfo = {
    earliestPointEpoch: number;
    lastPointsRemoved: number;
    lastSimplifyEpoch: number;
    totalPointsRemoved: number;
  }

  export interface Trackline {
    info: TracklineInfo;
    lastUpdate: Geo.TracklinePoint;
  }

  export type Union = Station | ThirdParty | Trackline;
}

export function isStationStatus(x: unknown): x is Status.Station {
  return Boolean((x as Status.Station)?.client);
}

export function isMystiStatus(x: unknown): x is Status.MystiStatus {
  if (!isNonEmptyRecord(x)) {
    return false;
  }

  return (
    typeof x.name === "string" &&
    typeof x.status === "string" &&
    typeof x.updateTime === "string"
  );
}

export function isThirdPartyStatus(x: unknown): x is Status.ThirdParty {
  return Boolean((x as Status.ThirdParty)?.id);
}

export function isTracklineStatus(x: unknown): x is Status.Trackline {
  return Boolean((x as Status.Trackline)?.lastUpdate);
}

export function isStatus<S extends Status.Union, ST extends StatusTree.Union>(x: unknown, typeOrTypes?: ST | ST[]): x is S {
  if (!typeOrTypes) {
    return isStationStatus(x) || isThirdPartyStatus(x) || isTracklineStatus(x);
  }

  if (Array.isArray(typeOrTypes)) {
    return typeOrTypes.some(t => isStatus(x, t));
  }

  switch (typeOrTypes) {
    case StatusTree.Stations:
      return isStationStatus(x);
    case StatusTree.ThirdParty:
      return isThirdPartyStatus(x);
    case StatusTree.Tracklines:
      return isTracklineStatus(x);
    default:
      throw new Error(`Unknown status tree type: ${typeOrTypes}`);
  }
}


export function getStatusIcon<S extends Status.Base>(statusContainer: S | S[] | Record<string, S>): StatusIcon.Union {

  if (isStatus(statusContainer)) {
    return (statusContainer as S)?.error
      ? StatusIcon.Error
      : StatusIcon.Ok;
  } else if (!Array.isArray(statusContainer)) {
    statusContainer = Object.values(statusContainer);
  }


  let errorCount = 0;

  for (const status of statusContainer) {
    if (status.error) errorCount += 1;
  }

  if (errorCount === 0 || statusContainer.length === 0) {
    return StatusIcon.Ok;
  } else if (statusContainer.length > errorCount) {
    return StatusIcon.Partial;
  }

  return StatusIcon.Error;
}
