/** Acoustic feature types (from Thayer Mahan projects) */

import { GeoType } from "../../misc/Geo";
import { FeatureType } from "../../misc/Types";
import { LayerGroup, MultiLayerGroup } from "../LayerGroup";
import { LayerConfig, LayerParamOptions, LayerType, MiscLayer } from "../LayerTypes";
import { fillInDefaultOptions, makeSimpleFilter } from "./common";



const ELLIPSE_LAYER: LayerConfig<LayerType.Fill> = {
  layerId: FeatureType.Ellipses,
  sourceId: FeatureType.Ellipses,
  factory: function(opts?: LayerParamOptions): LayerType.Fill {
    const { visible, timeDelta } = fillInDefaultOptions(opts);

    return {
      id: FeatureType.Ellipses,
      type: "fill",
      source: FeatureType.Ellipses,
      filter: makeSimpleFilter({ dataType: "ellipse", timeDelta }),
      layout: {
        "visibility": visible ? "visible" : "none"
      },
      paint: {
        "fill-color": ["get", "fillColor"],
        "fill-outline-color": ["get", "lineColor"]
      }
    };
  }
};

const ELLIPSE_OUTLINE_LAYER: LayerConfig<LayerType.Line> = {
  layerId: MiscLayer.EllipseOutlines,
  sourceId: FeatureType.Ellipses,
  factory: function(opts?: LayerParamOptions): LayerType.Line {
    const { visible, timeDelta } = fillInDefaultOptions(opts);

    return {
      id: MiscLayer.EllipseOutlines,
      type: "line",
      source: FeatureType.Ellipses,
      filter: makeSimpleFilter({ dataType: "ellipse", timeDelta }),
      layout: {
        "visibility": visible ? "visible" : "none"
      },
      paint: {
        "line-color": ["to-color", ["get", "lineColor"]],
        "line-width": [
          "case",
          ["has", "lineWidth"], ["get", "lineWidth"],
          0.25,
        ],
      }
    };
  }
};


const BEARING_LINE_LAYER: LayerConfig<LayerType.Line> = {
  layerId: FeatureType.BearingLines,
  sourceId: FeatureType.BearingLines,
  factory: function (opts?: LayerParamOptions): LayerType.Line {
    const { visible, timeDelta } = fillInDefaultOptions(opts);

    return {
      id: FeatureType.BearingLines,
      type: "line",
      source: FeatureType.BearingLines,
      filter: makeSimpleFilter({ dataType: "bearingLine", geometryType: GeoType.LineString, timeDelta }),
      layout: {
        "visibility": visible ? "visible" : "none",
      },
      paint: {
        "line-color": ["to-color", ["get", "lineColor"]],
        "line-width": [
          "case",
          ["has", "lineWidth"], ["get", "lineWidth"],
          2,
        ],
      }
    };
  }
};



const BEARING_LINE_OUTLINE_LAYER: LayerConfig<LayerType.Line> = {
  layerId: MiscLayer.BearingLineOutlines,
  sourceId: FeatureType.BearingLines,
  factory: function(opts?: LayerParamOptions): LayerType.Line {
    const { visible, timeDelta } = fillInDefaultOptions(opts);

    return {
      id: MiscLayer.BearingLineOutlines,
      type: "line",
      source: FeatureType.BearingLines,
      filter: makeSimpleFilter({ dataType: "bearingLine", geometryType: GeoType.LineString, timeDelta }),
      /*
            // no data type filter?
            filter: [
                "all",
                ["==", ["geometry-type"], "LineString"],
                ["has", "age"],
                ["<", ["get", "age"], Math.abs(maxAge)]
            ],
            */
      layout: {
        "visibility": visible ? "visible" : "none"
      },
      paint: {
        "line-color": "#1d1d1d",
        "line-width": 20,
        "line-opacity": 0.001,
      }
    };
  }
};


export const ACOUSTIC_GROUP: LayerGroup = new MultiLayerGroup({
  name: "acoustic",
  layers: [
    BEARING_LINE_LAYER,
    BEARING_LINE_OUTLINE_LAYER,
    ELLIPSE_LAYER,
    ELLIPSE_OUTLINE_LAYER,
  ],
});
