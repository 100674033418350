import { LayerConfig, LayerType } from "./LayerTypes";
import { NonEmptyArray } from "../misc/TypeUtils";


/** 
 * A container for groups of layers that should be treated like 1 big layer.
 *  
 * Each layer in said group should mostly all have the same data source, with the 
 * exception being for a feature that also has an associated trackline. 
 */ 
export interface LayerGroup {
    readonly name: string;

    /** 
     * This is a function, rather than a property, since we'll want to abstract between
     * different underlying containers.
     */
    iterLayers(): IterableIterator<LayerConfig<LayerType.Any>>;
}

/** A group consisting of just one layer */
export class SingleLayerGroup implements LayerGroup {
  readonly name: string;
    
  private readonly layer: LayerConfig<LayerType.Any>;

  constructor(layer: LayerConfig<LayerType.Any>) {
    this.layer = layer;
    this.name = layer.layerId;
  }

  *iterLayers(): IterableIterator<LayerConfig<LayerType.Any>> {
    yield this.layer;
  } 
}

export class MultiLayerGroup implements LayerGroup {
  readonly name: string;
  private readonly layers: NonEmptyArray<LayerConfig<LayerType.Any>>;

  constructor({
    name, 
    layers,
  }: {
        name: string,
        layers: NonEmptyArray<LayerConfig<LayerType.Any>>,
    }) {
    this.name = name;
    this.layers = layers;
  }

  iterLayers(): IterableIterator<LayerConfig<LayerType.Any>> {
    return this.layers[Symbol.iterator]();
  }
}


export class SightingLayerGroup implements LayerGroup {
  readonly name: string;
  readonly baseLayer: LayerConfig<LayerType.Any>;
  readonly withImagesLayer?: LayerConfig<LayerType.Any>;
  readonly priorityLayer?: LayerConfig<LayerType.Any>;
  readonly priorityWithImagesLayer?: LayerConfig<LayerType.Any>;

  constructor({ 
    name,
    baseLayer,
    withImagesLayer, 
    priorityLayer, 
    priorityWithImagesLayer 
  }: {
        name: string,
        baseLayer: LayerConfig<LayerType.Any>,
        withImagesLayer?: LayerConfig<LayerType.Any>,
        priorityLayer?: LayerConfig<LayerType.Any>,
        priorityWithImagesLayer?: LayerConfig<LayerType.Any>,
    }) {
    this.name = name;
    this.baseLayer = baseLayer;
    this.withImagesLayer = withImagesLayer;
    this.priorityLayer = priorityLayer;
    this.priorityWithImagesLayer = priorityWithImagesLayer;
  }


  *iterLayers(): IterableIterator<LayerConfig<LayerType.Any>> {
    yield this.baseLayer;

    if (this.priorityLayer) {
      yield this.priorityLayer;
    }

    if (this.withImagesLayer) {
      yield this.withImagesLayer;
    }

    if (this.priorityWithImagesLayer) {
      yield this.priorityWithImagesLayer;
    }
  }
}


export class TrackedFeatureLayerGroup implements LayerGroup {
  name: string;
  featureLayer: LayerConfig<LayerType.Any>;
  tracklineLayer: LayerConfig<LayerType.Any>;
    
  constructor({ 
    name,
    featureLayer, 
    tracklineLayer, 
  }: {
        name: string,
        featureLayer: LayerConfig<LayerType.Any>,
        tracklineLayer: LayerConfig<LayerType.Any>,
    }) {
    this.name = name;
    this.featureLayer = featureLayer;
    this.tracklineLayer = tracklineLayer;
  }

  *iterLayers(): IterableIterator<LayerConfig<LayerType.Any>> {
    yield this.featureLayer;
    yield this.tracklineLayer;
  }    
}


export class ConcatenatedGroup implements LayerGroup {
  name: string;
  groups: NonEmptyArray<LayerGroup>;

  constructor({
    name,
    groups,
  }: {
        name?: string,
        groups: NonEmptyArray<LayerGroup>,
    }) {
    this.name = name ?? groups.map(grp => grp.name).join("-");

    this.groups = groups;
  }

  *iterLayers(): IterableIterator<LayerConfig<LayerType.Any>> {
    for (const group of this.groups) {
      for (const layer of group.iterLayers()) {
        yield layer;
      }
    }    
  }
}
