import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Image } from "react-bootstrap";

import SetPassword from "../components/auth/SetPassword";
import SignIn from "../components/auth/SignIn";
import EulaModal from "../components/auth/EulaModal";
import { UsersAPI } from "../firebase/API";

import { getFileURL } from "../firebase/Storage";

import TopNav from "../components/nav/TopNav";
import StationList, { StationListProps } from "../components/status/StationList";
import ThirdPartyDataStatus from "../components/status/ThirdPartyDataStatus";
import FallbackOnError from "../components/misc/FallbackOnError";

import Dashboard from "../components/status/Dashboard";

import ProgressIndicator, { Progress } from "../components/misc/ProgressIndicator";

import {
  Action,
  StateDispatch,
  withState,
  StateProps,
} from "../provider/StateProvider";

import FirebaseAuth, { User } from "../firebase/FirebaseAuth";

import { parseUrlQuery } from "../misc/Utils";
import { MapDoc, EulaDoc } from "../misc/Types";

const mysti_logo = new URL("/public/static/images/mysticetus_logo.png", import.meta.url);



function acceptEula(
  user: User,
  eulaDoc: EulaDoc,
  dispatch: StateDispatch,
): Promise<void> {
  dispatch({ action: Action.OnLoadingStart });
  return UsersAPI.acceptEula(user.uid, eulaDoc.version).then(userDoc => {
    if (userDoc) {
      dispatch({ action: Action.RefreshUserDoc, userDoc, endLoading: true });
    } else {
      dispatch({ action: Action.OnLoadingEnd });
      console.error("invalid user document returned");
    }
  });
}


function Home(props: StateProps & Record<string, unknown>) {
  const parsedQuery = parseUrlQuery();
  const { state, dispatch } = props;

  const nav = useNavigate();

  const [showSignIn, setShowSignIn] = useState<boolean>(false);
  const [showSetPassword, setShowSetPassword] = useState<boolean>(false);
  const [showEula, setShowEula] = useState<boolean>(false);

  const [maps, setMaps] = useState<MapDoc[]>(Object.values(state.config?.maps ?? {}));

  // Pop up the sign in on our first mount
  useEffect(() => {
    if (!state.isLoading) {
      if (!state.user) {
        const validLink = FirebaseAuth.checkEmailSignInLink(window.location.href);

        if (validLink) {
          // Want to make sure this is actually the boolean value true, not
          // just a truthy value. That way a query parameter like:
          // "?newUser=anarbitrarystring" doesn't trigger a new user sign in
          if (parsedQuery.newUser === true) {
            setShowSetPassword(true);
          } else {
            // TODO: add a way for sign in links to just sign in, if setPassword
            // is not specified
          }
        } else {
          setShowSignIn(true);
        }
      } else if (state.config.eula.version !== state.config.userDoc.eulaVersion) {
        setShowEula(true);
      }
    }
  }, [state]);

  // Handle a sign out request
  useEffect(() => {
    if (!state.isLoading && state.user && parsedQuery.signOut === true) {
      FirebaseAuth.signOut(dispatch).then(() => {
        nav("/");
      });
    }

  }, [parsedQuery]);


  useEffect(() => {
    if (showSignIn) {
      document.title = "Mysticetus - Sign In";
    } else if (showSetPassword) {
      document.title = "Mysticetus - Password Reset";
    } else {
      setMaps(Object.values(state.config?.maps ?? {}));
      document.title = "Mysticetus";
    }

  }, [state, showSignIn, showSetPassword]);

  return (
    <>
      <TopNav openSignIn={() => setShowSignIn(true)} />
      <Container className="text-center my-4">
        {
          state.user?.email === "string"
            ? <p>{`Welcome ${state.user.email}`}</p>
            : null
        }
        {
          state.user && state.config
            ? <Container fluid>
              <FallbackOnError componentName='station-list' message='An error occured in the station status component'>
                <StationList />
              </FallbackOnError>

              {/*
                <StationStatusCards maps={Object.values(state.maps).map(cont => cont.doc)}/>
              <FallbackOnError componentName='StationStatus' message='An error occured in the station status component'>
                <StationStatus className="mb-4"/>
              </FallbackOnError>
              <FallbackOnError componentName='ThirdPartyDataStatus' message='An error occured in the third party data source component'>
                <ThirdPartyDataStatus className="mb-4"/>
              </FallbackOnError>
              */}
            </Container>
            : null
        }
        <a href="https://mysticetus.com" target="_blank" rel="noopener noreferrer">
          <Image id="home_logo" src={mysti_logo.href} alt="mysticetus.com"/>
        </a>
      </Container>
      <SignIn show={showSignIn} close={() => setShowSignIn(false)}/>
      <SetPassword nav={nav} show={showSetPassword} close={() => setShowSetPassword(false)}/>
      {
        state.user && state.config !== null
          ? <EulaModal show={showEula}
            title="Accept Terms of Service"
            confirmButtonText="Accept & Continue"
            cancelButtonText="Cancel & Sign Out"
            file={state.config.eula}
            onCancel={() => nav("/?signOut=true")}
            onConfirm={() => {
              acceptEula(state.user, state.config.eula, dispatch)
                .catch(err => alert(err));
            }}
            close={() => setShowEula(false)}/>
          : null
      }
    </>
  );
}

export default withState(Home);
