import React from "react";
import TopNav from "../../components/nav/TopNav";

const TracklineEditor = (): JSX.Element => {

  document.title = "Mysticetus - Trackline Editor";

  return (
    <>
      <TopNav title='Trackline Editor'/>
      <h1>TODO</h1>
    </>
  );
};

export default TracklineEditor;
