import React, { useState, useEffect } from "react";
import { Form, Row } from "react-bootstrap";

import MultiEmailInput from "../misc/MultiEmailInput";
import Alert from "../misc/Alert";

import LoadingSpinner from "../misc/LoadingSpinner";
import LoadingButton from "../misc/LoadingButton";
import MultipleSelect from "../misc/MultipleSelect";

import { UsersAPI } from "../../firebase/API";

import { stringSortFactory, formatDisplayList } from "../../misc/Utils";

import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

import type { MapDoc } from "../../misc/Types";


export interface CreateUsersProps {
  maps: Record<string, MapDoc>;
  currentUserEmail: string;
}

const CreateUsers = (props: React.PropsWithoutRef<CreateUsersProps>): JSX.Element => {

  const { maps, currentUserEmail } = props;

  const mapEntries = Object.entries(maps);

  mapEntries.sort(stringSortFactory({
    preproc: (x) => x[0].toLowerCase()
  }));

  const defaultSelections = {};

  mapEntries.forEach(mapEntry => {
    defaultSelections[mapEntry[0]] = false;
  });

  const [emails, setEmails] = useState<string[]>([]);

  const [selected, setSelected] = useState<{[key: string]: boolean}>(cloneDeep(defaultSelections));

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [emailsValid, setEmailsValid] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [miscError, setMiscError] = useState<string|null>(null);
  const [responseMessage, setResponseMessage] = useState<string|null>(null);


  const onSelectionChanged = (id: string, value: boolean) => {
    setSelected(curr => {
      return { ...curr, [id]: value };
    });
  };

  const submitNewUsers = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubmitLoading(true);

    UsersAPI.createPendingUsers(currentUserEmail, emails, selected)
      .then(() => {
        const respMessage = emails.length === 1
          ? `Created user: ${emails[0]}`
          : `Created users: ${formatDisplayList(emails)}`;

        setResponseMessage(respMessage);
        setEmails([]);
      }).catch((error: Error) => {
        setMiscError(`Encountered an error while writing pending users: ${error.message}`);
      }).finally(() => {
        setSubmitLoading(false);
      });
  };

  useEffect(() => {
    let selectionsValid = false;

    for (const key in selected) {
      if (typeof key === "string" && selected[key] === true) {
        selectionsValid = true;
        break;
      }
    }


    const inputsOk = selectionsValid && emailsValid && !submitLoading;

    setSubmitDisabled(!inputsOk);
  }, [selected, emailsValid, submitLoading]);

  const selectors = {
    id: (mapEntry: [string, MapDoc]): string => mapEntry[0],
    displayName: (mapEntry: [string, MapDoc]): string => mapEntry[1].displayName ?? mapEntry[1],
  };

  return (
    <Form onSubmit={submitNewUsers}>
      <Form.Group controlId='emailForm'>
        <Form.Label>Emails</Form.Label>
        <MultiEmailInput setEmailsValid={setEmailsValid}
          emails={emails}
          submitLoading={submitLoading}
          setEmails={setEmails}/>
      </Form.Group>
      <hr/>
      <Row className="mx-0">
        <Form.Label>Map Permissions</Form.Label>
      </Row>
      <Row className="mx-0">
        {
          Array.isArray(mapEntries) && defaultSelections && !isEmpty(defaultSelections)
            ? <MultipleSelect onSelectionChanged={onSelectionChanged}
              defaultSelections={defaultSelections}
              values={mapEntries}
              idSelector={selectors.id}
              displaySelector={selectors.displayName}/>
            : <LoadingSpinner/>
        }
      </Row>
      <hr/>
      <Row className="mx-0 mb-3 justify-content-between">
        <div>
          <Alert className='p-1 m-1' show={emails.length > 1} variant='primary'>
            *All users will be created with the same permissions*
          </Alert>
          <Alert className="p-1 m-1" show={responseMessage !== null} closeHandler={() => setResponseMessage(null)} variant="success">
            { responseMessage }
          </Alert>
          <Alert className='p-1 m-1' show={typeof miscError === "string"} variant='danger'>
            { miscError }
          </Alert>
        </div>
        <LoadingButton type='submit' disabled={submitDisabled} isLoading={submitLoading}>
          { emails.length > 1 ? "Create Users" : "Create User" }
        </LoadingButton>
      </Row>
    </Form>
  );
};

export default CreateUsers;
