import { GeoType } from "./Geo";

import {
  GeoJson,
  FirestoreGeoJson,
  typeGuards,
} from "./GeoJson";

import { FeatureType } from "../misc/Types";

import { calculateAge, ageCutoff, slowZoneExpired } from "./Utils";


// NOAA DMAs/Slow Zones update thier slow zones not by updating them,
// but by adding a new feature with a new id, but with the same name.
export function extractAndFormatGeoJsonRecord(geoType: GeoType, features: Record<string, unknown>): Record<string, GeoJson.Feature<typeof geoType>> {
  const formatted: Record<string, GeoJson.Feature<typeof geoType>> = {};

  const nameToIdMap: Record<string, string> = {};

  const ids = Object.keys(features);

  for (const id of ids) {
    // Grab the correctly formatted feature out
    const feature = extractAndFormatGeoJson(geoType, features[id]);

    // Skip if the feature is null
    if (!feature) continue;

    // Skip any non-sightings that are older than the cutoff
    if (feature.properties.dataType !== FeatureType.Sightings &&
        feature.properties.age &&
        feature.properties.age > ageCutoff) {
      continue;
    }


    // If we arent a slow zone, add it to the container and skip the rest
    // of the checks in this loop
    if (feature.properties.dataType !== FeatureType.SlowZones) {
      formatted[id] = feature;
      continue;
    }

    // If we're an expired slow zone, skip this feature.
    if (feature.properties.dataType === FeatureType.SlowZones && slowZoneExpired(feature)) {
      continue;
    }

    const name = feature.properties.name;

    let existingFeat: GeoJson.BaseFeature;
    let existingEpoch: number;
    let newEpoch: number;

    if (nameToIdMap[name]) {
      existingFeat = features[nameToIdMap[name]] as GeoJson.BaseFeature;
      existingEpoch = existingFeat.properties.epoch;

      newEpoch = feature.properties.epoch;

      // Grab the ID of the most updated feature (biggest epoch)
      const validId = existingEpoch < newEpoch
        ? id
        : nameToIdMap[name];

      // Replace the existing geojson with the newer one (might be the same)
      formatted[validId] = features[validId] as GeoJson.Feature;
    } else {
      // If there wasnt an existing feature, add its name/id to the
      // map + main container
      nameToIdMap[name] = id;
      formatted[id] = feature;
    }
  }

  return formatted;
}

export function extractAndFormatGeoJson(geoType: GeoType, feature: unknown): null | GeoJson.Feature {
  if (!feature) return null;

  // Cast/Convert to normal GeoJson as needed.
  const fixedFeature = typeGuards.isFirestoreGeoJson(feature)
    ? formatFirestoreToGeoJson(feature)
    : feature as GeoJson.Feature;

  switch (geoType) {
    case GeoType.Point:
      if (!typeGuards.isGeoJsonPoint(fixedFeature)) {
        return null;
      } 
      break;
    case GeoType.LineString:
      if (!typeGuards.isGeoJsonLineString(fixedFeature)) {
        return null;
      }
      break;
    case GeoType.Polygon:
      if (!typeGuards.isGeoJsonPolygon(fixedFeature)) {
        return null;
      }
      break;
    default:
      return null;
  }

  fixedFeature.properties.age = calculateAge(fixedFeature);

  return fixedFeature as GeoJson.Feature<typeof geoType>;
}

function formatFirestoreToGeoJson(firestoreGeoJson: FirestoreGeoJson.Feature): GeoJson.Feature {
  return {
    ...firestoreGeoJson,
    geometry: fixFirestoreGeometry(firestoreGeoJson.geometry)
  };
}


function fixFirestoreGeometry(geom: FirestoreGeoJson.Geometry): GeoJson.Geometry<typeof geom.type> {
  let fixedCoords: GeoJson.Coord<typeof geom.type>;
  switch (geom.type) {
    // if we're a point, can cast directly to the regular geojson geometry
    // format and skip everything else
    case GeoType.Point:
      return geom;
    case GeoType.LineString:
      fixedCoords = flattenFirestoreCoords(geom.coordinates);
      break;
    case GeoType.Polygon:
      fixedCoords = flattenFirestoreCoords(geom.coordinates).map(flattenFirestoreCoords);
      break;
  }

  return {
    coordinates: fixedCoords,
    type: geom.type,
  } as GeoJson.Geometry<typeof geom.type>;
}


function flattenFirestoreCoords<T>(rec: Record<string, T>|T[]): T[] {
  if (Array.isArray(rec)) return rec;

  const rawKeys = Object.keys(rec);

  const keys = Array.from(Array(rawKeys.length));

  for (let idx = 0; idx < rawKeys.length; idx++) {
    keys[idx] = {
      parsed: parseInt(rawKeys[idx]),
      key: rawKeys[idx]
    };
  }

  keys.sort((a, b) => a.parsed - b.parsed);

  return keys.map(keyBlob => rec[keyBlob.key]);
}
