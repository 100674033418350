import React, { useState, useEffect } from "react";

import { Image, ImageProps, Spinner, SpinnerProps } from "react-bootstrap";

import { getFileURL } from "../../firebase/Storage";


interface BaseLazyImageProps extends Omit<ImageProps, "src"> {
  spinnerVariant?: SpinnerProps["variant"];
  gcsPath?: string;
  src?: string;
  file?: File;
}

interface SrcLazyImageProps extends BaseLazyImageProps {
  src: string;
}

interface UnloadedLazyImageProps extends BaseLazyImageProps {
  gcsPath: string;
}

interface LoadedLazyImageProps extends BaseLazyImageProps {
  file: File;
}

export type LazyImageProps =
  SrcLazyImageProps
  | LoadedLazyImageProps
  | UnloadedLazyImageProps;


function initialImageUrl(srcOrFile?: string | File): string | null {
  return typeof srcOrFile === "string"
    ? srcOrFile
    : srcOrFile instanceof File
      ? URL.createObjectURL(srcOrFile)
      : null;
}

function LazyImage(props: LazyImageProps): JSX.Element {
  const { src, gcsPath, file, spinnerVariant, ...imageProps } = props;

  const [imageUrl, setImageUrl] = useState<null | string>(
    initialImageUrl(src ?? file)
  );

  const [isLoading, setIsLoading] = useState<boolean>(typeof gcsPath === "string");

  useEffect(() => {
    if (typeof gcsPath === "string") {
      setIsLoading(true);
      getFileURL(gcsPath)
        .then(url => setImageUrl(url))
        .catch(err => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [gcsPath]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-middle align-middle"
        style={{ height: imageProps.height, width: imageProps.width }}>
        <Spinner animation="border" variant={spinnerVariant ?? "primary"}/>
      </div>
    );
  } else if (typeof imageUrl !== "string") {
    return <h1>Error loading image</h1>;
  }

  return <Image {...imageProps} src={imageUrl}/>;
}


export default LazyImage;
