import React, { useState, useMemo, useEffect, useCallback } from "react";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { range } from "lodash";

import { Button, Modal, Placeholder } from "react-bootstrap";

import Alert from "../misc/Alert";
import LoadingButton from "../misc/LoadingButton";
import { EulaDoc, isEulaDoc } from "../../misc/Types";
import { randInt } from "../../misc/Utils";

import { getFileURL } from "../../firebase/Storage";



function buildPlaceholderRow(idx: number): JSX.Element {
  const cols = randInt(1, 4);

  return (
    <Placeholder animation="glow" as={Modal.Title} key={idx}
      className="my-1 d-flex flex justify-content-start">
      {
        range(cols).map(colIdx => (
          <React.Fragment key={colIdx}>
            <Placeholder xs={randInt(2 + cols, 6 - cols)} />
            <span className={`mx-${randInt(1, 3)}`}/>
          </React.Fragment>
        ))
      }
    </Placeholder>
  );
}

export interface EulaModalProps {
  file: string | File | EulaDoc;
  title: string;
  show?: boolean;
  close: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  disableConfirmButton?: boolean;
  disableCancelButton?: boolean;
  hideCancelButton?: boolean;
  hideConfirmButton?: boolean;
  requireView?: boolean;
  onConfirm?: (() => void) | (() => Promise<unknown>);
  onCancel?: (() => void) | (() => Promise<unknown>);
}

function EulaModal(props: React.PropsWithChildren<EulaModalProps>): JSX.Element {
  const show = useMemo(() => props.show ?? false, [props.show]);

  const closeModal = useCallback(() => {
    props.close();
    setHasViewedEula(false);
    setError(null);
  }, [props.close]);

  const [file, setFile] = useState<null | string | File>(
    isEulaDoc(props.file) ? null : props.file
  );

  const [docLoading, setDocLoading] = useState<boolean>(true);

  const [pageIdx, setPageIdx] = useState<number>(0);
  const [pageCount, setPageCount] = useState<null | number>(null);

  const [hasViewedEula, setHasViewedEula] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const onConfirmClicked = useCallback(() => {
    if (typeof props.onConfirm === "function") {
      setButtonLoading(true);
      const result = props.onConfirm();
      if (result instanceof Promise) {
        result.catch(err => setError(err.toString()))
          .finally(() => setButtonLoading(false));
      } else {
        setButtonLoading(false);
      }
    }

    closeModal();
  }, [props.onConfirm, closeModal]);


  const handleKeyPress = useCallback((event: KeyboardEvent | MouseEvent) => {
    if (docLoading || typeof pageCount !== "number") {
      return;
    }

    if ("key" in event) {
      switch (event.key) {
        case "ArrowLeft":
        case "ArrowUp":
          setPageIdx(curr => Math.min(pageCount, Math.max(0, curr - 1)));
          break;
        case "ArrowRight":
        case "ArrowDown":
        case "Space":
          setPageIdx(curr => Math.min(pageCount, Math.max(0, curr + 1)));
          break;
        default:
          break;
      }
    }
  }, [pageCount, docLoading]);





  const onCancelClicked = useCallback(() => {
    if (typeof props.onCancel === "function") {
      setButtonLoading(true);
      const result = props.onCancel();
      if (result instanceof Promise) {
        result.catch(err => setError(err.toString()))
          .finally(() => setButtonLoading(false));
      } else {
        setButtonLoading(false);
      }
    }

    closeModal();
  }, [props.onCancel, closeModal]);

  useEffect(() => {
    if (isEulaDoc(props.file)) {
      getFileURL(props.file.path)
        .then(file => setFile(file))
        .catch(err => console.error(err));
    } else {
      setDocLoading(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);

    return function() {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (!hasViewedEula &&
        !docLoading &&
        typeof pageCount === "number" &&
        pageIdx === pageCount) {
      setHasViewedEula(true);
    }
  }, [docLoading, pageCount, pageIdx, hasViewedEula]);

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mb-2">
          {
            docLoading
              ? <div className="d-flex w-75 flex-column justify-content-start">
                {range(0, 20).map(buildPlaceholderRow)}
              </div>
              : null
          }
          {
            file !== null
              ? <Document file={file}
                loading={""}
                onLoadSuccess={(args) => {
                  setPageCount(args.numPages - 1);
                  setDocLoading(false);
                }}
                onLoadError={(args) => {
                  setError(args.message);
                  setDocLoading(false);
                }}>
                <Page pageIndex={pageIdx}/>
              </Document>
              : null
          }
        </div>
        <Alert variant="danger" show={typeof error === "string"}>
          {error}
        </Alert>
        <div className="d-flex justify-content-around">
          <Button disabled={typeof pageCount !== "number" || pageIdx == 0}
            onClick={() => setPageIdx(Math.max(0, pageIdx - 1))}>
            Prev. Page
          </Button>
          <div>
            Page {pageIdx + 1} of {typeof pageCount === "number" ? pageCount + 1 : "???"}
          </div>
          <Button disabled={typeof pageCount !== "number" || pageIdx == pageCount}
            onClick={() => setPageIdx(Math.min(pageIdx + 1, pageCount ?? 0))}>
            Next Page
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer className="w-100 justify-content-center">
        { props.children ?? null }
        <div className="d-flex justify-content-center w-100">
          {
            props.hideConfirmButton
              ? null
              : <LoadingButton variant="primary"
                isLoading={buttonLoading}
                onClick={onConfirmClicked}
                disabled={props.disableConfirmButton || !(hasViewedEula || !props.requireView)}>
                { props.confirmButtonText ?? "Confirm" }
              </LoadingButton>
          }
          {
            props.hideCancelButton || props.hideConfirmButton
              ? null
              : <div className="p-1"/>
          }
          {
            props.hideCancelButton
              ? null
              : <LoadingButton variant="secondary"
                isLoading={buttonLoading}
                onClick={onCancelClicked}
                disabled={props.disableCancelButton}>
                { props.cancelButtonText ?? "Cancel" }
              </LoadingButton>
          }
        </div>
        <Alert variant="danger" show={typeof error === "string"}>
          {error}
        </Alert>
      </Modal.Footer>
    </Modal>
  );
}


export default EulaModal;
