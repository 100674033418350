import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import FormControl from "react-bootstrap/FormControl";
import FormCheck from "react-bootstrap/FormCheck";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

import PaginationWrapper, { PaginationWrapperProps } from "../misc/PaginationWrapper";
import ManageUser from "./ManageUser";

import type { MapDoc, UserDoc } from "../../misc/Types";

import { UsersAPI } from "../../firebase/API";

import { stringSortFactory, levenshteinEmailSort } from "../../misc/Utils";

const sortByMap = {
  "Email": stringSortFactory<UserDoc>({
    preproc: s => s.email,
  }),
  "Email Domain": stringSortFactory<UserDoc>({
    preproc: s => s.email.slice(s.email.lastIndexOf("@")),
  })
};

const sortKeys = Object.keys(sortByMap);

const defaultSort = sortKeys[0];

export type ManageExistingUsersProps = React.PropsWithoutRef<{
  maps: Record<string, MapDoc>;
  currentUserEmail: string;
}>;

const ManageExistingUsers = (props: ManageExistingUsersProps): JSX.Element => {
  const { maps, currentUserEmail } = props;
  const [rawUsers, setRawUsers] = useState<Record<string, UserDoc>>({});

  const [sortedUsers, setSortedUsers] = useState<UserDoc[]>([]);

  const [searchString, setSearchString] = useState("");

  const [sortDisabled, setSortDisabled] = useState(false);
  const [sortKey, setSortKey] = useState(defaultSort);
  const [sortAscending, setSortAscending] = useState(true);

  const [paginationProps, setPaginationProps] = useState<PaginationWrapperProps<UserDoc>>();


  const formatPage = (userSubset: UserDoc[]): null|JSX.Element => {
    if (!maps) return null;

    const mapEntries = Object.entries(maps);

    return (
      <Table striped bordered size='sm' responsive className='sticky no-padding'>
        <thead>
          <tr className='sticky-row text-center top'>
            <th className='sticky-cell left'><div>Users</div></th>
            {
              mapEntries.map(mapEntry => (
                <th key={`${mapEntry[1].displayName}-label`}>
                  <a href={`/map/${mapEntry[0]}`} target='_blank' rel="noopener noreferrer">
                    {mapEntry[1].displayName}
                  </a>
                </th>
              ))
            }
            <th className='sticky-cell right'>
              <div>
                User Enabled
              </div>
            </th>
            <th className='sticky-cell right'>
              <div>
                Delete User
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            userSubset.map(user => (
              <tr className="text-center" key={user.uid}>
                <ManageUser user={user} reloadUsers={reloadUsers} maps={mapEntries.map(ent => ent[0])}
                  currentUserEmail={currentUserEmail}/>
              </tr>
            ))
          }
        </tbody>
      </Table>
    );
  };

  // On the first render, get all user docs
  useEffect(() => {
    if (isEmpty(rawUsers)) {
      reloadUsers();
    }
  }, []);


  const reloadUsers = (): Promise<void> => {
    return UsersAPI.getAll()
      .then(userRecord => setRawUsers(userRecord))
      .catch(error => console.error(error));
  };

  // Whenever the raw listener 'users' or the maps are loaded, trigger this
  // to set the raw data.
  useEffect(() => {
    if (!isEmpty(rawUsers) && !isEmpty(maps)) {
      try {
        const sorted = Object.values(cloneDeep(rawUsers));

        const useSearchString = searchString.length > 0;

        setSortDisabled(useSearchString);

        if (useSearchString) {
          const levSearchArgs = {
            strings: sorted,
            searchString: searchString,
            strSelector: (u: UserDoc) => u.email
          };

          levenshteinEmailSort<UserDoc>(levSearchArgs).then(searched => {
            setSortedUsers(searched);
          });

          return;
        } else if (sortByMap[sortKey]) {
          sorted.sort(sortByMap[sortKey]);

          if (!sortAscending) {
            sorted.reverse();
          }
        }

        setSortedUsers(sorted);
      } catch (err) {
        console.error(err);
      }
    }
  }, [rawUsers, maps, searchString, sortKey, sortAscending]);

  useEffect(() => {
    if (sortedUsers.length > 0) {
      setPaginationProps({
        data: sortedUsers,
        pageFormatFunc: formatPage,
      });
    }
  }, [sortedUsers]);

  return (
    <>
      <Row className='medium-padding'>
        <Col className='left-align width-content'>
          <Dropdown>
            <Dropdown.Toggle disabled={sortDisabled}>
              { `Sort by ${sortKey}` }
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                sortKeys.map(key => (
                  <Dropdown.Item key={key} active={key === sortKey}
                    onClick={() => setSortKey(key)}>
                    {key}
                  </Dropdown.Item>
                ))
              }
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className='left-align vertical-center'>
          <FormCheck checked={sortAscending} disabled={sortDisabled}
            onChange={() => setSortAscending(!sortAscending)}
            label='Ascending order'/>
        </Col>
        <Col>
          <FormControl placeholder='Search emails'
            onChange={(e) => setSearchString(e.target.value)}/>
        </Col>
      </Row>
      <Row className='medium-padding'>
        <PaginationWrapper maxPerPage={15} {...paginationProps}/>
      </Row>
    </>
  );
};

export default ManageExistingUsers;
