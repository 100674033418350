import React, { useState, useCallback, useMemo, useEffect } from "react";

import { Form, Dropdown, FloatingLabel } from "react-bootstrap";

import { ClientDoc, isClientDoc } from "../../misc/Types";

import ConfigModal from "../misc/ConfigModal";

import LazyImage from "../misc/LazyImage";

import { GcsFile } from "../../firebase/Storage";

import { randInt, Valid, allValid } from "../../misc/Utils";

function randColor(): string {
  const randHex = () => randInt(0, 255).toString(16);

  return `#${randHex()}${randHex()}${randHex()}`;
}


export function emptyClientDoc(): ClientDoc {
  return {
    id: "",
    clientDisplayName: "",
    clientColor: randColor(),
    iconPath: undefined,
  };
}


interface BaseClientConfigProps {
  show?: boolean;
  onConfirm: (clientDoc: ClientDoc, newFile?: File) => Promise<unknown>;
  icons: GcsFile[];
  onCancel: () => unknown;
  close: () => void;
}


interface EditClientConfigProps extends BaseClientConfigProps {
  mode: "edit";
  clientDoc: ClientDoc;
}

interface NewClientConfigProps extends BaseClientConfigProps {
  mode: "new";
  clientDoc?: null | ClientDoc;
}

/// Lets us handle null/undef mode in parent components without throwing type
/// errors.
interface HiddenClientConfigProps extends BaseClientConfigProps {
  mode?: null | unknown;
  clientDoc?: null | ClientDoc;
}

export type ClientConfigProps =
  HiddenClientConfigProps
  | EditClientConfigProps
  | NewClientConfigProps;


function checkFields(clientDoc: ClientDoc): Valid<ClientDoc> {
  return {
    id: clientDoc.id.length > 0,
    clientDisplayName: clientDoc.clientDisplayName.length > 0,
    clientColor: clientDoc.clientColor.length === 7, // # + 6 hex chars
  };
}

enum IconRefType {
  Upload,
  FromExisting,
  Unset,
}


type IconSrc = { gcsPath: string } | File | GcsFile;

function ClientConfig(props: ClientConfigProps): JSX.Element {
  const [clientDoc, setClientDoc] = useState<ClientDoc>(
    props.mode === "edit" && isClientDoc(props.clientDoc)
      ? props.clientDoc
      : emptyClientDoc()
  );

  const [autofillDisplayName, setAutofillDisplayName] = useState<boolean>(
    props.mode === "new"
  );

  const [unsaved, setUnsaved] = useState<boolean>(false);

  const [iconRefType, setIconRefType] = useState<IconRefType>(IconRefType.Upload);
  const [iconFile, setIconFile] = useState<null | GcsFile | File>(null);

  const fieldValidity = useMemo(() => checkFields(clientDoc), [clientDoc]);
  const isValid = useMemo(() => allValid(fieldValidity), [fieldValidity]);

  const show = useMemo(() => props.show ?? false, [props.show]);

  const currentIcon = useMemo((): null | IconSrc => {
    if (typeof clientDoc.iconPath === "string") {
      return { gcsPath: clientDoc.iconPath };
    }

    if (iconFile !== null) {
      return iconFile;
    }

    return null;
  }, [clientDoc, iconFile]);

  const setField = useCallback(
    <K extends keyof ClientDoc, V = ClientDoc[K]>(key: K, value: V) => {
      setClientDoc(current => {
        return { ...current, [key]: value };
      });
    },
    []
  );

  const handleIcon = useCallback(
    (eventOrGCSPath: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof eventOrGCSPath === "string") {
        setIconFile(eventOrGCSPath);
      } else {
        const file = eventOrGCSPath.target.files?.item(0) ?? null;

        if (!file) {
          console.error("no file attached");
          return;
        }

        if (!file.type.startsWith("image")) {
          console.error("not an image");
          return;
        }

        setIconFile(file);
      }
    },
    [setField]
  );

  useEffect(() => {
    if ((iconRefType === IconRefType.Unset && clientDoc.iconPath !== undefined)
        || iconFile !== null) {
      setUnsaved(true);
    } else if (iconRefType !== IconRefType.Unset && iconFile === null) {
      setUnsaved(false);
    }

  }, [clientDoc, iconFile, iconRefType]);

  const onConfirm = useCallback((): Promise<unknown> => {
    if (!unsaved) {
      return Promise.resolve();
    }

    setUnsaved(false);
    let confirmCall: Promise<unknown> = Promise.resolve();
    if (iconRefType === IconRefType.Unset) {
      confirmCall = props.onConfirm({ ...clientDoc, iconPath: undefined });
    } else if (iconFile instanceof File) {
      confirmCall = props.onConfirm(clientDoc, iconFile);
    } else if (iconFile !== null && "downloadUrl" in iconFile) {
      confirmCall = props.onConfirm({
        ...clientDoc,
        iconPath: `clientIcons/${iconFile.name}`
      });
    }

    return confirmCall;
  }, [unsaved, props.onConfirm, clientDoc, iconFile, iconRefType, props.close]);

  return (
    <ConfigModal show={show}
      size="lg"
      disableConfirm={!isValid}
      onConfirm={onConfirm}
      closeOnConfirm
      close={props.close}
      onCancel={props.onCancel}
      saved={!unsaved}
      setSaved={() => setUnsaved(false)}
      title={
        props.mode === "edit"
          ? `Edit ${clientDoc.id}`
          : "Configure a new Client"
      }>
      <Form>
        <Form.Group controlId="id-input">
          <Form.Label>Client Id</Form.Label>
          <FloatingLabel label="Id/Domain">
            <Form.Control type="text" isInvalid={!fieldValidity.id}
              disabled={props.mode === "edit"}
              value={clientDoc.id}
              onChange={(e) => {
                setField("id", e.target.value);

                if (autofillDisplayName) {
                  setField("clientDisplayName", e.target.value);
                }
              }}/>
          </FloatingLabel>
          <Form.Text muted>
            This value must exactly match the <b>Domain</b> field, under the
            Command Center Realtime Import settings in the Mysticetus Client.
            Once set, this value cannot be changed without breaking document
            linkages
          </Form.Text>
        </Form.Group>
        <hr className="my-2"/>
        <Form.Group controlId="display-name-input">
          <Form.Label>Client Display Name</Form.Label>
          <FloatingLabel label="Client Display Name">
            <Form.Control type="text" isInvalid={!fieldValidity.clientDisplayName}
              value={clientDoc.clientDisplayName}
              onFocus={() => autofillDisplayName && setAutofillDisplayName(false)}
              onBlur={() => {
                clientDoc.clientDisplayName.length === 0 &&
                  props.mode === "new" &&
                  setAutofillDisplayName(true);
              }}
              onChange={(e) => setField("clientDisplayName", e.target.value)}/>
          </FloatingLabel>
          <Form.Text muted>
            The client name that end-users are shown
          </Form.Text>
        </Form.Group>
        <hr className="my-2"/>
        <Form.Group controlId="color-input">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Form.Label>Associated Color</Form.Label>
            <Form.Control type="color"
              value={clientDoc.clientColor}
              disabled={props.mode === "edit"}
              onChange={(e) => setField("clientColor", e.target.value)}/>
          </div>
          <Form.Text muted>
            The border color for Vessel/Sighting icons + Vessel tracklines as
            rendered by Command Center maps
          </Form.Text>
        </Form.Group>
        <hr className="my-2"/>
        <Form.Group controlId="icon-input">
          <Form.Label>Client Icon (Optional)</Form.Label>
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            {
              currentIcon === null
                ? <span className="text-muted">No icon currently set</span>
                : <>
                  <div>Current Icon:</div>
                  <div className="w-100 d-flex justify-content-center">
                    {
                      currentIcon === null
                        ? null
                        : "gcsPath" in currentIcon
                          ? <LazyImage fluid width="50%" gcsPath={currentIcon.gcsPath}/>
                          : "downloadUrl" in currentIcon
                            ? <LazyImage fluid width="50%" src={currentIcon.downloadUrl} />
                            : <LazyImage fluid width="50%" file={currentIcon} />
                    }
                  </div>
                </>
            }
          </div>
          <div>Set a new Icon</div>
          <div className="w-auto" onClick={() => setIconRefType(IconRefType.Upload)}>
            <Form.Check type="radio" label="New Icon Upload"
              checked={iconRefType === IconRefType.Upload}
              onChange={(e) => e.target.checked && setIconRefType(IconRefType.Upload)}/>
          </div>
          <div className="w-auto" onClick={() => setIconRefType(IconRefType.FromExisting)}>
            <Form.Check type="radio" label="From Existing Icons"
              checked={iconRefType === IconRefType.FromExisting}
              onChange={(e) => e.target.checked && setIconRefType(IconRefType.FromExisting)}/>
          </div>
          <div className="w-auto" onClick={() => setIconRefType(IconRefType.Unset)}>
            <Form.Check type="radio" label="Unset Icon"
              disabled={clientDoc.iconPath === undefined}
              checked={iconRefType === IconRefType.Unset}
              onChange={(e) => e.target.checked && setIconRefType(IconRefType.Unset)}/>
          </div>
          <div className="mt-2 d-flex justify-content-center w-100">
            {
              iconRefType === IconRefType.Upload
                ? <Form.Control type="file" onChange={handleIcon}/>
                : iconRefType === IconRefType.FromExisting
                  ? <Dropdown>
                    <Dropdown.Toggle>
                        Select Existing Icon
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        props.icons.length === 0
                          ? <Dropdown.Item disabled>
                                No existing icons found
                          </Dropdown.Item>
                          : props.icons.map((icon, idx) => (
                            <Dropdown.Item key={idx}
                              onClick={() => setIconFile(icon)}
                              className="d-flex flex-column justify-content-center border-top border-bottom">
                              <div className="w-100 px-2">
                                <div className="mx-auto px-1">
                                  {icon.name}
                                </div>
                              </div>
                              <LazyImage fluid className="w-100" src={icon.downloadUrl}/>
                            </Dropdown.Item>
                          ))
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  : null
            }
          </div>
          <Form.Text muted>
            The optional icon associated with this client
          </Form.Text>
        </Form.Group>
      </Form>
    </ConfigModal>
  );
}


export default ClientConfig;
