import { FeatureType } from "../misc/Types";
import { Geo, GeoType, isGeo } from "../misc/Geo";
import { GeoJson, isGeoJson } from "../misc/GeoJson";

import * as Sentry from "@sentry/react";

import TracklineContainer from "../misc/TracklineContainer";

import cloneDeep from "lodash/cloneDeep";
import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
// import remove from "lodash/remove";

import { openDB, IDBPDatabase, DBSchema } from "idb";

import { getPerformance, trace } from "firebase/performance";
import { Seconds, TimeDelta } from "../misc/TimeDelta";

const performance = getPerformance();

const currentCacheVersion: number = 4;

const indexedDBName = "command-center-data-cache";


export type UpdateContainer<D> = {
  data: D;
  lastUpdated: number;
}

export type EpochBounds = {
  min: number;
  max: number;
}

export interface GeoJsonCacheOpts {
  maxAge?: number;
  minAge?: number;
  clients?: true | string | string[]
}

export interface IndexedDBStore<D extends FeatureType, ValueType> {
  key: string; // geojson/trackline id
  value: ValueType;
  indexes: {
    "epoch": D extends FeatureType.LeaseAreas ? never : number;
    "client": D extends FeatureType.Sightings ? string : never;
  },
}

export interface TracklineRecord {
  id: string;
  points: Geo.TracklinePoint[];
}

export interface TracklineStoreContainer {
  key: string;
  value: TracklineRecord;
}

export interface PersistentCacheSchema extends DBSchema {
  [FeatureType.BearingLines]: IndexedDBStore<FeatureType.BearingLines, GeoJson.LineStringFeature>;
  [FeatureType.Buoys]: IndexedDBStore<FeatureType.Buoys, GeoJson.PointFeature>;
  [FeatureType.Ellipses]: IndexedDBStore<FeatureType.Ellipses, GeoJson.PolygonFeature>;
  [FeatureType.FishingGear]: IndexedDBStore<FeatureType.FishingGear, GeoJson.PointFeature>;
  [FeatureType.Gliders]: IndexedDBStore<FeatureType.Gliders, GeoJson.PointFeature>;
  [FeatureType.LeaseAreas]: IndexedDBStore<FeatureType.LeaseAreas, GeoJson.PolygonFeature>;
  [FeatureType.Sightings]: IndexedDBStore<FeatureType.Sightings, GeoJson.PointFeature>;
  [FeatureType.SlowZones]: IndexedDBStore<FeatureType.SlowZones, GeoJson.PolygonFeature>;
  tracklines: TracklineStoreContainer;
}

export type PersistentCachable = 
  FeatureType.BearingLines 
  | FeatureType.Buoys 
  | FeatureType.Ellipses 
  | FeatureType.FishingGear 
  | FeatureType.Gliders 
  | FeatureType.LeaseAreas 
  | FeatureType.Sightings 
  | FeatureType.SlowZones
  | "tracklines";


// InputType => recieved by addOrUpdate
// ContainerType  => contained as the values in this._data
// OutputType => returned in the getAll + getByXXXX methods
export abstract class BaseCache<Opts, InputType, ContainerType=InputType, OutputType=InputType> {
  public abstract dataType: PersistentCachable | FeatureType.Stations;
  protected _data: Record<string, ContainerType> = {};

  abstract addOrUpdate(dataRecord: Record<string, InputType>): boolean;
  abstract addOrUpdate(id: string, data: InputType): boolean;
  abstract getAll(opts?: Opts): Record<string, OutputType>;
  abstract getById(id: string, opts?: Opts): null | OutputType;
  abstract getByIds(ids: true | string[], opts?: Opts): Record<string, OutputType>;

  abstract getPersistentData(): Record<string, InputType|ContainerType|OutputType>;
  abstract loadPersistentData(data: Record<string, unknown>): void;

  public getIds = (): string[] => {
    return Object.keys(this._data);
  };

  public numFeats = (): number => Object.keys(this._data).length;

  public clearCache = (): void => {
    for (const id in this._data) {
      delete this._data[id];
    }
  };
}

export interface IListenerCache {
  isListenerRunning(key: string): boolean;
  setListener(key: string, unsub: () => void): void;
  clearListeners(excludeKey?: string): void;
}

export abstract class BaseGeoJsonCache<GT extends GeoType, Opts> extends BaseCache<Opts, GeoJson.Feature<GT>> {
  public abstract dataType: PersistentCachable | FeatureType.Stations;
  public abstract geoType: GeoType;

  abstract addOrUpdate(idOrRecord: string | Record<string, GeoJson.Feature<GT>>, data?: GeoJson.Feature<GT>): boolean;
  abstract getByClients(client: true | string | string[], opts?: Opts): Record<string, GeoJson.Feature<GT>>;

  public getPersistentData = (): Record<string, GeoJson.Feature<GT>> => {
    return cloneDeep(this._data);
  };

  public loadPersistentData = (dataRecord: Record<string, unknown>): void => {
    let geoJson: unknown;
    for (const id in dataRecord) {
      geoJson = dataRecord[id];

      if (isGeoJson(geoJson) && geoJson.geometry.type === this.geoType) {
        this._data[id] = geoJson;
      }
    }
  };
}

export abstract class GeoJsonCache<GT extends GeoType> extends BaseGeoJsonCache<GT, never> {
  public addOrUpdate = (idOrRecord: string | Record<string, GeoJson.Feature<GT>>, data?: GeoJson.Feature<GT>): boolean => {
    let isUpdate = false;
    if (typeof idOrRecord === "string" && data?.geometry?.type === this.geoType) {
      if (!data) throw new Error("Must specify data if idOrRecord is an Id (string)");

      isUpdate = Boolean(this._data[idOrRecord]);

      this._data[idOrRecord] = data;
    } else if (isObject(idOrRecord) && !isEmpty(idOrRecord)) {
      for (const id in idOrRecord) {
        // Skip if we dont have the correct geoType
        if (idOrRecord[id]?.geometry?.type !== this.geoType) {
          continue;
        }

        isUpdate = isUpdate || Boolean(this._data[id]);
        this._data[id] = idOrRecord[id];
      }
    }

    return isUpdate;
  };

  public getAll = (): Record<string, GeoJson.Feature<GT>> => {
    return this._data;
  };

  public getById = (id: string): null | GeoJson.Feature<GT> => {
    return this._data[id] ?? null;
  };

  public getByIds = (ids: true | string[]): Record<string, GeoJson.Feature<GT>> => {
    if (typeof ids === "boolean") {
      return this.getAll();
    }

    const out: Record<string, GeoJson.Feature<GT>> = {};

    for (const id of ids) {
      if (this._data[id]) {
        out[id] = this._data[id];
      }
    }

    return out;
  };

  public getByClients = (clients: true | string | string[]): Record<string, GeoJson.Feature<GT>> => {
    if (clients === true) return this.getAll();

    const out: Record<string, GeoJson.Feature<GT>> = {};

    let dataClient: string;
    for (const id in this._data) {
      dataClient = this._data[id].properties.client;
      // If we either have the same client, or are included in an array of clients
      if ((typeof clients === "string" && dataClient === clients) ||
          (clients.includes(dataClient))) {
        out[id] = this._data[id];
      }
    }

    return out;
  };
}

export abstract class GeoJsonEpochRangeCache<GT extends GeoType, Opts> extends BaseGeoJsonCache<GT, Opts> {
  public lastUpdated = 0;
  public cacheEpochBounds?: EpochBounds;

  public addOrUpdate = (idOrRecord: string | Record<string, GeoJson.Feature<GT>>, data?: GeoJson.Feature<GT>): boolean => {

    let isUpdate = false;
    if (typeof idOrRecord === "string") {
      if (!data) {
        console.error(`${this.dataType} cache must have data if specifying an Id`);
        return false;
      }

      this.addOrUpdateInner(idOrRecord, data);
    } else {
      let feature: GeoJson.Feature<GT>;
      for (const id in idOrRecord) {
        feature = idOrRecord[id];

        isUpdate = this.addOrUpdateInner(id, feature) || isUpdate;
      }
    }

    this.lastUpdated = Date.now() / 1000;

    return isUpdate;
  };

  private addOrUpdateInner = (id: string, data: GeoJson.Feature<GT>): boolean => {
    const isUpdate = Boolean(this._data[id]);

    if (typeof data.id !== "string") {
      data.id = data.properties.id;
    }

    this._data[id] = data;

    const newEpoch = data.properties.epoch;
    if (!this.cacheEpochBounds) {
      this.cacheEpochBounds = { min: newEpoch, max: newEpoch };
    } else if (this.cacheEpochBounds.max < newEpoch) {
      this.cacheEpochBounds.max = newEpoch;
    } else if (this.cacheEpochBounds.min > newEpoch) {
      this.cacheEpochBounds.min = newEpoch;
    }

    return isUpdate;
  };

  public getCacheBoundsByClients = (clients: true | string | string[]): null | EpochBounds => {
    if (clients === true) {
      return cloneDeep(this.cacheEpochBounds) ?? null;
    }

    if (typeof clients === "string") {
      clients = [clients];
    }

    if (clients.length === 0) return null;

    // we shouldnt have anything older than 1970, or newer than 1000x the unix
    // epoch, since we use an epoch in seconds instead of ms.
    const bounds = cloneDeep(this.cacheEpochBounds) ?? { min: 0, max: Date.now() };

    let featsFound = false;

    let feature: GeoJson.Feature;
    for (const id in this._data) {
      feature = this._data[id];

      if (feature && clients.includes(feature.properties.client)) {
        bounds.min = Math.max(bounds.min, feature.properties.epoch);
        bounds.max = Math.min(bounds.max, feature.properties.epoch);
        featsFound = true;
      }
    }

    return featsFound ? bounds : null;
  };
}

export abstract class ListenerCache<Opts, In, Cont=In, Out=In> extends BaseCache<Opts, In, Cont, Out> implements IListenerCache {
  protected _listeners: Record<string, { unsub: () => void }> = {};

  public isListenerRunning = (key: string): boolean => {
    return Boolean(this._listeners[key] ?.unsub);
  };

  public setListener = (key: string, unsub: () => void): void => {
    if (this._listeners[key]) {
      this._listeners[key].unsub();
    }

    this._listeners[key] = { unsub: unsub };
  };

  public clearListeners = (excludeKey?: string): void => {
    for (const key in this._listeners) {
      if (key !== excludeKey && this._listeners[key]) {
        this._listeners[key].unsub();
        delete this._listeners[key];
      }
    }
  };
}

export abstract class GeoJsonListenerCache<GT extends GeoType> extends GeoJsonCache<GT> implements IListenerCache {
  protected _listeners: Record<string, { unsub: () => void }> = {};

  public isListenerRunning = (key: string): boolean => {
    return Boolean(this._listeners[key] ?.unsub);
  };

  public setListener = (key: string, unsub: () => void): void => {
    if (this._listeners[key]) {
      this._listeners[key].unsub();
    }

    this._listeners[key] = { unsub: unsub };
  };

  public clearListeners = (excludeKey?: string): void => {
    for (const key in this._listeners) {
      if (key !== excludeKey && this._listeners[key]) {
        this._listeners[key].unsub();
        delete this._listeners[key];
      }
    }
  };
}


/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace Cache {
  export class BearingLine extends GeoJsonEpochRangeCache<GeoType.LineString, GeoJsonCacheOpts> {
    dataType: FeatureType.BearingLines = FeatureType.BearingLines;
    geoType: GeoType.LineString = GeoType.LineString;

    public getAll = (opts?: GeoJsonCacheOpts): Record<string, GeoJson.LineStringFeature> => {
      if (!opts || isEmpty(opts)) {
        return this._data;
      } else if (Array.isArray(opts.clients)) {
        return this.getByClients(opts.clients, opts);
      }

      const out: Record<string, GeoJson.LineStringFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts.minAge ? now - opts.minAge : null;

      let feature: GeoJson.LineStringFeature;

      for (const id in this._data) {
        feature = this._data[id];

        // Check start and stop epochs, and continue/skip to the next iteration
        // if either check fails.
        if ((startCutoff && startCutoff > feature.properties.epoch) ||
            (endCutoff && endCutoff < feature.properties.epoch)) {
          continue;
        }
        // If we make it here, add the feature
        out[id] = feature;
      }

      return out;
    };

    public getById = (id: string): null | GeoJson.LineStringFeature => {
      return this._data[id] ?? null;
    };

    public getByIds = (ids: true | string[], opts?: GeoJsonCacheOpts): Record<string, GeoJson.LineStringFeature> => {
      if (typeof ids === "boolean") {
        return this.getAll();
      }

      const out: Record<string, GeoJson.LineStringFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts && opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts && opts.minAge ? now - opts.minAge : null;

      let feature: GeoJson.LineStringFeature;
      for (const id of ids) {
        if (!this._data[id]) continue;

        feature = this._data[id];

        // Check start and stop epochs, and continue/skip to the next iteration
        // if either check fails.
        if ((startCutoff && startCutoff > feature.properties.epoch) ||
            (endCutoff && endCutoff < feature.properties.epoch)) {
          continue;
        }

        out[id] = feature;
      }

      return out;
    };

    public getByClients = (
      clients: true | string | string[],
      opts?: GeoJsonCacheOpts,
    ): Record<string, GeoJson.LineStringFeature> => {
      if (clients === true) return this.getAll();

      const getByClientTrace = trace(performance, "GET_BY_CLIENTS");
      getByClientTrace.putAttribute("clients", JSON.stringify(clients));
      getByClientTrace.start();

      const out: Record<string, GeoJson.LineStringFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts && opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts && opts.minAge ? now - opts.minAge : null;

      let dataClient: string;
      let feature: GeoJson.LineStringFeature;

      for (const id in this._data) {
        dataClient = this._data[id].properties.client;
        // If we either have the same client, or are included in an array of clients
        if ((typeof clients === "string" && dataClient === clients) ||
            (clients.includes(dataClient))) {
          feature = this._data[id];

          // Check start and stop epochs, and continue/skip to the next iteration
          // if either check fails.
          if ((startCutoff && startCutoff > feature.properties.epoch) ||
              (endCutoff && endCutoff < feature.properties.epoch)) {
            continue;
          }
          // If we make it through both conditionals, add the feature
          out[id] = feature;
        }
      }
      getByClientTrace.stop();
      getByClientTrace.incrementMetric("numFeats", Object.keys(out).length);
      return out;
    };
  }

  export class Buoy extends GeoJsonCache<GeoType.Point> {
    dataType: FeatureType.Buoys = FeatureType.Buoys;
    geoType: GeoType.Point = GeoType.Point;
  }

  export class Ellipse extends GeoJsonEpochRangeCache<GeoType.Polygon, GeoJsonCacheOpts> {
    dataType: FeatureType.Ellipses = FeatureType.Ellipses;
    geoType: GeoType.Polygon = GeoType.Polygon;

    public getAll = (opts?: GeoJsonCacheOpts): Record<string, GeoJson.PolygonFeature> => {
      if (!opts || isEmpty(opts)) {
        return this._data;
      } else if (Array.isArray(opts.clients)) {
        return this.getByClients(opts.clients, opts);
      }

      const out: Record<string, GeoJson.PolygonFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts.minAge ? now - opts.minAge : null;

      let feature: GeoJson.PolygonFeature;

      for (const id in this._data) {
        feature = this._data[id];

        // Check start and stop epochs, and continue/skip to the next iteration
        // if either check fails.
        if ((startCutoff && startCutoff > feature.properties.epoch) ||
            (endCutoff && endCutoff < feature.properties.epoch)) {
          continue;
        }
        // If we make it here, add the feature
        out[id] = feature;
      }

      return out;
    };

    public getById = (id: string): null | GeoJson.PolygonFeature => {
      return this._data[id] ?? null;
    };

    public getByIds = (ids: true | string[], opts?: GeoJsonCacheOpts): Record<string, GeoJson.PolygonFeature> => {
      if (typeof ids === "boolean") {
        return this.getAll();
      }

      const out: Record<string, GeoJson.PolygonFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts && opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts && opts.minAge ? now - opts.minAge : null;

      let feature: GeoJson.PolygonFeature;
      for (const id of ids) {
        if (!this._data[id]) continue;

        feature = this._data[id];

        // Check start and stop epochs, and continue/skip to the next iteration
        // if either check fails.
        if ((startCutoff && startCutoff > feature.properties.epoch) ||
            (endCutoff && endCutoff < feature.properties.epoch)) {
          continue;
        }

        out[id] = feature;
      }

      return out;
    };

    public getByClients = (
      clients: true | string | string[],
      opts?: GeoJsonCacheOpts,
    ): Record<string, GeoJson.PolygonFeature> => {
      if (clients === true) return this.getAll();

      const getByClientTrace = trace(performance, "GET_BY_CLIENTS");
      getByClientTrace.putAttribute("clients", JSON.stringify(clients));
      getByClientTrace.start();

      const out: Record<string, GeoJson.PolygonFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts && opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts && opts.minAge ? now - opts.minAge : null;

      let dataClient: string;
      let feature: GeoJson.PolygonFeature;

      for (const id in this._data) {
        dataClient = this._data[id].properties.client;
        // If we either have the same client, or are included in an array of clients
        if ((typeof clients === "string" && dataClient === clients) ||
            (clients.includes(dataClient))) {
          feature = this._data[id];

          // Check start and stop epochs, and continue/skip to the next iteration
          // if either check fails.
          if ((startCutoff && startCutoff > feature.properties.epoch) ||
              (endCutoff && endCutoff < feature.properties.epoch)) {
            continue;
          }
          // If we make it through both conditionals, add the feature
          out[id] = feature;
        }
      }
      getByClientTrace.stop();
      getByClientTrace.incrementMetric("numFeats", Object.keys(out).length);
      return out;
    };
  }

  export class Glider extends GeoJsonCache<GeoType.Point> {
    dataType: FeatureType.Gliders = FeatureType.Gliders;
    geoType: GeoType.Point = GeoType.Point;
  }

  export class LeaseArea extends GeoJsonCache<GeoType.Polygon> {
    dataType: FeatureType.LeaseAreas = FeatureType.LeaseAreas;
    geoType: GeoType.Polygon = GeoType.Polygon;
  }


  export class Sighting extends GeoJsonEpochRangeCache<GeoType.Point, GeoJsonCacheOpts> {
    dataType: FeatureType.Sightings = FeatureType.Sightings;
    geoType: GeoType.Point = GeoType.Point;

    withImages: Set<number> = new Set();

    public getAll = (opts?: GeoJsonCacheOpts): Record<string, GeoJson.PointFeature> => {
      /*
      const getAllTrace = performance.trace("GET_ALL_SIGHTINGS");
      getAllTrace.putAttribute("GET_ALL_OPTS", JSON.stringify(opts));

      getAllTrace.start();
      */

      if (!opts || isEmpty(opts)) {
        // getAllTrace.stop();
        // getAllTrace.incrementMetric("N_FEATS", this.numFeats());
        return this._data;
      } else if (Array.isArray(opts.clients)) {
        const results = this.getByClients(opts.clients, opts);
        // getAllTrace.stop();
        // getAllTrace.incrementMetric("N_FEATS", Object.keys(results).length);

        return results;
      }

      const out: Record<string, GeoJson.PointFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts.minAge ? now - opts.minAge : null;

      let feature: GeoJson.PointFeature;

      let imgId = 0;
      this.withImages.clear();

      for (const id in this._data) {
        feature = this._data[id];

        // Check start and stop epochs, and continue/skip to the next iteration
        // if either check fails.
        if ((startCutoff && startCutoff > feature.properties.epoch) ||
            (endCutoff && endCutoff < feature.properties.epoch)) {
          continue;
        }

        if (Array.isArray(feature.properties.images) &&
            feature.properties.images.length > 0) {
          feature.id = imgId;
          this.withImages.add(feature.id);

          imgId += 1;
        }

        // If we make it here, add the feature
        out[id] = feature;
      }

      // getAllTrace.stop();
      // getAllTrace.incrementMetric("N_FEATS", Object.keys(out).length);

      return out;
    };

    public getById = (id: string): null | GeoJson.PointFeature => {
      return this._data[id] ?? null;
    };

    public getByIds = (ids: true | string[], opts?: GeoJsonCacheOpts): Record<string, GeoJson.PointFeature> => {
      if (typeof ids === "boolean") {
        return this.getAll();
      }

      const out: Record<string, GeoJson.PointFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts && opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts && opts.minAge ? now - opts.minAge : null;

      let feature: GeoJson.PointFeature;
      for (const id of ids) {
        if (!this._data[id]) continue;

        feature = this._data[id];

        // Check start and stop epochs, and continue/skip to the next iteration
        // if either check fails.
        if ((startCutoff && startCutoff > feature.properties.epoch) ||
            (endCutoff && endCutoff < feature.properties.epoch)) {
          continue;
        }

        out[id] = feature;
      }

      return out;
    };

    public getByClients = (clients: true | string | string[], opts?: GeoJsonCacheOpts): Record<string, GeoJson.PointFeature> => {
      if (clients === true) return this.getAll();

      const getByClientTrace = trace(performance, "GET_BY_CLIENTS");
      getByClientTrace.putAttribute("clients", JSON.stringify(clients));
      getByClientTrace.start();

      const out: Record<string, GeoJson.PointFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts && opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts && opts.minAge ? now - opts.minAge : null;

      let dataClient: string;
      let feature: GeoJson.PointFeature;

      let imgId = 0;
      this.withImages.clear();

      for (const id in this._data) {
        dataClient = this._data[id].properties.client;
        // If we either have the same client, or are included in an array of clients
        if ((typeof clients === "string" && dataClient === clients) ||
            (clients.includes(dataClient))) {
          feature = this._data[id];

          // Check start and stop epochs, and continue/skip to the next iteration
          // if either check fails.
          if ((startCutoff && startCutoff > feature.properties.epoch) ||
              (endCutoff && endCutoff < feature.properties.epoch)) {
            continue;
          }

          if (Array.isArray(feature.properties.images) &&
              feature.properties.images.length > 0) {
            feature.id = imgId;
            imgId += 1;

            this.withImages.add(feature.id);
          }

          // If we make it through both conditionals, add the feature
          out[id] = feature;
        }
      }
      getByClientTrace.stop();
      getByClientTrace.incrementMetric("numFeats", Object.keys(out).length);
      return out;
    };
  }


  export class FishingGear extends GeoJsonEpochRangeCache<GeoType.Point, GeoJsonCacheOpts> {
    dataType: FeatureType.FishingGear = FeatureType.FishingGear;
    geoType: GeoType.Point = GeoType.Point;

    public getAll = (opts?: GeoJsonCacheOpts): Record<string, GeoJson.PointFeature> => {
      if (!opts || isEmpty(opts)) {
        return this._data;
      } else if (Array.isArray(opts.clients)) {
        return this.getByClients(opts.clients, opts);
      }

      const out: Record<string, GeoJson.PointFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts.minAge ? now - opts.minAge : null;

      let feature: GeoJson.PointFeature;
      for (const id in this._data) {
        feature = this._data[id];

        // Check start and stop epochs, and continue/skip to the next iteration
        // if either check fails.
        if ((startCutoff && startCutoff > feature.properties.epoch) ||
          (endCutoff && endCutoff < feature.properties.epoch)) {
          continue;
        }
        // If we make it here, add the feature
        out[id] = feature;
      }
      return out;
    };

    public getById = (id: string): null | GeoJson.PointFeature => {
      return this._data[id] ?? null;
    };

    public getByIds = (ids: true | string[], opts?: GeoJsonCacheOpts): Record<string, GeoJson.PointFeature> => {
      if (typeof ids === "boolean") {
        return this.getAll();
      }

      const out: Record<string, GeoJson.PointFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts && opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts && opts.minAge ? now - opts.minAge : null;

      let feature: GeoJson.PointFeature;
      for (const id of ids) {
        if (!this._data[id]) continue;

        feature = this._data[id];

        // Check start and stop epochs, and continue/skip to the next iteration
        // if either check fails.
        if ((startCutoff && startCutoff > feature.properties.epoch) ||
          (endCutoff && endCutoff < feature.properties.epoch)) {
          continue;
        }

        out[id] = feature;
      }

      return out;
    };

    public getByClients = (clients: true | string | string[], opts?: GeoJsonCacheOpts): Record<string, GeoJson.PointFeature> => {
      if (clients === true) { 
        return this.getAll(); 
      }

      const out: Record<string, GeoJson.PointFeature> = {};

      const now = Date.now() / 1000;

      const startCutoff = opts && opts.maxAge ? now - opts.maxAge : null;
      const endCutoff = opts && opts.minAge ? now - opts.minAge : null;

      let dataClient: string;
      let feature: GeoJson.PointFeature;

      for (const id in this._data) {
        dataClient = this._data[id].properties.client;
        // If we either have the same client, or are included in an array of clients
        if ((typeof clients === "string" && dataClient === clients) ||
          (clients.includes(dataClient))) {
          feature = this._data[id];

          // Check start and stop epochs, and continue/skip to the next iteration
          // if either check fails.
          if ((startCutoff && startCutoff > feature.properties.epoch) ||
            (endCutoff && endCutoff < feature.properties.epoch)) {
            continue;
          }

          // If we make it through both conditionals, add the feature
          out[id] = feature;
        }
      }
      
      return out;
    };
  }

  export class SlowZone extends GeoJsonCache<GeoType.Polygon> {
    dataType: FeatureType.SlowZones = FeatureType.SlowZones;
    geoType: GeoType.Polygon = GeoType.Polygon;
  }

  export class Station extends GeoJsonListenerCache<GeoType.Point> {
    dataType: FeatureType.Stations = FeatureType.Stations;
    geoType: GeoType.Point = GeoType.Point;
  }



  export class Trackline extends ListenerCache<TimeDelta, Geo.TracklinePoint[], TracklineContainer, Geo.Coordinate[]> {
    dataType: "tracklines" = "tracklines";

    public addNewPoints = (id: string, points: Geo.TracklinePoint | Geo.TracklinePoint[] | Record<string, Geo.TracklinePoint>): void => {
      if (isGeo.tracklinePoint(points)) {
        points = [points];
      }

      if (!this._data[id]) {
        this._data[id] = new TracklineContainer(id, points);
      } else if (isGeo.tracklineRecord(points) || Array.isArray(points)) {
        this._data[id].addManyPoints(points);
      } else {
        throw new Error("Could not determine data type of points input");
      }
    };

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    public addOrUpdate = (id: string | Record<string, Geo.TracklinePoint[]>): boolean => {
      throw new Error("Use addNewPoints for adding new trackline data, not addOrUpdate");
    };

    public getAll = (td?: TimeDelta): Record<string, Geo.Coordinate[]> => {
      const out: Record<string, Geo.Coordinate[]> = {};

      for (const id of Object.keys(this._data)) {
        out[id] = this._data[id].getLineStringCoords(td ?? { new: null, old: { delta: 0 as Seconds, label: "current" } });
      }

      return out;
    };

    public getById = (id: string, td?: TimeDelta): null | Geo.Coordinate[] => {
      return this._data[id]
        ? this._data[id].getLineStringCoords(td ?? { new: null, old: { delta: 0 as Seconds, label: "current" } })
        : null;
    };

    public getByIds = (ids: true | string[], td?: TimeDelta): Record<string, Geo.Coordinate[]> => {
      if (typeof ids === "boolean") {
        return this.getAll(td);
      }

      const out: Record<string, Geo.Coordinate[]> = {};

      for (const id of ids) {
        if (this._data[id]) {
          out[id] = this._data[id].getLineStringCoords(td ?? { new: null, old: { delta: 0 as Seconds, label: "current" } });
        }
      }

      return out;
    };

    public getOldestEpoch = (id: string): null | number => {
      const container = this._data[id];

      if (!container) return null;

      const firstPoint = container.getOldestPoint();

      if (!firstPoint) return null;

      return firstPoint.epoch;
    };

    public getNewestEpoch = (id: string): null | number => {
      const container = this._data[id];

      if (!container) return null;

      const lastPoint = container.getNewestPoint();

      if (!lastPoint) return null;

      return lastPoint.epoch;
    };

    public getPersistentData = (): Record<string, Geo.TracklinePoint[]> => {
      const out: Record<string, Geo.TracklinePoint[]> = {};

      for (const id in this._data) {
        out[id] = this._data[id].getRawPoints();
      }

      return out;
    };

    public loadPersistentData = (dataRecord: Record<string, unknown>): void => {

      let trackContainer: undefined | TracklineContainer;
      let cachedTrackPoints: undefined | unknown;
      for (const id in dataRecord) {
        trackContainer = this._data[id];

        cachedTrackPoints = dataRecord[id];

        if (!Array.isArray(cachedTrackPoints) || cachedTrackPoints.length === 0) {
          continue;
        }

        if (isGeo.tracklineArray(cachedTrackPoints)) {
          if (trackContainer) {
            trackContainer.addManyPoints(cachedTrackPoints);
          } else {
            this._data[id] = new TracklineContainer(id, cachedTrackPoints);
          }
        }
      }
    };
  }

  export type Union = (
    Buoy | Glider | Ellipse | FishingGear | LeaseArea | Sighting | SlowZone | Station | Trackline
  );

  export type ListenerUnion = Station | Trackline;
}


export interface DataCacheOpts {
  clients?: true|string[];
  initSightingTimeDelta: TimeDelta;
  leaseAreaIds?: true|string[];
}


class DataCache {
  private _initialLoadPromise: Promise<void>;

  private _indexedDB: null | IDBPDatabase<PersistentCacheSchema> = null;
  private _indexedDBAvailable: boolean = false;
  private _idxDBLoading: boolean = false;

  private _sightingTimeDelta: TimeDelta;
  private _leaseAreaIds?: true | string[];

  public readonly persistentCaches: PersistentCachable[] = [
    FeatureType.BearingLines,
    FeatureType.Buoys,
    FeatureType.Ellipses,
    FeatureType.Gliders,
    FeatureType.LeaseAreas,
    FeatureType.Sightings,
    FeatureType.FishingGear,
    "tracklines",
  ];

  public readonly [FeatureType.BearingLines] = new Cache.BearingLine();
  public readonly [FeatureType.Buoys] = new Cache.Buoy();
  public readonly [FeatureType.Ellipses] = new Cache.Ellipse();
  public readonly [FeatureType.Gliders] = new Cache.Glider();
  public readonly [FeatureType.LeaseAreas] = new Cache.LeaseArea();
  public readonly [FeatureType.Sightings] = new Cache.Sighting();
  public readonly [FeatureType.SlowZones] = new Cache.SlowZone();
  public readonly [FeatureType.FishingGear] = new Cache.FishingGear();
  public readonly [FeatureType.Stations] = new Cache.Station();
  public readonly tracklines = new Cache.Trackline();

  constructor(opts: DataCacheOpts) {
    this._sightingTimeDelta = opts.initSightingTimeDelta;
    this._leaseAreaIds = opts.leaseAreaIds;

    // Disable indexed DB / persistent caching until I can refactor this file
    this._initialLoadPromise = /* this.loadIndexedDB() */ Promise.resolve(null).then(db => {
      this._indexedDB = db;
      this._indexedDBAvailable = db !== null;

      if (this._indexedDBAvailable) {
        return this.sequentialLoad();
      }
    }).catch(error => {
      Sentry.captureException(error);
    });
  }

  public setSighingTimeDelta = (td: TimeDelta): Promise<void> => {
    this._sightingTimeDelta = td;

    if (!this._indexedDBAvailable || !this._indexedDB) {
      return Promise.resolve();
    }

    const now = Date.now() / 1000;

    const oldEpochCutoff = now - this._sightingTimeDelta.old.delta;
    const newEpochCutoff = now - (this._sightingTimeDelta.new?.delta ?? 0);

    const newQuery = IDBKeyRange.bound(newEpochCutoff, oldEpochCutoff);

    const sgtQuery = this._indexedDB.getAllFromIndex(FeatureType.Sightings, "epoch", newQuery);
    const fishGearQuery = this._indexedDB.getAllFromIndex(FeatureType.FishingGear, "epoch", newQuery);
    const ellipseQuery = this._indexedDB.getAllFromIndex(FeatureType.Ellipses, "epoch", newQuery);
    const bearingLineQuery = this._indexedDB.getAllFromIndex(FeatureType.BearingLines, "epoch", newQuery);

    return Promise.all([sgtQuery, fishGearQuery, ellipseQuery, bearingLineQuery])
      .then(results => {
        // Weird type inference is interpreting AnyFeature as an intersection rather than a union,
        // this type alias is just so we can cast and make the type error go away.
        type FeatureHack = GeoJson.LineStringFeature & GeoJson.PointFeature & GeoJson.PolygonFeature;

        for (const feature of results.flat()) {
          feature.properties.age = now - feature.properties.epoch;
          this[feature.properties.dataType].addOrUpdate(feature.properties.id, feature as FeatureHack);
        }
      }).catch(error => {
        Sentry.captureException(error);
      });
  };

  private loadIndexedDB = (): Promise<null | IDBPDatabase<PersistentCacheSchema>> => {
    if (this._idxDBLoading) {
      return Promise.reject(new Error("IndexedDB is already loading"));
    }

    this._idxDBLoading = true;

    const persistentCaches = cloneDeep(this.persistentCaches);

    const timeoutProm: Promise<null> = new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error("could not open indexedDB within 5 seconds")), 5000);
    });

    const openDbPromise = openDB<PersistentCacheSchema>(indexedDBName, currentCacheVersion, {
      upgrade(db, oldVersion, newVersion, transaction) {
        console.log("upgrade", db, oldVersion, newVersion, transaction);

        for (const cacheType of persistentCaches) {
          if (cacheType === "tracklines") continue;

          if (db.objectStoreNames.contains(cacheType)) {
            console.log(`deleting store: ${cacheType}`);
            db.deleteObjectStore(cacheType);
          }

          if (!db.objectStoreNames.contains(cacheType)) {
            const geoJsonStore = db.createObjectStore(cacheType, {
              keyPath: "properties.id",
            });

            if (cacheType !== FeatureType.LeaseAreas) {
              geoJsonStore.createIndex("epoch", "properties.epoch");
            }

            if (cacheType === FeatureType.Sightings 
              || cacheType === FeatureType.FishingGear 
              || cacheType === FeatureType.Ellipses 
              || cacheType === FeatureType.BearingLines) {
              geoJsonStore.createIndex("client", "properties.client");
            }
          }
        }

        if (db.objectStoreNames.contains("tracklines")) {
          db.deleteObjectStore("tracklines");
        }

        db.createObjectStore("tracklines", { keyPath: "id" });
      },
      blocked() {
        console.log("blocked");
      },
      blocking() {
        console.log("blocking");
      },
      terminated() {
        Sentry.captureMessage("IndexedDB terminated");
      },
    }).catch(error => {
      if (error instanceof DOMException) {
        console.log("IndexedDB not available, data caching is disabled");
      } else {
        console.log("idb openDB error", error);
        Sentry.captureException(error);
      }

      return null;
    });

    return Promise.race([openDbPromise, timeoutProm]).finally(() => {
      this._idxDBLoading = false;
    });
  };

  public initialize = (): Promise<void> => {
    return this._initialLoadPromise;
  };

  public updateCache = (dataType?: PersistentCachable): Promise<void> => {
    if (!dataType) return this.sequentialUpdate();

    return this.updateSingleStoreData(dataType);
  };

  public loadCache = (): Promise<void> => this.sequentialLoad();

  private sequentialLoad = (
    cacheTypes: PersistentCachable[] = cloneDeep(this.persistentCaches),
  ): Promise<void> => {
    if (!this._indexedDBAvailable) return Promise.resolve();
    
    const nextCacheType = cacheTypes.pop();

    if (!nextCacheType) {
      return Promise.resolve();
    }

    return this.loadSingleStoreData(nextCacheType)
      .then(() => this.sequentialLoad(cacheTypes))
      .catch(error => {
        console.error(`Loading type: ${nextCacheType} failed: `, error);
        Sentry.captureException(error);
      });
  };

  private loadSingleStoreData = (dataType: PersistentCachable): Promise<void> => {
    if (!this._indexedDBAvailable) return Promise.resolve();

    if (!this._indexedDB) {
      return Promise.reject("IndexedDB is not yet initialized");
    }

    const traceName = `LOAD_STORE_${dataType}`.toUpperCase();

    const loadStoreTrace = trace(performance, traceName);
    loadStoreTrace.start();

    let queryPromise: Promise<(TracklineRecord | GeoJson.LineStringFeature | GeoJson.PointFeature | GeoJson.PolygonFeature)[]>;
    if (
      dataType === FeatureType.Sightings 
      || dataType === FeatureType.FishingGear 
      || dataType === FeatureType.Ellipses 
      || dataType === FeatureType.BearingLines
    ) {
      const now = Date.now() / 1000;
      
      const oldEpochCutoff = now - this._sightingTimeDelta.old.delta;
      const newEpochCutoff = now - (this._sightingTimeDelta.new?.delta ?? 0);

      const query = IDBKeyRange.bound(oldEpochCutoff, newEpochCutoff);
      queryPromise = this._indexedDB.getAllFromIndex(dataType, "epoch", query);
    } else if (dataType === FeatureType.LeaseAreas && Array.isArray(this._leaseAreaIds)) {
      const promises: Promise<undefined|GeoJson.PolygonFeature>[] = [];

      for (const id of this._leaseAreaIds) {
        promises.push(this._indexedDB.get(dataType, id));
      }

      queryPromise = Promise.all(promises).then(results => {
        return results.filter(leaseArea => leaseArea !== undefined) as GeoJson.PolygonFeature[];
      }).catch(error => {
        console.error(error);
        Sentry.captureException(error);
        return [];
      });
    } else {
      queryPromise = this._indexedDB.getAll(dataType);
    }

    return queryPromise.then(cachedFeatures => {
      // console.log(`Loaded ${cachedFeatures.length} from ${dataType}`);
      const now = Date.now() / 1000;
      loadStoreTrace.incrementMetric("numFeatures", cachedFeatures.length);

      const insertTraceName = `INSERT_FROM_CACHE_${dataType}`.toUpperCase();

      const insertCacheTrace = trace(performance, insertTraceName);
      insertCacheTrace.incrementMetric("numFeatures", cachedFeatures.length);
      insertCacheTrace.start();

      for (const cachedData of cachedFeatures) {
        if (dataType === "tracklines") {
          const trackline = cachedData as TracklineRecord;
          this.tracklines.addNewPoints(trackline.id, trackline.points);
        } else {
          const feature = cachedData as GeoJson.Feature;

          if (
            dataType === FeatureType.FishingGear
            || dataType === FeatureType.Sightings
            || dataType === FeatureType.Ellipses
            || dataType === FeatureType.BearingLines
          ) {
            feature.properties.age = now - feature.properties.epoch;
          }

          this[dataType].addOrUpdate(
            feature.properties.id,
            // weird type resolution wants the intersection of all three types (which isn't possible), when 
            // it should be asking for the union between all three.
            feature as (GeoJson.Feature<GeoType.Polygon> & GeoJson.Feature<GeoType.LineString> & GeoJson.Feature<GeoType.Point>)
          );
        }
      }
      insertCacheTrace.stop();
      loadStoreTrace.stop();
    }).catch(error => {
      console.error(`Failed to load data from ${dataType}: `, error);
      Sentry.captureException(error);
    });
  };

  private sequentialUpdate = (cacheTypes: PersistentCachable[] = cloneDeep(this.persistentCaches)): Promise<void> => {
    if (!this._indexedDBAvailable) return Promise.resolve();

    const nextCacheType = cacheTypes.pop();

    if (!nextCacheType) {
      return Promise.resolve();
    }

    // console.log(`Saving cached data of type: ${nextCacheType}`);

    return this.updateSingleStoreData(nextCacheType)
      .then(() => this.sequentialUpdate(cacheTypes));
  };

  private updateSingleStoreData = (dataType: PersistentCachable): Promise<void> => {
    if (!this._indexedDBAvailable) return Promise.resolve();

    const cache = this[dataType] ?? null;

    if (!cache) return Promise.resolve();

    if (!this._indexedDB) {
      return Promise.reject(new Error("IdxDB is not yet set up"));
    }

    const updatedData = cache.getPersistentData();

    const promises: Promise<void|string>[] = [];

    if (dataType === "tracklines") {

      let container: TracklineRecord;
      for (const id in updatedData) {
        container = {
          id: id,
          points: (updatedData as Record<string, Geo.TracklinePoint[]>)[id],
        };

        if (Array.isArray(container.points), container.points.length > 0) {
          promises.push(this._indexedDB.put(dataType, container).catch(err => {
            console.error(`${dataType} - ${id}`, err, container);
          }));
        }
      }
    } else if ("geoType" in cache) {
      let feat: GeoJson.Feature<typeof cache.geoType>;
      for (const id in updatedData) {
        feat = (updatedData as Record<string, typeof feat>)[id];
        promises.push(this._indexedDB.put(dataType, feat).catch(err => {
          console.error(`${dataType} - ${id}`, err, feat);
        }));
      }
    } else {
      return Promise.reject(
        new Error(`couldn't find the right type of cache for type ${dataType}`)
      );
    }

    if (promises.length === 0) return Promise.resolve();
    return Promise.all(promises).then(() => { //strings => {
      //const successfulIds = remove(strings, (s) => typeof s === "string");
      //console.log(`Successfully updated ${successfulIds.length} features of type ${dataType}`);
    });
  };

  public cleanUp = (): Promise<void> => {
    if (!this._indexedDBAvailable) {
      return Promise.resolve();
    }

    return this.sequentialUpdate().finally(() => {
      this._indexedDB?.close();
      this._indexedDB = null;
    });
  };
}

export default DataCache;
