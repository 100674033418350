import "./scss/index.scss";

import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { withProfiler, ErrorBoundary } from "@sentry/react";

// Runs and initializes the firebase API.
// Also initializes Sentry + firebase performance if not running locally.
// Must do this before importing any project code
import { LOCAL_INSTANCE } from "./setup/Setup";


// State Provider
import StateProvider, { StateContext, DispatchContext } from "./provider/StateProvider";

// Specialized Routes
import AuthRequired from "./routes/AuthRequired";

// Main Routes
import Home from "./pages/Home";
import Settings from "./pages/Settings";
import MapView from "./pages/MapView";
import UnknownPage from "./pages/UnknownPage";

// Admin Routes
import EulaManagement from "./pages/admin/EulaManagement";
import LeaseAreaManagement from "./pages/admin/LeaseAreaManagement";
import UserManagement from "./pages/admin/UserManagement";
import MapConfiguration from "./pages/admin/MapConfiguration";
import TracklineEditor from "./pages/admin/TracklineEditor";

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <StateProvider>
        <ErrorBoundary fallback={<Home />}>
          <Routes>
            {/* Home page */}
            <Route path='/' element={<Home/>}/>

            {/* Redirects to handle old auth paths */}
            <Route path='/signin' element={<Navigate to='/' replace />} />
            <Route path='/signout' element={<Navigate to='/?signOut=true' replace />} />

            {/* settings page */}
            <Route
              path='/settings'
              element={
                <AuthRequired>
                  <Settings/>
                </AuthRequired>
              }
            />

            {/* Map Page */}
            <Route
              path='/map/:slug'
              element={
                <AuthRequired>
                  <MapView/>
                </AuthRequired>
              }
            />

            {/* Old map path redirect */}
            <Route path='/mapview/:slug' element={<Navigate to='/map/:slug' replace/>} />

            {/* ADMIN ROUTES */}
            <Route
              path='/admin/eula'
              element={
                <AuthRequired admin>
                  <EulaManagement/>
                </AuthRequired>
              }
            />
            <Route
              path='/admin/leaseAreas'
              element={
                <AuthRequired admin>
                  <LeaseAreaManagement/>
                </AuthRequired>
              }
            />
            <Route
              path='/admin/users'
              element={
                <AuthRequired admin>
                  <UserManagement/>
                </AuthRequired>
              }
            />
            <Route
              path='/admin/maps'
              element={
                <AuthRequired>
                  <MapConfiguration />
                </AuthRequired>
              }
            />
            <Route
              path='/admin/trackline/edit'
              element={
                <AuthRequired>
                  <TracklineEditor/>
                </AuthRequired>
              }
            />

            {/* 404 Page */}
            <Route path="/:slug" element={<UnknownPage/>}/>
          </Routes>
        </ErrorBoundary>
      </StateProvider>
    </BrowserRouter>
  );
}

export default LOCAL_INSTANCE ? App : withProfiler(App);
