import React, { useEffect, useState } from "react";
import Fade from "react-bootstrap/Fade";

import type { AlertProps as BootstrapAlertProps } from "react-bootstrap/Alert";

export interface AlertProps {
  show: boolean;
  style?: BootstrapAlertProps["style"];
  variant?: BootstrapAlertProps["variant"];
  className?: string;
  closeHandler?: () => void;
  fade?: number;
  fadeRightAway?: boolean;
  text?: string;
}

const Alert = (props: React.PropsWithChildren<AlertProps>): JSX.Element => {
  const { closeHandler, show } = props;

  const variant = props.variant ?? "primary";
  const fade = props.fade ?? 500;

  const alertClasses = [
    "align-center",
    `alert-${variant}`,
    "border",
    `border-${variant}`,
    "rounded",
    props.className ?? "",
  ];

  let divStyle = {
    display: show ? "block" : "none",
  };

  if (props.style) {
    divStyle = { ...divStyle, ...props.style };
  }

  const [fadeOpen, setFadeOpen] = useState(true);

  const onFadeExited = () => {
    if (typeof closeHandler === "function") {
      closeHandler();
      setFadeOpen(true);
    }
  };

  useEffect(() => {
    if (show &&
        props.fadeRightAway &&
        typeof fade === "number" &&
        fade > 0 &&
        typeof closeHandler === "function") {
      setTimeout(() => setFadeOpen(false), fade);
    }
  }, [show, fade]);

  return (
    <Fade in={fadeOpen} onExited={onFadeExited}>
      <div className={alertClasses.join(" ")} style={divStyle}>
        {props.children ?? props.text}
        {typeof closeHandler === "function" ? (
          <button
            type="button"
            className="ml-2 close"
            aria-label="Close"
            onClick={() => setFadeOpen(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        ) : null}
      </div>
    </Fade>
  );
};

export default Alert;
