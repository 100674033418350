import React, { useState } from "react";

import { ErrorBoundary } from "@sentry/react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";



export interface ErrorFallbackProps {
  message: string;
  componentName: string;
  fallback?: React.ReactNode|JSX.Element;
}


export interface FallbackOnErrorProps {
  children?: React.ReactNode;
  message?: string;
  componentName: string;
  fallback?: React.ReactNode|JSX.Element;
  [key: string]: unknown;
}


const ErrorFallback = (props: React.PropsWithoutRef<ErrorFallbackProps>): JSX.Element => {
  console.log("onerror", props);
  const [showAlert, setShowAlert] = useState<boolean>(true);
  return (
    <Container fluid>
      {props.fallback}
      <Alert variant='danger' onClose={() => setShowAlert(false)} show={showAlert} dismissible>
        <Alert.Heading>
          {`${props.componentName} ran into an error`}
        </Alert.Heading>
        {props.message}
      </Alert>
    </Container>
  );
};


const FallbackOnError = (props: FallbackOnErrorProps): JSX.Element => {
  const { children, message, componentName } = props;

  const innerProps: ErrorFallbackProps = {
    message: message ?? "Ran into an error",
    componentName: componentName,
    fallback: ErrorFallback,
  };

  return (
    <ErrorBoundary fallback={<ErrorFallback {...innerProps} />}>
      {children}
    </ErrorBoundary>
  );
};

export default FallbackOnError;
