import { cloneDeep } from "lodash";
import { FeatureType } from "../../misc/Types";
import { LayerGroup, SightingLayerGroup } from "../LayerGroup";
import { AddIconFn, ClientFilter, Layer, LayerConfig, LayerParamOptions, LayerType, MiscLayer, Filter } from "../LayerTypes";
import { HSL, RGB } from "../../map/Colors";
import { IconColorStrings, addIconFnBuilder, fillInDefaultOptions, getAgeDecayFillColorBuilder, mapboxExprClamp } from "./common";
import type { Expression, StyleFunction } from "mapbox-gl";
import { FixedArray, toFixedArray } from "../../misc/TypeUtils";
import { Icon, IconGeometry, Polygon } from "../icon";
import { TimeDelta } from "../../misc/TimeDelta";


const HAS_IMAGES = [
  "all",
  ["has", "images"],
  [">", ["length", ["get", "images"]], 0],
];

const DOES_NOT_HAVE_IMAGES = ["!", HAS_IMAGES];



const PRIORITY_SIGHTING_TRIGGERS = ["north atlantic right whale", "riwh"].map(trigger => (
  ["in", ["to-string", trigger], ["downcase", ["get", "name"]]]
));

const IS_PRIORITY_SIGHTING = [
  "any",
  PRIORITY_SIGHTING_TRIGGERS,
  [["all", ["has", "priority"], ["==", ["get", "priority"], true]]],
].flat();


const IS_NOT_PRIORITY_SIGHTING = [
  "==",
  IS_PRIORITY_SIGHTING,
  false,
];

const IS_PRIORITY_BIRD_SIGHTING = [
  "all",
  ["has", "priorityBirdDetection"],
  ["to-boolean", ["get", "priorityBirdDetection"]],
];


const IS_BIRD_SIGHTING = [
  "all",
  ["has", "birdDetection"],
  ["to-boolean", ["get", "birdDetection"]],
  // prevents updated sightings from showing as both 
  ["!", ["has", "priorityBirdDetection"]],
];


const IS_NOT_BIRD_SIGHTING = [
  "all",
  ["!", ["has", "birdDetection"]],
  ["!", ["has", "priorityBirdDetection"]],
];


// "#00b800";
const DEFAULT_SIGHTING_FILL_COLOR: HSL = RGB.toHsl({
  red: 0,
  green: 0xb8,
  blue: 0,
});

const SIGHTING_DECAY_COLORS: FixedArray<HSL, 10> = toFixedArray(
  Array.from(HSL.hslRange(DEFAULT_SIGHTING_FILL_COLOR, 10)),
  10,
);


// "#4472c4"
const DEFAULT_BIRD_SIGHTING_FILL_COLOR: HSL = RGB.toHsl({
  red: 0x44,
  green: 0x72,
  blue: 0xc4,
});

const BIRD_SIGHTING_DECAY_COLORS: FixedArray<HSL, 10> = toFixedArray(
  Array.from(HSL.hslRange(DEFAULT_BIRD_SIGHTING_FILL_COLOR, 10)),
  10,
);

// #ff0000;
const DEFAULT_PRIORITY_SIGHTING_FILL_COLOR: HSL = RGB.toHsl({ 
  red: 0xff, 
  green: 0x00, 
  blue: 0x00,
});


const PRIORITY_SIGHTING_DECAY_COLORS: FixedArray<HSL, 10> = toFixedArray(
  Array.from(HSL.hslRange(DEFAULT_PRIORITY_SIGHTING_FILL_COLOR, 10)),
  10,
);


const MARINE_SIGHTING_ICON: Icon = {
  geometry: IconGeometry.Circle,
};

const BIRD_SIGHTING_ICON: Icon = {
  geometry: IconGeometry.Polygon,
  getPolygons: function({ fillColor, borderColor }: IconColorStrings): Polygon[] {
        
    return [
      { 
        points: [{ x: 50, y: 0 }, { x: 0, y: 100 }, { x: 100, y: 100 }],
        fillColor: borderColor,
      },
      {
        points: [{ x: 50, y: 15 }, { x: 13, y: 90 }, { x: 87, y: 90 }],
        fillColor,
      }
    ];
  }
};

const PRIORITY_SIGHTING_ICON: Icon = {
  geometry: IconGeometry.Polygon,
  getPolygons: function({ fillColor, borderColor }: IconColorStrings): Polygon[] {
    // points were computed from the 2 lines in this SVG path, then scaled to a "0 0 100 100" viewBox 
    // m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z
    return [
      { 
        points: [
          { x: 19.125, y: 100.0 },
          { x: 27.25, y: 63.026314 },
          { x: 0.0, y: 38.157894 },
          { x: 36.0, y: 34.868423 },
          { x: 50.0, y: 0.0 },
          { x: 64.0, y: 34.868423 },
          { x: 100.0, y: 38.157894 },
          { x: 72.75, y: 63.026314 },
          { x: 80.875, y: 100.0 },
          { x: 50.0, y: 80.39474 },
          { x: 19.125, y: 100.0 },
        ],
        fillColor: borderColor 
      },
      {
        points: [
          { x: 30.375, y: 83.55263 },
          { x: 50.0, y: 71.18421 },
          { x: 69.625, y: 83.68421 },
          { x: 64.375, y: 60.263157 },
          { x: 81.625, y: 44.473682 },
          { x: 58.875, y: 42.36842 },
          { x: 50.0, y: 20.263157 },
          { x: 41.125, y: 42.23684 },
          { x: 18.375, y: 44.342106 },
          { x: 35.625, y: 60.13158 },
          { x: 30.375, y: 83.55263 },
        ],
        fillColor,
      }
    ];
  },
};


const GET_SIGHTING_AGE_DECAY 
    = getAgeDecayFillColorBuilder(SIGHTING_DECAY_COLORS, DEFAULT_SIGHTING_FILL_COLOR);

const GET_BIRD_SIGHTING_AGE_DECAY 
    = getAgeDecayFillColorBuilder(BIRD_SIGHTING_DECAY_COLORS, DEFAULT_BIRD_SIGHTING_FILL_COLOR);

const GET_PRIORITY_SIGHTING_AGE_DECAY 
    = getAgeDecayFillColorBuilder(PRIORITY_SIGHTING_DECAY_COLORS, DEFAULT_PRIORITY_SIGHTING_FILL_COLOR);




interface SightingOpts {
    hasImages?: boolean,
    isBird?: boolean,
    isPriority?: boolean,
}

function filterBuilderFactory(
  opts: SightingOpts
): (timeDelta: TimeDelta, clientFilter?: null | ClientFilter) => LayerType.Any["filter"] {
  const { hasImages, isBird, isPriority } = opts;

  let sightingTypeFilter: unknown[];

  if (isBird && isPriority) {
    // priority bird detection
    sightingTypeFilter = [IS_PRIORITY_BIRD_SIGHTING];
  } else if (isBird) {
    // regular bird detection
    sightingTypeFilter = [IS_BIRD_SIGHTING];
  } else if (isPriority) {
    // priority marine detection
    sightingTypeFilter = [
      IS_NOT_BIRD_SIGHTING,
      IS_PRIORITY_SIGHTING,
    ];
  } else {
    // regular marine detection
    sightingTypeFilter = [
      IS_NOT_BIRD_SIGHTING,
      IS_NOT_PRIORITY_SIGHTING,
    ];
  }

  const base = [
    "all",
    ["==", "sighting", ["get", "dataType"]],
    ["has", "age"],
    hasImages ? HAS_IMAGES : DOES_NOT_HAVE_IMAGES,
    ...sightingTypeFilter,
  ];

  return function(timeDelta: TimeDelta, clientFilter?: null | ClientFilter): LayerType.Any["filter"] {
    // make sure we don't modify the original, otherwise things will get weird
    const ret = cloneDeep(base);

    // add the max age filter ('base' already contains the check that 'age' exists)
    ret.push(["<", ["get", "age"], Math.abs(timeDelta.old.delta)]);
        
    // add the min age filter if specified
    if (timeDelta.new) {
      ret.push([">", ["get", "age"], Math.abs(timeDelta.new.delta)]);
    }
        
    switch (clientFilter?.type) {
      case Filter.Exclude:
        ret.push(["!=", ["get", "client"], clientFilter.client]);
        break;
      case Filter.Include:
        ret.push(["==", ["get", "client"], clientFilter.client]);
        break;
    }

    return ret;
  };
}


function iconImageBuilder(
  layer: Layer,
): (td: TimeDelta) => (string | StyleFunction | Expression) {

  const base: string | StyleFunction | Expression = [
    "concat",
    ["get", "client"],
    `-${layer}`,
  ];

  return function(timeDelta: TimeDelta): string | StyleFunction | Expression {
    const maxAge = timeDelta.old.delta;
    const minAge = timeDelta.new?.delta ?? 0; 
    // ensure that this is non-zero, im sure mapbox expressions wont handle a div-by-0 nicely
    const ageRange = Math.max(1, maxAge - minAge);


    // split into 2 steps for clarity
    // normalizes the age from 0-1, using the maxAge + minAge as bounds.
    // 
    // roughly equivelent to `clamp((age - minAge) / (maxAge - minAge), 0, 1)`;
    // 
    // since ages can be be outside the min..max age gap, we need to clamp between 0-1. 
    // the filter will likely remove most features, but this ensures we dont end up with missing icons. 
    const normalizedAge = mapboxExprClamp(["/", ["-", ["get", "age"], minAge], ageRange], 0, 1);

    // this converts the normalized age (0..=1) to an index in the range 0..10.
    // 
    // roughly equivelent to `clamp(Math.round(normalizedAge * 10), 0, 9)`;
    const normalizedAgeToIndex = mapboxExprClamp(["round", ["*", normalizedAge, 10]], 0, 9);

    return [
      ...base,
      "-0",
      ["to-string", normalizedAgeToIndex],
    ];
  };
}

function buildSightingLayerPaint(timeDelta: TimeDelta): LayerType.Circle["paint"] {
  const maxAge = timeDelta.old.delta;
  const minAge = timeDelta.new?.delta ?? 0;


  return {
    "circle-radius": 5,
    "circle-color": [
      "interpolate-lab",
      ["linear"],
      // value
      ["max", ["min", ["abs", ["get", "age"]], Math.abs(maxAge - 1)], 0],
      // start bound 
      Math.abs(minAge), ["rgb", 0, 0xb8, 0],
      // end bound
      Math.abs(maxAge), ["rgb", 0, 0, 0]
    ],
    "circle-stroke-color": [
      "case",
      // try to use 'color' first, 
      ["has", "color"], ["to-color", ["get", "color"]],
      // and fallback to 'clientColor'.
      ["has", "clientColor"], ["to-color", ["get", "clientColor"]],
      "#123456",
    ],
    "circle-stroke-width": ["number", ["feature-state", "stroke"], 2],
  };
}


type FilterBuilder 
    = (timeDelta: TimeDelta, clientFilter?: null | ClientFilter) => LayerType.Any["filter"];


interface LayerSpecificParts {
    type: "circle" | "symbol",
    filterBuilder: FilterBuilder,
    addIconFn?: AddIconFn;
}

function getLayerSpecificParts(layer: Layer): LayerSpecificParts {
  switch (layer) {
    case FeatureType.Sightings:
      return { 
        type: "circle",
        filterBuilder: filterBuilderFactory({}),
      };
    case MiscLayer.SightingsWithImages:
      return { 
        type: "symbol", 
        filterBuilder: filterBuilderFactory({ hasImages: true }),
        addIconFn: addIconFnBuilder(MARINE_SIGHTING_ICON, GET_SIGHTING_AGE_DECAY, true),
      };
    case MiscLayer.PrioritySightings:
      return {
        type: "symbol", 
        filterBuilder: filterBuilderFactory({ isPriority: true }),
        addIconFn: addIconFnBuilder(PRIORITY_SIGHTING_ICON, GET_PRIORITY_SIGHTING_AGE_DECAY, false)
      };
    case MiscLayer.PrioritySightingsWithImages:
      return { 
        type: "symbol", 
        filterBuilder: filterBuilderFactory({ isPriority: true, hasImages: true }),
        addIconFn: addIconFnBuilder(PRIORITY_SIGHTING_ICON, GET_PRIORITY_SIGHTING_AGE_DECAY, true),
      };
    case MiscLayer.BirdSightings:
      return { 
        type: "symbol", 
        filterBuilder: filterBuilderFactory({ isBird: true }),
        addIconFn: addIconFnBuilder(BIRD_SIGHTING_ICON, GET_BIRD_SIGHTING_AGE_DECAY, false)
      };
    case MiscLayer.BirdSightingsWithImages:
      return { 
        type: "symbol", 
        filterBuilder: filterBuilderFactory({ isBird: true, hasImages: true }),
        addIconFn: addIconFnBuilder(BIRD_SIGHTING_ICON, GET_BIRD_SIGHTING_AGE_DECAY, true),
      };
    case MiscLayer.PriorityBirdSightings:
      return {
        type: "symbol", 
        filterBuilder: filterBuilderFactory({ isBird: true, isPriority: true }), 
        addIconFn: addIconFnBuilder(PRIORITY_SIGHTING_ICON, GET_BIRD_SIGHTING_AGE_DECAY, false)
      };
    case MiscLayer.PriorityBirdSightingsWithImages:
      return { 
        type: "symbol",
        filterBuilder: filterBuilderFactory({ isBird: true, isPriority: true, hasImages: true }),
        addIconFn: addIconFnBuilder(PRIORITY_SIGHTING_ICON, GET_BIRD_SIGHTING_AGE_DECAY, true),
      };
    default: // throwing is fine here, since this should never happen outside of dev 
      throw new Error(`expected a layer variant related to sightings, not ${layer}`);
  }
}


function layerConfigBuilder(
  layerId: Layer,
  iconSize?: number,
): LayerConfig<LayerType.Any> {
  const { type, addIconFn, filterBuilder } = getLayerSpecificParts(layerId);
    
  // only base sightings use circle layers
  if (type === "circle") {
    // return early to avoid casting for a special case where we don't need an icon fn
    return {
      layerId,
      sourceId: FeatureType.Sightings,
      factory: function (opts?: LayerParamOptions) {
        const { timeDelta, visible, clientFilter } = fillInDefaultOptions(opts);

        return {
          id: layerId,
          type,
          source: FeatureType.Sightings,
          filter: filterBuilder(timeDelta, clientFilter),
          layout: {
            "visibility": visible ? "visible" : "none",
          },
          paint: buildSightingLayerPaint(timeDelta),
        };
      },
    } as LayerConfig<LayerType.Circle>;
  }

  // should only ever happen in dev, mainly just for typechecking
  if (typeof addIconFn !== "function") {
    throw new Error(`${layerId} config missing addIconFn`);
  }

  if (typeof iconSize !== "number") {
    throw new Error(`${layerId} is missing a specified iconSize`);
  }

  const baseLayout = {
    "icon-size": iconSize,
    "icon-allow-overlap": true,
  };

  const iconImageFn = iconImageBuilder(layerId);

  const factory = function(opts?: LayerParamOptions): LayerType.Symbol {
    const { timeDelta, visible, clientFilter } = fillInDefaultOptions(opts);
        
    return {
      id: layerId, 
      type,
      source: FeatureType.Sightings,
      filter: filterBuilder(timeDelta, clientFilter),
      layout: {
        "visibility": visible ? "visible" : "none",
        "icon-image": iconImageFn(timeDelta),
        ...baseLayout
      }

    };
  };

  return {
    layerId,
    sourceId: FeatureType.Sightings,
    shouldLazilyLoadIcons: true,
    hasAgeDecay: true,
    addIcon: addIconFn,
    factory
  };
} 



export const BIRD_SIGHTING_LAYER_GROUP: LayerGroup = new SightingLayerGroup({
  name: MiscLayer.BirdSightings,
  baseLayer: layerConfigBuilder(MiscLayer.BirdSightings, 0.23),
  priorityLayer: layerConfigBuilder(MiscLayer.PriorityBirdSightings, 0.30),
  withImagesLayer: layerConfigBuilder(MiscLayer.BirdSightingsWithImages, 0.5),
  priorityWithImagesLayer: layerConfigBuilder(MiscLayer.PriorityBirdSightingsWithImages, 0.5),
});



export const MARINE_SIGHTING_LAYER_GROUP: LayerGroup = new SightingLayerGroup({
  name: FeatureType.Sightings,
  baseLayer: layerConfigBuilder(FeatureType.Sightings),
  priorityLayer: layerConfigBuilder(MiscLayer.PrioritySightings, 0.25),
  withImagesLayer: layerConfigBuilder(MiscLayer.SightingsWithImages, 0.5),
  priorityWithImagesLayer: layerConfigBuilder(MiscLayer.PrioritySightingsWithImages, 0.5),
});

