import React from "react";

import { Brand } from "ts-brand";

import type {
  Map as MapboxMap,
  Popup as MapboxPopup,
  Control as MapboxControl,
  GeoJSONSource as MapboxGeoJsonSource,
  EventData as MapboxEventData,
  MapMouseEvent as MapboxMapMouseEvent,
  MapSourceDataEvent as MapboxMapSourceDataEvent,
  MapboxGeoJSONFeature,
  FlyToOptions,
} from "mapbox-gl";

import { isNonEmptyRecord } from "../misc/Utils";
import { isPlainObject } from "lodash";

import type { Layer } from "../layers/LayerTypes";

import type { Geo } from "./Geo";
import { validate } from "uuid";

import { z } from "zod"; 


export type FeatureId = Brand<string, "featureId">;

// Re-export mapbox types, renamed to avoid clobbering our types.

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace Mapbox {
  export type Map = MapboxMap;
  export type Popup = MapboxPopup;
  export type Control = MapboxControl;
  export type EventData = MapboxEventData;
  export type MapSourceDataEvent = MapboxMapSourceDataEvent;
  export type FlyToOpts = FlyToOptions;
  export type PopupEventArgs = MapboxEventData & MapboxMapMouseEvent;
  export type GeoJsonFeature = MapboxGeoJSONFeature;
  export type GeoJsonProperties = GeoJsonFeature["properties"];

  export type GeoJsonSource = MapboxGeoJsonSource;
}

/// Sentinel value for something that is being loaded. Indicates a placeholder
/// should be shown instead.
export const Loading = "LOADING";
export type Loading = typeof Loading;

/** Branded type representing a uuid */
export type Uuid = Brand<string, "uuid">;

/** Checks if a given input is a valid uuid */
export function isUuid(x: unknown): x is Uuid {
  return typeof x === "string" && validate(x);
}

/** Branded type representing a number of seconds */
export type Seconds = Brand<number, "seconds">;


/** 
 * Branded type representing a number of seconds after the unix epoch 
 * Should be used in places where the builtin Date type can't be 
 * (or isn't) used.
 */
export type Timestamp = Brand<number, "timestamp">;

// Putting in a namespace to sorta kinda get associated methods without 
// defining a full on class
/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace Timestamp {
  export function fromDate(d: Date): Timestamp {
    return (d.getUTCMilliseconds() / 1000) as Timestamp;
  }
  
  export function toDate(ts: Timestamp): Date {
    return new Date(ts * 1000);
  }
}


export interface BaseDataConfig {
  type: DataType;
}

export interface SightingConfig extends BaseDataConfig {
  clients: string[],
  stations: string[],
  defaultMaxAge: Seconds;
}



export interface MapConfig {
  thirdParty: boolean;
  tracklines: boolean;
}


export interface ImageInfo {
  src?: string,
  text?: string,
  layers: DataType[];
  storageLocation?: string;
}

export interface MapPosition {
  center: Geo.Coordinate;
  zoom: number;
}


type LegendIconKind = 
  { type: "svg", svg: string }
  | { type: "img", src: string };

interface BaseLegendIcon {
  height?: number, 
  width?: number 
}

export type LegendIcon = BaseLegendIcon & LegendIconKind;

export interface LegendItem {
  icons: LegendIcon[];
  clientId?: string;
  layers: Layer[];
  text?: string;
}

/*
export interface LegendItem extends CanvasInfo {
  src?: string;
  clientId?: string;
  layers: DataType.Layers[];
  dropdown?: Omit<LegendItem, "dropdown">[];
}
*/

export interface MapMenuConfig {
  // polygons: Record<string, WithRequired<LegendItem, "polygon">>;
  polygons: Record<string, LegendItem>;
  sightings: { 
    base: LegendItem, 
    basePriority: LegendItem,
    dropdown: Record<string, LegendItem>,
  };
  birdSightings: {
    base: LegendItem,
    basePriority: LegendItem,
    dropdown: Record<string, LegendItem>, 
  };
  stations: { base: LegendItem, dropdown: Record<string, LegendItem> }; 
  acoustic?: Record<string, LegendItem>;
  buoys: Record<string, LegendItem>;
  gliders: Record<string, LegendItem>;
}

export type PopupRef = React.RefObject<Mapbox.Popup>

export interface IdAndData<D> {
  id: string;
  data: D;
}


export type MapSources = true | Record<string, boolean | DataType[]>;

export type MapPermissions = Record<string, boolean>;

export interface MapDoc {
  readonly id: string;
  readonly displayName: string;
  readonly leaseAreas?: true | Uuid[];
  readonly sources: MapSources;
  readonly showAcousticData?: boolean;
}

export function isMapDoc(obj: unknown): obj is MapDoc {
  if (!isNonEmptyRecord(obj) ||
      typeof obj.id !== "string" ||
      typeof obj.displayName !== "string") {
    return false;
  }

  if (obj.leaseAreas !== undefined) {
    if (obj.leaseAreas !== true && !Array.isArray(obj.leaseAreas)) {
      return false;
    }
  }

  return (
    obj.sources === true || isPlainObject(obj.sources)
  );
}


export interface BaseUserDoc {
  readonly email: string;
  mapPermissions: MapPermissions;
}

export function isBaseUserDoc(obj: unknown): obj is BaseUserDoc {
  if (!isNonEmptyRecord(obj)) {
    return false;
  }

  return (
    typeof obj.email === "string" &&
    isPlainObject(obj.mapPermissions)
  );
}


export interface PendingUserDoc extends BaseUserDoc {
  retryCount: number;
}

export interface EulaDoc {
  path: string;
  version: string;
}

export function isEulaDoc(obj: unknown): obj is EulaDoc {
  if (!isNonEmptyRecord(obj)) {
    return false;
  }

  return (
    typeof obj.path === "string" &&
    typeof obj.version === "string"
  );
}

export interface UserDoc extends BaseUserDoc {
  readonly admin: boolean;
  readonly displayName?: string;
  readonly uid: string;
  disabled?: boolean;
  eulaVersion?: string;
}

export function isUserDoc(obj: unknown): obj is UserDoc {
  if (!isBaseUserDoc(obj)) {
    return false;
  }

  return (
    typeof (obj as UserDoc).admin === "boolean" &&
    (typeof (obj as UserDoc).displayName === "string" || (obj as UserDoc).displayName === undefined) &&
    typeof (obj as UserDoc).uid === "string" &&
    (typeof (obj as UserDoc).eulaVersion === "string" || (obj as UserDoc).eulaVersion === undefined)
  );
}


export interface UserClaims {
  readonly admin: boolean;
  readonly reader: boolean;
}

export function isUserClaims(x: unknown): x is UserClaims {
  if (!isNonEmptyRecord(x)) {
    return false;
  }

  return (
    typeof x.admin === "boolean" &&
    typeof x.reader === "boolean"
  );
}


export interface ClientDoc {
  id: string;
  clientColor: string;
  clientDisplayName: string;
  iconPath?: string;
}


export function isClientDoc(obj: unknown): obj is ClientDoc {
  if (!isNonEmptyRecord(obj)) {
    return false;
  }

  return (
    typeof obj.id === "string" &&
    typeof obj.clientDisplayName === "string" &&
    typeof obj.clientColor === "string" &&
    obj.clientColor.startsWith("#")
  );
}

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export type Selector<I, O> = (x: I) => O;

export type StringSelector<I> = Selector<I, string>;

export type Flatten<T> = T extends Array<infer U> ? U : T;


export type RecordReducer<K extends number|string, D> = (a: Record<K, D>, c: D) => Record<K, D>;

export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type WithRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type RecordValue<D extends Record<string, unknown>> = (
  D extends Record<string, infer ValueType> ? ValueType : never
);


/** Data types for features on the map. */
export enum FeatureType {
  // Firestore collection - 'bearingLines'
  BearingLines = "bearingLines",
  // In Firestore collection - 'buoys'
  Buoys = "buoys",
  // Firestore collection - 'ellipses'
  Ellipses = "ellipses",
  // Firestore collection - 'fishingGear'
  FishingGear = "fishingGear",
  // In Firestore collection - 'gliders'
  Gliders = "gliders",
  // In Firestore collection - 'leaseAreas'
  LeaseAreas = "leaseAreas",
  // Firestore collection - 'sightings'. covers all sightings of all types
  Sightings = "sightings",
  // Firestore collection - 'slowZones'
  SlowZones = "slowZones",
  // Not in firestore, loaded via NOAA's API at runtime and cached where possible
  Sma = "smas",
  // In Realtime Database tree '/stations'
  Stations = "stations",
}

export const ALL_FEATURE_DATA_TYPES = Object.values(FeatureType);



export const FEATURE_TYPE_PARSER = z.nativeEnum(FeatureType);


export function isFeatureType(x: unknown): x is FeatureType {
  return FEATURE_TYPE_PARSER.safeParse(x).success;
}



/** Data types for configuration: users, maps, clients, etc. */
export enum ConfigDataType {
  // In Firestore collection - 'clients'
  Clients = "clients",
  // In Firestore collection - 'maps'
  Maps = "maps",
  // In Firestore collection - 'pendingUsers'
  PendingUsers = "pendingUsers",
  // In Firestore collection - 'users'
  Users = "users",
}


const CONFIG_DATA_TYPE_PARSER = z.nativeEnum(ConfigDataType);

export function isConfigDataType(x: unknown): x is ConfigDataType {
  return CONFIG_DATA_TYPE_PARSER.safeParse(x).success;
}



/** Data types relating to the status of a station/glider/buoy/trackline/etc */
export enum StatusDataType {
  // In Realtime Database tree '/status/stations'
  StationStatus = "status/stations",
  // In Realtime Database tree '/status/????'
  ThirdPartyStatus = "status/thirdparty",
  // In Realtime Database tree '/status/tracklines'
  TracklineStatus = "status/tracklines",
}


const STATUS_DATA_TYPE_PARSER = z.nativeEnum(StatusDataType);

export function isStatusDataType(x: unknown): x is StatusDataType {
  return STATUS_DATA_TYPE_PARSER.safeParse(x).success;
}

export type DataType = FeatureType | ConfigDataType | StatusDataType;

export function isDataType(x: unknown): x is DataType {
  return (
    isFeatureType(x)
    || isConfigDataType(x)
    || isStatusDataType(x)
  );
}
