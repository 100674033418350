
import { TimeDelta } from "../../misc/TimeDelta";
import { FeatureType } from "../../misc/Types";
import { LayerGroup, SingleLayerGroup } from "../LayerGroup";
import { BUILTIN_ICON, ClientFilter, Filter, LayerParamOptions, LayerType } from "../LayerTypes";
import { fillInDefaultOptions, makeSimpleFilter } from "./common";


export const BUOY_LAYER_GROUP: LayerGroup = new SingleLayerGroup({
  layerId: FeatureType.Buoys,
  sourceId: FeatureType.Buoys,
  hasAgeDecay: false,
  addIcon: BUILTIN_ICON,
  factory: function(opts?: LayerParamOptions): LayerType.Symbol {
    const { visible } = fillInDefaultOptions(opts);

    return {
      id: FeatureType.Buoys,
      type: "symbol",
      source: FeatureType.Buoys,
      filter: ["==", ["get", "dataType"], "buoy"],
      layout: {
        "visibility": visible ? "visible" : "none",
        "icon-image": [
          "concat",
          [
            "case",
            [
              "all",
              ["has", "client"],
              ["==", ["get", "client"], "Vineyard-DEME"],
            ],
            "vineyardDemeBase",
            [
              "all",
              ["has", "recentDetections"],
              ["to-boolean", ["get", "recentDetections"]],
            ],
            "detection",
            "base",
          ],
          "BuoyIcon",
        ],
        "icon-size": 0.4,
        "icon-allow-overlap": true
      }
    };
  }
});


function fishingGearFilterFactory(timeDelta: TimeDelta, clientFilter?: null | ClientFilter): LayerType.Circle["filter"] {
  const base = makeSimpleFilter({ dataType: "fishingGear", timeDelta });

  switch (clientFilter?.type) {
    case Filter.Exclude:
      base.push(["!=", ["get", "client"], clientFilter.client]);
      break;
    case Filter.Include:
      base.push(["==", ["get", "client"], clientFilter.client]);
      break;
  }

  return base;
}

export const FISHING_GEAR_GROUP: LayerGroup = new SingleLayerGroup({
  layerId: FeatureType.FishingGear,
  sourceId: FeatureType.FishingGear,
  hasAgeDecay: true,
  addIcon: BUILTIN_ICON,
  factory: function(opts?: LayerParamOptions): LayerType.Symbol {
    const { visible, timeDelta, clientFilter } = fillInDefaultOptions(opts);

    return {
      id: FeatureType.FishingGear,
      type: "symbol",
      source: FeatureType.FishingGear,
      filter: fishingGearFilterFactory(timeDelta, clientFilter),
      layout: {
        "visibility": visible ? "visible" : "none",
        "icon-image": "fishingGear",
        "icon-size": 0.45,
        "icon-allow-overlap": true
      }
    };
  }
});
