import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";


interface BaseMultipleSelectProps<D> {
  idSelector: (x: D) => string;
  displaySelector: (x: D) => string;
}

export interface MultipleSelectProps<D> extends BaseMultipleSelectProps<D> {
  values: D[];
  onSelectionChanged: (id: string, value: boolean) => void;
  defaultSelections: {[key: string]: boolean};
  striped?: boolean,
}

export interface MultipleSelectItemProps<D> extends BaseMultipleSelectProps<D> {
  value: D;
  handleChange: (x: boolean) => void;
  initialState: boolean;
}

const MultipleSelectItem = <D, >(props: React.PropsWithoutRef<MultipleSelectItemProps<D>>): JSX.Element => {
  const { value, handleChange } = props;

  const id = props.idSelector(value);
  const display = props.displaySelector(value);

  const [selected, setSelected] = useState<boolean>(props.initialState);

  useEffect(() => {
    handleChange(selected);
  }, [selected]);

  return (
    <tr>
      <td className='checkbox-cell'>
        <input type='checkbox' className='checkbox' id={id}
          checked={selected} readOnly/>
        <label htmlFor={id} className='checkbox-label' onClick={() => setSelected(s => !s)}>
          <span>{display}</span>
        </label>
      </td>
    </tr>
  );
};

const MultipleSelect = <D, >(props: React.PropsWithoutRef<MultipleSelectProps<D>>): JSX.Element => {
  const { values, onSelectionChanged } = props;

  return (
    <Table striped={props.striped}>
      <tbody>
        {
          Array.isArray(values)
            ? values.map((value, index) => {
              const id = props.idSelector(value);

              const handleChange = (newSelect: boolean) => {
                onSelectionChanged(id, newSelect);
              };

              return (
                <MultipleSelectItem key={index} handleChange={handleChange}
                  value={value} idSelector={props.idSelector}
                  initialState={props.defaultSelections[id] ?? false}
                  displaySelector={props.displaySelector}/>
              );
            })
            : null
        }
      </tbody>
    </Table>
  );
};

export default MultipleSelect;
