import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import TopNav from "../components/nav/TopNav";

import UpdatePassword from "../components/auth/UpdatePassword";


function Settings(): JSX.Element {
  return (
    <>
      <TopNav />
      

      <Container fluid>
        <Row className="justify-content-center mt-4">
          <Col/>
          <Col>
            <UpdatePassword/>
          </Col>
          <Col/>
        </Row>
      </Container>
    </>
  );
}


export default Settings;
