


import React from "react";
import { FeatureType, MapDoc } from "../misc/Types";
import { IndexedTimeOffset, makeIndexedTimeDeltas, Seconds, TimeDelta, TimeOffset } from "../misc/TimeDelta";
import { range } from "lodash";


export interface TimeDeltaRanges {
    [FeatureType.Sightings]: TimeDelta<IndexedTimeOffset>;
    tracklines: TimeDelta<IndexedTimeOffset>;
}
  
 
  
export const TIME_DELTAS: IndexedTimeOffset[] = makeIndexedTimeDeltas([
  { label: "Current", delta: 0 as Seconds },
  TimeOffset.fromMinutes(1),
  TimeOffset.fromMinutes(5),
  TimeOffset.fromMinutes(10),
  TimeOffset.fromMinutes(15),
  TimeOffset.fromMinutes(30),
  TimeOffset.fromHours(1),
  TimeOffset.fromHours(2),
  TimeOffset.fromHours(4),
  TimeOffset.fromHours(6),
  TimeOffset.fromDays(1),
  TimeOffset.fromDays(2),
  TimeOffset.fromDays(3),
  TimeOffset.fromDays(4),
  ...range(5, 180, 5).map(TimeOffset.fromDays)
]);
  
  
export const DEFAULT_TIME_DELTAS: TimeDeltaRanges = {
  [FeatureType.Sightings]: { new: null, old: TIME_DELTAS[8], },
  tracklines: { new: null, old: TIME_DELTAS[8], }
};


export interface MapState {
    mapDocument: MapDoc | null;
    timeDeltas: TimeDeltaRanges;
}

const DEFAULT_MAP_STATE: MapState = {
  mapDocument: null, 
  timeDeltas: DEFAULT_TIME_DELTAS,
};


export type MapStateDispatchParams = {
    mapDocument?: undefined | null | MapDoc;
    timeDeltaUpdates?: null | undefined | Partial<TimeDeltaRanges>;
};


export interface MapStateProps {
    mapState: MapState;
    updateMapState: (mapDocument: null | MapDoc, timeDeltaUpdates: Partial<TimeDeltaRanges>) => void;
}

export type MapStateDispatch = React.Dispatch<MapStateDispatchParams>;


export const MapStateContext = React.createContext<MapStateProps>({ 
  mapState: DEFAULT_MAP_STATE,
  // need a temp function to match the actual type. real function actually provided in MapStateProvider
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  updateMapState: () => {},
});
// export const MapStateDispatchContext = React.createContext<MapStateDispatch>(() => null);


export function useMapStateContext(): MapStateProps {
  return React.useContext(MapStateContext);
}

// export function useMapStateDispatch(): MapStateDispatch {
//    return React.useContext(MapStateDispatchContext);
// }

function MapStateReducer(state: MapState, dispatch: MapStateDispatchParams): MapState {
  const newState = {
    mapDocument: dispatch.mapDocument ?? state.mapDocument,
    timeDeltas: { 
      ...state.timeDeltas,
      ...(dispatch.timeDeltaUpdates ?? {}),
    },
  };

  console.log({ newState });

  return newState;
}


function MapStateProvider(props: React.PropsWithChildren<unknown>): JSX.Element {
  const { children } = props;

  const [mapState, setMapState] = React.useState(DEFAULT_MAP_STATE);

  const updateMapState = React.useCallback((mapDocument: null | MapDoc, timeDeltaUpdates: Partial<TimeDeltaRanges>) => {
    setMapState(current => {
      const ret = {
        mapDocument: mapDocument ?? current.mapDocument,
        timeDeltas: { 
          ...current.timeDeltas,
          ...timeDeltaUpdates,
        }
      };

      console.log("setMapState", { ret });
      return ret;
    });
  }, [setMapState]);

  return (
    <MapStateContext.Provider value={{ mapState, updateMapState }}>
        
      {/*<MapStateDispatchContext.Provider value={updateMapState}>*/}
      {children}
      {/*</MapStateDispatchContext.Provider>*/}
    </MapStateContext.Provider>
  );
}
  
export default MapStateProvider;
  



export function withMapState<P>(
  Child: React.FunctionComponent<P & MapStateProps>
): React.FunctionComponent<P> {
  const composed: React.FunctionComponent<P> = (props: P) => (
    <MapStateProvider>
      <MapStateContext.Consumer>
        {
          (mapStateProps) => {
            console.log(
              "withMapState", { mapStateProps }
            );
            return (
              <>
                {/*<MapStateDispatchContext.Consumer>*/}
                <Child {...mapStateProps} {...props}/>
              </>
            );    
          }
        }
      </MapStateContext.Consumer>
    </MapStateProvider>
  );
  
  composed.displayName = Child.displayName;
  
  return composed;
}
  