import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoadingSpinner from "../components/misc/LoadingSpinner";

import { useStateContext } from "../provider/StateProvider";

export interface AuthRouteProps {
  admin?: boolean;
}

function AuthRequired(
  props: React.PropsWithChildren<AuthRouteProps>
): JSX.Element {
  const { admin } = props;

  const state = useStateContext();
  const location = useLocation();

  if (state.isLoading) {
    return <LoadingSpinner/>;
  } else if (state.user !== null) {
    if (admin === true && state.config.admin !== true) {
      alert("This path requires admin priveledges");
      return <Navigate to="/" state={{ from: location }}/>;
    }

    return props.children as JSX.Element;
  } else {
    // If we don't return before this, the user isn't signed in.
    return <Navigate replace to="/signin"/>;
  }
}

export default AuthRequired;
