import React, { useMemo } from "react";

import {
  Button,
  ListGroup,
  ListGroupProps,
  ListGroupItemProps,
  Placeholder,
} from "react-bootstrap";

import { range } from "lodash";

import { Loading } from "../../misc/Types";
import { randInt } from "../../misc/Utils";


export interface ConfigListProps<V> {
  items: Loading | V[];
  titleSelector: (item: V) => string | number;
  contentFactory?: (item: V) => JSX.Element | null;
  itemPropsFactory?: (item: V) => undefined | ListGroupItemProps;
  openEditor?: (item: V) => void;
  onDelete?: (item: V) => void;
  disableEditorButton?: (item: V) => boolean | null | undefined;
  disableDeleteButton?: (item: V) => boolean | null | undefined;
}

function ConfigList<V>(props: ConfigListProps<V> & ListGroupProps): JSX.Element {
  const {
    items: rawItems,
    titleSelector,
    contentFactory,
    itemPropsFactory,
    openEditor,
    onDelete,
    disableEditorButton,
    disableDeleteButton,
    ...listGroupProps
  } = props;

  const items: Loading | V[] = useMemo(() => rawItems, [rawItems]);

  return (
    <ListGroup {...listGroupProps}>
      {
        items === Loading
          ? range(7).map(idx => (
            <PlaceholderListItem key={idx}
              showEditButton={typeof openEditor === "function"}
              showDeleteButton={typeof onDelete === "function"}
              showContent={typeof contentFactory === "function"}/>
          ))
          : items.map((item, idx) => (
            <ConfigListItem key={idx}
              item={item}
              titleSelector={titleSelector}
              contentFactory={contentFactory}
              itemPropsFactory={itemPropsFactory}
              openEditor={openEditor}
              onDelete={onDelete}
              disableDeleteButton={disableDeleteButton}
              disableEditorButton={disableEditorButton}
            />
          ))
      }
    </ListGroup>
  );

}


interface ConfigListItemProps<V> extends Omit<ConfigListProps<V>, "items"> {
  item: V;
}


function ConfigListItem<V>(props: ConfigListItemProps<V>): JSX.Element {
  const {
    item,
    titleSelector,
    contentFactory,
    itemPropsFactory,
    openEditor,
    onDelete,
    disableEditorButton,
    disableDeleteButton,
  } = props;

  const title = useMemo(() => titleSelector(item), [item, titleSelector]);

  const content = useMemo(
    () => typeof contentFactory === "function" ? contentFactory(item) : null,
    [item, contentFactory]
  );

  const itemProps = useMemo(
    () => typeof itemPropsFactory === "function" ? itemPropsFactory(item) : null,
    [item, itemPropsFactory]
  );


  return (
    <ListGroup.Item {...itemProps} className="d-flex justify-content-between align-self-stretch align-items-stretch w-100">
      <div className="d-flex justify-content-start border-right pr-3 w-25">
        <h3><b>{title}</b></h3>
      </div>
      <div className="w-100 py-2 px-3">
        {content}
      </div>
      {
        typeof openEditor === "function" || typeof onDelete === "function"
          ? <div style={{ width: "17.5%" }} className="align-self-stretch border-left pl-3">
            <div className="d-flex flex-column h-100 justify-content-center align-items-stretch">
              {
                typeof openEditor === "function"
                  ? <Button className="my-2"
                    disabled={disableEditorButton && Boolean(disableEditorButton(item))}
                    onClick={() => openEditor(item)}>
                          Edit
                  </Button>
                  : null
              }
              {
                typeof onDelete === "function"
                  ? <Button className="my-2" variant="danger"
                    disabled={disableDeleteButton && Boolean(disableDeleteButton(item))}
                    onClick={() => onDelete(item)}>
                          Delete
                  </Button>
                  : null
              }
            </div>
          </div>
          : null
      }
    </ListGroup.Item>
  );
}


interface PlaceholderListItemProps {
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showContent?: boolean;
}

function PlaceholderListItem(props: PlaceholderListItemProps): JSX.Element {
  const titleWidth = useMemo(() => randInt(1, 4), []);


  return (
    <Placeholder as={ListGroup.Item} animation="glow" className="d-flex justify-content-between w-100">
      <div className="d-flex justify-content-start">
        <Placeholder md={titleWidth}/>
        {
          props.showContent
            ? <div className="w-100">
              <Placeholder md={randInt(2, 5)} />
              <Placeholder md={randInt(2, 5)} />
              <Placeholder md={randInt(2, 5)} />
              <Placeholder md={randInt(2, 5)} />
            </div>
            : null
        }
      </div>
      <div className="d-flex flex-column justify-content-center w-auto">
        {
          props.showEditButton
            ? <Placeholder.Button md={4} className="my-2"/>
            : null
        }
        {
          props.showDeleteButton
            ? <Placeholder.Button md={4} className="my-2" variant="danger"/>
            : null
        }
      </div>
    </Placeholder>
  );
}


export default ConfigList;
