import isObject from "lodash/isObject";

import type mapboxgl from "mapbox-gl";
import { Point } from "mapbox-gl";
import { FeatureType } from "./Types";

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace Geo {
  // Represents a latitidue and longitude (in degrees)
  export type LngLat = [number, number];

  // Extends LatLng to also have an altitude, in meters.
  export type LngLatAlt = [number, number, number];

  // Wraps both LngLat and LngLatAlt.
  export type Coordinate = LngLat | LngLatAlt;

  export type NormalVector = [number, number, number];

  export type BoundingLatLng = [LngLat, LngLat];

  export interface MinMaxLatLng {
    minLat: number;
    maxLat: number;
    minLon: number;
    maxLon: number;
  }


  export interface TracklinePoint {
    epoch: number,
    point: Coordinate,
  }

  export type TracklinePoints = Record<string | number, TracklinePoint>;
}

export enum GeoType {
  Point = "Point",
  LineString = "LineString",
  Polygon = "Polygon",
}



export type FeatureTypeWithTracks = FeatureType.Gliders | FeatureType.Stations; 

export type FeatureTypeWithNoTracks = Exclude<FeatureType, FeatureTypeWithTracks>;

export type FeatureTypeHasTrackLine<F extends FeatureType> =
  F extends FeatureTypeWithTracks
    ? true
    : false;



export function FeatureTypeToGeoType(dt: FeatureType): GeoType {
  switch (dt) {
    case FeatureType.Buoys:
    case FeatureType.Ellipses:
    case FeatureType.FishingGear:
    case FeatureType.Gliders:
    case FeatureType.Sightings:
    case FeatureType.Stations:
      return GeoType.Point;
    case FeatureType.SlowZones:
    case FeatureType.LeaseAreas:
    case FeatureType.Sma:
      return GeoType.Polygon;
    case FeatureType.BearingLines:
      return GeoType.LineString;
    default:
      throw new Error(`can't get geo type for unknown feature data type: ${dt}`);
  }
}



export function validLatLng(lon: number, lat: number): boolean {
  return (
    lon >= -180 && lon <= 180 &&
    lat >= -90 && lat <= 90
  );
}

export function isTracklinePoint(x: unknown): x is Geo.TracklinePoint {
  const casted = x as Geo.TracklinePoint;
  return (
    casted &&
    typeof casted.epoch === "number" &&
    isCoordinate(casted.point)
  );
}

export function isTracklineArray(x: unknown): x is Geo.TracklinePoint[] {
  const casted = x as Geo.TracklinePoint[];

  if (!Array.isArray(casted)) return false;
  if (casted.length === 0) return true;

  // Assume if the first point is valid, the rest are.
  return isTracklinePoint(casted[0]);
}

export function isTracklineRecord(x: unknown): x is Record<string, Geo.TracklinePoint> {
  if (isObject(x)) {
    const ids = Object.keys(x);

    if (ids.length === 0) return true;

    // Assume if the first point is valid, the rest are.
    return isTracklinePoint(x[ids[0]]);
  }

  return false;
}


export function sortByDistanceTo(
  targetPoint: mapboxgl.Point,
  features: mapboxgl.MapboxGeoJSONFeature[] 
): mapboxgl.MapboxGeoJSONFeature[] {
  const sortable: [number, mapboxgl.MapboxGeoJSONFeature][] = [];
  const nonPointFeats: mapboxgl.MapboxGeoJSONFeature[] = []; 
  for (const feat of features) {
    if (feat.geometry.type !== "Point") {
      nonPointFeats.push(feat);
      continue;
    }
    
    const featPoint = new Point(feat.geometry.coordinates[0], feat.geometry.coordinates[1]);

    const dist = featPoint.distSqr(targetPoint);

    sortable.push([dist, feat]);
  }

  const sorted = sortable.sort((a, b) => {
    if (a[0] > b[0]) {
      return -1;
    } else if (a[0] < b[0]) {
      return 1;
    } else {
      return 0;
    }
  });

  return sorted.map(pair => pair[1]).concat(...nonPointFeats);
}


export function isCoordinate(x: unknown): x is Geo.Coordinate {
  if (Array.isArray(x) &&
      (x.length === 2 || x.length === 3) &&
      typeof x[0] === "number" &&
      typeof x[1] === "number") {
    return validLatLng(x[0], x[1]);
  }

  return false;
}

export const isGeo = {
  tracklinePoint: isTracklinePoint,
  tracklineArray: isTracklineArray,
  tracklineRecord: isTracklineRecord,
  coordinate: isCoordinate,
};
