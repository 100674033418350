
import React, { useMemo } from "react";
import "./SightingAgeLegend.css";
import type { TimeDelta } from "../../map/MapManager";


export interface SightingAgeLegendProps {
    timeDelta: TimeDelta;
}

const SightingAgeLegend = React.memo((props: SightingAgeLegendProps): JSX.Element => {
  const { timeDelta } = props;

  return (
    <div className="smaller-text">
      <div>Sighting Age Scale:</div>
      <div className="sighting-age-legend-scale"/>
      <div className="d-flex w-100 flex-row justify-content-between">
        <span>
          {timeDelta.new?.label ?? "Just Reported"}
        </span>
        <span>
          {timeDelta.old.label}
        </span>
      </div>
    </div>
  );
});

SightingAgeLegend.displayName = "SightingAgeLegend";

export default SightingAgeLegend;