import React from "react";

import Spinner, { SpinnerProps } from "react-bootstrap/Spinner";
import OverlayTrigger, { OverlayTriggerProps } from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button, { ButtonProps } from "react-bootstrap/Button";

import type { WithRequired } from "../../misc/Types";

import { kebabCase, omit } from "lodash";

export interface LoadingButtonProps extends Omit<ButtonProps, "onMouseOver"> {
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  isLoading?: boolean;
  tooltip?: string;
  placement?: OverlayTriggerProps["placement"];
  delay?: OverlayTriggerProps["delay"];
  tooltipClassName?: string;
  spinnerSize?: SpinnerProps["size"];
}

const LoadingButtonWithTooltip = (
  props: WithRequired<React.PropsWithChildren<LoadingButtonProps>, "tooltip">
): JSX.Element => {
  const { tooltipClassName, placement, tooltip, ...restProps } = props;

  return (
    <OverlayTrigger placement={placement} delay={props.delay}
      overlay={
        <Tooltip id={kebabCase(tooltip)}
          className={tooltipClassName}>
          {tooltip}
        </Tooltip>
      }>
      <LoadingButtonBase {...restProps}/>
    </OverlayTrigger>
  );
};

const LoadingButtonBase = (props: LoadingButtonProps): JSX.Element => {
  const { isLoading, className, onMouseOver, ...restProps } = props;

  const buttonClass = ["loading-button"];

  const innerContainerClass = [
    "grid-overlap-item",
  ];

  if (className) {
    buttonClass.push(className);
    innerContainerClass.push(className);
  }

  return (
    <div className='d-inline-block w-auto h-auto' onMouseOver={onMouseOver}>
      <Button className={className} {...omit(restProps, ["spinnerSize", "isLoading"])}>
        <div className="grid-overlap w-auto h-auto">
          <div style={{ visibility: isLoading ? "inherit" : "hidden" }}
            className={innerContainerClass.join(" ")}
          >
            <Spinner
              animation="border"
              as="span"
              size={props.spinnerSize}
              variant={props.variant}
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          <div style={{ visibility: isLoading ? "hidden" : "inherit" }}
            className={innerContainerClass.join(" ")}
          >
            {props.children}
          </div>
        </div>
      </Button>
    </div>
  );
};

const LoadingButton = (props: LoadingButtonProps): JSX.Element => {
  return typeof props.tooltip === "string"
    ? <LoadingButtonWithTooltip tooltip={props.tooltip} {...props}/>
    : <LoadingButtonBase {...props}/>;
};


export default LoadingButton;
