/**
 * Layers for polygon feature types that are given to every map (lease areas + slow zones)
 */


import { FeatureType } from "../../misc/Types";
import { LayerGroup, SingleLayerGroup } from "../LayerGroup";
import { LayerParamOptions, LayerType } from "../LayerTypes";
import { fillInDefaultOptions } from "./common";


export const SMA_LAYER_GROUP: LayerGroup = new SingleLayerGroup({
  layerId: FeatureType.Sma,
  sourceId: FeatureType.Sma,
  factory: function(params?: LayerParamOptions): LayerType.Fill {
    const opts = fillInDefaultOptions(params);

    return {
      id: FeatureType.Sma,
      type: "fill",
      source: FeatureType.Sma,
      filter: ["==", ["get", "dataType"], "sma"],
      layout: {
        "visibility": opts.visible ? "visible" : "none"
      },
      paint: {
        "fill-color": "#770000",
        "fill-opacity": 0.15,
        "fill-outline-color": "#000000",
        "fill-antialias": true
      }
    };
  }
});

export const LEASE_AREA_LAYER_GROUP: LayerGroup = new SingleLayerGroup({
  layerId: FeatureType.LeaseAreas,
  sourceId: FeatureType.LeaseAreas,
  factory: function(params?: LayerParamOptions): LayerType.Fill {
    const opts = fillInDefaultOptions(params);

    return {
      id: FeatureType.LeaseAreas,
      type: "fill",
      source: FeatureType.LeaseAreas,
      filter: ["==", ["get", "dataType"], "leaseArea"],
      layout: {
        "visibility": opts.visible ? "visible" : "none"
      },
      paint: {
        "fill-color": ["get", "fillColor"],
        "fill-opacity": ["get", "fillOpacity"],
        "fill-outline-color": ["get", "fillOutlineColor"]
      }
    };
  }
});


export const SLOW_ZONE_LAYER_GROUP: LayerGroup = new SingleLayerGroup({
  layerId: FeatureType.SlowZones,
  sourceId: FeatureType.SlowZones,
  factory: function(params?: LayerParamOptions): LayerType.Fill {
    const opts = fillInDefaultOptions(params);

    return {
      id: FeatureType.SlowZones,
      type: "fill",
      source: FeatureType.SlowZones,
      filter: ["==", ["get", "dataType"], "slowZone"],
      layout: {
        "visibility": opts.visible ? "visible" : "none"
      },
      paint: {
        "fill-color": [
          "case",
          [
            "all",
            ["has", "displayProperties.triggerType"],
            ["==", ["to-string", ["get", "displayProperties.triggerType"]], "Acoustic"]
          ],
          "#F7FD04",
          "#F7FD04",
        ],
        "fill-opacity": 0.15,
        "fill-outline-color": "#000000",
        "fill-antialias": true
      }
    };
  }
});
