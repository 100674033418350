import React, { useRef, useState, useMemo, useEffect, HTMLAttributes, useCallback } from "react";
import { debounce } from "lodash";


import "./BoundedRangeSlider.css";


export interface Values {
    high: number;
    low: number;
}


export interface BoundedRangeSliderProps extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
    min: number;
    max: number;
    
    values: Values;
    /** optional label formatting */
    label?: (value: number) => string | JSX.Element;
    /** the minimum gap between the high and low sliders. defaults to 0 if not set */
    minGap?: number;
    /** onChange handler */
    onChange({ low, high }: Values);
}

const TRACK_STYLE: React.CSSProperties = {
  position: "absolute",
  width: "100%",
  borderRadius: "3px",
  height: "5px",
  zIndex: 1001,
};

const RANGE_STYLE: React.CSSProperties = {
  position: "absolute",
  zIndex: 1002,
  borderRadius: "3px",
  height: "5px",
};

const LABEL_STYLE_COMMON: React.CSSProperties = {
  fontSize: "12px",
  marginTop: "20px",
};


export const BoundedRangeSlider = (props: BoundedRangeSliderProps): JSX.Element => {
  const { 
    min, 
    max, 
    onChange, 
    label, 
    values, 
    minGap: optMinGap,
    style, 
    ...rest
  } = props; 


  const [lowValue, setLowValue] = useState(values.low);
  const [highValue, setHighValue] = useState(values.high);

  const minGap = useMemo(() => optMinGap ?? 0, [optMinGap]); 

  const range = useRef<HTMLDivElement>(null);

  const clamp = useCallback((n: number) => Math.max(Math.min(n, max), min), [max, min]);

  const getRatio = (value: number) => (value - min) / (max - min);


  const changeHighValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let newHigh = clamp(parseInt(e.currentTarget.value));
        
    // push the other slider with it, maintaining the gap 
    if (newHigh <= lowValue) {
      const newLow = clamp(newHigh - minGap);

      // if we're at the end, we might need to forcibly adjust one side to stay away from the end
      if (newHigh - newLow < minGap) {
        newHigh = newLow + minGap;
      }
      setLowValue(newLow);
    }

    setHighValue(newHigh);
  }, [lowValue, minGap, max]);

  // identical to changeHighValue, but with signs + comparisons flipped as needed 
  const changeLowValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let newLow = clamp(parseInt(e.currentTarget.value));
        
    if (newLow >= highValue) {
      const newHigh = clamp(newLow + minGap);

      if (newHigh - newLow < minGap) {
        newLow = newHigh - minGap;
      }
            
      setHighValue(newHigh);
    }

    setLowValue(newLow);

  }, [highValue, minGap, min]);

  const makeLabel = useCallback((value: number) => {
    return typeof label === "function"
      ? label(value)
      : `${value}`;
  }, [label]);

  const lowLabel = useMemo(() => makeLabel(lowValue), [makeLabel, lowValue]);
  const highLabel = useMemo(() => makeLabel(highValue), [makeLabel, highValue]);


  useEffect(() => {
    onChange({ low: lowValue, high: highValue });

    if (range.current) {
      const minPercen = getRatio(lowValue) * 100;
      const maxPercen = getRatio(highValue) * 100;

      range.current.style.left = `${Math.round(minPercen)}%`;
      range.current.style.width = `${Math.round(maxPercen - minPercen)}%`;
    }

  }, [lowValue, highValue]);

  return (
    <div 
      className="d-flex w-100 mt-4 align-items-center justify-content-center" 
      style={{ height: "3rem", ...(style ?? {}) }}
      {...rest}
    >
      <input 
        id="lowValue" 
        className="thumb thumb-low"
        type="range" 
        value={lowValue} 
        min={min} 
        max={max} 
        onChange={changeLowValue}
        style={{ zIndex: lowValue < highValue ? "1005" : undefined }}
      />
      <input 
        id="highValue"
        className="thumb thumb-high"
        type="range" 
        value={highValue} 
        min={min} 
        max={max} 
        onChange={changeHighValue}
      />

      <div className="position-relative w-100">
        <div className="position-absolute border" style={TRACK_STYLE} />
        <div ref={range} className="position-absolute border bg-primary" style={RANGE_STYLE} />
        <div className="position-absolute w-100 mt-3 text-left h-auto" style={{}}>{lowLabel}</div>
        <div className="position-absolute w-100 mt-3 text-right h-auto" style={{}}>{highLabel}</div>
      </div>
    </div>
  );
};

BoundedRangeSlider.displayName = "BoundedRangeSlider";
