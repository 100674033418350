import React, { useState } from "react";
import FormCheck from "react-bootstrap/FormCheck";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import LoadingButton from "../misc/LoadingButton";

import { UserDoc } from "../../misc/Types";
import { UsersAPI } from "../../firebase/API";


export interface ManageUserProps {
  user: UserDoc;
  maps: string[];
  currentUserEmail: string;
  reloadUsers: () => Promise<void>;
}

enum OpenModalType {
  Disable,
  Delete,
}

const ManageUser = (props: React.PropsWithoutRef<ManageUserProps>): JSX.Element => {
  const { user, maps, currentUserEmail } = props;


  // Make sure we disable the ability to disable ourselves,
  const allowDisable = currentUserEmail !== user.email;

  const [userDisabled, setUserDisabled] = useState<boolean>(user.disabled ?? false);
  const [mapPerms, setMapPerms] = useState<Record<string, boolean>>(user.mapPermissions);
  const [showModal, setShowModal] = useState<null | OpenModalType>(null);
  const [permUpdating, setPermUpdating] = useState<Record<string, boolean>>({});
  const [toggleDisableLoading, setToggleDisableLoading] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);

  const toggleDisabledConfirmed = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (currentUserEmail === user.email) {
      alert("Cannot disable the current user");
      return;
    }

    setShowModal(null);
    setToggleDisableLoading(true);

    UsersAPI.toggleUserDisable(user.uid)
      .then(disabled => setUserDisabled(disabled))
      .finally(() => setToggleDisableLoading(false));
  };

  const deleteUser = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (currentUserEmail === user.email) {
      alert("Cannot delete yourself");
      return;
    }

    setShowModal(null);

    if (!deleteUserLoading) {
      setDeleteUserLoading(true);

      UsersAPI.deleteUser(user.uid)
        .catch(error => alert(error))
        .then(() => props.reloadUsers())
        .catch(error => alert(error))
        .finally(() => setDeleteUserLoading(false));
    }
  };

  const updatePermFactory = (map: string): () => void => {
    return () => {
      setPermUpdating(updating => {
        return { ...updating, [map]: true };
      });

      UsersAPI.updateUserPermissions(user.uid, { [map]: !mapPerms[map] })
        .then(mapPerms => setMapPerms(mapPerms))
        .catch(error => console.error(error))
        .finally(() => {
          setPermUpdating(updating => {
            return { ...updating, [map]: false };
          });
        });
    };
  };

  const handleConfirm = (e: React.MouseEvent<HTMLElement>) => {
    switch (showModal) {
      case OpenModalType.Delete:
        deleteUser(e);
        break;
      case OpenModalType.Disable:
        toggleDisabledConfirmed(e);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <td className='sticky-cell left'>
        {
          currentUserEmail === user.email
            ? `${user.email ?? user.displayName} - (you)`
            : user.email ?? user.displayName
        }
      </td>
      {
        maps.map(map => (
          <td key={`${user.uid}-${map}`} onClick={updatePermFactory(map)}>
            <div className='inline-block m-auto h-100 w-100'>
              {
                permUpdating[map] === true
                  ? <Spinner className='align-middle' animation="border" role="status" size='sm' variant='primary'>
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  : <FormCheck inline className='m-auto fix-left-offset-center' checked={mapPerms[map]} readOnly type="switch"/>
              }
            </div>
          </td>
        ))
      }
      <td className='sticky-cell right' onClick={() => allowDisable ? setShowModal(OpenModalType.Disable) : null}>
        <div className='inline-block m-auto h-100 w-100'>
          {
            toggleDisableLoading
              ? <Spinner className='middle-align' animation="border" role="status" size='sm' variant='primary'>
                <span className="sr-only">Loading...</span>
              </Spinner>
              : <FormCheck inline type="switch">
                <FormCheck.Input disabled={!allowDisable} checked={!userDisabled} readOnly/>
                <FormCheck.Label className='m-auto fix-left-offset-center'/>
              </FormCheck>
          }
        </div>
      </td>
      <td className='sticky-cell right' onClick={() => setShowModal(OpenModalType.Delete)}>
        <div className='inline-block middle'>
          {
            <LoadingButton className="p-0 vertical-align" size="sm"
              variant="danger" isLoading={deleteUserLoading}>
              Delete User
            </LoadingButton>
          }
        </div>
      </td>
      <Modal show={showModal !== null} onHide={() => setShowModal(null)} className='no-padding'>
        <Modal.Header>
          Confirm
        </Modal.Header>
        <Modal.Body>
          <span className='block'>
            Confirm
            {
              showModal == OpenModalType.Disable
                ? userDisabled ? " re-enabling " : " disabling "
                : " deleting "
            }
            <b>{user.email}</b>?
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(null)}>
            Cancel
          </Button>
          <Button onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageUser;
