import React, { useState, useEffect } from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import Dropdown from "react-bootstrap/Dropdown";

import { Link } from "react-router-dom";

import { useStateContext } from "../../provider/StateProvider";

import AdminNav from "./AdminNav";

import isEmpty from "lodash/isEmpty";
import { stringSortFactory } from "../../misc/Utils";


export interface TopNavProps {
  title?: string,
  signOut?: boolean
  openSignIn?: () => void;
}

export type IdNameMap = {
  id: string;
  displayName: string;
}

export interface MapLinksProps {
  mapLinks: IdNameMap[];
}

function MapLinks(props: MapLinksProps): JSX.Element {
  const { mapLinks } = props;

  if (mapLinks.length <= 2) {
    return (
      <>
        {
          mapLinks.map(mapInfo => (
            <NavItem key={mapInfo.id}>
              <a href={`/map/${mapInfo.id}`} className="nav-link"
                style={{ textTransform: "capitalize" }}>
                {`${mapInfo.displayName} Map`}
              </a>
            </NavItem>
          ))
        }
      </>
    );
  }
  return (
    <Dropdown>
      <Dropdown.Toggle as={Nav.Link} className="dropdown-item d-inline-flex flex-row-reverse align-items-center">
        <span className="ml-1">Maps</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          mapLinks.map(mapInfo => (
            <Dropdown.Item key={mapInfo.id} href={`/map/${mapInfo.id}`} style={{ textTransform:"capitalize", color: "#000000" }}>
              {mapInfo.displayName}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

function TopNav(props: React.PropsWithoutRef<TopNavProps>): JSX.Element {
  const { openSignIn } = props;
  const state = useStateContext();

  const [mapLinks, setMapLinks] = useState<IdNameMap[]>([]);

  useEffect(() => {
    setMapLinks(() => {
      if (state.user === null || isEmpty(state.config.maps)) {
        return [];
      }

      const newMapLinks: IdNameMap[] = [];

      for (const mapDoc of Object.values(state.config.maps)) {
        newMapLinks.push({
          id: mapDoc.id,
          displayName: mapDoc.displayName ?? mapDoc.id,
        });

        newMapLinks.sort(stringSortFactory({
          preproc: idMap => idMap.id.toLowerCase()
        }));
      }

      return newMapLinks;
    });
  }, [state.config]);

  return (
    <Navbar bg="light" expand="md" className="sticky-top mb-0">
      <Navbar.Brand as={Link} to="/" >
        <span style={{ fontWeight:"bold", color:"rgba(0, 0, 0, 0.5)" }}>
          Mysticetus Command Center
        </span>
      </Navbar.Brand>
      {
        props.title
          ? <Navbar.Brand>
            <span style={{ color:"rgba(0, 0, 0, 0.5)" }}>
              {props.title}
            </span>
          </Navbar.Brand>
          : null
      }
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <NavItem>
            <Nav.Link as={Link} to="/">Home</Nav.Link>
          </NavItem>
          {
            state.user && state.config?.admin && !props.signOut
              ? <AdminNav as={Nav.Link} drop='down'/>
              : null
          }
          {
            (state.user && state.config && !props.signOut && mapLinks.length > 0)
              ? <>
                <MapLinks mapLinks={mapLinks}/>
                <NavItem>
                  <Nav.Link as={Link} to="/settings">Settings</Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link as={Link} to="/?signOut=true">Sign Out</Nav.Link>
                </NavItem>
              </>
              : typeof openSignIn === "function"
                ? <NavItem>
                  <Nav.Link as='button' onClick={() => openSignIn()}>
                        Sign In
                  </Nav.Link>
                </NavItem>
                : null
          }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default TopNav;
