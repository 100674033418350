import type { Map } from "mapbox-gl";
import { HSL, ColorString, RGB } from "./Colors";


// "#00b800";
export const DEFAULT_SIGHTING_FILL_COLOR: HSL = RGB.toHsl({ 
  red: 0,
  green: 0xb8,
  blue: 0,
});

export const SIGHTING_DECAY_COLORS: HSL[] = Array.from(HSL.hslRange(DEFAULT_SIGHTING_FILL_COLOR, 10));


// "#4472c4"
export const DEFAULT_BIRD_SIGHTING_FILL_COLOR: HSL = RGB.toHsl({
  red: 0x44,
  green: 0x72,
  blue: 0xc4,
});

export const BIRD_SIGHTING_DECAY_COLORS: HSL[] = Array.from(HSL.hslRange(DEFAULT_BIRD_SIGHTING_FILL_COLOR, 10));



const DEFAULT_PRIORITY_SIGHTING_FILL_COLOR: string = "#ff0000";

// #ffcd5c;
const DEFAULT_STATION_FILL_COLOR: RGB = { red: 0xff, green: 0xcd, blue: 0x5c };


const ICON_PULSE_DURATION_MS = 5000;



export interface Size {
    height: number;
    width: number;
}

export function formatColor(color: ColorString | RGB | HSL): ColorString {
  if (typeof color === "string") {
    return color;
  } else if ("red" in color) {
    return RGB.format(color);
  } else {
    return HSL.format(color);
  }
}


export function loadSvgImage(iconSvg: string, size: number | Size = 100): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    try {
      // make into a `Size` regardless of the incoming type
      if (typeof size === "number") {
        size = { width: size, height: size };
      }

      // console.log()
      const inlineSvg = "data:image/svg+xml;charset=utf-8;base64," + btoa(iconSvg);

      const image = new Image(size.width, size.height);

      image.onload = () => resolve(image);
      image.onerror = reject;

      image.src = inlineSvg;
    } catch (err) {
      reject(err);
    }
  });
}


export function polygonIconSvg(
  borderColor: string | RGB | HSL,
  fillColor: string | RGB | HSL,
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return (
    `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <rect width="95" height="95" fill="${fillColorHex}" stroke="${borderColorHex}" stroke-width="10"/>
        </svg>`
  );
}

export function birdSightingIconSvg(
  borderColor: string | RGB | HSL,
  fillColor: string | RGB | HSL = DEFAULT_BIRD_SIGHTING_FILL_COLOR,
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return (
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100">
            <polygon points="50,0 0,100 100,100" style="fill:${borderColorHex}"/>
            <polygon points="50,15 13,90 87,90" style="fill:${fillColorHex}"/>
        </svg>`
  );
}

export function bearingLineIconSvg(
  lineColor: string | RGB | HSL,
): string {
  return (
    `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <line x1="10" y1="90" x2="90" y2="10" stroke="${formatColor(lineColor)}" stroke-width="5" stroke-linecap="round"/>
        </svg>`
  );
}

export function ellipseIconSvg(
  borderColor: string | RGB | HSL,
  fillColor: string | RGB | HSL,
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return (
    `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <ellipse 
                cx="50" cy="50" 
                rx="35" ry="45" 
                transform="rotate(45 50 50)" 
                fill="${fillColorHex}" stroke="${borderColorHex}"
                stroke-width="5"
            />
        </svg>`
  );
}


export function regularSightingSvg(
  borderColor: string | RGB | HSL,
  fillColor: string | RGB | HSL = DEFAULT_SIGHTING_FILL_COLOR
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  // follows the same stroke width/radius ratio as the sightingWithImg svg lower down in the file
  return (
    `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="50" cy="50"
                r="49"
                fill="${borderColorHex}" 
                stroke-width="2"
                stroke="#000000"
            />    
            <circle 
                cx="50" cy="50" 
                r="33"
                fill="${fillColorHex}"
            />
        </svg>`
  );
}


export function prioritySightingSvg(
  borderColor: string | RGB | HSL, 
  fillColor: string | RGB | HSL = DEFAULT_PRIORITY_SIGHTING_FILL_COLOR
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return ( 
    `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="80 216 800 760">
            <path 
                d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z"
                fill="${borderColorHex}"
                stroke="#000000"
                stroke-width="2"
            />
            <path 
                d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Z"
                fill="${fillColorHex}"
            />
        </svg>`
  );

  /// <svg xmlns="http://www.w3.org/2000/svg" viewBox="80 216 800 760">             <path d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" fill="${borderColorHex}"></path>             <path d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Z" fill="${fillColorHex}">
  /// </path>
  /// </svg>
}


export function priorityBirdSightingIconSvg(
  borderColor: string | RGB | HSL,
  fillColor: string | RGB | HSL = DEFAULT_BIRD_SIGHTING_FILL_COLOR,
): string {
  return prioritySightingSvg(borderColor, fillColor);
}


interface StyleImageInterface {
    height: number;
    width: number;
    map: Map;
    context: null | CanvasRenderingContext2D;
    data: Uint8ClampedArray;
    onAdd: () => void;
    render: () => boolean;
}

export function newSightingWithImage(
  map: Map,
  borderColor: string | RGB | HSL,
  fillColor: string | RGB | HSL = DEFAULT_SIGHTING_FILL_COLOR,
  size = 64,
): StyleImageInterface {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);
    
  return {
    map,
    width: size,
    height: size,
    context: null,
    data: new Uint8ClampedArray(size * size * 4),
    onAdd: function() {
      const canvas = document.createElement("canvas");
      canvas.height = size;
      canvas.width = size;
      this.context = canvas.getContext("2d", { willReadFrequently: true })!;
    },
    render: function() {
      if (!this.context) {
        return false;
      }

      const phase = 
                (performance.now() % ICON_PULSE_DURATION_MS) 
                * (2 * Math.PI / ICON_PULSE_DURATION_MS);
            
      // normalizes from -1..1 to 0..0.25 
      const t = (Math.sin(phase) + 1) / 8;

      // 0.5 is the maximum allowed radius (within a unit square),
      // and 't' can be half of that at most.
      const normalizedRadius = 0.5 - t;

      // since half of the stroke falls outside the radius, the outer edge of 
      // the circle will clip with the edges of the canvas if not accounted for.
      const fullRadius = size * normalizedRadius;
      const strokeWidth = fullRadius / 3;
      // subtracting half the stroke will keep everything within the 
      // canvas bounds.
      const radius = fullRadius - (strokeWidth / 2);

      // clear the canvas
      this.context.clearRect(0, 0, size, size);
            
      // draw the circle
      this.context.beginPath();
      this.context.arc(size / 2, size / 2, radius, 0, Math.PI * 2);
      this.context.fillStyle = fillColorHex;
      this.context.strokeStyle = borderColorHex;
      this.context.lineWidth = strokeWidth;
      this.context.fill();
      this.context.stroke();
            
      this.data = this.context.getImageData(0, 0, size, size).data;
      map.triggerRepaint();
      return true;
    },
  };
}



export function sightingWithImageSvg(
  borderColor: string | RGB | HSL,
  fillColor: string | RGB | HSL = DEFAULT_SIGHTING_FILL_COLOR,
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return (`\
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" fill="${borderColorHex}">
                <animate
                    attributeName="r"
                    values="17;25;17"
                    dur="5s"
                    repeatCount="indefinite"
                    begin="0" 
                />
            </circle>
            <circle cx="50" cy="50" fill="${fillColorHex}">
                <animate
                    attributeName="r"
                    values="13.6;20;13.6"
                    dur="5s"
                    repeatCount="indefinite"
                    begin="0" 
                />
            </circle>
        </svg>`
  );
}    
    
export function stationIconSvg(
  borderColor: string | RGB | HSL,
  fillColor: string | RGB | HSL = DEFAULT_STATION_FILL_COLOR
): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor);

  return (
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100">
            <path d="M0 100 L50  0 L100 100 L50 75 Z" stroke="black" fill="${borderColorHex}" />
            <path d="M22 78 L50 22 L 78  78 L50 64 Z" stroke="black" fill="${fillColorHex}" />
        </svg>`
  );
}


