import React, { useState, useEffect } from "react";

import { useMatch, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TopNav from "../components/nav/TopNav";

function UnknownPage() {
  const slug = useMatch(":slug");
  const navigate = useNavigate();

  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const id = setInterval(() => setCountdown(c => c - 1), 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      navigate("/");
    }

  }, [countdown]);


  return (
    <>
      <TopNav/>
      <Container fluid>
        <Row>
          <Col md='auto' style={{ textAlign: "center" }}>
            <h1>404</h1>
          </Col>
          <Col md='auto' style={{ textAlign: "center" }}>
            {
              slug
                ? `Unknown resource - '${slug}'`
                : "Unknown resource"
            }
          </Col>
          <Col md='auto' style={{ textAlign: "center" }}>
          Returning home in {Math.max(0, countdown)} seconds
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UnknownPage;
