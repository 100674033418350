import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";

import AdminNav from "./AdminNav";

import { useStateContext } from "../../provider/StateProvider";
import { stringSortFactory } from "../../misc/Utils";

import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";

import type { IdNameMap } from "./TopNav";

const settings_cog_icon = new URL("/public/static/icons/settings_cog.svg", import.meta.url);

export interface MapNavProps {
  excludeMap?: string;
  compact?: boolean;
  drop?: "up" | "down";
  iconWidth?: number;
  iconHeight?: number;
  className?: string;
}

const MapNav = (props: React.PropsWithoutRef<MapNavProps>): JSX.Element => {
  const state = useStateContext();

  let { compact, drop, iconWidth, iconHeight } = props;

  const [mapDocs, setMapDocs] = useState<null|IdNameMap[]>(null);


  useEffect(() => {
    if (state.config) {
      const sortedMapDocs: IdNameMap[] = [];

      for (const mapDoc of Object.values(state.config.maps)) {
        if (mapDoc.id === props.excludeMap) {
          continue;
        }

        sortedMapDocs.push({
          id: mapDoc.id,
          displayName: mapDoc.displayName ?? mapDoc.id,
        });
      }

      sortedMapDocs.sort(stringSortFactory({
        preproc: mapDoc => mapDoc?.displayName?.toLowerCase(),
      }));

      setMapDocs(sortedMapDocs);
    }
  }, [state]);

  compact = typeof compact === "boolean" ? compact : false;
  drop = drop ?? "down";
  iconWidth = iconWidth ?? 28;
  iconHeight = iconHeight ?? 28;

  const toggleClasses: string[] = [
    "map-nav-toggle",
    "py-0",
  ];

  if (typeof props.className === "string") {
    toggleClasses.push(props.className);
  }

  return (
    <Dropdown drop={drop} className='center-middle'>
      <Dropdown.Toggle as='button' className={toggleClasses.join(" ")}>
        <Image className="ml-1" width={iconWidth} height={iconHeight} src={settings_cog_icon.href}/>
      </Dropdown.Toggle>
      <Dropdown.Menu className="map-menu py-2 px-3">
        {
          state.config?.admin
            ? <AdminNav toggleClassName="px-0 py-1" menuClassName="map-menu" compact={compact}/>
            : null
        }
        <Dropdown.Item className="px-0 py-1" href='/'>
          Home
        </Dropdown.Item>
        {
          Array.isArray(mapDocs)
            ? mapDocs.map(mapDoc => {
              return (
                <Dropdown.Item className="px-0 py-1" key={mapDoc.displayName ?? mapDoc.id} href={`/map/${mapDoc.id}`} style={{ textTransform:"capitalize" }}>
                  { `${mapDoc.displayName ?? mapDoc.id} Map` }
                </Dropdown.Item>
              );
            })
            : <Spinner animation="border" as='span' variant='primary'>
              <span className="sr-only">Loading...</span>
            </Spinner>
        }
        <Dropdown.Item className="px-0 py-1" href='/?signOut=true'>
          Sign Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MapNav;
