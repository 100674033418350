import { ColorString } from "../map/Colors";
import { IconColorStrings, PulsingIconProps, RenderFrameProps } from "./groups/common";



export enum IconGeometry {
    Circle = "Circle",
    Polygon = "Polygon",
}

/** 
 * A 2d point in a plane that spans 100 units in both the x and y direction 
 * (i.e, x and y should be between 0 and 100)
 */
export interface Point {
    x: number;
    y: number;
}


export interface Polygon { 
    points: Point[];
    fillColor: ColorString;
}   

interface CircleIcon {
    geometry: IconGeometry.Circle;
}

interface PolygonIcon {
    geometry: IconGeometry.Polygon;
    getPolygons(iconColors: IconColorStrings): Iterable<Polygon>;
}

export type Icon = CircleIcon | PolygonIcon;



function circleFrameRenderer({ ctx, size, t, colors }: RenderFrameProps): boolean {
  // 0.5 is the maximum allowed radius (within a unit square),
  // and 't' can be half of that at most.
  const normalizedRadius = 0.5 - (t / 4);

  // since half of the stroke falls outside the radius, the outer edge of 
  // the circle will clip with the edges of the canvas if not accounted for.
  const fullRadius = size * normalizedRadius;
  const strokeWidth = fullRadius / 3;
  // subtracting half the stroke will keep everything within the 
  // canvas bounds.
  const radius = fullRadius - (strokeWidth / 2);

  // clear the canvas
  ctx.clearRect(0, 0, size, size);

  // draw the circle
  ctx.beginPath();
  ctx.arc(size / 2, size / 2, radius, 0, Math.PI * 2);
  ctx.fillStyle = colors.fillColor;
  ctx.strokeStyle = colors.borderColor;
  ctx.lineWidth = strokeWidth;
  ctx.fill();
  ctx.stroke();

  return true;
}



interface Scalars {
    size: number;
    scalePointsBy: number;
    t: number;
    targetRadiusScale: number;
}

function makeScalars({ t, size }: { t: number, size: number }): Scalars {
  const scalePointsBy = size / 100;
  // scale t from 0-1 => 0.5-1
  const targetRadiusScale = ((1 - t) / 2) + 0.5;

  return {
    size,
    scalePointsBy,
    t,
    targetRadiusScale,
  };
}


function scalePoint(pt: Point, scalars: Scalars): Point {
  function scaleCoordinate(c: number, scalars: Scalars): number {
    // steps: 
    // 0: input point is assumed to be designed for a 100x100 unit plane, with 
    //    the center being 50,50 (__not__ 0,0)
    // 1: subtract by 50 to offset the center to 0,0
    // 2: scale the coordinate
    // 3: add 50 to offset the center back to 50,50.
    // 4: scale the coordinate to fit in a new plane, 'size' x 'size' units in size.
    return (((c - 50) * scalars.targetRadiusScale) + 50) * scalars.scalePointsBy;
  }

  return {
    x: scaleCoordinate(pt.x, scalars),
    y: scaleCoordinate(pt.y, scalars),
  };
}


function renderPolygon(
  ctx: CanvasRenderingContext2D, 
  poly: Polygon, 
  scalars: Scalars
) {
  if (poly.points.length < 3) {
    throw new Error("can construct a polygon with less than 3 points");
  }

  const polyPath = new Path2D();

  let pt = scalePoint(poly.points[0], scalars);
  polyPath.moveTo(pt.x, pt.y);

  for (let idx = 1; idx < poly.points.length; idx += 1) {
    pt = scalePoint(poly.points[idx], scalars);
    polyPath.lineTo(pt.x, pt.y);
  }

  polyPath.closePath();

  ctx.fillStyle = poly.fillColor;
  ctx.fill(polyPath);
}


function polygonRendererFactory(
  icon: PolygonIcon
): PulsingIconProps["renderFrame"] {
  return function({ ctx, colors, ...rest }: RenderFrameProps): boolean {
    const scalars = makeScalars(rest);

    ctx.clearRect(0, 0, scalars.size, scalars.size);
        
    for (const poly of icon.getPolygons(colors)) {
      renderPolygon(ctx, poly, scalars);
    } 

    return true;
  };
}


export function rendererFactory(icon: Icon): PulsingIconProps["renderFrame"] {
  switch (icon.geometry) {
    case IconGeometry.Circle:
      return circleFrameRenderer;
    case IconGeometry.Polygon:
      return polygonRendererFactory(icon);
  }
}


function circleSvgBuilder(colors: IconColorStrings): string {
  return ( 
    `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="50" cy="50"
                r="49"
                fill="${colors.borderColor}" 
                stroke-width="2"
                stroke="#000000"
            />    
            <circle 
                cx="50" cy="50" 
                r="33"
                fill="${colors.fillColor}"
            />
        </svg>`
  );
} 


function makePolygonSvg(poly: Polygon): string {
  // trailing space at the end to separate each point. a final trailing space will be 
  // handled by the browser just
  const points = poly
    .points
    .map(pt => `${pt.x},${pt.y} `);

  const pointsStr = "".concat(...points);

  return (
    `<polygon points="${pointsStr}" style="fill:${poly.fillColor}"/>`
  );
}

function polygonSvgBuilder(icon: PolygonIcon, colors: IconColorStrings): string {
  const polygons: string[] = [];
  for (const polygon of icon.getPolygons(colors)) {
    polygons.push(makePolygonSvg(polygon));
  }

  return ( 
    "<svg viewBox=\"0 0 100 100\" xmlns=\"http://www.w3.org/2000/svg\">"
      .concat(...polygons, "</svg>")
  );
} 




export function svgBuilderFactory(icon: Icon): (colors: IconColorStrings) => string {
  switch (icon.geometry) {
    case IconGeometry.Circle:
      return circleSvgBuilder;
    case IconGeometry.Polygon:
      return function(colors: IconColorStrings): string {
        return polygonSvgBuilder(icon, colors);
      };
  }
}
