import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Card, Button, Accordion, ListGroup, Placeholder, Image } from "react-bootstrap";

import { StationStatusAPI, ClientsAPI } from "../../firebase/API";

import { Status, StatusIcon } from "../../misc/Status";
import { ClientDoc, FeatureType } from "../../misc/Types";
import { GeoJson } from "../../misc/GeoJson";
import { isNonEmptyRecord, formatTimeDeltaString } from "../../misc/Utils";
import { useStateContext, State } from "../../provider/StateProvider";



function extractClients(state: State): Set<string> {
  const clients = Object.entries(state.config?.maps ?? {})
    .map(([client, mapDoc]) => {
      const docClients = [client];

      for (const [sharedClient, layers] of Object.entries(mapDoc.sources)) {
        if ((typeof layers === "boolean" && layers) ||
          (Array.isArray(layers) && layers.includes(FeatureType.Stations))) {
          docClients.push(sharedClient);
        }
      }

      return docClients;
    })
    .flat();

  return new Set(clients);
}

enum AlertLevel {
  Ok = "ok",
  Warn = "warn",
  Error = "error",
}


function getAlertLevelFromStatus(status: Status.MystiStatus): AlertLevel {
  if (status.status.trim().toLocaleLowerCase() === "ok") {
    return AlertLevel.Ok;
  }

  if (status.name.toLocaleLowerCase().includes("watchdog")) {
    return AlertLevel.Warn;
  }

  return AlertLevel.Error;
}

function shouldIgnoreStatus(status: Status.MystiStatus): boolean {
  const IGNORED = ["Watchdog (GPS)", "Gliders&Buoys (Woods Hole)"];

  return IGNORED.includes(status.name.trim());
}


// leaving the props around, we'll likely need them for something eventually.
/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface StationListProps {

}

const collator = new Intl.Collator("en");  


function sortStatuses(statusRec: Record<string, Status.Station>): Status.Station[] {
  // used to deduplicate by name, giving priority to whichever was most recently updated.
  const byName: Record<string, Status.Station> = {};
  const now = Date.now() / 1000;

  for (const status of Object.values(statusRec)) {
    // Skip any status updates older than 36h
    if (now - status.lastUpdated > 36 * 3600) {
      continue;
    }

    const existing = byName[status.name];

    if (existing && existing.lastUpdated > status.lastUpdated) {
      continue;
    }

    byName[status.name] = status;
  }

  // extract the by-name unique stations, then sort by client, then name
  return Object.values(byName).sort((a, b) => {
    const clientCmp = collator.compare(a.client, b.client);
    return clientCmp != 0 
      ? clientCmp
      : collator.compare(a.name, b.name);
  });
}

// leaving the props around, we'll likely need them for something eventually.
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
function StationList(props: StationListProps): JSX.Element {
  const state = useStateContext();

  const clients = useMemo<Set<string>>(
    () => extractClients(state),
    [state]
  );

  const [epoch, setEpoch] = useState<number>(Date.now() / 1000);

  const [clientDocs, setClientDocs] = useState<Record<string, ClientDoc>>({});
  const [rawStatuses, setRawStatuses] = useState<Record<string, Status.Station>>({});

  const statuses = useMemo<Status.Station[]>(
    () => sortStatuses(rawStatuses),
    [rawStatuses],
  );

  const [unsub, setUnsub] = useState<null | (() => void)>(null);


  const handleStatusUpdate = useCallback((statuses: Record<string, Status.Station>) => {
    setRawStatuses(current => ({ ...current, ...statuses }));
  }, []);

  useEffect(() => {
    const epochTimer = setInterval(() => setEpoch(Date.now() / 1000), 1000);

    return function() {
      if (typeof unsub === "function") {
        unsub();
      }

      clearInterval(epochTimer);
    };
  }, []);

  useEffect(() => {
    if (typeof unsub === "function") {
      unsub();
      setUnsub(null);
    }

    const clientArr = Array.from(clients);

    ClientsAPI.getByIds(clientArr)
      .then(docs => setClientDocs(current => ({ ...current, ...docs })))
      .catch(error => console.error(`error getting client docs: ${error}`));

    const opts = { clients: clientArr };

    StationStatusAPI.getAndListen(handleStatusUpdate, opts)
      .then(setUnsub);
  }, [clients]);

  return (
    <Accordion className="mb-2">
      {
        statuses.length === 0
          ? "No Stations Available"
          : statuses.map((status, idx) => (
            isNonEmptyRecord(clientDocs[status.client])
              ? <StationListItem key={idx}
                idx={idx}
                epoch={epoch}
                clientDoc={clientDocs[status.client]}
                status={status}/>
              : null
          ))
      }
    </Accordion>
  );
}

interface SightingInfoProps {
  timedOut: boolean,
  epoch: number,
  sighting: null | GeoJson.PointFeature;
  className?: string;
}

function SightingInfo(props: SightingInfoProps): null | JSX.Element {
  const { sighting, timedOut, epoch } = props;

  let content: JSX.Element;

  if (timedOut && sighting === null) {
    content = <div>No sightings found</div>;
  } else if (sighting === null) {
    content = (
      <Placeholder as='div' animation="glow">
        <Placeholder xs={10} />
        <Placeholder xs={7} />
      </Placeholder>
    );
  } else {
    content = (
      <div className="d-flex flex-column font-weight-light justify-content-start">
        <div>{sighting.properties.name}</div>
        <div>
          Reported {formatTimeDeltaString(epoch - sighting.properties.epoch)} ago
        </div>
      </div>
    );
  }

  return (
    <div className={props.className}>
      <div className="text-left font-weight-bold">
        Last Detection:
      </div>
      {content}
    </div>
  );
}


function StatusOverview(props: {status: Status.Station}): JSX.Element {
  const { status } = props;

  let message: null | string | JSX.Element = null;
  let icon: StatusIcon.Union;

  if (!Array.isArray(status.status) || status.status.length === 0) {
    icon = StatusIcon.Ok;
    message = (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span>Not receiving status updates</span>
        <span>Must be running Mysticetus version 2021.32 or later</span>
      </div>
    );
  } else {
    let warnCount = 0;
    let errCount = 0;
    for (const inner of status.status) {
      if (shouldIgnoreStatus(inner)) {
        continue;
      }

      switch (getAlertLevelFromStatus(inner)) {
        case AlertLevel.Ok:
          break;
        case AlertLevel.Warn:
          warnCount += 1;
          break;
        case AlertLevel.Error:
          errCount += 1;
          break;
      }
    }

    if (status.vesselActivity === "Docked") {
      icon = StatusIcon.Ok;
    } else if (errCount > 0) {
      icon = StatusIcon.Error;
      message = errCount === 1
        ? `${errCount} Mysticetus component requires attention`
        : `${errCount} Mysticetus components require attention`;
    } else if (warnCount > 0) {
      icon = StatusIcon.Partial;

      message = warnCount === 1
        ? `${warnCount} Warning reported`
        : `${warnCount} Warnings reported`;
    } else {
      icon = StatusIcon.Ok;
    }
  }

  return (
    <div className="align-middle mx-2">
      <div className="d-flex h-100 flex-row justify-content-end align-content-middle align-items-center">
        {
          message !== null
            ? <div className="mr-2 font-weight-light">{message}</div>
            : null
        }
        <div className="h-auto mt-1 mx-auto">
          <Image width="35rem" height="35rem" src={icon.src.href} />
        </div>
      </div>
    </div>
  );
}


function StationStatus(
  props: { status: Status.Station, epoch: number }
): JSX.Element {
  const { status, epoch } = props;
  return (
    <div className="d-flex flex-column">
      <div className="font-weight-bold">
        Station Status:
      </div>
      <div className="d-flex flex-column font-weight-light justify-content-start">
        <div>
          Activity: {status.vesselActivity ?? "Unknown"}
        </div>
        <div>
          Reported {formatTimeDeltaString(epoch - status.lastUpdated)} ago
        </div>
      </div>
    </div>
  );
}


interface DatetimeDisplay {
  datetime: string;
  epoch: number;
  className?: string;
}

function DatetimeDisplay(props: DatetimeDisplay): JSX.Element {
  const { datetime, epoch } = props;

  const parsed: number = useMemo(() => Date.parse(datetime) / 1000, [datetime]);
  const delta = isNaN(parsed)
    ? null
    : formatTimeDeltaString(epoch - parsed);

  return (
    <div className={`font-weight-light inline-block ${props.className}`}>
      {
        typeof delta === "string"
          ? <>{datetime} - {delta} ago</>
          : datetime
      }
    </div>
  );
}


interface StatusListProps {
  status: Omit<Status.Station, "status"> & Required<Pick<Status.Station, "status">>;
  epoch: number;
}



function getIconFromStatus(status: Status.MystiStatus): StatusIcon.Union {
  switch (getAlertLevelFromStatus(status)) {
    case AlertLevel.Ok:
      return StatusIcon.Ok;
    case AlertLevel.Warn:
      return StatusIcon.Partial;
    case AlertLevel.Error:
      return StatusIcon.Error;
  }
}

function StatusList(props: StatusListProps): JSX.Element {
  const { status, epoch } = props;

  return (
    <>
      {
        typeof status.appVersion === "string"
          ? <div className="text-left font-weight-light">
            OnBoard App Version: {status.appVersion} {typeof status.machineName === "string" ? <>({status.machineName})</> : null}
          </div>
          : null
      }
      <ListGroup variant="flush">
        {
          status.status.map(status => (
            <ListGroup.Item key={status.name} className="px-0 w-100 d-flex flex-row">
              <div className="d-flex flex-column justify-content-between w-100">
                <div className="d-flex justify-content-between">
                  <span className="font-weight-bold">
                    {status.name}
                  </span>
                  <span>{filterMystiInteractionText(status.status)}</span>
                </div>
                <div className="d-flex justify-content-between text-left font-weight-light">
                  <span>Last checked</span>
                  <DatetimeDisplay datetime={status.updateTime} epoch={epoch}/>
                </div>
              </div>
              <div className="pl-2 ml-2 border-left d-flex flex-column justify-content-center">
                <Image width="30rem" height="30rem" src={getIconFromStatus(status).src.href} />
              </div>
            </ListGroup.Item>
          ))
        }
      </ListGroup>
    </>
  );
}







function filterMystiInteractionText(text: string): string {
  const clickIndex = text.indexOf("Click");

  return clickIndex > 0
    ? text.slice(0, clickIndex).trim()
    : text;
}

interface StationListItemProps {
  status: Status.Station;
  clientDoc: ClientDoc;
  epoch: number;
  idx: number;
}

function StationListItem(props: StationListItemProps): JSX.Element {
  const { status, clientDoc, epoch, idx } = props;

  const hasStatusArray = useMemo(() => Array.isArray(status.status), [status]);

  const [lastSighting, setLastSighting] = useState<null | GeoJson.PointFeature>(null);
  const [timedOut, setTimedOut] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const [bgColor, hoverColor] = useMemo<[string, string]>(() => {
    if (idx % 2 === 0) {
      return ["#DDDDDD", "#BBBBBB"];
    } else {
      return ["#EEEEEE", "#CCCCCC"];
    }
  }, [idx]);


  useEffect(() => {
    /*
    const unsub = SightingsAPI.listenToNewestByStation(
      setLastSighting,
      { client: status.client, stationId: status.id }
    );
    */

    const timeout = setTimeout(() => setTimedOut(true), 5000);

    return function() {
      // unsub();
      clearInterval(timeout);
    };
  }, []);

  return (
    <Accordion.Item eventKey={`${idx}`}>
      <Card.Header
        className="w-100 p-0"
        style={{ backgroundColor: isHovering ? hoverColor : bgColor }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}>
        <Accordion.Button as={Button}
          ref={(el: HTMLButtonElement) => el && el.style.setProperty("color", "#000000", "important")}
          style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
          <div className="w-100 d-flex flex-row justify-content-between">
            <div className="w-auto d-flex flex-column flex-wrap justify-content-start align-items-middle">
              <div className="text-left font-weight-bold">
                {
                  typeof status.marineTrafficLink === "string"
                    ? <h3>{clientDoc.clientDisplayName} - <a href={status.marineTrafficLink} target="_blank" rel="noopener noreferrer">{status.name}</a></h3>
                    : <h3>{clientDoc.clientDisplayName} - {status.name}</h3>
                }
              </div>
              <StationStatus epoch={epoch} status={status}/>
              {/*
                <SightingInfo className="mt-2"
                  epoch={epoch}
                  sighting={lastSighting}
                  timedOut={timedOut}/>
              */}
            </div>
            <StatusOverview status={status}/>
          </div>
        </Accordion.Button>
      </Card.Header>
      {
        hasStatusArray
          ? <Accordion.Body>
            <StatusList epoch={epoch} status={status as StatusListProps["status"]}/>
          </Accordion.Body>
          : null
      }
    </Accordion.Item>
  );
}

export default StationList;
