import {
  getFirestore,
  limit,
  where,
  query,
  doc,
  collection,
  getDoc,
  getDocs,
  enableMultiTabIndexedDbPersistence,
  startAfter,
  deleteDoc,
  orderBy,
  onSnapshot,
  updateDoc,
  setDoc,
  startAt,
  endAt,
  runTransaction,
  documentId,
  DocumentData,
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
  QueryDocumentSnapshot,
} from "firebase/firestore";

import { GeoType } from "../misc/Geo";
import { GeoJson } from "../misc/GeoJson";

import { DATA_TYPE_TO_GEO_TYPE } from "../misc/GeoUtils";
import { extractAndFormatGeoJson } from "../misc/GeoJsonUtils";

import difference from "lodash/difference";

import {
  DataType,
  PendingUserDoc,
  UserDoc,
  MapPermissions,
  EulaDoc,
  isEulaDoc,
  ConfigDataType,
  FeatureType,
} from "../misc/Types";

import { IdCallback } from "./API";

import { initFirebase } from "../setup/Setup";
import { OnLoaded } from "../hooks/useLoadTracker";
initFirebase();

const firestore = getFirestore();

enableMultiTabIndexedDbPersistence(firestore)
  .catch(err => console.warn(`Firestore cache unavailable: ${err.code}`));

// export type GetOptions = firebase.firestore.GetOptions;

// Some helper union types for chaining/bundling together similar types.
export type QueryChain = Query | CollectionReference;
export type Snapshot = DocumentSnapshot | QueryDocumentSnapshot;


const UNIVERSAL_CLIENTS: string[] = ["NOAA"];

export interface PaginatedQuery {
  paginationLimit?: number;
}

export interface GeoJsonQuery {
  clients: true | string[];
  startAtEpoch?: number;
  endAtEpoch?: number;
  omitIds?: string[];
}

export type PaginatedGeoJsonQuery = GeoJsonQuery & PaginatedQuery;


const logRequests = (
  process.env.REACT_APP_LOCAL_DEBUG && process.env.REACT_APP_LOG_REQUESTS
);


// Container with prebuilt GetOptions to pick between
// requesting cached or server values
function extractDocumentSnapshot<T extends DocumentData>(docSnap: DocumentSnapshot, singleRequest = true): T | null {
  if (logRequests && singleRequest) console.log("Made a request from Firestore");

  return docSnap.exists() ? docSnap.data() as T : null;
}

function extractGeoJsonSnapshot<GT extends GeoType>(
  geoType: GT,
  docSnap: DocumentSnapshot,
  singleRequest = true,
): null | GeoJson.Feature<GT> {
  if (logRequests && singleRequest) console.log("Made a request from Firestore");
  if (!docSnap.exists) return null;

  return extractAndFormatGeoJson(geoType, docSnap.data());
}

function extractDocumentArray<T extends DocumentData>(docSnaps: DocumentSnapshot[]): T[] {
  if (logRequests) console.log("Made a request from Firestore");

  const out: T[] = [];

  let extracted: T | null;
  for (const snap of docSnaps) {
    extracted = extractDocumentSnapshot<T>(snap, false);

    if (extracted) {
      out.push(extracted);
    }
  }

  return out;
}

function extractDocumentRecord<T extends DocumentData>(docSnaps: DocumentSnapshot[]): Record<string, T> {
  if (logRequests) console.log("Made a request from Firestore");

  const out: Record<string, T> = {};

  let extracted: T | null;

  for (const snap of docSnaps) {
    extracted = extractDocumentSnapshot<T>(snap, false);

    if (extracted) {
      out[snap.id] = extracted;
    }
  }

  return out;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
function extractGeoJsonArray<GT extends GeoType>(
  geoType: GT,
  docSnaps: DocumentSnapshot[],
): GeoJson.Feature<GT>[] {
  if (logRequests) console.log("Made a request from Firestore");

  const out: GeoJson.Feature<GT>[] = [];

  let extracted: null | GeoJson.Feature<GT>;
  for (const snap of docSnaps) {
    extracted = extractGeoJsonSnapshot(geoType, snap, false);

    if (extracted) {
      out.push(extracted);
    }
  }

  return out;
}



/* eslint-enable @typescript-eslint/no-unused-vars */
function extractGeoJsonRecord<GT extends GeoType>(
  geoType: GT,
  docSnaps: DocumentSnapshot[],
): Record<string, GeoJson.Feature<GT>> {
  if (logRequests) console.log("Made a request from Firestore");

  const out: Record<string, GeoJson.Feature<GT>> = {};

  let extracted: null | GeoJson.Feature<GT>;
  for (const snap of docSnaps) {
    extracted = extractGeoJsonSnapshot(geoType, snap, false);

    if (extracted) {
      out[snap.id] = extracted;
    }
  }

  return out;
}


// Pagination helpers

/* eslint-disable @typescript-eslint/no-unused-vars */
function paginateDocumentRequest<D extends DocumentData>(
  queryRef: QueryChain,
  callback: IdCallback<D>,
  paginationSize = 25,
): Promise<null | Error> {
  return getDocs(query(queryRef, limit(paginationSize))).then(querySnap => {
    let nextRequest: null | Promise<null | Error> = null;
    // If we got the full request size, start the next pagination query
    if (querySnap.docs.length >= paginationSize) {
      const lastDoc = querySnap.docs[querySnap.docs.length - 1];
      nextRequest = paginateDocumentRequest<D>(
        query(queryRef, startAfter(lastDoc)),
        callback,
        paginationSize
      );
    }

    let doc: null | D;
    for (const docSnap of querySnap.docs) {
      doc = extractDocumentSnapshot<D>(docSnap, false);

      // If we extracted the document, pass it to the callback.
      if (doc) callback(docSnap.id, doc);
    }

    // Then return the running request if we have one.
    return nextRequest ?? Promise.resolve(null);
  }).catch(error => {
    console.error("Pagination Error");
    console.error(error);

    return error;
  });
}


/* eslint-enable @typescript-eslint/no-unused-vars */
function paginateGeoJsonRequest<GT extends GeoType>(
  queryRef: QueryChain,
  geoType: GT,
  callback: IdCallback<GeoJson.Feature<GT>>,
  onLoaded?: OnLoaded,
  paginationSize = 25,
): Promise<null | Error> {
  return getDocs(query(queryRef, limit(paginationSize))).then(querySnap => {
    let nextRequest: null | Promise<null | Error> = null;
    // If we got the full request size, start the next pagination query
    if (querySnap.docs.length >= paginationSize) {
      const lastDoc = querySnap.docs[querySnap.docs.length - 1];
      nextRequest = paginateGeoJsonRequest<GT>(
        query(queryRef, startAfter(lastDoc)),
        geoType,
        callback,
        onLoaded,
        paginationSize
      );
    }

    let feature: null | GeoJson.Feature<GT>;
    for (const docSnap of querySnap.docs) {
      feature = extractGeoJsonSnapshot(geoType, docSnap, false);

      // If we extracted the feature, pass it to the callback.
      if (feature) {
        callback(docSnap.id, feature);
      }
    }

    if (nextRequest === null) {
      // onLoaded && onLoaded();
    }

    // Then return the running request if we have one.
    return nextRequest;
  }).catch(error => {
    console.error("Pagination Error");
    console.error(error);

    onLoaded && onLoaded();

    return error;
  });
}
/*
async function paginateGeoJsonRequest<GT extends GeoType>(
  queryRef: QueryChain,
  geoType: GT,
  callback: IdCallback<GeoJson.Feature<GT>>,
  onLoaded?: OnLoaded,
  paginationSize = 25,
): Promise<null | Error> {

  let feature: null | GeoJson.Feature<GT>;
  
  try {
    
    const start = performance.now();
    const querySnap = await getDocs(query(queryRef));
    const end = performance.now();
    
    console.log({ query: end - start,  results: querySnap.docs.length });
    
    for (const docSnap of querySnap.docs) {
      feature = extractGeoJsonSnapshot(geoType, docSnap, false);    
      // If we extracted the feature, pass it to the callback.
      if (feature) {
        callback(docSnap.id, feature);
      }
    }

    onLoaded && onLoaded();
    return null;
  } catch (error) {
    console.error("Pagination Error");
    console.error(error);

    onLoaded && onLoaded();

    return error as Error;
  }
}
*/


// Only 4 functions actually will call firebase, getAll, getById,
// getByIds and queryCollection. All document/geojson specific extraction
// and array/record organization is done by functions that call these 4.
function getAll(collec: DataType, omitIds?: string[]): Query {
  let collecRef: QueryChain = collection(firestore, collec);

  if (omitIds && omitIds.length > 0) {
    if (omitIds.length === 1) {
      collecRef = query(collecRef, where(documentId(), "!=", omitIds[0]));
    } else {
      if (omitIds.length > 10) {
        omitIds = omitIds.slice(0, 10);
      }

      collecRef = query(collecRef, where(documentId(), "not-in", omitIds));
    }
  }

  return collecRef;
}

function getById(collec: DataType, id: string): DocumentReference {
  return doc(collection(firestore, collec), id);
}

function getByIds(collec: DataType, ids: true | string[], omitIds?: string[]): null | DocumentReference | DocumentReference[] | Query {
  if (omitIds && Array.isArray(ids)) {
    ids = difference(ids, omitIds);
  }

  if (typeof ids === "boolean") {
    return getAll(collec, omitIds);
  }

  if (ids.length === 0) return null;
  if (ids.length === 1) return doc(collection(firestore, collec), ids[0]);

  const collecRef = collection(firestore, collec);
  const docRefs: DocumentReference[] = [];

  for (const id of ids) {
    docRefs.push(doc(collecRef, id));
  }

  return docRefs;
}


// -------------- Document requests ------------------ //
export function getAllDocuments<D extends DocumentData>(collec: DataType): Promise<Record<string, D>> {
  return getDocs(getAll(collec))
    .then(querySnaps => extractDocumentRecord(querySnaps.docs));
}

export function getDocumentById<D extends DocumentData>(collec: DataType, id: string): Promise<null | D> {
  return getDoc(getById(collec, id))
    .then(docSnap => docSnap.exists() ? extractDocumentSnapshot(docSnap) : null);
}

export function getDocumentsByIds<D extends DocumentData>(collec: DataType, ids: true | string[]): Promise<Record<string, D>> {
  const docRefs = getByIds(collec, ids);

  if (!docRefs) return Promise.resolve({});

  if (Array.isArray(docRefs)) {
    return Promise.all(docRefs.map(innerRef => getDoc(innerRef)))
      .then(docSnaps => extractDocumentRecord<D>(docSnaps));
  }

  if (docRefs instanceof Query) {
    return getDocs(docRefs)
      .then((docSnaps) => extractDocumentRecord<D>(docSnaps.docs));
  }

  return getDoc(docRefs)
    .then((docSnap) => {
      const extracted = extractDocumentSnapshot<D>(docSnap);

      if (extracted === null) {
        return {};
      }

      return { [docSnap.id]: extracted };
    });
}


// ------------- GeoJson Requests ------------------- //
export function getAllGeoJson<GT extends GeoType>(collec: FeatureType, omitIds?: string[]): Promise<Record<string, GeoJson.Feature<GT>>> {
  const geoType = DATA_TYPE_TO_GEO_TYPE[collec];

  return getDocs(getAll(collec, omitIds))
    .then(querySnap => extractGeoJsonRecord(geoType, querySnap.docs));
}

export function getGeoJsonById<GT extends GeoType>(collec: FeatureType, id: string): Promise<null | GeoJson.Feature<GT>> {
  const geoType = DATA_TYPE_TO_GEO_TYPE[collec];

  return getDoc(getById(collec, id))
    .then(docSnap => docSnap.exists() ? extractGeoJsonSnapshot(geoType, docSnap) : null);
}

export function getGeoJsonByIds<GT extends GeoType>(collec: FeatureType, ids: true | string[], omitIds?: string[]): Promise<Record<string, GeoJson.Feature<GT>>> {
  const geoType = DATA_TYPE_TO_GEO_TYPE[collec];

  const docRefs = getByIds(collec, ids, omitIds);

  if (!docRefs) return Promise.resolve({});

  if (Array.isArray(docRefs)) {
    return Promise.all(docRefs.map(innerRef => getDoc(innerRef)))
      .then(docSnaps => extractGeoJsonRecord(geoType, docSnaps));
  }

  if (docRefs instanceof Query) {
    return getDocs(docRefs)
      .then((docSnaps) => extractGeoJsonRecord(geoType, docSnaps.docs));
  }

  return getDoc(docRefs)
    .then((docSnap) => {
      const extracted = extractGeoJsonSnapshot(geoType, docSnap);

      if (extracted === null) {
        return {};
      }

      return { [docSnap.id]: extracted };
    });
}

function getGeoJsonByClients<GT extends GeoType>(
  collec: FeatureType,
  clients: true | string[],
  omitIds?: string[],
): Promise<Record<string, GeoJson.Feature<GT>>> {
  if (clients === true) return getAllGeoJson(collec, omitIds);

  let queryRef: QueryChain = collection(firestore, collec);
  const geoType = DATA_TYPE_TO_GEO_TYPE[collec];

  // Return an empty record if we have no clients specified.
  switch (clients.length) {
    case 0:
      return Promise.resolve({});
    case 1:
      queryRef = query(queryRef, where("properties.client", "==", clients[0]));
      break;
    default:
      queryRef = query(queryRef, where("properties.client", "in", clients));
      break;
  }

  if (omitIds && omitIds.length > 0) {
    queryRef = query(queryRef, orderBy(documentId()));

    if (omitIds.length === 1) {
      queryRef = query(queryRef, where(documentId(), "!=", omitIds[0]));
    } else if (
      omitIds.length < 10) {
      queryRef = query(queryRef, where(documentId(), "not-in", omitIds));
    } else {
      for (let idx = 0; idx < omitIds.length; idx++) {
        queryRef = query(queryRef, where(documentId(), "!=", omitIds[idx]));
      }
    }
  }

  return getDocs(queryRef)
    .then(querySnap => extractGeoJsonRecord(geoType, querySnap.docs) as Record<string, GeoJson.Feature<GT>>);
}

function getGeoJsonByClient<GT extends GeoType>(collec: FeatureType, client: string, omitIds?: string[]): Promise<Record<string, GeoJson.Feature<GT>>> {
  return getGeoJsonByClients(collec, [client], omitIds);
}


// --------------- User collection functions ------------ //
function updateUserPermissions(uid: string, permissions: MapPermissions): Promise<MapPermissions> {
  const docRef = doc(collection(firestore, ConfigDataType.Users), uid);

  let mapPerms: MapPermissions;
  return runTransaction(firestore, transaction => {
    return transaction.get(docRef).then(docSnap => {
      if (!docSnap.exists) return;

      const doc = docSnap.data() as UserDoc;

      doc.mapPermissions = {
        ...doc.mapPermissions,
        ...permissions,
      };

      mapPerms = doc.mapPermissions;

      transaction.update(docRef, doc as unknown as Record<string, Partial<unknown>>);
    });
  }).then(() => mapPerms);
}

function toggleUserDisable(uid: string): Promise<boolean> {
  const docRef = doc(collection(firestore, ConfigDataType.Users), uid);

  let disabled: boolean;
  return runTransaction(firestore, transaction => {
    return transaction.get(docRef).then(docSnap => {
      if (!docSnap.exists) return;

      const doc = docSnap.data() as UserDoc;

      doc.disabled = typeof doc.disabled === "boolean"
        ? !doc.disabled
        : true;

      disabled = doc.disabled;
      transaction.update(docRef, doc as unknown as Record<string, Partial<unknown>>);
    });
  }).then(() => disabled);
}

function createPendingUsers(createdBy: string, emails: string | string[], permissions: MapPermissions): Promise<string[]> {
  if (!Array.isArray(emails)) {
    emails = [emails];
  }

  const collecRef = collection(firestore, ConfigDataType.PendingUsers);
  const docPromises: Promise<void>[] = [];
  const docIds: string[] = [];

  let docRef: DocumentReference;
  for (const email of emails) {
    docRef = doc(collecRef);

    docIds.push(docRef.id);

    docPromises.push(setDoc(docRef, {
      email: email,
      mapPermissions: permissions,
      createdBy: createdBy,
      retryCount: 0,
    }));
  }

  return Promise.all(docPromises).then(() => docIds);
}

function doUsersExist(emails: string[]): Promise<Record<string, boolean>> {
  const userQuery = query(
    collection(firestore, ConfigDataType.Users),
    where("email", "in", emails)
  );

  return getDocs(userQuery).then(querySnap => {
    const userDocs = extractDocumentArray<UserDoc>(querySnap.docs);

    const userExistsMap: Record<string, boolean> = {};

    for (const email of emails) {
      userExistsMap[email] = false;
    }

    for (const userDoc of userDocs) {
      userExistsMap[userDoc.email] = true;
    }

    return userExistsMap;
  });
}

function doesUserExist(email: string): Promise<boolean> {
  const userQuery = query(
    collection(firestore, "users"),
    where("email", "==", email)
  );

  return getDocs(userQuery).then(snapshot => !snapshot.empty);
}

function tryRequestNewSignInLink(email: string): Promise<void> {
  const queryRef = query(
    collection(firestore, ConfigDataType.PendingUsers),
    where("email", "==", email)
  );

  return getDocs(queryRef).then(querySnap => {
    const docSnap = querySnap.docs.pop();

    if (querySnap.empty || !docSnap || !docSnap.exists) {
      throw new Error(
        "Could not find an account associated with that email address"
      );
    }

    const docData = docSnap.data() as PendingUserDoc;

    if (typeof docData.retryCount === "number" && docData.retryCount >= 3) {
      throw new Error("This account has hit the maximum number of retries");
    }

    return updateDoc(docSnap.ref, {
      retryCount: (docData.retryCount ?? 0) + 1
    });
  });
}

function deleteAllDocsWithEmail(email: string): Promise<void> {
  const queryRef = query(
    collection(firestore, ConfigDataType.PendingUsers),
    where("email", "==", email)
  );

  return getDocs(queryRef).then(querySnap => {
    const deleteProms = querySnap.docs.map(docSnap => deleteDoc(docSnap.ref));

    if (deleteProms.length === 0) {
      // This array needs to be returned so we match types, but functionally
      // it serves no purpose
      return Promise.resolve([]);
    }

    return Promise.all(deleteProms);
  }).then(() => {
    // Collapse void[] to just void
  });
}


// Sightings
function buildGeoJsonQuery(dataType: FeatureType, sgtQuery: GeoJsonQuery): null | QueryChain {
  let queryRef: QueryChain = collection(firestore, dataType);

  const { clients, startAtEpoch, endAtEpoch } = sgtQuery;

  if (Array.isArray(clients)) {
    for (const univ_client of UNIVERSAL_CLIENTS) {
      if (!clients.includes(univ_client)) {
        clients.push(univ_client);
      }
    }

    switch (clients.length) {
      case 0:
        return null;
      case 1:
        queryRef = query(queryRef, where("properties.client", "==", clients[0]));
        break;
      default:
        queryRef = query(queryRef, where("properties.client", "in", clients));
        break;
    }
  }

  if (startAtEpoch && endAtEpoch) {
    queryRef = query(
      queryRef,
      orderBy("properties.epoch"),
      startAt(startAtEpoch),
      endAt(endAtEpoch),
    );
  } else if (startAtEpoch) {
    queryRef = query(
      queryRef,
      orderBy("properties.epoch"),
      startAt(startAtEpoch),
    );
  } else if (endAtEpoch) {
    queryRef = query(
      queryRef,
      orderBy("properties.epoch"),
      endAt(endAtEpoch),
    );
  }

  return queryRef;
}

function paginateGeoJsonQuery<GT extends GeoType>(
  dataType: FeatureType,
  query: PaginatedGeoJsonQuery,
  callback: IdCallback<GeoJson.Feature<GT>>,
  onLoaded?: OnLoaded,
): Promise<null | Error> {
  const queryRef = buildGeoJsonQuery(dataType, query);
  const geoType = DATA_TYPE_TO_GEO_TYPE[dataType] as GT;

  if (!queryRef) return Promise.resolve(null);

  const paginationLimit = query.paginationLimit ?? 25;

  return paginateGeoJsonRequest(queryRef, geoType, callback, onLoaded, paginationLimit);
}

function listenToNewFeatures<GT extends GeoType>(
  dataType: FeatureType,
  callback: IdCallback<GeoJson.Feature<GT>>,
  clients: true | string | string[]
): () => void {

  let collecRef: QueryChain = collection(firestore, dataType);

  if (Array.isArray(clients)) {
    for (const univ_client of UNIVERSAL_CLIENTS) {
      clients.push(univ_client);
    }
  } else if (typeof clients === "string") {
    clients = [clients, ...UNIVERSAL_CLIENTS];
  }

  if (Array.isArray(clients) && clients.length === 1) {
    clients = clients[0];
  }

  if (typeof clients === "string") {
    collecRef = query(collecRef, where("properties.client", "==", clients));
  } else if (Array.isArray(clients)) {
    collecRef = query(collecRef, where("properties.client", "in", clients));
  }

  collecRef = query(
    collecRef,
    orderBy("properties.epoch"),
    startAfter(Date.now() / 1000)
  );

  const geoType = DATA_TYPE_TO_GEO_TYPE[dataType] as GT;

  return onSnapshot(collecRef, (querySnap) => {
    const sightingRecord = extractGeoJsonRecord(geoType, querySnap.docs);

    for (const id in sightingRecord) {
      if (sightingRecord[id]) {
        callback(id, sightingRecord[id]);
      }
    }
  }, (error) => console.error(error));
}


// -------------- Document/Collection listeners ------------- //
function listenToDocument<T extends DocumentData>(collec: DataType, id: string, callback: (x: T) => void): () => void {
  // Get the entire collection and call the callback for
  // an initial value.
  return onSnapshot(
    doc(collection(firestore, collec), id),
    (docSnap: DocumentSnapshot) => {
      const data = extractDocumentSnapshot<T>(docSnap);
      if (data) callback(data);
    },
    error => console.log(error)
  );
}

function listenToDocumentByIds<T extends DocumentData>(collec: DataType, ids: true | string[], callback: (rec: Record<string, T>) => void): () => void {
  if (ids === true) {
    return listenToDocumentCollection(collec, callback);
  }

  if (ids.length === 0) return () => { /* Do nothing */ };
  if (ids.length === 1) {
    const id = ids[0];
    return listenToDocument<T>(collec, id, (doc) => callback({ [id]: doc }));
  }

  const queryRef = query(
    collection(firestore, collec),
    where(documentId(), "in", ids)
  );

  // Get the entire collection and call the callback for
  // an initial value.
  return onSnapshot(
    queryRef,
    (querySnap: QuerySnapshot) => {
      const data = extractDocumentRecord<T>(querySnap.docs);
      callback(data);
    },
    error => console.log(error)
  );
}

function listenToDocumentCollection<T extends DocumentData>(collec: DataType, callback: (rec: Record<string, T>) => void): () => void {
  // Get the entire collection and call the callback for
  // an initial value.
  return onSnapshot(
    collection(firestore, collec),
    (querySnap) => callback(extractDocumentRecord<T>(querySnap.docs)),
    error => console.log(error)
  );
}

function listenToGeoJsonCollection<GT extends GeoType>(collec: FeatureType, callback: (rec: Record<string, GeoJson.Feature<GT>>) => void): () => void {
  // Get the entire collection and call the callback for
  // an initial value.
  return onSnapshot(
    collection(firestore, collec),
    (querySnap) => callback(extractGeoJsonRecord(DATA_TYPE_TO_GEO_TYPE[collec], querySnap.docs)),
    error => console.log(error)
  );
}



function universalListener<GT extends GeoType>(
  collec: FeatureType,
  callback: (rec: Record<string, GeoJson.Feature<GT>>) => void,
  clients: true | string[],
): () => void {
  // if we can see everything, defer to the listener that gets everything
  if (typeof clients === "boolean" && clients) {
    return listenToGeoJsonCollection(collec, callback);
  }

  const collecRef = collection(firestore, collec);

  const wrappedCallback = (querySnap: QuerySnapshot) => {
    callback(extractGeoJsonRecord(DATA_TYPE_TO_GEO_TYPE[collec], querySnap.docs));
  };

  const baseQuery = query(collecRef, where("properties.universalFeature", "==", true));
  const unsubs: (() => void)[] = [];

  unsubs.push(
    onSnapshot(baseQuery, wrappedCallback, (error) => console.log(error))
  );

  for (let i = 0; i < clients.length; i += 7) {
    const chunk = clients.slice(i, i + 7);

    const chunkQuery = query(collecRef, where("properties.client", "in", chunk));

    unsubs.push(
      onSnapshot(chunkQuery, wrappedCallback, (error) => console.log(error))
    );
  }

  return function() {
    for (const unsub of unsubs) {
      unsub();
    }
  };
}


function listenToNewestSightingByStation(
  callback: (sighting: GeoJson.PointFeature) => void,
  ops: { client: string, stationId: string }
): () => void {
  const sgtQuery = query(
    collection(firestore, FeatureType.Sightings),
    where("properties.client", "==", ops.client),
    where("stationId", "==", ops.stationId),
    orderBy("properties.epoch", "desc"),
    limit(1)
  );


  return onSnapshot(sgtQuery, (snapshot) => {
    const docSnap = snapshot.docs.pop();

    if (docSnap !== undefined) {
      const sighting = extractAndFormatGeoJson(GeoType.Point, docSnap.data());

      if (sighting) {
        callback(sighting);
      }
    }
  });
}

/*
const addFeatureToCollection = <GT extends GeoType>(collec: DataType.FirestoreGeoJson, feature: GeoJson.Feature<GT>) => {
  // check the geometry type
  const geoType = feature?.geometry?.type;
  if (!geoType) {
    return Promise.reject("Feature geometry type is missing");
  }

  // try and get an ID
  let id: string;
  if (typeof feature?.properties?.id === "string") {
    id = feature.properties.id;
  }
  else if (typeof feature?.id === "string") {
    id = feature.id;
    feature.properties.id = id;
  }
  else {
    return Promise.reject("Feature is missing an Id");
  }

  let firestoreFeature: FirestoreGeoJson.Feature<typeof geoType>;
  switch (geoType) {
    case GeoType.Point:
      // Points can be saved directly to firestore
      firestoreFeature = feature as FirestoreGeoJson.Feature<typeof geoType>;
      break;
    case GeoType.LineString:
    case GeoType.Polygon:
    default:
      return Promise.reject(`Unknown geometry type: ${geoType}`);
  }

  const collecRef = firestore.collection(collec);
};
*/


export function getEulaDoc(): Promise<null | EulaDoc> {
  return getDoc(doc(collection(firestore, "eula"), "eula")).then(docSnap => {
    if (docSnap.exists()) {
      const eulaDoc = docSnap.data() as unknown;

      if (isEulaDoc(eulaDoc)) {
        return eulaDoc;
      }
    }

    return null;
  });
}

export function setEulaDoc(eulaDoc: EulaDoc): Promise<void> {
  return setDoc(doc(collection(firestore, "eula"), "eula"), eulaDoc);
}


function deleteDocument(
  collec: DataType,
  docId: string
): Promise<void> {
  return deleteDoc(doc(collection(firestore, collec), docId));
}

function updateDocument<D extends { [key: string]: unknown }>(
  collec: DataType,
  docId: string,
  docData: D,
): Promise<D> {
  return updateDoc(doc(collection(firestore, collec), docId), docData)
    .then(() => docData);
}


function setDocument<D extends { [key: string]: unknown }>(
  collec: DataType,
  docId: string,
  docData: D,
): Promise<D> {
  return setDoc(doc(collection(firestore, collec), docId), docData)
    .then(() => docData);
}


export function getClientMapping(): Promise<Record<string, string>> {
  return getDoc(doc(collection(firestore, "mappings"), "clients")).then(snap => snap.data() ?? {});
}



const Firestore = {
  listenToDocumentCollection,
  listenToGeoJsonCollection,
  listenToDocument,
  getClientMapping,
  listenToDocumentByIds,
  doesUserExist,
  doUsersExist,
  updateUserPermissions,
  toggleUserDisable,
  createPendingUsers,
  tryRequestNewSignInLink,
  getAllDocuments,
  getDocumentById,
  getDocumentsByIds,
  getAllGeoJson,
  getGeoJsonById,
  getGeoJsonByIds,
  getGeoJsonByClient,
  getGeoJsonByClients,
  listenToNewFeatures,
  paginateGeoJsonQuery,
  deleteAllDocsWithEmail,
  deleteDocument,
  listenToNewestSightingByStation,
  getEulaDoc,
  setEulaDoc,
  updateDocument,
  setDocument,
  universalListener,
};

export default Firestore;
