import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";

import range from "lodash/range";

import { tryParseInt } from "../../misc/Utils";

const maxPerPageStops = [5, 10, 20, 50, 100];


export interface PaginationWrapperProps<D> {
  data?: D[];
  pageFormatFunc?: (data: D[]) => null|JSX.Element;
  numPagesToShow?: number|string;
  maxPerPage?: number|string;
}


const PaginationWrapper = <D, >(props: React.PropsWithoutRef<PaginationWrapperProps<D>>): JSX.Element => {
  const { data, pageFormatFunc } = props;

  const numPagesToShow = tryParseInt(props.numPagesToShow) ?? 6;

  const defaultMaxPerPage = tryParseInt(props.maxPerPage) ?? 5;

  if (!maxPerPageStops.includes(defaultMaxPerPage)) {
    maxPerPageStops.push(defaultMaxPerPage);

    maxPerPageStops.sort((a, b) => a - b);
  }

  const numSidePages = Math.ceil(numPagesToShow / 2);

  const [activeIndex, setActiveIndex] = useState(0);

  const [pages, setPages] = useState<JSX.Element[]>([]);
  const [maxPerPage, setMaxPerPage] = useState<number>(defaultMaxPerPage);

  const refreshPageSelectBar = (): JSX.Element => {
    const maxIndex = Array.isArray(pages) ? pages.length - 1 : 0;

    const minBarIdx = Math.max(0, activeIndex - numSidePages);
    const maxBarIdx = Math.min(maxIndex + 1, activeIndex + numSidePages);

    return (
      <>
        <Pagination.First disabled={activeIndex === 0}
          onClick={() => setActiveIndex(0)}/>
        <Pagination.Prev disabled={activeIndex === 0}
          onClick={() => setActiveIndex(activeIndex - 1)}/>
        {
          minBarIdx > 0
            ? <Pagination.Item onClick={() => setActiveIndex(0)}>
                1
            </Pagination.Item>
            : null
        }
        {
          minBarIdx > 1
            ? <Pagination.Ellipsis disabled/>
            : null
        }
        {
          range(minBarIdx, maxBarIdx).map(idx => {
            return (
              <Pagination.Item key={idx} onClick={() => setActiveIndex(idx)}
                active={activeIndex === idx}>
                { idx + 1 }
              </Pagination.Item>
            );
          })
        }
        {
          maxBarIdx <= maxIndex
            ? maxBarIdx >= maxIndex - 1
              ? <Pagination.Item onClick={() => setActiveIndex(maxBarIdx)}>
                { maxBarIdx + 1 }
              </Pagination.Item>
              : <Pagination.Ellipsis disabled/>
            : null
        }
        {
          maxBarIdx < maxIndex
            ? <Pagination.Item onClick={() => setActiveIndex(maxIndex)}>
              { maxIndex + 1 }
            </Pagination.Item>
            : null
        }
        <Pagination.Next disabled={activeIndex === maxIndex}
          onClick={() => setActiveIndex(activeIndex + 1)}/>
        <Pagination.Last disabled={activeIndex === maxIndex}
          onClick={() => setActiveIndex(maxIndex)}/>
      </>
    );
  };


  useEffect(() => {
    if (data && Array.isArray(data) && typeof pageFormatFunc === "function") {
      const pageContainer: JSX.Element[] = [];

      let formattedPage: null|JSX.Element;

      for (let idx = 0; idx < data.length; idx += maxPerPage) {
        const pageSubset = data.length - idx < maxPerPage
          ? data.slice(idx)
          : data.slice(idx, idx + maxPerPage);

        formattedPage = pageFormatFunc(pageSubset);

        if (formattedPage) {
          pageContainer.push(formattedPage);
        }
      }

      if (activeIndex < 0 || pageContainer.length - 1 < activeIndex) {
        setActiveIndex(
          Math.min(Math.max(0, activeIndex), pageContainer.length - 1)
        );
      }

      setPages(pageContainer);
    }
  }, [data, maxPerPage, pageFormatFunc]);


  return (
    <Container fluid className='no-side-padding'>
      {
        Array.isArray(pages) && pages.length > 0
          ? pages[activeIndex]
          : null
      }
      <div className='d-inline-flex w-100 justify-content-between align-items-center align-self-center'>
        <Pagination className="m-0">
          { refreshPageSelectBar() }
        </Pagination>
        <div className="d-inline-block">
          <span>Rows per page:</span>
          <Dropdown className='d-inline-block ml-2' drop='down'>
            <Dropdown.Toggle variant='outline-secondary'>
              { maxPerPage }
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                maxPerPageStops.map(stop => {
                  return (
                    <Dropdown.Item key={stop} active={maxPerPage === stop}
                      onSelect={() => setMaxPerPage(stop)}>
                      { stop }
                    </Dropdown.Item>
                  );
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Container>
  );
};

export default PaginationWrapper;
