import { initializeApp, FirebaseApp } from "firebase/app";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


export const LOCAL_INSTANCE = window.location.hostname === "localhost";

process.env.REACT_APP_LOCAL_DEBUG = LOCAL_INSTANCE ? "1" : undefined;

// Print the environment variables if we're running locally.
if (LOCAL_INSTANCE) {
  console.log("process.env:");
  console.log(process.env);
}

// If a sentry DSN is specified as an environment variable, initialize.
if (!LOCAL_INSTANCE && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}


export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_BASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};


let _FIREBASE_APP: null | FirebaseApp = null;

export function initFirebase(): FirebaseApp  {
  if (_FIREBASE_APP === null) {
    // Initialize Firebase
    if (LOCAL_INSTANCE) {
      console.log("setting up firebase", { firebaseConfig });
    }
  
    _FIREBASE_APP = initializeApp(firebaseConfig);
  }

  return _FIREBASE_APP;
}
