import React, { useState } from "react";
import { Carousel, Image, Modal, Spinner } from "react-bootstrap";
import type { SightingImages } from "../../hooks/useFeaturePopupOptions";

export interface ImageCarouselProps {
  srcSet: string | string[] | SightingImages;
  title?: string;
  className?: string;
  disableFullscreen?: boolean,
}

interface InnerImageCarouselProps extends ImageCarouselProps {
  srcSet: string | string[];
  title?: string;
  handleClick?: (e?: React.MouseEvent) => void;
}


function InnerImageCarousel(props: InnerImageCarouselProps): null | JSX.Element {
  const [loaded, setLoaded] = useState<boolean>(false);

  const imageSrcs = Array.isArray(props.srcSet)
    ? props.srcSet
    : [props.srcSet];

  if (imageSrcs.length === 0) {
    return null;
  }

  return (
    <>
      {
        !loaded
          ? <div className="w-100 d-inline-flex justify-content-center">
            <Spinner animation="border" className='mx-auto' variant="primary">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          : null
      }
      <Carousel
        controls={imageSrcs.length > 1}
        indicators={imageSrcs.length > 1}
        className={`w-100 ${props.className}`}>
        {
          imageSrcs.map((src: string, idx: number) => {
            let alt: string | undefined = "";
            let parsedSrc = "";

            if (typeof src === "string") {
              alt = src.split("/").pop()?.split(".").shift()?.split("&").shift();
              parsedSrc = src;
            }

            return (
              <Carousel.Item key={idx} onClick={props.handleClick}>
                <Image
                  src={parsedSrc}
                  alt={alt}
                  onLoad={() => setLoaded(true)}
                  className="w-100 d-block"/>
              </Carousel.Item>
            );
          })
        }
      </Carousel>
    </>
  );
}

function ImageCarousel(props: ImageCarouselProps): null | JSX.Element {
  const [showFullscreen, setShowFullscreen] = useState<boolean>(false);

  const handleClick = () => {
    if (props.disableFullscreen === true) {
      return;
    }

    if (!showFullscreen) {
      setShowFullscreen(true);
    }
  };

  let expandedSet: string[];
  let thumbSet: string[];
  if (typeof props.srcSet === "string") {
    expandedSet = [props.srcSet];
    thumbSet = [props.srcSet];
  } else if (Array.isArray(props.srcSet)) {
    expandedSet = props.srcSet;
    thumbSet = props.srcSet;
  } else {
    expandedSet = props.srcSet.fullRes;
    thumbSet = props.srcSet.thumbnails;
    
    // sometimes we'll only have one or the other, so we need to reuse the one
    // with images in both cases.
    if (thumbSet.length === 0 && expandedSet.length > 0) {
      thumbSet = expandedSet;
    } else if (expandedSet.length === 0 && thumbSet.length > 0) {
      expandedSet = thumbSet;
    }
  }


  return (
    <>
      <InnerImageCarousel {...props} srcSet={thumbSet} handleClick={handleClick}/>
      <Modal size="xl" show={showFullscreen && expandedSet.length > 0} onHide={() => setShowFullscreen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title ?? ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InnerImageCarousel {...props} srcSet={expandedSet}/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageCarousel;
