import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

const visibility_icon = new URL("/public/static/icons/visibility.svg", import.meta.url);

export interface PasswordInputProps {
  value: string;
  onChange: (val: string) => void;
  disabled?: boolean;
  confirmValue?: string;
  onConfirmChange?: (val: string) => void;
  autoComplete?: boolean;
  allowUnhide?: boolean;
  label?: string;
  size?: "sm"| "lg";
  className?: string;
  placeholder?: string;
}

const PasswordInput = (props: React.PropsWithoutRef<PasswordInputProps>): JSX.Element => {
  const { value, onChange } = props;
  const allowUnhide = props.allowUnhide ?? true;

  const [hidePassword, setHidePassword] = useState<boolean>(true);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!hidePassword) setHidePassword(true);

    onChange(e.currentTarget.value);
  };

  return (
    <>
      <Form.Group>
        <Form.Label>
          {props.label ?? "Password"}
        </Form.Label>
        <Row className='d-flex w-100 m-auto flex-row-reverse align-items-md-strech justify-content-between align-self-center flex-nowrap'>
          {
            allowUnhide
              ? <div className='grid-overlap w-auto div-toggle-button m-0'
                onClick={() => setHidePassword(c => !c)}>
                <Row style={{ visibility: hidePassword ? "visible" : "hidden" }}
                  className='justify-content-md-center center grid-overlap-item'>
                  <Image src={visibility_icon.href} className='px-1'
                    height='24' width='24'/>
                </Row>
                <Row style={{ visibility: hidePassword ? "hidden" : "visible" }}
                  className='justify-content-md-center center px-0 grid-overlap-item'>
                  <span>x</span>
                </Row>
              </div>
              : null
          }
          <Form.Control className='flex-shrink-1'
            disabled={props.disabled ?? false}
            size={props.size ?? "sm"}
            type={hidePassword ? "password" : undefined}
            placeholder={props.placeholder ?? "Password"}
            value={value}
            autoComplete={props.autoComplete ? "current-password" : undefined}
            onChange={handleTextChange}/>
        </Row>
      </Form.Group>
      {
        typeof props.confirmValue === "string" && typeof props.onConfirmChange === "function"
          ? <PasswordInput className='mt-1'
            label={`Confirm ${props.label ?? "Password"}`}
            value={props.confirmValue}
            onChange={props.onConfirmChange}
            placeholder={`Confirm ${props.placeholder ?? "Password"}`}
            size={props.size ?? "sm"}
            autoComplete={props.autoComplete}
            allowUnhide={allowUnhide}
            disabled={props.disabled ?? false}/>
          : null
      }
    </>
  );
};

export default PasswordInput;
