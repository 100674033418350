import { cloneDeep } from "lodash";
import { Brand } from "ts-brand";

type Enumerate<N extends number, Acc extends number[] = []> = Acc["length"] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc["length"]]>

export type Range<Start extends number, UpTo extends number> = Exclude<Enumerate<UpTo>, Enumerate<Start>>

export type RangeInclusive<Start extends number, End extends number> = Range<Start, End> | End;


export interface HSL {
    /** units of degrees */
    hue: RangeInclusive<0, 360>;
    /** (0..=100) percent */
    saturation: RangeInclusive<0, 100>;
    /** (0..=100) percent  */
    luminance: RangeInclusive<0, 100>;
}

export interface RGB {
    red: Range<0, 256>;
    green: Range<0, 256>;
    blue: Range<0, 256>;
}

/** 
 * A string formatted color, for some CSS/DOM element.
 * (usually in either a hex '#FFFFFF' or `hsl(...)` format)  
 */
export type ColorString = Brand<string, "colorString">;


export function clampInclusive<
    Start extends number, 
    End extends number
>(
  n: number, 
  start: Start, 
  end: End
): RangeInclusive<Start, End> {
  return Math.min(Math.max(start, n), end) as RangeInclusive<Start, End>;
}  

export function clamp<
    Start extends number,
    End extends number
>(
  n: number,
  start: Start,
  end: End
): Range<Start, End> {
  return clampInclusive(n, start, end - 1) as Range<Start, End>;
}  




export function* generateNRandomColors(n: number): Generator<HSL, void, never> {
  const hueShiftPerColor = 360 / n;    
    
  const colorFromIndex = function(index: number): HSL {
    return {
      hue: clampInclusive(hueShiftPerColor * index, 0, 360),
      luminance: 50,
      saturation: 100,
    };
  };

  let i = 0;
  while (i < n) {
    yield colorFromIndex(i);
    i += 1;
  }
}

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace RGB {
    export function format(rgb: RGB): ColorString {
      function toHexDigitPair(s: number): string {
        const n = s.toString(16);
        if (n.length === 1) {
          return "0" + n;
        } else {
          return n;
        }
      }
    
      const r = toHexDigitPair(rgb.red);
      const g = toHexDigitPair(rgb.green);
      const b = toHexDigitPair(rgb.blue);
      return `#${r}${g}${b}` as ColorString;
    }

    export function toHsl(rgb: RGB): HSL {
      // normalize between 0..1
      const r = rgb.red / 255.0;
      const g = rgb.green / 255.0;
      const b = rgb.blue / 255.0;


      const maxChroma = Math.max(r, g, b);
      const minChroma = Math.min(r, g, b);
      const chromaRange = maxChroma - minChroma;

      let hue: number;
      // 'neutral' colors are given a hue of 0 by convention (according to wikipedia)
      if (chromaRange === 0) {
        hue = 0;
      } else if (maxChroma === r) {
        hue = ((g - b) / chromaRange) % 6;
      } else if (maxChroma === g) {
        hue = ((b - r) / chromaRange) + 2;
      } else /* if (maxChroma === this.blue) */ {
        hue = ((r - g) / chromaRange) + 4;
      }

      hue *= 60;

      const luminance = (maxChroma + minChroma) / 2;

      const saturation = (luminance === 0 || luminance === 1)
        ? 0
        : chromaRange / (1 - Math.abs(2 * luminance - 1));
        
      return {
        hue: clampInclusive(hue, 0, 360),
        luminance: clampInclusive(luminance * 100, 0, 100),
        saturation: clampInclusive(saturation * 100, 0, 100),
      };
    }



    // Formula pulled from https://www.easyrgb.com/en/math.php
    export function toXYZ(rgb: RGB): XYZ {
      function convertComponent(comp: number): number {
        if (comp > 0.04045) {
          return Math.pow((comp + 0.055) / 1.055, 2.4);
        } else {
          return comp / 12.92;
        }
      }


      const r = 100 * convertComponent(rgb.red / 255.0);
      const g = 100 * convertComponent(rgb.green / 255.0);
      const b = 100 * convertComponent(rgb.blue / 255.0); 

      return { 
        x: clampInclusive(r * 0.4124 + g * 0.3576 + b * 0.1805, 0, 100),
        y: clampInclusive(r * 0.2126 + g * 0.7152 + b * 0.0722, 0, 100),
        z: clampInclusive(r * 0.0193 + g * 0.1192 + b * 0.9505, 0, 100),
      };
    }

}

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace HSL {
    export function format(hsl: HSL): ColorString {
      return `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.luminance}%)` as ColorString;
    }

    export function* hslRange(baseColor: HSL, nPoints: number): Generator<HSL, void, never> {
      const luminanceDeltaPerPoint = baseColor.luminance / nPoints;
      let luminance: RangeInclusive<0, 100> = baseColor.luminance;
        
      while (luminance >= 0 && nPoints > 0) {
        const next = cloneDeep(baseColor);
        next.luminance = clampInclusive(luminance, 0, 100);
        luminance -= luminanceDeltaPerPoint;
        nPoints -= 1;
        yield next;
      }
    }

    export function toRgb(hsl: HSL): RGB {
      // this HSL luminance ranges from 0-100, but needs to be 0-1. Instead of 
      // the normalized multiplication by 2, we pre-divide by 100. 
      const chroma = (1 - Math.abs((hsl.luminance / 50) - 1)) * hsl.saturation;

      const hue = hsl.hue / 60;

      const x = chroma * (1 - Math.abs((hue % 2) - 1));

      let baseRgb: [number, number, number];

      if (0 <= hue && hue < 1) {
        baseRgb = [chroma, x, 0];
      } else if (1 <= hue && hue < 2) {
        baseRgb = [x, chroma, 0];
      } else if (2 <= hue && hue < 3) {
        baseRgb = [0, chroma, x];
      } else if (3 <= hue && hue < 4) {
        baseRgb = [0, x, chroma];
      } else if (4 <= hue && hue < 5) {
        baseRgb = [x, 0, chroma];
      } else /* if (5 <= hue && hue < 6) */ {
        baseRgb = [chroma, 0, x];
      }
        
      const [r1, g1, b1] = baseRgb;

      const m = (hsl.luminance / 100.0) - (chroma / 2);

      return {
        red: clamp(r1 + m, 0, 256),
        green: clamp(g1 + m, 0, 256),
        blue: clamp(b1 + m, 0, 256),
      };
    }
}


export interface CIELab {
    lightness: RangeInclusive<0, 100>;
    a: number;
    b: number;
}

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace CIELab {
    export function toXYZ(cie: CIELab, reference: XYZ.Reference = XYZ.EQUAL): XYZ {
      function convertComponent(comp: number): number {
        const pow3 = Math.pow(comp, 3);
        if (pow3 > 0.008856) { 
          return pow3;
        } else {
          return (comp - 16 / 116) / 7.787;
        }
      }

        
      const y = (cie.lightness + 16.0) / 116.0;
      const x = cie.a / 500.0 + y;
      const z = y - cie.b / 200.0;

      return { 
        x: clampInclusive(convertComponent(x) * reference.refX, 0, 100),
        y: clampInclusive(convertComponent(y) * reference.refY, 0, 100),
        z: clampInclusive(convertComponent(z) * reference.refZ, 0, 100),
      };
    }


    export function differenceSquared(color1: CIELab, color2: CIELab): number {
      const kL = 1;
      const K1 = 0.045;
      const K2 = 0.015;

      const deltaL = color1.lightness - color2.lightness;
      const c1 = Math.sqrt(Math.pow(color1.a, 2) + Math.pow(color1.b, 2));
      const c2 = Math.sqrt(Math.pow(color2.a, 2) + Math.pow(color2.b, 2));
      const deltaC = c1 - c2;
      const EabSquared = (
        Math.pow(color2.lightness - color1.lightness, 2)
            + Math.pow(color2.a - color1.a, 2)
            + Math.pow(color2.b - color1.b, 2)
      );

      const Hab = Math.sqrt(EabSquared - Math.pow(deltaL, 2) - Math.pow(deltaC, 2));

      const SL = 1;
      const SC = 1 + K1 * c1;
      const SH = 1 + K2 * c1;

      const term1 = deltaL / (kL * SL);
      const term2 = deltaC / (SC);
      const term3 = Hab / (SH);

      return ( 
        Math.pow(term1, 2)
            + Math.pow(term2, 2)
            + Math.pow(term3, 2)
      );
    }

    export function difference(color1: CIELab, color2: CIELab): number {
      return Math.sqrt(differenceSquared(color1, color2));
    }
}


export interface XYZ { 
    x: RangeInclusive<0, 100>;
    y: RangeInclusive<0, 100>;
    z: RangeInclusive<0, 100>;
}

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace XYZ {
    export interface Reference {
        refX: number;
        refY: number;
        refZ: number;
    }

    export const EQUAL: Reference = {
      refX: 100.0,
      refY: 100.0,
      refZ: 100.0,
    };

    export const F5: Reference = {
      refX: 93.369,
      refY: 100.0,
      refZ: 98.636,
    };



    export function toCIELab(xyz: XYZ, reference: Reference = EQUAL): CIELab {
      function convertComponent(comp: number): number {
        if (comp > 0.008856) {
          return Math.pow(comp, 1.0 / 3.0);
        } else {
          return (7.787 * comp) + (16.0 / 116.0);
        }
      }

      const x = convertComponent(xyz.x / reference.refX);
      const y = convertComponent(xyz.y / reference.refY);
      const z = convertComponent(xyz.z / reference.refZ);

      return {
        lightness: clampInclusive((116 * y) - 16, 0, 100),
        a: 500 * (x - y),
        b: 200 * (y - z),
      };
    }

    export function toRgb(xyz: XYZ): RGB {
      const x = xyz.x / 100.0;
      const y = xyz.y / 100.0;
      const z = xyz.z / 100.0;

      const r = x * 3.2406 + y * -1.5372 + z * -0.4986;
      const g = x * -0.9689 + y * 1.8758 + z * 0.0415;
      const b = x * 0.0557 + y * -0.2040 + z * 1.0570;

      function convertComponent(comp: number): number {
        if (comp > 0.0031308) { 
          return 1.055 * Math.pow(comp, (1 / 2.4)) - 0.055;
        } else {
          return 12.92 * comp;
        } 
      }
    
      return {
        red: clampInclusive(255 * convertComponent(r), 0, 255),
        green: clampInclusive(255 * convertComponent(g), 0, 255), 
        blue: clampInclusive(255 * convertComponent(b), 0, 255), 
      };
    }
}

