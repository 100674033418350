import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
} from "react";

import {
  Container,
  Card,
  Col,
  Row,
  Placeholder,
  Form,
} from "react-bootstrap";

import EulaModal from "../../components/auth/EulaModal";
import TopNav from "../../components/nav/TopNav";
import Alert from "../../components/misc/Alert";
import LoadingButton from "../../components/misc/LoadingButton";

import { EulaDoc } from "../../misc/Types";

import { getEulaDoc, setEulaDoc } from "../../firebase/Firestore";
import { getFileURL, uploadFile } from "../../firebase/Storage";


export interface AlertData {
  variant: Parameters<typeof Alert>[0]["variant"];
  message: string;
}

function uploadEula(
  file: string | File,
  newVersion: string,
): Promise<EulaDoc> {
  const path = typeof file === "string"
    ? "eula/EULA.pdf"
    : `eula/${file.name}`;

  return uploadFile(path, file).then(path => {
    const newEulaDoc: EulaDoc = {
      path,
      version: newVersion,
    };

    return setEulaDoc(newEulaDoc).then(() => newEulaDoc);
  });
}


function EulaManagement(): JSX.Element {
  const [eulaDoc, setEulaDoc] = useState<null | EulaDoc>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [alert, setAlert] = useState<null | AlertData>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [showPreview, setShowPreview] = useState<boolean>(false);

  const [eulaFile, setEulaFile] = useState<null | File | string>(null);

  const [newEulaVersion, setNewEulaVersion] = useState<string>("");

  useEffect(() => {
    setLoading(true);
    getEulaDoc()
      .then(setEulaDoc)
      .catch(err => setAlert({ variant: "danger", message: err.toString() }))
      .finally(() => setLoading(false));
  }, []);

  const uploadNewEula = useCallback(() => {
    if (eulaFile === null) {
      setAlert({
        variant: "danger",
        message: "Could not find uploaded file, refresh the page and try again"
      });
      return Promise.resolve();
    }

    if (newEulaVersion.length === 0) {
      setAlert({
        variant: "danger",
        message: "Must set a new EULA version name",
      });
      return Promise.resolve();
    }

    setLoading(true);
    return uploadEula(eulaFile, newEulaVersion)
      .then(newDoc => setEulaDoc(newDoc))
      .catch(err => setAlert({ variant: "danger", message: err.toString() }))
      .finally(() => setLoading(false));

  }, [eulaFile, newEulaVersion]);

  const handleFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    console.log({ fileList: event.target.files });

    const file = event.target.files?.item(0) ?? null;

    if (file === null) {
      setAlert({
        variant: "danger",
        message: "Must select a file",
      });

      return;
    }

    if (!file.type.startsWith("application/pdf")) {
      setAlert({
        variant: "danger",
        message: "EULA must be a PDF file",
      });

      return;
    }

    setEulaFile(file);
    setShowPreview(true);
  }, []);

  const getExistingEula = useCallback(() => {
    setEulaFile(null);

    if (eulaDoc === null) {
      setAlert({ variant: "warn", message: "No existing EULA found" });
      return;
    }

    setLoading(true);
    getFileURL(eulaDoc.path)
      .then(url => {
        if (url === null) {
          setAlert({ variant: "danger", message: "No EULA file at expected path" });
        } else {
          setEulaFile(url);
          setShowPreview(true);
        }
      })
      .catch(err => setAlert({ variant: "danger", message: err.toString() }))
      .finally(() => setLoading(false));
  }, [eulaDoc]);

  return (
    <>
      <TopNav title='EULA Management'/>
      <Container>
        <Row>
          <Col/>
          <Col>
            <Card className="w-auto my-2">
              <Card.Header>
              EULA Management/Preview
              </Card.Header>
              <Card.Body className="flex-column justify-content-between">
                <div className="d-flex justify-content-between">
                  <div>Current EULA version:</div>
                  <div className="ml-2">
                    {
                      loading
                        ? <Placeholder animation="glow" md={3}>
                        </Placeholder>
                        : eulaDoc === null
                          ? "No Existing EULA found"
                          : eulaDoc.version
                    }
                  </div>
                </div>
                <div className="d-flex justify-content-around w-100 mt-2">
                  <LoadingButton
                    isLoading={loading}
                    disabled={eulaDoc === null}
                    onClick={getExistingEula}>
                  Preview Existing EULA
                  </LoadingButton>
                </div>
                <hr/>
                <div className="w-auto d-flex flex-column justify-content-between">
                  <Form.Group controlId="fileInput">
                    <Form.Label>
                    Select a new EULA
                    </Form.Label>
                    <Form.Control ref={fileInputRef} type="file" onChange={handleFile}/>
                    <Form.Text>
                    EULA file must be in a PDF format
                    </Form.Text>
                  </Form.Group>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col/>
        </Row>
      </Container>
      {
        eulaFile !== null
          ? <EulaModal file={eulaFile}
            show={showPreview}
            close={() => {
              setShowPreview(false);
              setEulaFile(null);
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
            }}
            hideConfirmButton={typeof eulaFile === "string"}
            disableConfirmButton={newEulaVersion.length === 0 || newEulaVersion === eulaDoc?.version}
            onConfirm={uploadNewEula}
            title={
              typeof eulaFile === "string"
                ? "Preview existing EULA"
                : "Preview new EULA"
            }>
            {
              typeof eulaFile !== "string"
                ? <Form>
                  <Form.Group controlId="newEulaVersion">
                    <Form.Label>Enter a name for the new EULA</Form.Label>
                    <Form.Control type="text"
                      value={newEulaVersion}
                      onChange={(e) => setNewEulaVersion(e.target.value)}
                      isInvalid={newEulaVersion.length === 0 || newEulaVersion === eulaDoc?.version}
                    />
                    <Form.Control.Feedback type="invalid">
                      {
                        newEulaVersion === eulaDoc?.version
                          ? "Cannot match the current EULA name"
                          : "Must enter a EULA name"
                      }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
                : null
            }
          </EulaModal>
          : null
      }
    </>
  );
}


export default EulaManagement;
