import { ColorString, HSL, RGB } from "../../map/Colors";
import { formatColor } from "../../map/Icons";
import { FeatureType } from "../../misc/Types";
import { LayerGroup, TrackedFeatureLayerGroup } from "../LayerGroup";
import { LayerConfig, LayerParamOptions, LayerType, MiscLayer } from "../LayerTypes";
import { Icon, IconGeometry, Polygon, svgBuilderFactory } from "../icon";
import { IconColorStrings, fillInDefaultOptions, addIconFnBuilder } from "./common";


const DEFAULT_STATION_FILL_COLOR: RGB = { red: 0xff, green: 0xcd, blue: 0x5c };



const STATION_ICON: Icon = {
  geometry: IconGeometry.Polygon,
  getPolygons(iconColors: IconColorStrings): Polygon[] {
    return [
      {
        points: [{ x: 0, y: 100 }, { x: 50, y: 0 }, { x: 100, y: 100 }, { x: 50, y: 75 }],
        fillColor: iconColors.borderColor,
      },
      {
        points: [{ x: 22, y: 78 }, { x: 50, y: 22 }, { x: 78, y: 78 }, { x: 50, y: 64 }],
        fillColor: iconColors.fillColor,
      }
    ];
  },
};


const STATION_ICON_SVG_BUILDER = svgBuilderFactory(STATION_ICON);

function stationIconSvgBuilder({ 
  fillColor, 
  borderColor 
}: {
    borderColor: ColorString | RGB | HSL,
    fillColor?: ColorString | RGB | HSL,
}): string {
  const borderColorHex = formatColor(borderColor);
  const fillColorHex = formatColor(fillColor ?? DEFAULT_STATION_FILL_COLOR);

  return (
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100">
            <path d="M0 100 L50  0 L100 100 L50 75 Z" stroke="black" fill="${borderColorHex}" />
            <path d="M22 78 L50 22 L 78  78 L50 64 Z" stroke="black" fill="${fillColorHex}" />
        </svg>`
  );
}


const STATION_LAYER: LayerConfig<LayerType.Symbol> = {
  hasAgeDecay: false,
  layerId: FeatureType.Stations,
  sourceId: FeatureType.Stations,
  addIcon: addIconFnBuilder(STATION_ICON, () => DEFAULT_STATION_FILL_COLOR, false),
  factory: function(opts?: LayerParamOptions): LayerType.Symbol {
    const { visible } = fillInDefaultOptions(opts);

    return {
      id: FeatureType.Stations,
      type: "symbol",
      source: FeatureType.Stations,
      filter: [
        "all",
        ["==", ["get", "dataType"], "station"],
        ["==", ["geometry-type"], "Point"],
        [
          "case",
          ["has", "age"],
          // ignore anything older than 48 hours, no matter what
          [">=", 48 * 60 * 60, ["get", "age"]], 
          false,
        ]
      ],
      layout: {
        "visibility": visible ? "visible" : "none",
        "icon-image": [
          "case",
          ["has", "client"],
          [
            "concat",
            ["get", "client"],
            "-station"
          ],
          "base-station",
        ],
        "icon-size": 0.35,
        "icon-rotate": [
          "case",
          ["has", "heading"],
          ["to-number", ["get", "heading"]],
          0,
        ],
        "icon-allow-overlap": true,
        "text-field": [
          "case",
          ["has", "name"], ["get", "name"],
          "Unnamed Station",
        ],
        "text-allow-overlap": true,
        "text-size": 12,
        "text-anchor": "top",
        "text-offset": [0, 0.5]
      },
      paint: {
        "text-halo-color": ["to-color", "#FFFFFF"],
      }
    };
  }
};


const STATION_TRACKLINE_LAYER: LayerConfig<LayerType.Line> = {
  layerId: MiscLayer.StationTracklines,
  sourceId: FeatureType.Stations,
  factory: function(opts?: LayerParamOptions): LayerType.Line {
    const { visible } = fillInDefaultOptions(opts);

    return {
      id: MiscLayer.StationTracklines,
      type: "line",
      source: FeatureType.Stations,
      filter: [
        "all",
        ["==", ["get", "dataType"], "station"],
        ["==", ["geometry-type"], "LineString"],
      ],
      layout: {
        "visibility": visible ? "visible" : "none",
      },
      paint: {
        "line-color": [
          "case",
          // try to use the overridden 'color' first
          ["has", "color"], ["to-color", ["get", "color"]],
          // and fallback to the known color worst case.
          ["has", "clientColor"], ["to-color", ["get", "clientColor"]],
          ["to-color", "#000000"]
        ],
        // Data-driven line-gradient not supported by mapbox yet.
        // Once they implement this, we can re-add this
        // "line-gradient": [
        //   'interpolate',
        //   ['linear'],
        //   ['line-progress'],
        //   0,
        //   '#000000',
        //   1,
        //   ['get', 'clientColor']
        // ]
      }
    };
  }
};


export const STATION_LAYER_GROUP: LayerGroup = new TrackedFeatureLayerGroup({
  name: FeatureType.Stations,
  featureLayer: STATION_LAYER, 
  tracklineLayer: STATION_TRACKLINE_LAYER,
});
