import React, { useMemo } from "react";
import { Image, ListGroup, Table, Spinner } from "react-bootstrap";

import ImageCarousel from "../misc/ImageCarousel";

import { 
  SightingImages,
  doesFeatureHaveImages,
  useFeaturePopupOptions,
} from "../../hooks/useFeaturePopupOptions";

import { Mapbox, MapSources } from "../../misc/Types";

import {
  isNonEmptyRecord,
} from "../../misc/Utils";




const RED_X_ICON = new URL("/public/static/icons/red_circle_x.svg", import.meta.url);



export type MapPopupProps = {
  sources: MapSources;
  feature: Mapbox.GeoJsonFeature;
  admin: boolean;
  closeHandler?: () => void;
}




export const PopupRow = React.memo((props: { entry: [string, unknown] }): JSX.Element => {
  const [key, data] = props.entry;

  let rowChildren: JSX.Element|null = null;
  let noPadding = false;

  if (typeof data === "string" && data.startsWith("http")) {
    rowChildren = (
      <a href={data} target='_blank' rel="noopener noreferrer">
        {key}
      </a>
    );
  } else if (isNonEmptyRecord(data)) {
    noPadding = true;

    const entries = Object.entries<unknown>(data);

    rowChildren = (
      <Table size='sm' striped className='no-margins'>
        <thead>
          <tr className='centered-row'>
            <th colSpan={2}>{ key }</th>
          </tr>
        </thead>
        <tbody>
          {
            entries.map(([innerKey, innerData]) => (
              <tr key={`${innerKey}-${innerData}`}>
                <td>{ innerKey }</td>
                <td>{ String(innerData) }</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    );
  } else {
    rowChildren = (<>{key}: {data}</>);
  }

  const listItemClasses = [
    "smaller-text",
    noPadding ? "no-padding" : "reduced-padding"
  ];

  return (
    <ListGroup.Item key={`${key}-${data}`} className={listItemClasses.join(" ")}>
      {rowChildren}
    </ListGroup.Item>
  );
});

PopupRow.displayName = "PopupRow";


export interface PopupHeaderProps {
  statusImgSrc?: null | string;
  name: string | string[] | JSX.Element
  closeHandler?: () => void;
}

export const PopupHeader = React.memo((props: PopupHeaderProps): JSX.Element => { 
  const { statusImgSrc, closeHandler, name } = props;

  return (
    <div className='d-flex align-middle justify-content-between'>
      {
        typeof statusImgSrc === "string"
          ? <Image className="my-auto" width='16' height='16' src={statusImgSrc} />
          : null
      }
      <div className="mx-1 align-middle w-auto">
        <b className="d-block text-center">
          {
            Array.isArray(name)
              ? name.join("\n")
              : name
          }
        </b>
      </div>
      { 
        closeHandler 
          ? <div className='my-auto image-button' onClick={() => closeHandler?.()}>
            <Image width='20' height='20' src={RED_X_ICON.href} />
          </div>
          : null
      }
    </div>
  );
});

PopupHeader.displayName = "PopupHeader";


export interface SightingImageCarouselProps {
  feature: mapboxgl.MapboxGeoJSONFeature;
  isLoading: boolean;
  images: SightingImages;
}


export const SightingImageCarousel = React.memo((props: SightingImageCarouselProps): JSX.Element => {
  const { feature, isLoading, images } = props;

  if (isLoading) {
    return ( 
      <ListGroup.Item className="smaller-text reduced-padding">
        <div className="w-100 d-inline-flex justify-content-center">
          <Spinner animation="border" className='mx-auto' variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </ListGroup.Item>
    );
  } else if (images.fullRes.length > 0 || images.thumbnails.length > 0) {
    return ( 
      <ListGroup.Item className="smaller-text reduced-padding">
        <ImageCarousel
          srcSet={images}
          title={feature.properties?.name}
          className="w-25" />
      </ListGroup.Item>
    );
  } else {
    return ( 
      <ListGroup.Item className="smaller-text reduced-padding">
        Error: Could not retrieve images
      </ListGroup.Item>
    );
  }
});

SightingImageCarousel.displayName = "SightingImageCarousel";






const MapPopup = (props: React.PropsWithoutRef<MapPopupProps>): JSX.Element => {
  const { sources, feature, closeHandler, admin } = props;

  const {
    isLoading,
    statusImgSrc,    
    sightingImages,
    name,
    shouldRenderImages,
    displayEntries,
  } = useFeaturePopupOptions(sources, feature, admin);


  return (
    <div className="mapbox-popup-container">
      <ListGroup variant="flush">
        <ListGroup.Item 
          className='reduced-padding' 
          variant={displayEntries.length > 0 ? "secondary" : undefined}
        >
          <PopupHeader 
            closeHandler={closeHandler}
            name={name}
            statusImgSrc={statusImgSrc}
          />
        </ListGroup.Item>
        {
          displayEntries.map((entry, idx) => ( 
            <PopupRow key={idx} entry={entry} />
          ))
        }
        {
          shouldRenderImages
            ? <SightingImageCarousel 
              feature={feature}
              isLoading={isLoading}
              images={sightingImages}
            />
            : null
        }
      </ListGroup>
    </div>
  );
};

export default MapPopup;
