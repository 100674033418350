

export type NonEmptyArray<T> = [T, ...T[]];



// Recursive type adds an element until it reaches the right size, N. Works 
// up to a size of 1000 (TS compiler recursion limits) 
export type FixedArray<T, N extends number, A extends T[] = []> =
    A["length"] extends N 
        ? A 
        : FixedArray<T, N, [...A, T]>;

export function isFixedArray<T, N extends number>(array: T[], size: N): array is FixedArray<T, N> {
  return array.length === size;
}

export function toFixedArray<T, N extends number>(array: T[], size: N): FixedArray<T, N> {
  if (!isFixedArray(array, size)) {
    throw new Error(`expected an array with ${size} elements, found one with ${array.length}`);
  }
    
  return array;
}
