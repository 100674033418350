
import React, { useMemo, useState, useEffect } from "react";
import { StatusIcon } from "../misc/Status";

import StorageAPI from "../firebase/Storage";

import { FeatureType, MapSources, Mapbox } from "../misc/Types";
import { GeoJson } from "../misc/GeoJson";
import { capitalizeFirstLetter, formatDisplayString, formatTimeDeltaString, isNonEmptyRecord } from "../misc/Utils";
import { MiscLayer } from "../layers/LayerTypes";



const SPECIAL_FIELD_ORDERINGS = {
  "Detections": 0,
  "Operator": -1,
  "Data From": -2,
  "TimeSinceLastReport": -2,
  "ReportTimeUTC": -2,
  "Marine Traffic Page": -1,
};

const UNSHARED_FIELDS = [
  "Detection Distance",
  "Station",
];


const DISTANCE_EST_METHOD_KEY = "Distance Estimation Method";

// Checks for a name that starts with A### (where ### is some number)
const ACOUSTIC_NAME_REGEX = /^A\d+/;

const SHAREABLE_DATA_TYPES = [
  "sighting",
  "station",
];

const CENTRAL_OREGON_BBOX = {
  // NW corner, northern oregon coast(ish)
  min_lat: -123.4831653,
  max_lon: 45.3411379,
  // SE corner, near idaho/nevada
  max_lat: -117.5562274,
  min_lon: 42.3916097,
};


function isTestFeature(feature: Mapbox.GeoJsonFeature): boolean {
  if (feature.geometry.type !== "Point") {
    return false;
  }


  const lat = feature.geometry.coordinates[0];
  const lon = feature.geometry.coordinates[1];

  return (
    CENTRAL_OREGON_BBOX.max_lat > lat && lat > CENTRAL_OREGON_BBOX.min_lat
        && CENTRAL_OREGON_BBOX.max_lon > lon && lon > CENTRAL_OREGON_BBOX.min_lon
  );
}

export function doesFeatureHaveImages(feat: Mapbox.GeoJsonFeature): boolean {
  return (
    feat.properties?.dataType === "sighting"
        && Array.isArray(feat.properties.images)
        && feat.properties.images.length > 0
  );
}



function loadSightingImages(images: string[]): Promise<SightingImages> {
  return Promise.all(images.map(StorageAPI.getFileURL)).then(results => {
    const sgtImgs: SightingImages = {
      fullRes: [],
      thumbnails: [],
    };

    for (const url of results) {
      // pop the front image every time, that way if an image wasn't found
      // we keep with the same position in 'images' 
      const imgPath = images.shift();

      if (typeof url !== "string" || typeof imgPath !== "string") {
        continue;
      }

      if (imgPath.includes("/thumb/")) {
        sgtImgs.thumbnails.push(url);
      } else {
        sgtImgs.fullRes.push(url);
      }
    }

    return sgtImgs;    
  });
}


function doesUserHaveFullFeaturePerms(sources: MapSources, feature: mapboxgl.MapboxGeoJSONFeature): boolean {
  // all users can see all fields, if the data type isn't shared
  if (!SHAREABLE_DATA_TYPES.includes(feature.properties?.dataType)) {
    return true;
  }

  // user is an admin
  if (typeof sources === "boolean") {
    return sources;
  }

  const sourceInfo = sources[feature.properties?.client];

  // user has full perms for the given client 
  if (sourceInfo === true) {
    return true;
  }

  // user can see specifically all data from this specific data type, for this specific client
  if (Array.isArray(sourceInfo) && sourceInfo.includes(feature.properties?.dataType)) {
    return true;
  }

  // otherwise, no
  return false;
}


function isAcoustic(
  props: Mapbox.GeoJsonProperties,
  displayProps: GeoJson.DisplayProperties, 
  checkName: boolean = false,
): boolean {
  if (
    checkName
        && typeof props?.name === "string" 
        && ACOUSTIC_NAME_REGEX.test(props.name)
  ) {
    return true;    
  }

  const estMethod = displayProps[DISTANCE_EST_METHOD_KEY];

  return (
    typeof estMethod === "string"
        && estMethod.toLocaleLowerCase() === "acoustic"
  );
}


function extractProperties(props: Mapbox.GeoJsonProperties, layerId: string): GeoJson.DisplayProperties {
  if (!props) return {};

  let displayProps: GeoJson.DisplayProperties = {};

  if (typeof props.displayProperties === "string") {
    displayProps = JSON.parse(props.displayProperties);
  } else if (isNonEmptyRecord(displayProps)) {
    displayProps = props.displayProperties as GeoJson.DisplayProperties;
  }

  if (typeof props.age === "number" && props.age > 0) {
    displayProps.TimeSinceLastReport = formatTimeDeltaString(props.age);
  }

  switch (layerId) {
    // We only want to display the name for these layer types
    case FeatureType.LeaseAreas:
      displayProps = {};
      break;
    case MiscLayer.StationTracklines:
    case MiscLayer.GliderTracklines:
      break;
      // NOAA-SMA is a builtin layer from the mapbox style
      // this extracts a known field for the main name
    case "NOAA-SMA":
      displayProps = {
        name: props["Restr Area"] ?? props["Restr_Area"],
        // ...props
      };
      break;
  }

  if (typeof props.marineTrafficLink === "string" &&
        props.marineTrafficLink.startsWith("https://")) {
    displayProps.vesselInformation = props.marineTrafficLink;
  }

  if (props.dataType === FeatureType.Stations &&
        typeof props.speed === "number" &&
        !isNaN(props.speed)) {
    displayProps.vesselSpeed = `${props.speed.toFixed(1)} km/h`;
  }

  // grab this flag __before__ modifying things in 'displayProps'
  const setAcousticDesc = isAcoustic(props, displayProps);

  for (const [key, value] of Object.entries(displayProps)) {
    if (key === "name") continue;

    // Delete the entry from the displayProps
    delete displayProps[key];

    const keyLower = key.toLocaleLowerCase();

    if (keyLower === "description" && typeof value === "string") {
      const valLower = value.toLocaleLowerCase(); 
            
      // skip 'vehicle' as a description
      if (valLower === "vehicle") {
        continue;
      }
    }

    const formattedStr = formatDisplayString(key);

    if (typeof value === "string") {
      const trimmed = value.trim();

      if (trimmed.length > 0) {
        displayProps[formattedStr] = trimmed;
      }
    } else if (typeof value === "number") {
      displayProps[formattedStr] = value;
    } else if (typeof value === "object") {
      displayProps[formattedStr] = value as Record<string, string | number>;
    }
  }

  if (setAcousticDesc) {
    displayProps.description = "Acoustic Detection";
  }
    

  return displayProps;
}


function extractNameAndDisplayEntries(
  feature: mapboxgl.MapboxGeoJSONFeature,
  userHasFullPerms: boolean,
  userIsAdmin: boolean,
  isTestFeature: boolean,
): { name: string | JSX.Element, displayEntries: [string, unknown][] } {

  // extract the display properties
  const displayProps = extractProperties(feature.properties, feature.layer.id);

  // pull the name out, from where ever we can get it
  let name: string | JSX.Element = feature.properties?.name ?? displayProps?.name;

  // remove any duplicate names in the display props 
  if (displayProps.name) {
    delete displayProps.name;
  }

  if (feature.layer.id === "NOAA-SMA") {
    name = <>NOAA Seasonal Management Area <br/> { name } </>;
  } else if (typeof name !== "string" || name.trim().length === 0) {
    // fallback name, if we cant find the name for some reason
    name = `Unknown ${capitalizeFirstLetter(feature.layer.id)}`;
  }

  const displayEntries = Object.entries(displayProps).filter(ent => {
    const isSpecialField = SPECIAL_FIELD_ORDERINGS[ent[0]] !== undefined;
    const hasPerms = userHasFullPerms || !UNSHARED_FIELDS.includes(ent[0]);

    return !isSpecialField && hasPerms;
  });

  // add the data source
  const source = feature.properties?.clientDisplayName ?? feature.properties?.client;
  if (typeof source === "string" && source.length > 0) {
    displayEntries.push(["Source", source]);
  }

  // admin-only debug tool to include the raw feature guid/uuid
  if (userIsAdmin) {
    displayEntries.push(["id", feature.properties!.id]);
  }

  // put specific/special fields in a known order
  for (const [name, order] of Object.entries(SPECIAL_FIELD_ORDERINGS)) {
    if (displayProps[name]) {
      let index = order;
      if (order < 0) {
        index = displayEntries.length + order + 1;
      }

      displayEntries.splice(index, 0, [name, displayProps[name]]);
    }
  }

  if (isTestFeature) {
    if (typeof name === "string") {
      name = `[TEST] - ${name}`;
    } else {
      name = <span>[Test] - {name}</span>;
    }
  }

  return { name, displayEntries };
}





export interface SightingImages {
  thumbnails: string[];
  fullRes: string[];
}



export interface FeaturePopupOptions {
  isLoading: boolean;
  statusImgSrc: null | string;
  imageUrls: string[];
  shouldRenderImages: boolean;
  /** Both 'thumbnails' and 'fullRes' will be empty arrays if there are no sighting images
   * __OR__ if the feature isn't a sighting. Differentiating between these 2 states isn't 
   * important here, but making array checks cleaner is always nice.  
   */
  sightingImages: SightingImages;
  name: string | JSX.Element;
  testFeature: boolean;
  displayEntries: [string, unknown][];
}


export function getFeatureStatusImgSrc(feature: Mapbox.GeoJsonFeature): null | string {
  switch (feature.properties?.dataType) {
    case "glider":
    case "buoy":
    case "slowZone":
      return feature.properties?.age < 12 * 60 * 60
        ? StatusIcon.Ok.src.href
        : StatusIcon.Error.src.href;            
    default:
      return null;
  }
}

/**
 * Internal state logic for MapPopup, encapsulated in a single hook.
 * 
 * Extracted so it can be shared between MapPopup and MultiMapPopup
 * 
 * @param feat the geojson feature, from the mapbox query
 * @returns the relevant options for the given feature, as they load (if needed)
 */
export function useFeaturePopupOptions(
  sources: MapSources, 
  feat: Mapbox.GeoJsonFeature,
  admin?: boolean,
): FeaturePopupOptions {
  const feature = useMemo(() => feat, [feat]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const [statusImgSrc, setStatusImgSrc] = useState<string | null>(null);

  const [sightingImages, setSightingImages] = useState<SightingImages>({
    thumbnails: [],
    fullRes: [],
  });

  const fullFieldPermissions = useMemo(
    () => doesUserHaveFullFeaturePerms(sources, feature),
    [sources, feature],
  );

  const testFeature = useMemo(
    () => feature.properties?.test === true || isTestFeature(feature), 
    [feature],
  );


  const { name, displayEntries } = useMemo(
    () => extractNameAndDisplayEntries(feature, fullFieldPermissions, admin ?? false, testFeature),
    [feature, fullFieldPermissions, testFeature]
  );


  useEffect(() => {
    setStatusImgSrc(getFeatureStatusImgSrc(feature));
        
    if (feature.properties?.dataType === "sighting") {
      // reset any existing images
      setImageUrls([]);
      let images: unknown = feature.properties.images;
      if (typeof images === "string") {
        try {
          images = JSON.parse(images);
        } catch (err) {
          console.log(err);
        }
      }

      if (Array.isArray(images) && images.length > 0) {
        setImageUrls(images);
      }
    }
  }, [feature]);


  useEffect(() => {
    if (Array.isArray(imageUrls) && imageUrls.length > 0) {
      setIsLoading(true);
      loadSightingImages(imageUrls)
        .then(setSightingImages)
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }
  }, [imageUrls]);

  return {
    statusImgSrc,
    sightingImages,
    testFeature,
    shouldRenderImages: (
      imageUrls.length > 0 
            || sightingImages.fullRes.length > 0 
            || sightingImages.thumbnails.length > 0
    ),
    isLoading,
    name,
    imageUrls,
    displayEntries,
  };
}
