import cloneDeep from "lodash/cloneDeep";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import levenshtein from "fast-levenshtein";
import { inPlaceSort } from "fast-sort";
import type { GeoJson } from "./GeoJson";

import { DataType, FeatureType, StringSelector } from "../misc/Types";
import { z } from "zod";


/**
 * Helper that combines z.optional + z.nullable into one function.
 * @param ty 
 * @param params 
 * @returns 
 */
export function nullish<T extends z.ZodTypeAny>(ty: T, params?: z.RawCreateParams): z.ZodOptional<z.ZodNullable<T>> {
  return z.optional(z.nullable(ty, params), params);
}




export type Valid<V extends Record<string | number | symbol, unknown>> = {
  [key in keyof V]: boolean;
}


export function allValid<V extends Record<string | number | symbol, unknown>>(checks: Valid<V>): boolean {
  return (Object.values(checks) as boolean[]).every(valid => valid);
}


export interface StringSortOptions<T> {
  preproc?: StringSelector<T>,
  ascending?: boolean,
}

interface BaseLevArgs<T> {
  strings: T[];
  searchString: string;
  strSelector?: T extends string ? never : StringSelector<T>;
}

export interface TryParseNumberOpts {
  includeNaN?: boolean;
  fallback?: number;
}

export interface RGBColor {
  r: number;
  g: number;
  b: number;
}

// Wraps the base lev arguments by requiring a selector function if the
// array is not an array of strings
type LevArgs<T> = T extends string
  ? BaseLevArgs<string>
  : Required<BaseLevArgs<T>>;

const propToDisplayStringRegex = /([A-Z]?[a-z]+|[A-Z]+|[0-9]+)/g;
const letterRegex = /([a-z]|[A-Z])/;

// presorted list of common time zone abbreviations
const timeZoneAbbrs = [
  "ACT", "AET", "AGT", "ART", "AST", "BET", "BST", "CAT", "CDT", "CNT", "CST",
  "CTT", "EAT", "ECT", "EDT", "EET", "EST", "GMT", "HST", "IET", "IST", "JST",
  "MET", "MIT", "MST", "NET", "NST", "PDT", "PLT", "PNT", "PRT", "PST", "SST",
  "UTC", "VST"
];


export function isNullish(x: unknown): x is null | undefined {
  return x === null || x === undefined;
}


export function isNonEmptyRecord(x: unknown): x is { [key: string | number]: unknown } {
  return isPlainObject(x) && !isEmpty(x);
}


export function randInt(max: number): number;
export function randInt(min: number, max: number): number;
export function randInt(min: number, max?: number): number {
  if (typeof max === "number") {
    const randRange = (max - min) * Math.random();

    return Math.floor(randRange + min);
  } else {
    return randInt(0, min);
  }
}

export function catchError<T>(promise: Promise<T>, onError: (err: unknown) => void = console.error): Promise<T | null> {
  return promise.catch(err => { 
    onError(err);
    return null;
  });
} 


export enum Ordering {
  Less = -1,
  Equal = 0,
  Greater = 1,
}

export function sortedValues<
  K extends number | string | symbol,
  V,
  O extends number | string | boolean | bigint | null | undefined,
>(
  record: Record<K, V>,
  keySelector?: (value: V) => O,
  ascending: boolean = false,
): V[] {
  if (typeof keySelector === "function") {
    const sel = keySelector;

    const sortFn = (a: V, b: V): number => {
      const aKey = sel(a);
      const bKey = sel(b);

      const aNullish = isNullish(aKey);
      const bNullish = isNullish(bKey);

      if (aNullish && bNullish) {
        return Ordering.Equal;
      } else if (aNullish) {
        return ascending ? Ordering.Greater : Ordering.Less; 
      } else if (bNullish) {
        return ascending ? Ordering.Less : Ordering.Greater; 
      }

      // not-null assertions valid here, since we check for them above,
      // and return if either (or both) are null-ish 
      if (aKey! > bKey!) {
        return ascending ? Ordering.Less : Ordering.Greater;
      } else if (aKey! < bKey!) {
        return ascending ? Ordering.Greater : Ordering.Less;
      } else {
        return Ordering.Equal;
      }
    };

    const values: V[] = Object.values(record);
    values.sort(sortFn);
    return values;
  } else {
    const entries: [string, V][] = Object.entries(record);
    entries.sort((a, b) => {
      const aKey = a[0][0].toLowerCase();
      const bKey = b[0][0].toLowerCase();

      if (aKey > bKey) {
        return ascending ? Ordering.Less : Ordering.Greater;
      } else if (aKey < bKey) {
        return ascending ? Ordering.Greater : Ordering.Less;
      } else {
        return 0;
      }
    });

    return entries.map(entry => entry[1]);
  }
}


export type Primitive = "string"
  | "number"
  | "bigint"
  | "boolean"
  | "symbol"
  | "undefined"
  | "object"
  | "function";

export function isTypeOrUndefined(x: unknown, type: Primitive): boolean {
  return x === undefined || typeof x === type;
}

export function isValidOrUndefined<T>(x: unknown, pred: (obj: unknown) => obj is T): x is undefined | T {
  return x === undefined || pred(x);
}


export function formatEpoch(epoch: number, showTZ: boolean = false): string {
  const date = new Date(epoch * 1000);

  return showTZ
    ? date.toLocaleString("en-US", { timeZoneName: "short" })
    : date.toLocaleString();
}

export const parseUrlQuery = (): Record<string, number|string|boolean> => {
  const params = new URLSearchParams(window.location.search);

  const parsed: Record<string, number|string|boolean> = {};

  params.forEach((value, key) => {
    let parsedValue: null|number|boolean;
    if (value === "true" || value === "false") {
      parsedValue = Boolean(value);
    } else if (value.includes(".")) {
      parsedValue = tryParseFloat(value, { includeNaN: false });
    } else {
      parsedValue = tryParseInt(value, { includeNaN: false });
    }

    parsed[key] = parsedValue ?? value;
  });

  return parsed;
};

export const ageCutoff =  24 * 60 * 60; // 24 hours in seconds

// Helper function that allows for nicer handling of plural/singular args.
export function formatPluralArgs<T extends true|string[]>(singular?: string, plural?: T): T {
  if (plural) return plural;
  else if (singular) return [singular] as T;
  else {
    throw new Error("Both the plural and singluar arguments failed to be returned.");
  }

}

export const stringSortFactory = <T>(opts: StringSortOptions<T>): (a: T, b: T) => number => {
  if (typeof opts.ascending !== "boolean") opts.ascending = true;


  return (a: T, b: T): number => {
    let aString: string;
    let bString: string;

    if (typeof a === "string" && typeof b === "string") {
      aString = a;
      bString = b;
    } else if (opts.preproc) {
      aString = opts.preproc(a);
      bString = opts.preproc(b);
    } else {
      throw new Error("Must define a preproc function to extract strings.");
    }


    if (aString > bString) return opts.ascending ? 1 : -1;
    if (aString < bString) return opts.ascending ? -1 : 1;
    else return 0;
  };
};

export const caseInsensitiveIncludes = (array: string[], item: string): string|null => {
  if (typeof item !== "string") {
    throw new Error(`Expected the item to be a string, not ${typeof item}`);
  }


  if (!Array.isArray(array)) {
    throw new Error(`Expected an array, but instead recieved ${typeof array}`);
  }


  if (!array.every(elem => typeof elem === "string")) {
    throw new Error("Expected an array of all strings");
  }


  const lowerItem = item.toLowerCase();

  const lowerMap = array.reduce((a, c) => {
    a[c.toLowerCase()] = c;
    return a;
  }, {});

  if (lowerMap[lowerItem]) {
    return lowerMap[lowerItem];
  }


  return null;
};

export const formatDisplayList = (array: {toString(): string}[]): string|null => {
  // Handle cases for a small or invalid input.
  if (!Array.isArray(array)) return array;
  if (array.length === 0) return null;
  if (array.length === 1) return array[0].toString();

  const copy = cloneDeep(array);

  const lastElem = copy.pop();

  return `${copy.join(", ")} and ${lastElem}`;
};


export function tryParseInt(num: null|undefined|string|number, opts: TryParseNumberOpts = {}): number|null {
  // default to rejecting NaN if not specified
  const includeNaN = opts.includeNaN ?? false;
  const { fallback } = opts;

  if (num === null || num === undefined) return null;

  // Right off the bat, if we already have a number, return it.
  // Handle NaN + falling back to the provided value if needed.
  if (typeof num === "number") {
    if (isNaN(num)) {
      if (includeNaN) return num;

      return fallback ? Math.floor(fallback) : null;
    }

    return num;
  }

  const parsed = parseInt(num);

  if (isNaN(parsed)) {
    if (includeNaN) return parsed;

    return fallback ? Math.floor(fallback) : null;
  }

  return parsed;
}

export function tryParseFloat(num: null|undefined|string|number, opts: TryParseNumberOpts = {}): number|null {
  // default to rejecting NaN if not specified
  const includeNaN = opts.includeNaN ?? false;
  const { fallback } = opts;

  if (num === null || num === undefined) return null;

  // Right off the bat, if we already have a number, return it.
  // Handle NaN + falling back to the provided value if needed.
  if (typeof num === "number") {
    if (isNaN(num)) {
      if (includeNaN) return num;

      return fallback ?? null;
    }

    return num;
  }

  const parsed = parseFloat(num);

  if (isNaN(parsed)) {
    if (includeNaN) return parsed;

    return fallback ?? null;
  }

  return parsed;
}

// Returns true if the slow zone is expired.
export function slowZoneExpired(geojson: GeoJson.Feature): boolean {
  if (geojson.properties.dataType !== FeatureType.SlowZones ||
      !geojson.properties.expiryEpoch) {
    return false;
  }

  let expiryEpoch: unknown = geojson.properties.expiryEpoch;
  if (typeof expiryEpoch === "string") {
    expiryEpoch = tryParseFloat(expiryEpoch);
  }


  if (typeof expiryEpoch === "number") {
    const now = Date.now() / 1000;

    return now - expiryEpoch > 0;
  }

  console.error(
    `recieved invalid expiryEpoch: ${expiryEpoch}, with type: ` +
    `${typeof expiryEpoch}`
  );

  return false;
}

export const getMaxOfArray = (arr: number[]): number => {
  if (arr.length === 0) return 0;

  return arr.reduce((a, b) => Math.max(a, b));
};

export const getMinOfArray = (arr: number[]): number => {
  if (arr.length === 0) return 0;

  return arr.reduce((a, b) => Math.min(a, b));
};


const sumOfArray = (array: number[]): number => array.reduce((a, c) => a + c);

const meanOfArray = (array: number[]): number => sumOfArray(array) / array.length;

const medianOfArray = (array: number[]): number => {
  const clone = cloneDeep(array);

  clone.sort((a, b) => a - b);

  const midIdx = Math.floor(clone.length / 2);

  if (clone.length % 2 === 0) {
    return (clone[midIdx - 1] + clone[midIdx]) / 2;
  }

  return clone[midIdx];
};

const stdOfArray = (array: number[]): number => {
  const mean = meanOfArray(array);
  const sum = array.reduce((a, c) => a + Math.pow(c - mean, 2));

  return Math.sqrt(sum / array.length);
};

export const arrayMath = {
  max: getMaxOfArray,
  min: getMinOfArray,
  sum: sumOfArray,
  mean: meanOfArray,
  median: medianOfArray,
  std: stdOfArray,
};

export const capitalizeFirstLetter = (str: string): string => {
  if (typeof str !== "string") {
    throw new Error(
      "'Utils.formatDisplayString' expected a string as an input, not " +
      `'${str}' with type: '${typeof str}'`
    );
  }


  if (str.length === 0) {
    return "";
  }


  let outputStr = "";

  let idxOfFirstLetter = -1;
  for (let idx = 0; idx < str.length; idx++) {
    const char = str.charAt(idx);

    if (letterRegex.test(char)) {
      outputStr += char.toUpperCase();
      idxOfFirstLetter = idx;
      break;
    } else {
      outputStr += char;
    }

  }

  if (idxOfFirstLetter > -1 && str.length > idxOfFirstLetter + 1) {
    outputStr += str.slice(idxOfFirstLetter + 1);
  }



  return outputStr;
};

export const wrapTZAbbrsInParens = (str: string): string => {
  return timeZoneAbbrs.includes(str) ? `(${str})` : str;
};

export const formatDisplayString = (str: string): string => {
  if (typeof str !== "string") {
    throw new Error(
      "'Utils.formatDisplayString' expected a string as an input, not " +
      `'${str}' with type: '${typeof str}'`
    );
  }


  if (str.length === 0) {
    return "";
  }


  // spaces confuse the regex (and this is the easiest way to fix it)
  str = str.replace(" ", "_");

  const matches = [...str.matchAll(propToDisplayStringRegex)];

  return matches.map(mat => mat[0]) // grab the matched string from the array of match info
    .map(capitalizeFirstLetter) // capitalise the first letter
    .map(wrapTZAbbrsInParens)
    .join(" "); // join with spaces
};

export const formatRGBAString = (color: string | RGBColor, opacity: number): string => {
  if (typeof color === "string") {
    color = extractRGBString(color);
  }

  if (typeof opacity !== "number" || opacity < 0 || opacity > 1) {
    throw new Error("Opactiy must be a number between 0 and 1.");
  }


  return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`;

};

export const extractRGBString = (rgbStr: string): RGBColor => {
  rgbStr = rgbStr.trim().replace("#", "");

  if (rgbStr.length !== 6) {
    throw new Error(
      `rgb string has more than 6 components, invalid: ${rgbStr}`
    );
  }

  return {
    r: parseInt(rgbStr.slice(0, 2), 16),
    g: parseInt(rgbStr.slice(2, 4), 16),
    b: parseInt(rgbStr.slice(4, 6), 16),
  };
};


export function addAlphaToRgb<F>(
  hexColor: string,
  alpha: number,
  fallback?: F,
): F | string {
  try {
    const rgb = extractRGBString(hexColor);

    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;

  } catch (err) {
    if (fallback !== undefined) {
      return fallback;
    } else {
      console.error(err);
      throw err;
    }
  }
}

export const calculateAge = (geojson: GeoJson.Feature): number => {
  return (Date.now() / 1000) - geojson.properties.epoch;
};

export function levenshteinEmailSort<T>(args: LevArgs<T>): Promise<T[]> {

  const { strings, searchString, strSelector } = args;

  if (strings.length <= 1) return Promise.resolve(strings as T[]);

  const promises = strings.map((data: T | string) => {
    const str = strSelector
      ? strSelector(data as T)
      : data as string;

    if (typeof str !== "string") {
      return null;
    }

    const atIdx = str.lastIndexOf("@");

    const name = str.substring(0, atIdx);
    const domain = str.substring(atIdx);

    let distance = 1000;

    if (searchString[0] === "@") {
      for (let i = 0; i < domain.length - searchString.length; i++) {
        const subDist = levenshtein.get(
          searchString,
          domain.substring(i, i + searchString.length)
        );

        distance = Math.min(distance, subDist);
      }
    } else if (searchString.includes("@")) {
      distance = Math.min(levenshtein.get("@" + domain, searchString),
        levenshtein.get(name + "@", searchString));
    } else {
      for (let i = 0; i < name.length - searchString.length; i++) {
        const subDist = levenshtein.get(
          searchString,
          name.substring(i, i + searchString.length)
        );

        distance = Math.min(distance, subDist);
      }
    }


    return {
      data: data,
      distance: distance
    };
  });

  return Promise.all(promises).then(computedDists => {
    const filtered = filterInvalid(computedDists);
    inPlaceSort(filtered).asc(container => container.distance);

    /*
    computedDists.forEach(dat => {
      const search = strSelector ? strSelector(dat.data as T) : dat.data as string;
      console.log(`${search} - ${searchString} - distance = ${dat.distance}`);
    });
    */

    return filtered.map(wrap => wrap.data as T);
  });
}

function filterInvalid<T>(array: (T | null | undefined)[]): T[] {
  return array.filter(elem => elem !== null && elem !== undefined) as T[];
}

export const formatTimeDeltaString = (delta: number, secPrec = 0): null|string => {
  if (isNaN(delta) || typeof delta !== "number") return null;

  // default to seconds
  let ageStr = `${delta.toFixed(secPrec)} seconds`;

  // if the age is more than 48 hours, use units of days
  if (delta > 172800) {
    ageStr = `${(delta / 86400).toFixed(2)} days`;
  }

  // if over 1.5 hours, use units of hours
  else if (delta > 5400) {
    ageStr = `${(delta / 3600).toFixed(2)} hours`;
  }

  // if over 90 seconds, use units of minutes
  else if (delta > 90) {
    ageStr = `${(delta / 60).toFixed(1)} minutes`;
  } else if (delta > 5) {
    ageStr = `${Math.round(delta)} seconds`;
  }

  // if we're less than that, the default will keep us in seconds.

  return ageStr;
};

export const windowString = (string: string, interval: number): string[] => {
  const windows: string[] = [];

  let splitStr = string.split(" ");

  while (splitStr.length) {
    const windowContainer: string[] = [];
    while (splitStr.length && windowContainer.join(" ").length < interval) {
      windowContainer.push(splitStr[0]);
      splitStr = splitStr.slice(1);
    }

    windows.push(windowContainer.join(" "));
  }

  return windows;
};
