import {
  getDatabase,
  onValue,
  onChildAdded,
  onChildMoved,
  onChildChanged,
  onChildRemoved,
  off,
  child,
  ref,
  orderByChild,
  query,
  get,
  startAt,
  runTransaction,
  endAt,
  DatabaseReference as Reference,
  Query,
  DataSnapshot,
  EventType,
} from "firebase/database";

import type { Geo } from "../misc/Geo";
import { isNonEmptyRecord } from "../misc/Utils";
import { GeoJson, isGeoJsonPoint } from "../misc/GeoJson";
import { Status, StatusTree, isStatus } from "../misc/Status";

import type { GenericObj } from "./API";

import { initFirebase } from "../setup/Setup";
initFirebase();

// similar helper union type for chaining a query
type QueryChain = Reference | Query;

export interface TracklineListenerOpts extends GenericObj {
  id: string;
  startEpoch?: number;
  endEpoch?: number;
}

export interface IdOpts extends GenericObj {
  ids: true | string | string[];
}

export interface ClientOpts extends GenericObj {
  clients: true | string[];
}

export interface StatusListenerOpts extends Partial<IdOpts & ClientOpts> {
  tree: StatusTree.Union;
}

export type ListenerCallback = (a: DataSnapshot | null, b?: string | null) => unknown;

const database = getDatabase();

/*
const logRequests = (
  process.env.REACT_APP_LOCAL_DEBUG && process.env.REACT_APP_LOG_REQUESTS
);
*/

// helper function to handle the firebase tree shaking update, without
// refactoring too much.
function on(ref: Query | Reference, event: EventType, callback: (snap: DataSnapshot) => unknown): () => void {
  switch (event) {
    case "value":
      return onValue(ref, callback);
    case "child_added":
      return onChildAdded(ref, callback);
    case "child_moved":
      return onChildMoved(ref, callback);
    case "child_changed":
      return onChildChanged(ref, callback);
    case "child_removed":
      return onChildRemoved(ref, callback);
    default:
      throw new Error(`unknown event type: '${event}'`);
  }
}

// Generic utils
function listenToRef(ref: QueryChain, eventTypes: EventType[], callback: (snap: DataSnapshot) => void): () => void {
  const unsubs: (() => void)[] = [];

  let unsubCallback: ListenerCallback;
  for (const event of eventTypes) {
    // console.log(`setting up listener of type ${event} at path ${ref.ref.key}`);
    unsubCallback = on(ref, event, (snap) => callback(snap));
    unsubs.push(() => off(ref, event, unsubCallback));
  }

  return () => unsubs.forEach(unsub => unsub());
}


// Stations
function getStationsByClient(client: string): Promise<Record<string, GeoJson.PointFeature>> {
  const stationRef = child(ref(database, "/stations"), client);

  return get(stationRef).then(snapshot => {
    const data = snapshot.val();

    const out: Record<string, GeoJson.PointFeature> = {};

    let feature: unknown;
    for (const key in data) {
      feature = data[key];

      if (isGeoJsonPoint(feature)) {
        feature.properties.age = (Date.now() / 1000) - feature.properties.epoch;
        out[key] = feature;
      }
    }

    return out;
  });
}

function getStationsByClients(clients: true | string[]): Promise<Record<string, GeoJson.PointFeature>> {
  if (Array.isArray(clients)) {
    return Promise.all(clients.map(client => getStationsByClient(client)))
      .then(records => {
        const out: Record<string, GeoJson.PointFeature> = {};

        for (const rec of records) {
          for (const key in rec) {
            out[key] = rec[key];
          }
        }

        return out;
      });
  }

  const stationRef = ref(database, "/stations");

  return get(stationRef).then(snapshot => {
    const data = snapshot.val();

    const out: Record<string, GeoJson.PointFeature> = {};

    let feature: unknown;
    for (const client in data) {
      for (const key in data[client]) {
        feature = data[client][key];

        if (isGeoJsonPoint(feature)) {
          feature.properties.age = (Date.now() / 1000) - feature.properties.epoch;
          out[key] = feature;
        }
      }
    }

    return out;
  });
}


function listenToStationsByClient(client: string, callback: (id: string, station: GeoJson.PointFeature) => void): () => void {
  const eventTypes: EventType[] = [
    "child_changed",
    "child_added",
  ];

  const stationRef = child(ref(database, "/stations"), client);

  return listenToRef(stationRef, eventTypes, (snapshot) => {
    if (snapshot.exists() && snapshot.key) {
      const data = snapshot.val();

      if (isGeoJsonPoint(data)) {
        data.properties.age = (Date.now() / 1000) - data.properties.epoch;
        callback(snapshot.key, data);
      }
    }
  });
}

function listenToStationsByClients(clients: true | string[], callback: (id: string, station: GeoJson.PointFeature) => void): () => void {
  if (clients === true) {
    return listenToAllStations(callback);
  }

  const unsubs: (() => void)[] = [];

  for (const client of clients) {
    // console.log(`setting up station listener for client ${client}`);
    unsubs.push(listenToStationsByClient(client, callback));
  }

  return () => unsubs.forEach(unsub => unsub());
}

function listenToAllStations(callback: (id: string, station: GeoJson.PointFeature) => void): () => void {
  const eventTypes: EventType[] = [
    "child_added",
    "child_changed",
  ];

  const stationRef = ref(database, "/stations");

  return listenToRef(stationRef, eventTypes, (snap) => {
    if (snap.exists() && snap.key) {
      const stations = snap.val();
      // console.log(stations);

      if (isGeoJsonPoint(stations)) {
        stations.properties.age = (Date.now() / 1000) - stations.properties.epoch;
        callback(snap.key, stations);
      } else {
        let station: unknown;
        for (const id in stations) {
          station = stations[id];
          if (isGeoJsonPoint(station)) {
            station.properties.age = (Date.now() / 1000) - station.properties.epoch;

            callback(station.properties.id, station);
          }
        }
      }
    }
  });
}


// Tracklines
function getTracklineById(id: string, startEpoch?: number, endEpoch?: number): Promise<null | Record<string, Geo.TracklinePoint>> {
  let trackRef: QueryChain = query(child(ref(database, "/tracklines"), id), orderByChild("epoch"));

  if (startEpoch) {
    trackRef = query(trackRef, startAt(startEpoch));
  }

  if (endEpoch) {
    trackRef = query(trackRef, endAt(endEpoch));
  }

  return get(trackRef).then(snapshot => {
    if (snapshot.exists() && snapshot.key) {
      return snapshot.val() as Record<string, Geo.TracklinePoint>;
    }

    return null;
  });
}

function getTracklineByIds(ids: string[], startEpoch?: number, endEpoch?: number): Promise<Record<string, Record<string, Geo.TracklinePoint>>> {
  const promises = ids.map(id => getTracklineById(id, startEpoch, endEpoch).then(res => {
    return { [id]: res };
  }));

  return Promise.all(promises).then(results => {
    const out: Record<string, Record<string, Geo.TracklinePoint>> = {};

    let track: Record<string, Geo.TracklinePoint> | null;
    for (const tracklines of results) {
      for (const trackId in tracklines) {
        track = tracklines[trackId];
        if (track) {
          out[trackId] = track;
        }
      }
    }

    return out;
  });
}

function listenToTracklineById(props: TracklineListenerOpts, callback: (id: string, x: Geo.TracklinePoint) => void): () => void {
  const { id, startEpoch, endEpoch } = props;

  let trackRef: QueryChain = query(child(ref(database, "/tracklines"), id), orderByChild("epoch"));

  if (startEpoch) {
    trackRef = query(trackRef, startAt(startEpoch));
  }

  if (endEpoch) {
    trackRef = query(trackRef, endAt(endEpoch));
  }

  const unsubCallback = onChildAdded(trackRef, (snap) => {
    if (snap.exists() && snap.key) {
      const data = snap.val() as Geo.TracklinePoint;
      callback(id, data);
    }
  });

  return () => off(trackRef, "child_added", unsubCallback);
}


// Status
function listenToStatusUpdates<S extends Status.Union>(callback: (id: string, status: S) => void, props: StatusListenerOpts): () => void {
  const { tree, ids, clients } = props;

  const path = "/status" + (tree.startsWith("/") ? tree : "/" + tree);
  const statusRef = ref(database, path);

  const eventTypes: EventType[] = ["child_added", "child_changed"];

  let childrenPaths: true | string[] = [];

  switch (tree) {
    case StatusTree.Stations:
    case StatusTree.ThirdParty:
      childrenPaths = clients ?? [];
      break;
    case StatusTree.Tracklines:
      if (typeof ids === "string") {
        childrenPaths = [ids];
      } else if (ids) {
        childrenPaths = ids;
      }
      break;
    default:
      throw new Error(`Unknown status tree ${tree}`);
  }

  if (Array.isArray(childrenPaths) && childrenPaths.length === 0) {
    return () => {/* ... */ };
  }

  const unsubContainer: (() => void)[] = [];

  const innerCallback = function(snapshot: DataSnapshot): void {
    let data = snapshot.val();

    if (!snapshot.exists() || !data || !snapshot.key) return;

    if (isStatus<S, typeof tree>(data, tree)) {
      callback(snapshot.key, data);
    } else if (tree === StatusTree.ThirdParty || tree === StatusTree.Stations) {
      data = Object.values(data);

      for (const status of data) {
        if (isStatus<Status.ThirdParty, typeof tree>(status, tree)) {
          if (!status.source) {
            status.source = snapshot.key;
          }

          callback(status.id, status as S);
        }
      }

    } else {
      console.log(data);
      console.log("something went wrong");
    }
  };

  if (typeof childrenPaths === "boolean") {
    unsubContainer.push(
      listenToRef(statusRef, eventTypes, innerCallback)
    );
  } else {
    for (const childPath of childrenPaths) {
      unsubContainer.push(
        listenToRef(child(statusRef, childPath), eventTypes, innerCallback)
      );
    }
  }

  return function() {
    // throw new Error("should not be called");
    unsubContainer.forEach(unsub => unsub());
  };
}



function getCurrentStationStatusTree(clients: true | string[]): Promise<Record<string, Status.Station>> {
  const rootRef = ref(database, "/status/stations/");

  if (typeof clients === "boolean") {
    return get(rootRef).then(snap => {
      const tree = snap.val();

      const statuses = {};

      for (const vesselTree of Object.values(tree)) {
        if (!isNonEmptyRecord(vesselTree)) {
          continue;
        }

        for (const vesselStatus of Object.values(vesselTree)) {
          if (isStatus<Status.Station, StatusTree.Stations>(vesselStatus)) {
            statuses[vesselStatus.id] = vesselStatus;
          }
        }
      }

      return statuses;
    });
  } else {
    return Promise.all(clients.map(path => get(child(rootRef, path))))
      .then(snaps => {
        const statuses = {};

        for (const snap of snaps) {
          const vesselTree = snap.val();

          if (!isNonEmptyRecord(vesselTree)) {
            continue;
          }

          for (const vesselStatus of Object.values(vesselTree)) {
            if (isStatus<Status.Station, StatusTree.Stations>(vesselStatus)) {
              statuses[vesselStatus.id] = vesselStatus;
            }
          }
        }

        return statuses;
      });
  }
}



function setStationMTLink(clientId: string, stationId: string, mtLink: string): Promise<void> {
  const stationRef = child(child(ref(database, "/stations"), clientId), stationId);

  return runTransaction(stationRef, (station) => {
    if (!station) return;

    if (isGeoJsonPoint(station)) {
      station.properties.marineTrafficLink = mtLink;

      return station;
    }
  }).then(transactionResult => console.log({ transactionResult }));
}

const RTDatabase = {
  listenToAllStations: listenToAllStations,
  listenToStationsByClient: listenToStationsByClient,
  listenToStationsByClients: listenToStationsByClients,
  getTracklineById: getTracklineById,
  getTracklineByIds: getTracklineByIds,
  listenToTracklineById: listenToTracklineById,
  listenToStatusUpdates: listenToStatusUpdates,
  getStationsByClients: getStationsByClients,
  getStationsByClient: getStationsByClient,
  setStationMTLink: setStationMTLink,
  getCurrentStationStatusTree,
};

export default RTDatabase;
