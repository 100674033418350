import React, { useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";

import LoadingButton from "../misc/LoadingButton";

import PasswordInput from "./PasswordInput";
import { isEmail } from "../misc/MultiEmailInput";
import { useStateDispatch } from "../../provider/StateProvider";

import FirebaseAuth from "../../firebase/FirebaseAuth";

export interface SignInProps {
  close: () => void;
  show: boolean;
  email?: string;
}

const SignIn = (props: React.PropsWithoutRef<SignInProps>): JSX.Element => {

  const { email: initEmail, show, close } = props;

  const dispatch = useStateDispatch();

  const [email, setEmail] = useState<string>(initEmail ?? "");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [afterFirstStateChange, setAfterFirstStateChange] = useState(false);
  const [resetRequested, setResetRequested] = useState(false);
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState("");
  const [info, setInfo] = useState<string | null>(null);

  const trySignIn = (): Promise<void> => {
    if (email.length === 0) {
      return Promise.reject("Must enter an email address");
    } else if(!isEmail(email)) {
      return Promise.reject("Email is not valid");
    } else if (password.length === 0) {
      return Promise.reject("Must enter a password");
    } else {
      return FirebaseAuth.signInWithEmailPass(dispatch, email, password)
        .then(user => {
          if (user) close();
          setIsLoading(false);
        });
    }
  };

  const trySendEmailReset = (): Promise<void> => {
    return FirebaseAuth.sendPasswordResetEmail(email).then(() => {
      setInfo(`Reset email sent to ${email}`);

      setTimeout(() => {
        setResetRequested(false);
        setIsLoading(false);
      }, 3000);
    });
  };

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();

    if (isLoading) return;

    setIsLoading(true);
    const actionPromise = resetRequested
      ? trySendEmailReset()
      : trySignIn();

    actionPromise.catch(error => {
      setAuthError(error.message ?? error);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (isEmail(email) && (password.length > 4 || resetRequested)) {
      setSubmitDisabled(false);
    } else if (afterFirstStateChange) {
      // We want to wait until after the first state change,
      // since autofill wont trigger one the user interacts with
      // the site.
      setSubmitDisabled(true);
    }

    if (!afterFirstStateChange) {
      setAfterFirstStateChange(true);
    }
  }, [email, password, resetRequested]);

  return (
    <Modal show={show} onHide={() => close()}
      onExited={() => setResetRequested(false)}>
      <Modal.Header closeButton>
        <h1>
          {
            resetRequested ?
              "Reset Password" :
              "Sign In"
          }
        </h1>
      </Modal.Header>
      <Modal.Body>
        <Row className="m-2">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>
                {resetRequested ? "Send a password reset email to:" : "Email"}
              </Form.Label>
              <Form.Control size="sm" type="text" autoComplete="username"
                onChange={(e) => setEmail(e.target.value)}
                placeholder='email' value={email} />
            </Form.Group>
            {
              !resetRequested
                ? <PasswordInput onChange={setPassword} value={password}/>
                : null
            }
            <Form.Group>
              <Row className='mt-2 d-md-flex justify-content-md-start flex-md-nowrap align-items-strech'>
                <LoadingButton disabled={submitDisabled}
                  className='w-auto mr-1'
                  isLoading={isLoading}
                  type="submit">
                Submit
                </LoadingButton>
                <Button variant="secondary" className='w-auto ml-1'
                  onClick={() => setResetRequested(c => !c)}>
                  <span style={{ whiteSpace: "nowrap" }}>
                    {
                      resetRequested ? "Cancel" : "Forgot Password?"
                    }
                  </span>
                </Button>
              </Row>
            </Form.Group>
            {
              info !== null
                ? <Alert variant="primary">
                  { info }
                </Alert>
                : null
            }
            {
              authError.length > 0
                ? <Alert variant="danger">
                  { authError }
                </Alert>
                : null
            }
          </Form>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SignIn;
