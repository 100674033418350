import { Brand } from "ts-brand";



export type Seconds = Brand<number, "seconds">;

const MINUTE: Seconds = 60 as Seconds;
const HOUR: Seconds = 60 * MINUTE as Seconds;
const DAY: Seconds = 24 * HOUR as Seconds;
const WEEK: Seconds = 7 * DAY as Seconds;
const MONTH: Seconds = 30 * DAY as Seconds;
const YEAR: Seconds = 365 * DAY as Seconds;
    


export interface TimeOffset {
    label: string;
    delta: Seconds;
}


export interface IndexedTimeOffset extends TimeOffset {
    index: number;
}

export interface ResolvedTimeOffset extends TimeOffset {
    resolved: Date;
}
 
 
export interface TimeDelta<T extends TimeOffset = TimeOffset> {
  /** If 'new' is null, there is no bound (i.e we want all data up to the present) */
  new: null | T;
  old: T; 
}



export namespace TimeOffset {
    export function fromDays(days: number): TimeOffset {
      if (days === 1) {
        return { delta: days * DAY as Seconds, label: "1 day" };
      }  

      return { delta: days * DAY as Seconds, label: `${days} days` };
    }

    export function fromHours(hours: number): TimeOffset {
      if (hours === 1) {
        return { delta: hours * HOUR as Seconds, label: "1 hour" };
      }  

      return { delta: hours * HOUR as Seconds, label: `${hours} hours` };
    }

    export function fromMinutes(minutes: number): TimeOffset {
      if (minutes === 1) {
        return { delta: minutes * MINUTE as Seconds, label: "1 minute" };
      }  

      return { delta: minutes * MINUTE as Seconds, label: `${minutes} minutes` };
    }
}



export function now(): Seconds {
  return (Date.now() / 1000) as Seconds;
}


export function timestampToDate(s: Seconds): Date {
  // Date.now expects a timestamp in milliseconds, not seconds.
  return new Date(s * 1000);
}


export function resolveTimeOffset<T extends TimeOffset>(offset: T, relative: Seconds = now()): T & ResolvedTimeOffset {
  const resolved = timestampToDate(relative - offset.delta as Seconds);
  return { ...offset, resolved };
} 


export function makeIndexedTimeDeltas<T extends TimeOffset>(tds: T[]): IndexedTimeOffset[] {
  const ret = new Array(tds.length);
  
  let index = 0;
  for (const td of tds) {
    ret[index] = { ...td, index };
    index += 1;
  }
  
  return ret;
}