import type { Map } from "mapbox-gl";


export interface SmaDate {
  day: number;
  month: number;
}

const nowSmaDate = () => dateToSmaDate(new Date(Date.now()));

export function buildSmaVisibilityCallback(map: Map): () => void {
  let lastUpdateTime = nowSmaDate();

  updateSmaVisibility(map, lastUpdateTime);

  return function() {
    const current = nowSmaDate();

    if (!sameSmaDate(lastUpdateTime, current)) {
      updateSmaVisibility(map, current);
      lastUpdateTime = current;
    }
  };
}

function sameSmaDate(a: SmaDate, b: SmaDate): boolean {
  return a.day == b.day && a.month == b.month;
}

function dateToSmaDate(date: Date): SmaDate {
  return {
    day: date.getDate(),
      
    // getMonth returns a 0-based month (i.e january = 0),
    // but the 'months' in the SMA layers start from 1.
    month: date.getMonth() + 1,
  };
}


export function updateSmaVisibility(map: Map, smaDate: SmaDate) {
  const { day, month } = smaDate;

  map.setFilter("NOAA-SMA", [
    "all",
    // ensure fields exist
    ["has", "st_mo"],
    ["has", "st_day"],
    ["has", "end_mo"],
    ["has", "end_day"],
    [
      "case",
      // if the start month is equal, we're true if the day is <=
      ["==", ["get", "st_mo"], month], ["<=", ["get", "st_day"], day],
      // if the end month is equal, we're true if the day is <=
      ["==", ["get", "end_mo"], month], [">=", ["get", "end_day"], day],

      // if we're after the start month or before the end month 
      [
        "case",
        // if the start month is before the end month (eg. runs from april -> june), check if the current month is between the two
        ["<", ["get", "st_mo"], ["get", "end_mo"]], 
        ["all",
          ["<", ["get", "st_mo"], month],
          [">", ["get", "end_mo"], month],
        ],
        // if the end month is before the start month (eg. runs from nov -> april), check if the current month is 
        // greater than the start month or less than the end month
        [">", ["get", "st_mo"], ["get", "end_mo"]], 
        ["any",
          ["<", ["get", "st_mo"], month],
          [">", ["get", "end_mo"], month],
        ],
        // fallback/default case
        false
      ],
      true,
      // fallback/default case
      false
    ]
  ]);
}
