import { useCallback, useMemo, useState } from "react";
import { Brand } from "ts-brand";


export type OnLoaded = Brand<() => void, "onLoaded">;


export interface LoadTracker {
    isLoading: boolean;
    startJob(): OnLoaded;
    wrapPromise<T>(p: Promise<T>): Promise<T>;
} 



export function useLoadTracker(): LoadTracker {
  const [lastFinished, setLastFinished] = useState<number>(0);
  const [mostRecent, setMostRecent] = useState<number>(0);

  const [firstTs, setFirstTs] = useState<number>(0);

  const isLoading: boolean = useMemo(() => {
    // HACK: when opening the MapMenu dropdown, startJob gets called for some
    // reason (and never completes). To get around this erroneous "job", we ignore the first call
    // by ensuring that mostRecent is greater (which can only happen if the function has been called twice or more). 
    return lastFinished < mostRecent && firstTs < mostRecent;
  }, [firstTs, lastFinished, mostRecent]);


  const startJob = useCallback(function(): OnLoaded {
    const ts = Date.now();

    setFirstTs(current => {
      if (current === 0) {
        return ts;
      } else {
        return current;
      }
    });

    const update = function(current: number): number {
      return Math.max(current, ts);
    };

    setMostRecent(update);

    return function() {
      setLastFinished(update);
    } as OnLoaded;
  }, []);

  return {
    isLoading,
    startJob,
    wrapPromise: function<T>(p: Promise<T>): Promise<T> {
      const onCompleted = startJob(); 
      return p.finally(onCompleted);
    }
  };
}