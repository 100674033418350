import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

export type AdminNavProps = React.PropsWithoutRef<{
  compact?: boolean;
  drop?: "up"|"down";
  as?: React.ElementType,
  title?: string;
  toggleClassName?: string;
  menuClassName?: string;
}>;

const AdminNav = (props: AdminNavProps): JSX.Element => {
  const itemClass = props.compact === true ? "no-vert-padding" : "";

  const drop = props.drop ?? "down";

  const toggleClasses: string[] = [
    "dropdown-item",
    "dropdown-toggle",
    "d-flex",
    "flex-nowrap",
    "justify-content-start",
    "w-auto",
    "align-items-center",
  ];

  if (drop === "down" || drop === "up") {
    toggleClasses.push("flex-row-reverse");
  }

  if (typeof props.toggleClassName === "string") {
    toggleClasses.push(props.toggleClassName);
  }

  return (
    <Dropdown drop={drop}>
      <Dropdown.Toggle as={props.as ?? "a"} className={toggleClasses.join(" ")}>
        <span className="ml-1">{ props.title ?? "Admin" }</span>
      </Dropdown.Toggle>
      <Dropdown.Menu className={props.menuClassName}>
        {/*
        <Dropdown.Item href='/admin/maps' className={itemClass}>
          Map Management
        </Dropdown.Item>
        */}
        <Dropdown.Item href='/admin/eula' className={itemClass}>
          EULA Management
        </Dropdown.Item>
        <Dropdown.Item href='/admin/users' className={itemClass}>
          User Management
        </Dropdown.Item>
        {/*
        <Dropdown.Item href='/admin/leaseAreas' className={itemClass}>
          Lease Area Management
        </Dropdown.Item>
        <Dropdown.Item href='/admin/marinetraffic/config' className={itemClass}>
          Marine Traffic Config
        </Dropdown.Item>
        */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AdminNav;
