import ReactDOM from "react-dom";

abstract class MapControlWrapper {
  protected _container: HTMLDivElement;
  protected _map?: mapboxgl.Map;

  constructor(child: JSX.Element, className?: string | string[]) {
    this._container = document.createElement("div");

    const classes = ["mapboxgl-ctrl"];
    if (typeof className === "string") {
      classes.push(className);
    } else if (Array.isArray(className)) {
      classes.push(...className);
    }

    this._container.className = classes.join(" ");

    ReactDOM.render(child, this._container);
  }

  public onAdd = (map: mapboxgl.Map): HTMLDivElement => {
    this._map = map;
    return this._container;
  };

  public onRemove = (): void => {
    this._container.parentNode?.removeChild(this._container);
    this._map = undefined;
  };
}

export default MapControlWrapper;
