import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  uploadString,
  listAll,
  StorageReference,
} from "firebase/storage";

import { initFirebase } from "../setup/Setup";
initFirebase();

const storage = getStorage();


export interface GcsFile {
  name: string;
  downloadUrl: string;
}


export function getFileURL(path: string): Promise<null | string> {
  return getDownloadURL(ref(storage, path)).then(url => {
    if (typeof url === "string") {
      return url;
    }

    return null;
  });
}

export function uploadFile(path: string, file: File | string | Blob): Promise<string> {
  const storageRef = ref(storage, path);

  if (typeof file === "string") {
    return uploadString(storageRef, file).then(result => result.ref.fullPath);
  } else {
    return uploadBytes(storageRef, file).then(result => result.ref.fullPath);
  }
}

export function listFiles(prefix?: string): Promise<GcsFile[]> {
  function buildFileInfo(refer: StorageReference): Promise<GcsFile> {
    return getDownloadURL(refer).then(downloadUrl => {
      return { downloadUrl, name: refer.name };
    });
  }

  return listAll(ref(storage, prefix))
    .then(listResult => Promise.all(listResult.items.map(buildFileInfo)));
}

const Storage = { getFileURL, uploadFile, listFiles };

export default Storage;
