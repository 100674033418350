
import React from "react";
import { MapSources, Mapbox } from "../../misc/Types";
import MapPopup, { MapPopupProps, PopupHeader, PopupRow, SightingImageCarousel } from "./MapPopup";
import { doesFeatureHaveImages, useFeaturePopupOptions } from "../../hooks/useFeaturePopupOptions";
import { Accordion, ListGroup, useAccordionButton } from "react-bootstrap";


export type MultiMapPopupProps = {
    sources: MapSources;
    features: Mapbox.GeoJsonFeature[];
    admin: boolean;
    closeHandler?: () => void;
}


const TogglePopupHeader = React.memo((props: React.PropsWithChildren<{ eventKey: string, index: number }>): JSX.Element => {
  const { eventKey, children, index, ...rest } = props; 

  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <ListGroup.Item 
      variant="secondary" 
      {...rest}
      onClick={decoratedOnClick}
    >
      {children}
    </ListGroup.Item>
  );
});

TogglePopupHeader.displayName = "TogglePopupHeader";



function MultiMapPopupItem(props: MapPopupProps & { index: number }): JSX.Element {
  const { sources, feature, closeHandler, admin, index } = props;

  const {
    isLoading,
    statusImgSrc,
    shouldRenderImages,
    sightingImages,
    name,
    displayEntries,
  } = useFeaturePopupOptions(sources, feature, admin);

  return (
    <Accordion.Item eventKey={index.toString()} className='reduced-padding'>
      <ListGroup variant="flush">
        <TogglePopupHeader eventKey={index.toString()} index={index}>
          <PopupHeader
            closeHandler={closeHandler}
            name={name}
            statusImgSrc={statusImgSrc}
          />
        </TogglePopupHeader>
        <Accordion.Body className="p-0">
          {
            displayEntries.map((entry, idx) => (
              <PopupRow key={idx} entry={entry} />
            ))
          }
          {
            shouldRenderImages
              ? <SightingImageCarousel
                feature={feature}
                isLoading={isLoading}
                images={sightingImages}
              />
              : null
          }
        </Accordion.Body>
      </ListGroup>
    </Accordion.Item>
  );
}

MultiMapPopupItem.displayName = "MultiMapPopupItem";


const MultiMapPopup = React.memo((props: MultiMapPopupProps): null | JSX.Element => {
  const { sources, features, admin, closeHandler } = props;
    
  // bail early in the case of no features or a single feature
  if (features.length === 0) {
    return null;
  } else if (features.length === 1) {
    return ( 
      <MapPopup 
        feature={features[0]} 
        sources={sources} 
        admin={admin} 
        closeHandler={closeHandler}
      />
    );
  }

  return (
    <Accordion className="mapbox-popup-container" flush={true}> 
      {
        features.map((feature, index) => (
          <MultiMapPopupItem
            key={index}
            index={index}
            sources={sources}
            feature={feature}
            admin={admin}
            closeHandler={index === 0 ? closeHandler : undefined}
          />
        ))
      }
    </Accordion>
  );
});

MultiMapPopup.displayName = "MultiMapPopup";


export default MultiMapPopup;
