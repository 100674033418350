import React, { useEffect, useState } from "react";

import FirebaseAuth, { User } from "../firebase/FirebaseAuth";
import { MapsAPI, UsersAPI } from "../firebase/API";
import { getEulaDoc } from "../firebase/Firestore";

import { stringSortFactory, isNonEmptyRecord } from "../misc/Utils";

import { cloneDeep, isPlainObject } from "lodash";

import {
  EulaDoc,
  isEulaDoc,
  MapDoc,
  UserDoc,
  UserClaims,
  isUserDoc,
} from "../misc/Types";


export interface UserConfig {
  userDoc: UserDoc;
  userClaims?: UserClaims;
  eula: EulaDoc;
  admin: boolean;
  maps: Record<string, MapDoc>;
}

export function isUserConfig(obj: unknown): obj is UserConfig {
  if (!isNonEmptyRecord(obj)) {
    return false;
  }

  return (
    isUserDoc(obj.userDoc) &&
    isEulaDoc(obj.eula) &&
    typeof obj.admin === "boolean" &&
    isPlainObject(obj.maps)
  );
}


export interface Authenticated {
  user: User;
  config: UserConfig;
}

export interface Unauthenticated {
  user: null;
  config: null | UserConfig;
}

export type State = { isLoading: boolean } & (Authenticated | Unauthenticated);


export enum Action {
  SignIn,
  SignOut,
  RefreshAuth,
  OnLoadingStart,
  OnLoadingEnd,
  LoadLocalState,
  RefreshUserDoc,
}

export interface SignInParams {
  action: Action.SignIn;
  config: UserConfig;
  user: User;
  endLoading?: boolean;
}

export interface SignOutParams {
  action: Action.SignOut;
  endLoading?: boolean;
}

export interface RefreshAuthParams {
  action: Action.RefreshAuth;
  user: User;
  endLoading?: boolean;
}

export interface SetLoadingParams {
  action: Action.OnLoadingEnd | Action.OnLoadingStart;
}

export interface LoadLocalStateParams {
  action: Action.LoadLocalState;
  endLoading?: boolean;
}

export interface RefreshUserDocParams {
  action: Action.RefreshUserDoc;
  userDoc: UserDoc;
  endLoading?: boolean;
}

export type DispatchParams
  = SignInParams
  | SignOutParams
  | RefreshAuthParams
  | SetLoadingParams
  | LoadLocalStateParams
  | RefreshUserDocParams;


export type StateDispatch = React.Dispatch<DispatchParams>;

const DEFAULT_STATE: State = { user: null, config: null, isLoading: true };


export const StateContext = React.createContext<State>(DEFAULT_STATE);
export const DispatchContext = React.createContext<StateDispatch>(() => null);


export const useStateContext = (): State => React.useContext(StateContext);
export const useStateDispatch = (): StateDispatch => React.useContext(DispatchContext);


function setLocalState(config: null | UserConfig): null | UserConfig {
  if (config !== null) {
    localStorage.setItem("userConfig", JSON.stringify(config));
  }

  return config;
}

function getLocalState(): null | UserConfig {
  const stateString = localStorage.getItem("userConfig");

  if (!stateString) {
    return null;
  }

  try {
    const config = JSON.parse(stateString);

    return isUserConfig(config)
      ? config
      : null;
  } catch (err) {
    console.error("Error trying to parse locally stored state:", err);
    return null;
  }
}


function StateReducer(initialState: State, dispatch: DispatchParams): State {
  // Mutating initialState causes react to skip rerendering components, so
  // we need to clone the entire thing so react thinks its an entirely new
  // value, therefore triggering rerenders as expected.
  let state = cloneDeep(initialState);

  switch (dispatch.action) {
    case Action.SignIn:
      state.user = dispatch.user;
      state.config = dispatch.config;
      if (dispatch.endLoading) {
        state.isLoading = false;
      }
      break;
    case Action.SignOut:
      state = DEFAULT_STATE;
      if (dispatch.endLoading) {
        state.isLoading = false;
      }
      break;
    case Action.RefreshAuth:
      state.config = getLocalState();
      if (state.config !== null) {
        state.user = dispatch.user;
      } else {
        console.error(
          "cannot refresh user auth, no user config found"
        );
      }
      if (dispatch.endLoading) {
        state.isLoading = false;
      }
      break;
    case Action.OnLoadingStart:
      state.isLoading = true;
      break;
    case Action.OnLoadingEnd:
      state.isLoading = false;
      break;
    case Action.LoadLocalState:
      state.config = getLocalState();
      if (dispatch.endLoading) {
        state.isLoading = false;
      }
      break;
    case Action.RefreshUserDoc:
      state.config = getLocalState();
      if (state.config !== null) {
        state.config.userDoc = dispatch.userDoc;
      }
      if (dispatch.endLoading) {
        state.isLoading = false;
      }
      break;
    default:
      throw new Error(
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        `Unknown StateReducer action type: '${(dispatch as any).action}'`
      );
  }

  setLocalState(state.config);

  return state;
}


function StateProvider(props: React.PropsWithChildren<unknown>): JSX.Element {
  const { children } = props;

  const [state, dispatch] = React.useReducer(StateReducer, DEFAULT_STATE);

  const setAuthLoading = (isLoading: boolean) => {
    if (isLoading == state.isLoading) {
      return;
    }

    if (isLoading) {
      dispatch({ action: Action.OnLoadingStart });
    } else {
      dispatch({ action: Action.OnLoadingEnd });
    }
  };

  useEffect(() => {
    setAuthLoading(true);
    FirebaseAuth.refreshAuth(dispatch)
      .catch(err => console.error(err))
      .finally(() => setAuthLoading(false));
  }, []);


  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default StateProvider;


export interface StateProps {
  state: State;
  dispatch: StateDispatch;
}


export function withState<P>(
  Child: React.FunctionComponent<P & StateProps>
): React.FunctionComponent<P> {
  const composed: React.FunctionComponent<P> = (props: P) => (
    <StateContext.Consumer>
      {
        (state) =>
          <DispatchContext.Consumer>
            {
              (dispatch) =>
                <Child state={state} dispatch={dispatch} {...props}/>
            }
          </DispatchContext.Consumer>
      }
    </StateContext.Consumer>
  );

  composed.displayName = Child.displayName;

  return composed;
}
