import { FeatureTypeToGeoType, Geo, GeoType } from "./Geo";
import { DataType, FEATURE_TYPE_PARSER, FeatureId, FeatureType } from "../misc/Types";

import type { DocumentData } from "firebase/firestore";

import { z } from "zod";
import { isNonEmptyRecord, nullish } from "./Utils";


type Coordinate = Geo.Coordinate;





function isValidPointCoords(point: Geo.Coordinate): boolean {
  if ((point.length < 2 || point.length > 3) ||
      (isNaN(point[0]) || isNaN(point[1])) ||
      (point[0] < -180 || point[0] > 180) ||
      (point[1] < -90 || point[1] > 90)) {
    return false;
  }

  return true;
}

function isValidLineStringCoords(lineString: Geo.Coordinate[]): boolean {
  for (const inner of lineString) {
    if (!Array.isArray(inner) || !isValidPointCoords(inner)) {
      return false;
    }
  }

  return true;
}

function isValidPolygonCoords(lineString: Geo.Coordinate[][]): boolean {
  for (const inner of lineString) {
    if (!Array.isArray(inner) || !isValidLineStringCoords(inner)) {
      return false;
    }
  }

  return true;
}

export function isGeoJsonPoint(data: unknown): data is GeoJson.PointFeature {
  const asGeoJson = data as GeoJson.PointFeature;

  if (asGeoJson?.geometry?.type !== "Point" ||
      !Array.isArray(asGeoJson?.geometry?.coordinates)) {
    return false;
  }

  return isValidPointCoords(asGeoJson.geometry.coordinates);
}

export function isGeoJsonLineString(data: unknown): data is GeoJson.LineStringFeature {
  const asGeoJson = data as GeoJson.LineStringFeature;

  if (asGeoJson?.geometry?.type !== "LineString" ||
      !Array.isArray(asGeoJson?.geometry?.coordinates)) {
    return false;
  }

  return isValidLineStringCoords(asGeoJson.geometry.coordinates);
}

export function isGeoJsonPolygon(data: unknown): data is GeoJson.PolygonFeature {
  const asGeoJson = data as GeoJson.PolygonFeature;

  if (asGeoJson?.geometry?.type !== "Polygon" ||
      !Array.isArray(asGeoJson?.geometry?.coordinates)) {
    return false;
  }

  return isValidPolygonCoords(asGeoJson.geometry.coordinates);
}

export function isGeoJson(data: unknown, geoType?: GeoType): data is GeoJson.Feature<typeof geoType extends GeoType ? typeof geoType : GeoType> {
  switch (geoType) {
    case GeoType.Point:
      return isGeoJsonPoint(data);
    case GeoType.LineString:
      return isGeoJsonLineString(data);
    case GeoType.Polygon:
      return isGeoJsonPolygon(data);
    default:
      return isNonEmptyRecord(data) && "type" in data && data.type === "Feature";
  }
}


export function isFirestoreGoJsonLineString(data: unknown): data is FirestoreGeoJson.LineStringFeature {
  return (data as FirestoreGeoJson.LineStringFeature)?.geometry?.type === "LineString";
}

export function isFirestoreGeoJsonPolygon(data: unknown): data is FirestoreGeoJson.PolygonFeature {
  return (data as FirestoreGeoJson.PolygonFeature)?.geometry?.type === "Polygon";
}

export function isFirestoreGeoJson(data: unknown): data is FirestoreGeoJson.Feature {
  return isFirestoreGeoJsonPolygon(data) || isFirestoreGoJsonLineString(data);
}

// Re-exporting the type guards in an object to help reduce long import
// statements in the case we need multiple
export const typeGuards = {
  isGeoJson: isGeoJson,
  isGeoJsonPoint: isGeoJsonPoint,
  isGeoJsonLineString: isGeoJsonLineString,
  isGeoJsonPolygon: isGeoJsonPolygon,
  isFirestoreGeoJson: isFirestoreGeoJson,
  isFirestoreGoJsonLineString: isFirestoreGoJsonLineString,
  isFirestoreGeoJsonPolygon: isFirestoreGeoJsonPolygon,
};

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace GeoJson {
  // Define the extended geometry types
  export type LineStringCoords = Coordinate[];
  export type PolygonCoords = Coordinate[][];

  // Create a container to check for all 3
  export type CoordinateTypes = Coordinate | LineStringCoords | PolygonCoords;

  // Conditional type that returns the coordinate type.
  export type Coord<G extends GeoType> = (
    G extends GeoType.Polygon ? PolygonCoords
    : G extends GeoType.LineString ? LineStringCoords
    : G extends GeoType.Point ? Coordinate
    : never
  );

  export interface BaseGeometry {
    type: GeoType;
  }

  export interface Point extends BaseGeometry {
    type: GeoType.Point;
    coordinates: Coordinate;
  }

  export interface LineString extends BaseGeometry {
    type: GeoType.LineString;
    coordinates: LineStringCoords;
  }

  export interface Polygon extends BaseGeometry {
    type: GeoType.Polygon;
    coordinates: PolygonCoords;
  }

  export interface DisplayProperties {
    station?: string;
    reportTimeUTC?: string;
    description?: string;
    [key: string]: unknown;
  }

  export interface Properties {
    name: string;
    id: FeatureId;
    dataType: FeatureType;
    images?: string[];
    epoch: number;
    age?: number;
    mmsi?: number;
    client: string;
    displayProperties?: DisplayProperties;
    [key: string]: unknown;
  }

  export interface BaseFeature {
    type: "Feature";
    id?: string | number;
    properties: Properties;
  }

  export type GeometryTypes = Point|LineString|Polygon;

  export type Geometry<G extends GeoType> = (
    G extends GeoType.Point ? Point
    : G extends GeoType.LineString ? LineString
    : G extends GeoType.Polygon ? Polygon
    : never
  );



  type FeatureTypeToGeoType<F extends FeatureType> =
    F extends (
      FeatureType.Buoys | FeatureType.Ellipses 
      | FeatureType.FishingGear | FeatureType.Gliders 
      | FeatureType.Sightings | FeatureType.Stations
    )
      ? GeoType.Point
    : F extends FeatureType.SlowZones | FeatureType.LeaseAreas | FeatureType.Sma
      ? GeoType.Polygon
    : F extends FeatureType.BearingLines
      ? GeoType.LineString
    : never;


  export interface Feature<T extends FeatureType | GeoType = GeoType> extends BaseFeature {
    geometry: Geometry<T extends FeatureType ? FeatureTypeToGeoType<T> : T>;
  }

  export interface FeatureCollection {
    type: "FeatureCollection";
    features: Feature<FeatureType | GeoType>[];
  }

  export type PointFeature = Feature<GeoType.Point>;
  export type LineStringFeature = Feature<GeoType.LineString>;
  export type PolygonFeature = Feature<GeoType.Polygon>;

  export type VesselOrTrackFeature = PointFeature | LineStringFeature;

  export type AnyFeature = PointFeature | LineStringFeature | PolygonFeature;
}

// Since Firestore doesn't support multi dimensional arrays, LineString/Polygon
// coordinates are encoded in maps (~objects) instead. After reading
// data from firestore, this format should be converted to normal geojson ASAP.

/* eslint-disable-next-line @typescript-eslint/no-namespace */
export namespace FirestoreGeoJson {
  export type LineStringCoords = Record<string, Coordinate>;
  export type PolygonCoords = Record<string, LineStringCoords>;

  export type CoordinateTypes = Coordinate|LineStringCoords|PolygonCoords;

  // Conditional type that returns the coordinate type.
  export type Coord<G extends GeoType> = (
    G extends GeoType.Polygon 
      ? PolygonCoords : G extends GeoType.LineString 
      ? LineStringCoords : G extends GeoType.Point 
      ? Coordinate : never
  );


  export interface LineString extends GeoJson.BaseGeometry {
    type: GeoType.LineString;
    coordinates: LineStringCoords;
  }

  export interface Polygon extends GeoJson.BaseGeometry {
    type: GeoType.Polygon;
    coordinates: PolygonCoords;
  }

  export type Geometry<G extends GeoType = GeoType> = (
    G extends GeoType.Point ? GeoJson.Point
    : G extends GeoType.LineString ? LineString
    : G extends GeoType.Polygon ? Polygon
    : never
  );

  export interface Feature<GT extends GeoType = GeoType> extends GeoJson.BaseFeature, DocumentData {
    geometry: Geometry<GT>;
  }

  export type PointFeature = Feature<GeoType.Point>;
  export type LineStringFeature = Feature<GeoType.LineString>;
  export type PolygonFeature = Feature<GeoType.Polygon>;
}


export const emptyFeatureCollection: GeoJson.FeatureCollection = {
  type: "FeatureCollection",
  features: []
};
