import React from "react";
import Spinner from "react-bootstrap/Spinner";

export interface LoadingSpinnerProps {
  text?: string;
  show?: boolean;
}

const LoadingSpinner = (
  props: React.PropsWithChildren<LoadingSpinnerProps>
): JSX.Element|null => {
  return typeof props.show === "boolean" && props.show
    ? (
      <div className="loading-spinner">
        <div className='w-auto mx-auto'>
          <Spinner animation="border" className='mx-auto' variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
        <div className='w-auto mx-auto'>
          {props.children ?? props.text ?? "Loading..."}
        </div>
      </div>
    )
    : null;
};

export default LoadingSpinner;
