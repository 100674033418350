import Firestore, { PaginatedGeoJsonQuery } from "./Firestore";
import RTDatabase, { IdOpts, ClientOpts, TracklineListenerOpts } from "./RTDatabase";

import { Geo, GeoType } from "../misc/Geo";
import { GeoJson } from "../misc/GeoJson";

import FirebaseAuth from "./FirebaseAuth";

import {
  FeatureType,
  MapDoc,
  PendingUserDoc,
  UserDoc,
  ClientDoc,
  MapPermissions,
  isUserDoc,
  DataType,
  ConfigDataType,
  StatusDataType,
} from "../misc/Types";

import { Status, StatusTree } from "../misc/Status";
import { OnLoaded } from "../hooks/useLoadTracker";

export interface GenericObj {
  [key: string]: unknown
}


export type Callback<D> = (x: D) => void;
export type IdCallback<D> = (id: string, x: D) => void;
export type RecordCallback<D> = (x: Record<string, D>) => void;

type DocumentType = MapDoc | UserDoc | ClientDoc | PendingUserDoc;

export interface Listener<D, O extends GenericObj | undefined = undefined> {
  (callback: Callback<D>, opts: O): () => void;
}

export interface IdListener<D, O extends GenericObj | undefined = undefined> {
  (callback: IdCallback<D>, opts: O): () => void;
}

export interface RecordListener<D, O extends GenericObj | undefined = undefined> {
  (callback: RecordCallback<D>, opts: O): () => void;
}

export interface IDocumentAPI<D extends DocumentType> {
  getAll(): Promise<Record<string, D>>;
  getById(id: string): Promise<null|D>;
  getByIds(ids: true|string[]): Promise<Record<string, D>>;
  set(doc: D): Promise<D>;
  update(doc: D): Promise<D>;
  delete(id: string): Promise<void>;
  listenToCollection: RecordListener<D>;
  listenToDocument: Listener<D, {id: string}>;
  listenToDocumentByIds: RecordListener<D, {ids: true|string[]}>;
}

export interface IPendingUserAPI {
  getById(id: string): Promise<null|PendingUserDoc>;
  tryRequestNewSignInLink(email: string): Promise<void>;
  deleteAllDocsWithEmail(email: string): Promise<void>;
}

export interface IUsersAPI extends IDocumentAPI<UserDoc> {
  doesUserExist(email: string): Promise<boolean>;
  doUsersExist(emails: string[]): Promise<Record<string, boolean>>;
  createPendingUsers(createdBy: string, emails: string|string[], permissions: MapPermissions): Promise<string[]>;
  updateUserPermissions(uid: string, permissions: MapPermissions): Promise<MapPermissions>;
  toggleUserDisable(uid: string): Promise<boolean>;
  deleteUser(uid: string): Promise<void>;
  acceptEula(uid: string, eulaVersion: string): Promise<null | UserDoc>;
}

export interface GeoJsonAPIOpts {
  maxAge?: number;
  minAge?: number;
  omitIds?: string[];
  [key: string]: unknown;
}

export interface IGeoJsonAPI<GT extends GeoType> {
  getAll(options?: GeoJsonAPIOpts): Promise<Record<string, GeoJson.Feature<GT>>>;
  getById(id: string, options?: GeoJsonAPIOpts): Promise<null|GeoJson.Feature<GT>>;
  getByIds(ids: true|string[], options?: GeoJsonAPIOpts): Promise<Record<string, GeoJson.Feature<GT>>>;
  listenToCollection: RecordListener<GeoJson.Feature<GT>>;
  paginatedQuery(query: PaginatedGeoJsonQuery, callback: IdCallback<GeoJson.Feature<GT>>, onLoaded?: OnLoaded): Promise<null|Error>;
  listenToNew: IdListener<GeoJson.Feature<GT>, { clients: true | string | string[] }>;

}

export interface IGenericGeoJsonAPI<GT extends GeoType> extends IGeoJsonAPI<GT> {
  listenToCollection: RecordListener<GeoJson.Feature<GT>>;
}

export interface IAddableGeoJsonAPI<GT extends GeoType> extends IGeoJsonAPI<GT> {
  addFeature(feature: GeoJson.Feature<GT>): Promise<void>;
  addFeatures(features: Record<string, GeoJson.Feature<GT>>): Promise<void>;
}

export interface IClientGeoJsonAPI<GT extends GeoType> extends IGeoJsonAPI<GT> {
  getByClient(client: string, options?: GeoJsonAPIOpts): Promise<Record<string, GeoJson.Feature<GT>>>;
  getByClients(clients: true|string[], options?: GeoJsonAPIOpts): Promise<Record<string, GeoJson.Feature<GT>>>;
}

export interface ISightingsAPI extends IClientGeoJsonAPI<GeoType.Point> {
  listenToNewestByStation: Listener<GeoJson.PointFeature, {client: string, stationId: string}>
}

export type IFishingGearAPI = IClientGeoJsonAPI<GeoType.Point>


export interface IRTDatabaseAPI<D, O extends IdOpts | ClientOpts | TracklineListenerOpts | undefined = undefined> {
  listen: IdListener<D, O>;
}

export interface IStationStatusAPI extends IRTDatabaseAPI<Status.Station, ClientOpts> {
  getAndListen: (callback: RecordCallback<Status.Station>, opts: ClientOpts) => Promise<() => void>;
}

export interface ITracklineAPI extends IRTDatabaseAPI<Geo.TracklinePoint, TracklineListenerOpts> {
  getById(id: string, startEpoch?: number, endEpoch?: number): Promise<null|Record<string, Geo.TracklinePoint>>;
  getByIds(ids: string[], startEpoch?: number, endEpoch?: number): Promise<Record<string, Record<string, Geo.TracklinePoint>>>;
}

export interface IStationAPI extends IRTDatabaseAPI<GeoJson.PointFeature, ClientOpts> {
  getByClient(client: string): Promise<Record<string, GeoJson.PointFeature>>;
  getByClients(clients: true|string[]): Promise<Record<string, GeoJson.PointFeature>>;
  setStationMarineTrafficLink(clientId: string, stationId: string, mtLink: string): Promise<void>;
}

const baseDocumentAPIFactory = <D extends DocumentType>(
  dataType: DataType,
  docKeySelector: (doc: D) => string,
): IDocumentAPI<D> => {
  return {
    getAll: () => Firestore.getAllDocuments<D>(dataType),
    getById: (id: string) => Firestore.getDocumentById<D>(dataType, id),
    getByIds: (ids: true|string[]) => Firestore.getDocumentsByIds<D>(dataType, ids),
    set: (doc: D) => Firestore.setDocument(dataType, docKeySelector(doc), doc),
    update: (doc: D) => Firestore.updateDocument(dataType, docKeySelector(doc), doc),
    delete: (idOrDoc: string | D) => Firestore.deleteDocument(dataType, typeof idOrDoc === "string" ? idOrDoc : docKeySelector(idOrDoc)),
    listenToCollection: (callback) => Firestore.listenToDocumentCollection<D>(dataType, callback),
    listenToDocument: (callback, opts) => Firestore.listenToDocument<D>(dataType, opts.id, callback),
    listenToDocumentByIds: (callback, opts) => Firestore.listenToDocumentByIds<D>(dataType, opts.ids, callback),
  };
};

const baseGeoJsonAPIFactory = <GT extends GeoType>(dataType: FeatureType): IGeoJsonAPI<GT> => {
  return {
    getAll: () => Firestore.getAllGeoJson<GT>(dataType),
    getById: (id) => Firestore.getGeoJsonById<GT>(dataType, id),
    getByIds: (ids) => Firestore.getGeoJsonByIds<GT>(dataType, ids),
    listenToCollection: (cb) => Firestore.listenToGeoJsonCollection<GT>(dataType, cb),
    paginatedQuery: (query, callback, onLoaded) => Firestore.paginateGeoJsonQuery(dataType, query, callback, onLoaded),
    listenToNew: (callback, opts) => Firestore.listenToNewFeatures(dataType, callback, opts.clients),
  };
};

function listenWithUniversalArgs<GT extends GeoType>(
  dataType: FeatureType.BearingLines | FeatureType.Buoys | FeatureType.Ellipses | FeatureType.Gliders | FeatureType.FishingGear,
  callback: (rec: Record<string, GeoJson.Feature<GT>>) => void,
  client_perms: true | string[],
): () => void {

  return Firestore.universalListener(dataType, callback, client_perms);
}

const genericGeoJsonAPIFactory = <GT extends GeoType>(dataType: FeatureType): IGenericGeoJsonAPI<GT> => {
  return {
    ...baseGeoJsonAPIFactory(dataType),
    listenToCollection: (callback) => Firestore.listenToGeoJsonCollection(dataType, callback),
  };
};

export const BearingLinesAPI = {
  listenWithUniversalArgs,
  ...genericGeoJsonAPIFactory<GeoType.LineString>(FeatureType.BearingLines)
};

export const BuoysAPI = {
  listenWithUniversalArgs,
  ...genericGeoJsonAPIFactory<GeoType.Point>(FeatureType.Buoys)
};

export const ClientsAPI = baseDocumentAPIFactory<ClientDoc>(
  ConfigDataType.Clients,
  (doc: ClientDoc) => doc.id,
);


export const EllipsesAPI = {
  listenWithUniversalArgs,
  ...genericGeoJsonAPIFactory<GeoType.Polygon>(FeatureType.Ellipses)
};



export const GlidersAPI = {
  listenWithUniversalArgs,
  ...genericGeoJsonAPIFactory<GeoType.Point>(FeatureType.Gliders),
};

export const LeaseAreasAPI: IAddableGeoJsonAPI<GeoType.Polygon> = {
  ...baseGeoJsonAPIFactory<GeoType.Polygon>(FeatureType.LeaseAreas),
  // TODO
  /* eslint-disable @typescript-eslint/no-unused-vars */
  addFeature: (feature: GeoJson.PolygonFeature) => Promise.resolve(),
  addFeatures: (features: Record<string, GeoJson.PolygonFeature>) => Promise.resolve(),
  /* eslint-enable @typescript-eslint/no-unused-vars */
};

export const MapsAPI = baseDocumentAPIFactory<MapDoc>(
  ConfigDataType.Maps,
  (doc: MapDoc) => doc.id,
);


export const PendingUsersAPI: IPendingUserAPI = {
  getById: (id: string) => Firestore.getDocumentById<PendingUserDoc>(ConfigDataType.PendingUsers, id),
  tryRequestNewSignInLink: (email: string) => fetch("/api/users/requestNewSignInLink", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email }),
  }).then(resp => resp.json()),
  deleteAllDocsWithEmail: Firestore.deleteAllDocsWithEmail,
};


export type SightingsAPIOpts = Pick<GeoJsonAPIOpts, "omitIds" | "maxAge" | "minAge">;
export const SightingsAPI: ISightingsAPI = {
  ...baseGeoJsonAPIFactory(FeatureType.Sightings),
  getByClient: (client, opts?: SightingsAPIOpts) => Firestore.getGeoJsonByClient(FeatureType.Sightings, client, opts?.omitIds),
  getByClients: (clients, opts?: SightingsAPIOpts) => Firestore.getGeoJsonByClients(FeatureType.Sightings, clients, opts?.omitIds),
  listenToNewestByStation: (callback, opts) => Firestore.listenToNewestSightingByStation(callback, opts),
};


export type FishingGearAPIOpts = Pick<GeoJsonAPIOpts, "omitIds" | "maxAge" | "minAge">;
export const FishingGearAPI: IFishingGearAPI = {
  ...baseGeoJsonAPIFactory(FeatureType.FishingGear),
  getByClient: (client, opts?: FishingGearAPIOpts) => Firestore.getGeoJsonByClient(FeatureType.FishingGear, client, opts?.omitIds),
  getByClients: (clients, opts?: FishingGearAPIOpts) => Firestore.getGeoJsonByClients(FeatureType.FishingGear, clients, opts?.omitIds),
};

export const SlowZonesAPI = genericGeoJsonAPIFactory<GeoType.Polygon>(FeatureType.SlowZones);

export const StationsAPI: IStationAPI = {
  getByClients: RTDatabase.getStationsByClients,
  getByClient: RTDatabase.getStationsByClient,
  setStationMarineTrafficLink: RTDatabase.setStationMTLink,
  listen: (callback, opts) => {
    if (opts && opts.clients === true) {
      return RTDatabase.listenToAllStations(callback);
    } else if (opts && Array.isArray(opts.clients)) {
      return RTDatabase.listenToStationsByClients(opts.clients, callback);
    } else if (opts && typeof opts.clients === "string") {
      return RTDatabase.listenToStationsByClient(opts.clients, callback);
    } else {
      throw new Error(`StationsAPI recieved unknown clients, ${opts?.clients}`);
    }
  },
};

export const StationStatusAPI: IStationStatusAPI = {
  listen: (callback, statusOpts) => {
    return RTDatabase.listenToStatusUpdates(callback, { tree: StatusTree.Stations, ...statusOpts });
  },
  getAndListen: (callback, statusOpts) => {
    return RTDatabase.getCurrentStationStatusTree(statusOpts.clients)
      .then(data => {
        callback(data);

        return RTDatabase.listenToStatusUpdates(
          (id, status) => {
            callback({ [id]: status as Status.Station });
          },
          { tree: StatusTree.Stations, ...statusOpts }
        );
      });
  },
};

export const ThirdPartyStatusAPI: IRTDatabaseAPI<Status.ThirdParty, undefined> = {
  listen: (callback) => RTDatabase.listenToStatusUpdates(callback, { tree: StatusTree.ThirdParty, clients: true }),
};

export const TracklinesAPI: ITracklineAPI = {
  listen: (callback, id) => RTDatabase.listenToTracklineById(id, callback),
  getById: (id, startEpoch?, endEpoch?) => RTDatabase.getTracklineById(id, startEpoch, endEpoch),
  getByIds: (ids, startEpoch?, endEpoch?) => RTDatabase.getTracklineByIds(ids, startEpoch, endEpoch),
};

export const TracklineStatusAPI: IRTDatabaseAPI<Status.Trackline, IdOpts> = {
  listen: (callback: IdCallback<Status.Trackline>, opts: IdOpts) => RTDatabase.listenToStatusUpdates(callback, { tree: StatusTree.Tracklines, ...opts }),
};


export const UsersAPI: IUsersAPI = {
  ...baseDocumentAPIFactory<UserDoc>(
    ConfigDataType.Users,
    (doc: UserDoc) => doc.uid,
  ),
  doesUserExist: Firestore.doesUserExist,
  doUsersExist: Firestore.doUsersExist,
  createPendingUsers: Firestore.createPendingUsers,
  toggleUserDisable: Firestore.toggleUserDisable,
  updateUserPermissions: Firestore.updateUserPermissions,
  deleteUser: (uid: string) => Firestore.deleteDocument(ConfigDataType.Users, uid),
  acceptEula: (uid: string, eulaVersion: string) => {
    return FirebaseAuth.getToken().then(token => {
      if (token === null) {
        return null;
      }
      const fetchOpts = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ uid, eulaVersion }),
      };

      return fetch("/api/users/eulaTermsAccepted", fetchOpts)
        .then(resp => resp.json())
        .then(json => isUserDoc(json) ? json : null);
    });
  },
};


const API = {
  [FeatureType.BearingLines]: BearingLinesAPI,
  [FeatureType.Buoys]: BuoysAPI,
  [ConfigDataType.Clients]: ClientsAPI,
  [FeatureType.Ellipses]: EllipsesAPI,
  [FeatureType.FishingGear]: FishingGearAPI,
  [FeatureType.Gliders]: GlidersAPI,
  [FeatureType.LeaseAreas]: LeaseAreasAPI,
  [ConfigDataType.Maps]: MapsAPI,
  [ConfigDataType.PendingUsers]: PendingUsersAPI,
  [FeatureType.Sightings]: SightingsAPI,
  [FeatureType.SlowZones]: SlowZonesAPI,
  [FeatureType.Stations]: StationsAPI,
  [StatusDataType.StationStatus]: StationStatusAPI,
  [StatusDataType.ThirdPartyStatus]: ThirdPartyStatusAPI,
  tracklines: TracklinesAPI,
  [StatusDataType.TracklineStatus]: TracklineStatusAPI,
  [ConfigDataType.Users]: UsersAPI,
};

export default API;
