import { Geo, GeoType } from "./Geo";
import { GeoJson } from "./GeoJson";
import { FeatureType } from "./Types";

export const DATA_TYPE_TO_GEO_TYPE: Record<FeatureType, GeoType> = {
  [FeatureType.BearingLines]: GeoType.LineString,
  [FeatureType.Buoys]: GeoType.Point,
  [FeatureType.Ellipses]: GeoType.Polygon,
  [FeatureType.FishingGear]: GeoType.Point,
  [FeatureType.Gliders]: GeoType.Point,
  [FeatureType.LeaseAreas]: GeoType.Polygon,
  [FeatureType.Sightings]: GeoType.Point,
  [FeatureType.Sma]: GeoType.Polygon,
  [FeatureType.SlowZones]: GeoType.Polygon,
  [FeatureType.Stations]: GeoType.Point,
};

export const toRadians = Math.PI / 180;
export const toDegrees = 180 / Math.PI;

export const mapboxTileSize = 512;

export const baseScale = (2 * Math.PI) / mapboxTileSize;

export const maxPhi = 2 * Math.atan(Math.pow(Math.E, Math.PI)) - Math.PI / 2;

export const normVectorFromLatLonPoint = (point: Geo.Coordinate): Geo.NormalVector => {
  if (!Array.isArray(point) || (point.length !== 2 && point.length !== 3)) {
    throw new Error(
      "Recieved a point that is invalid. Expected an array with 2 or 3 " +
      "elements (long, lat, + optional altitude), but instead recieved a " +
      `point: '${point}', with type: '${typeof point}`
    );
  }


  const cosLon = Math.cos(point[0] * toRadians);
  const sinLon = Math.sin(point[0] * toRadians);

  const cosLat = Math.cos(point[1] * toRadians);
  const sinLat = Math.sin(point[1] * toRadians);

  return [
    cosLat * cosLon,
    cosLat * sinLon,
    sinLat
  ];
};


export const latLonFromNormVector = (normVector: Geo.NormalVector): Geo.Coordinate => {
  if (!Array.isArray(normVector) || normVector.length !== 3) {
    throw new Error(
      "Recieved an invalid normal vector. Expected an array with 3 " +
      `elements, but instead recieved: '${normVector}', ` +
      `with type: '${typeof normVector}`
    );
  }


  const lat = Math.asin(normVector[2]) * toDegrees;
  const lon = Math.atan2(normVector[1], normVector[0]) * toDegrees;

  return [lon, lat] as Geo.Coordinate;
};

const sumVectors = (accum: Geo.NormalVector, curr: Geo.NormalVector): Geo.NormalVector => {
  return [
    accum[0] + curr[0],
    accum[1] + curr[1],
    accum[2] + curr[2],
  ] as Geo.NormalVector;
};

export const averageVectors = (vectors: Geo.NormalVector[]): Geo.NormalVector => {
  if (!Array.isArray(vectors) || !vectors.every(vec => vec.length === 3)) {
    throw new Error(
      `Recieved invalid vectors: '${vectors}', ` +
      `with type: '${typeof vectors}`
    );
  }


  return (
    vectors.reduce(sumVectors).map(component => component / vectors.length)
  ) as Geo.NormalVector;
};


const lnTanPlusSec = (phi: number): number => {
  const tanPhi = Math.tan(phi);

  const secPhi = 1 / (Math.cos(phi) + Number.EPSILON);

  return Math.log(tanPhi + secPhi);
};



const calcZoomFromMinMaxLat = (minLat: number, maxLat: number, nPixels: number): number => {
  const constTerm = nPixels * baseScale;

  const maxTerm = lnTanPlusSec(maxLat * toRadians);
  const minTerm = lnTanPlusSec(minLat * toRadians);

  const inverseDelta = 1 / (maxTerm - minTerm + Number.EPSILON);

  return Math.log2(constTerm * inverseDelta);
};

const calcZoomFromMinMaxLon = (minLon: number, maxLon: number, nPixels: number): number => {
  const constTerm = nPixels * baseScale;

  // Must convert to radians, that way this becomes 'dimensionless'
  // and wont mess with the log2 later.
  const deltaLon = toRadians * (maxLon - minLon);
  const invDelta = 1 / (deltaLon + Number.EPSILON);

  return Math.log2(constTerm * invDelta);
};

export const calculateZoomFromBounds = (bounds: Geo.BoundingLatLng, padding = 0.3): number => {
  const paddingPix = padding * Math.min(window.innerHeight, window.innerWidth);

  const latPixels = window.innerHeight - paddingPix;
  const lonPixels = window.innerWidth - paddingPix;

  const latZoom = calcZoomFromMinMaxLat(bounds[0][1], bounds[1][1], latPixels);

  const lonZoom = calcZoomFromMinMaxLon(bounds[0][0], bounds[1][0], lonPixels);

  // Return the lowest zoom (i.e the most zoomed out)
  return Math.min(latZoom, lonZoom);
};

export const extractMinMaxLatLng = <GT extends GeoType>(feature: GeoJson.Feature<GT>): null | Geo.MinMaxLatLng => {

  const coords = feature.geometry.coordinates;

  if (coords.length === 0) {
    return null;
  }


  let minMax: Geo.MinMaxLatLng;

  switch (feature.geometry.type) {
    case GeoType.Point:
      minMax = {
        minLon: coords[0] as number,
        maxLon: coords[0] as number,
        minLat: coords[1] as number,
        maxLat: coords[1] as number,
      };
      break;
    case GeoType.LineString:
      minMax = minMaxFromLineStringCoords(coords as Geo.Coordinate[]);
      break;
    case GeoType.Polygon:
      minMax = minMaxFromPolygonCoords(coords as Geo.Coordinate[][]);
      break;
    default:
      console.log(`unknown geotype ${feature.geometry}`);
      return null;
  }

  return minMax;
};

const minMaxFromLineStringCoords = (coords: Geo.Coordinate[]): Geo.MinMaxLatLng => {
  const minMax: Geo.MinMaxLatLng = {
    minLon: coords[0][0],
    maxLon: coords[0][0],
    minLat: coords[0][1],
    maxLat: coords[0][1],
  };

  for (let idx = 1; idx < coords.length; idx++) {
    minMax.minLon = Math.min(minMax.minLon, coords[idx][0]);
    minMax.maxLon = Math.max(minMax.maxLon, coords[idx][0]);
    minMax.minLat = Math.min(minMax.minLat, coords[idx][1]);
    minMax.maxLat = Math.max(minMax.maxLat, coords[idx][1]);
  }

  return minMax;
};

const minMaxFromPolygonCoords = (coords: Geo.Coordinate[][]): Geo.MinMaxLatLng => {
  const minMax: Geo.MinMaxLatLng = {
    minLon: 181,
    maxLon: -181,
    minLat: 91,
    maxLat: -91,
  };

  let lineStringMinMax: Geo.MinMaxLatLng;
  for (const lineString of coords) {
    if (lineString.length > 0) {
      lineStringMinMax = minMaxFromLineStringCoords(lineString);

      minMax.minLon = Math.min(minMax.minLon, lineStringMinMax.minLon);
      minMax.maxLon = Math.max(minMax.maxLon, lineStringMinMax.maxLon);
      minMax.minLat = Math.min(minMax.minLat, lineStringMinMax.minLat);
      minMax.maxLat = Math.max(minMax.maxLat, lineStringMinMax.maxLat);
    }
  }


  return minMax;
};

export const getBoundingCoordinatesOfFeatures = (features: GeoJson.Feature[]): null | Geo.BoundingLatLng => {
  if (features.length === 0) {
    return null;
  }

  const feat = features.pop() as GeoJson.Feature;

  let minMax = extractMinMaxLatLng(feat);

  let currMinMax: null | Geo.MinMaxLatLng;

  for (let featIdx = 0; featIdx < features.length; featIdx++) {
    currMinMax = extractMinMaxLatLng(features[featIdx]);

    if (!minMax) {
      minMax = currMinMax;
    } else if (currMinMax) {
      minMax.minLon = Math.min(minMax.minLon, currMinMax.minLon);
      minMax.maxLon = Math.max(minMax.maxLon, currMinMax.maxLon);
      minMax.minLat = Math.min(minMax.minLat, currMinMax.minLat);
      minMax.maxLat = Math.max(minMax.maxLat, currMinMax.maxLat);
    }
  }

  if (!minMax) return null;


  return [
    [minMax.minLon, minMax.minLat],
    [minMax.maxLon, minMax.maxLat]
  ] as Geo.BoundingLatLng;
};
