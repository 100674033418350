import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Form, Button, FloatingLabel, ListGroup, ToggleButtonGroup, ToggleButton } from "react-bootstrap";


import { ClientDoc, MapDoc, isMapDoc, Loading, DataType, FeatureType } from "../../misc/Types";

import ConfigModal from "../misc/ConfigModal";
import { randInt, sortedValues, Valid, allValid, stringSortFactory } from "../../misc/Utils";

import { isEqual, isPlainObject } from "lodash";

interface BaseMapConfigProps {
  show?: boolean;
  onConfirm: (mapDoc: MapDoc, deleteDoc?: boolean) => Promise<unknown>;
  onCancel: () => unknown;
  close: () => void;
  clientDocs: Loading | Record<string, ClientDoc>;
}

interface EditMapConfigProps extends BaseMapConfigProps {
  mode: "edit";
  mapDoc: MapDoc;
}

interface NewMapConfigProps extends BaseMapConfigProps {
  mode: "new";
  mapDoc?: unknown;
}

/// Lets us handle null/undef mode in parent components without throwing type
/// errors.
interface HiddenMapConfigProps extends BaseMapConfigProps {
  mode?: null | unknown;
  mapDoc?: unknown;
}

export type MapConfigProps =
  HiddenMapConfigProps
  | EditMapConfigProps
  | NewMapConfigProps;




export function emptyMapDoc(): MapDoc {
  return {
    id: "",
    displayName: "",
    sources: { },
    leaseAreas: [],
  };
}

function areMapSourcesValid(sources: MapDoc["sources"]): boolean {
  if (typeof sources === "boolean") {
    return true;
  }

  return isPlainObject(sources);
}

function computeMapDocValidity(mapDoc: MapDoc): Valid<MapDoc> {
  return {
    id: mapDoc.id.length > 0 && mapDoc.id !== "admin",
    displayName: mapDoc.displayName.length > 0 && mapDoc.displayName.toLowerCase() !== "admin",
    sources: areMapSourcesValid(mapDoc.sources)
  };
}

interface MapSource {
  sightings: boolean;
  stations: boolean;
}


type OnSrcChange = (name: null | string, value: null | MapSource) => void;

interface SourceConfigProps {
  sourceName?: string;
  source: MapSource;
  clients: Record<string, ClientDoc>,
  onChange: OnSrcChange;
  editor?: boolean;
  disabled?: boolean;
}

interface ClientMapSource extends MapSource {
  name?: string;
}

function buildConfigProps(
  mapDoc: MapDoc,
  clients: Record<string, ClientDoc>,
  onChange: OnSrcChange,
  disabled?: boolean,
): SourceConfigProps[] {
  const props: SourceConfigProps[] = [];

  if (typeof mapDoc.sources === "boolean") {
    return props;
  }

  for (const [sourceName, perms] of Object.entries(mapDoc.sources)) {
    const source: MapSource = {
      sightings: typeof perms === "boolean"
        ? perms
        : perms.includes(FeatureType.Sightings),
      stations: typeof perms === "boolean"
        ? perms
        : perms.includes(FeatureType.Stations),
    };

    props.push({ sourceName, source, clients, onChange, disabled });
  }

  props.sort(stringSortFactory({ preproc: p => p.sourceName ?? "" }));

  return props;
}


function SourceConfig(props: SourceConfigProps): JSX.Element {
  const [sourceName, setSourceName] = useState<null | string>(
    props.sourceName ?? null
  );

  const [source, setSource] = useState(props.source);



  const checkedVals = useMemo(() => {
    const checked: number[] = [];
    source.sightings && checked.push(1);
    source.stations && checked.push(2);
    return checked;
  }, [source]);

  const clients = useMemo(() => {
    return sortedValues(props.clients, doc => doc.clientDisplayName);
  }, [props.clients]);

  const onChange = useCallback((e: number[]) => {
    setSource(() => {
      // needs to be a 'new' object for react to pick up the change since it
      // just checks reference equality
      return {
        stations: e.includes(2),
        sightings: e.includes(1),
      };
    });
  }, []);

  const onDelete = useCallback(
    () => props.onChange(sourceName, null),
    [sourceName]
  );

  useEffect(() => {
    if (typeof sourceName === "string" && sourceName.length > 0) {
      props.onChange(sourceName, source);
    }
  }, [source, sourceName]);

  return (
    <ListGroup.Item className="d-flex justify-content-between">
      <div className="d-flex w-25 flex-column justify-content-center">
        <Form.Select size="lg" disabled={!props.editor} onChange={(e) => setSourceName(e.target.value)}>
          {
            props.editor
              ? <>
                <option/>
                {
                  clients.map((doc, idx) => (
                    <option key={idx}>
                      {doc.id}
                    </option>
                  ))
                }
              </>
              : <option>
                {sourceName}
              </option>
          }
        </Form.Select>
      </div>
      <div className="w-auto d-flex flex-column">
        <div className="d-flex h-100 justify-content-end align-self-center">
          <ToggleButtonGroup className="ml-auto" type="checkbox" value={checkedVals}
            onChange={onChange}>
            <ToggleButton
              className="my-auto"
              variant="outline-primary"
              id={sourceName + "-sighting-check"}
              value={1}>
              Sightings
            </ToggleButton>
            <ToggleButton
              className="my-auto"
              variant="outline-primary"
              id={sourceName + "-station-check"}
              value={2}>
              Stations
            </ToggleButton>
          </ToggleButtonGroup>
          <Button className="ml-2" variant="danger" onClick={onDelete}>
            Delete
          </Button>
        </div>
      </div>
    </ListGroup.Item>
  );
}





function MapConfig(props: MapConfigProps): JSX.Element {
  const show = useMemo(() => props.show ?? false, [props.show]);
  const close = useMemo(() => props.close, [props.close]);
  const clients = useMemo(() => props.clientDocs, [props.clientDocs]);

  const [mapDoc, setMapDoc] = useState<MapDoc>(
    props.mode === "edit" && isMapDoc(props.mapDoc)
      ? props.mapDoc
      : emptyMapDoc()
  );

  const [newSource, setNewSource] = useState<null | ClientMapSource>(null);

  const isNewSourceValid = useMemo(() => {
    return (
      newSource !== null &&
      typeof newSource.name === "string" &&
      newSource.name.length > 0 &&
      clients[newSource.name] !== undefined
    );
  }, [newSource]);

  const [unsaved, setUnsaved] = useState<boolean>(false);

  const fieldValidity = useMemo<Valid<MapDoc>>(
    () => computeMapDocValidity(mapDoc),
    [mapDoc]
  );

  const [autofillDisplayName, setAutofillDisplayName] = useState<boolean>(
    props.mode === "new"
  );

  const onSourceChange = useCallback(
    (name: string | null, change: null | MapSource) => {
      function mapSourceToArray(
        source: null | MapSource
      ): undefined | FeatureType[] {
        if (source === null) {
          return undefined;
        }

        const array: FeatureType[] = [];
        source.sightings && array.push(FeatureType.Sightings);
        source.stations && array.push(FeatureType.Stations);
        return array;
      }

      setMapDoc(current => {
        if (typeof current.sources === "boolean") {
          return current;
        }

        if (typeof name === "string") {
          const updated = { ...current };
          updated.sources[name] = mapSourceToArray(change);
          return updated;
        }

        return current;
      });
    },
    []
  );

  const onNewSourceChange = useCallback(
    (name: null | string, change: null | MapSource) => {
      if (name === null || change === null) {
        setNewSource(null);
      } else {
        setNewSource({ name, ...change });
      }
    },
    [],
  );

  const sourceProps = useMemo(() => {
    return clients === Loading
      ? null
      : buildConfigProps(mapDoc, clients, onSourceChange);
  }, [onSourceChange]);


  const setField = useCallback(
    <K extends keyof MapDoc, V = MapDoc[K]>(key: K, value: V) => {
      setMapDoc(current => {
        return { ...current, [key]: value };
      });
    },
    []
  );

  const addSource = useCallback(() => {
    if (newSource !== null && isNewSourceValid) {
      setMapDoc(curr => {
        if (typeof curr.sources === "boolean" ||
            typeof newSource.name !== "string") {
          return curr;
        }

        const mapDoc = { ...curr };

        const sourceArray: FeatureType[] = [];
        newSource.sightings && sourceArray.push(FeatureType.Sightings);
        newSource.stations && sourceArray.push(FeatureType.Stations);

        mapDoc.sources[newSource.name] = sourceArray;

        return mapDoc;
      });
    }
    setNewSource(null);
    setNewSource({ sightings: false, stations: false });
  }, [newSource]);

  return (
    <ConfigModal
      show={show}
      size="lg"
      saved={!unsaved}
      setSaved={() => setUnsaved(false)}
      close={close}
      title={
        props.mode === "edit"
          ? `Edit ${mapDoc.displayName} Map`
          : "Configure a new Map"
      }>
      <Form>
        <Form.Group controlId="id-input">
          <Form.Label>Map Id</Form.Label>
          <FloatingLabel label="Map Id">
            <Form.Control type="text" isInvalid={!fieldValidity.id}
              disabled={props.mode === "edit"}
              value={mapDoc.id}
              onChange={(e) => {
                setField("id", e.target.value);

                if (autofillDisplayName) {
                  setField("displayName", e.target.value);
                }
              }}/>
          </FloatingLabel>
          <Form.Text muted>
            Internal ID for the map.
            Also defines the trailing URL path for the map
            (i.e https://maps.mysticetus.com/map/{"{Id}"})
            <br/>
            Once set, this value cannot be changed without breaking document
            linkages
          </Form.Text>
        </Form.Group>
        <hr className="my-2"/>
        <Form.Group controlId="display-name-input">
          <Form.Label>Map Display Name</Form.Label>
          <FloatingLabel label="Map Display Name">
            <Form.Control type="text" isInvalid={!fieldValidity.displayName}
              value={mapDoc.displayName}
              onFocus={() => setAutofillDisplayName(false)}
              onBlur={() => {
                mapDoc.displayName.length === 0 &&
                  props.mode === "new" &&
                  setAutofillDisplayName(true);
              }}
              onChange={(e) => setField("displayName", e.target.value)}/>
          </FloatingLabel>
          <Form.Text muted>
            The name of the map as shown in the UI
          </Form.Text>
        </Form.Group>
        <hr className="my-2"/>
        <Form.Label>Map Data Sources</Form.Label>
        <ListGroup>
          {
            sourceProps === null
              ? null
              : sourceProps.map((props, idx) => (
                <SourceConfig key={idx} {...props}/>
              ))
          }
          {
            newSource !== null && clients !== Loading
              ? <SourceConfig sourceName={newSource.name}
                source={{ sightings: newSource.sightings, stations: newSource.stations }}
                clients={clients}
                onChange={onNewSourceChange}
                editor
              />
              : null
          }
          <ListGroup.Item className="d-flex justify-content-center">
            <Button className="w-50"
              disabled={(newSource !== null && !isNewSourceValid)}
              onClick={addSource}>
              Add Source
            </Button>
          </ListGroup.Item>
        </ListGroup>
      </Form>
    </ConfigModal>
  );
}

export default MapConfig;
