import React from "react";

import TopNav from "../../components/nav/TopNav";

const LeaseAreaManagement = (): JSX.Element => {

  document.title = "Mysticetus - Lease Areas";

  return (
    <>
      <TopNav title='Lease Area Management'/>
      <h1>TODO</h1>
    </>
  );
};


export default LeaseAreaManagement;
