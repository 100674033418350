import React, { useState, useCallback, useMemo } from "react";

import { Modal, ModalProps } from "react-bootstrap";

import Alert from "./Alert";
import LoadingButton from "./LoadingButton";

export type ModalModes = "edit" | "new" | null | undefined;

export interface ConfigModalProps extends Omit<ModalProps, "show"> {
  title: string;
  show?: null | boolean;
  headerText?: string;
  close?: () => void;
  onConfirm?: (e: React.MouseEvent<HTMLButtonElement>) => unknown | Error | Promise<unknown | Error>;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => unknown | Error | Promise<unknown | Error>;
  disableCancel?: boolean;
  disableConfirm?: boolean;
  closeOnCancel?: boolean;
  closeOnConfirm?: boolean;
  saved?: boolean;
  setSaved?: () => void;
  valid?: boolean;
}


function ConfigModal(props: React.PropsWithChildren<ConfigModalProps>): JSX.Element {
  const {
    title: propsTitle,
    show: propsShow,
    headerText,
    close: closeModal,
    onConfirm: propsOnConfirm,
    onCancel: propsOnCancel,
    disableCancel,
    disableConfirm,
    closeOnCancel,
    saved: propsSaved,
    setSaved,
    closeOnConfirm,
    ...modalProps
  } = props;

  const title = useMemo(() => propsTitle, [propsTitle]);
  const show = useMemo(() => propsShow, [propsShow]);
  const saved = useMemo(() => propsSaved, [propsSaved]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [error, setError] = useState<null | Error | string>(null);

  const onConfirm = useCallback((e) => {
    if (typeof propsOnConfirm === "function") {
      setIsLoading(true);
      Promise.resolve(propsOnConfirm(e))
        .finally(() => {
          setIsLoading(false);
          if (props.closeOnConfirm && typeof closeModal === "function") {
            closeModal();
          }
        });
    } else if (props.closeOnConfirm && typeof closeModal === "function") {
      closeModal();
    }
  }, [propsOnConfirm, closeModal, props.closeOnConfirm]);


  const onCancel = useCallback((e) => {
    if (typeof propsOnCancel === "function") {
      setIsLoading(true);
      const result = propsOnCancel(e);

      if (result instanceof Promise) {
        result.catch(err => console.error(err))
          .finally(() => setIsLoading(false));
      } else {
        if (result instanceof Error) {
          console.error(result);
          setError(result);
        }

        setIsLoading(false);
      }
    }

    if (props.closeOnCancel && typeof closeModal === "function") {
      closeModal();
    }
  }, [propsOnCancel, closeModal, props.closeOnCancel]);

  return (
    <Modal {...modalProps} show={show}>
      <Modal.Header closeButton={typeof closeModal === "function"} onHide={closeModal}>
        <Modal.Title>{title}</Modal.Title>
        {props.headerText}
      </Modal.Header>
      <Modal.Body>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          {
            typeof props.onConfirm === "function"
              ? <LoadingButton onClick={onCancel}
                variant="secondary" isLoading={isLoading}
                disabled={props.disableCancel}>
                  Cancel
              </LoadingButton>
              : null
          }
          {
            typeof props.onConfirm === "function"
              ? <LoadingButton onClick={onConfirm}
                isLoading={isLoading} disabled={props.disableConfirm || saved}>
                <div>Confirm Changes</div>
                {
                  !saved
                    ? <span className="text-black">*Unsaved</span>
                    : null
                }
              </LoadingButton>
              : null
          }
        </div>
        <Alert variant="danger" show={error !== null}>
          {error?.toString()}
        </Alert>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfigModal;
