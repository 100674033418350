import React, { useEffect, useMemo, useState, useCallback } from "react";


import { Button, Nav, Container, Tabs, Tab, Placeholder } from "react-bootstrap";

import TopNav from "../../components/nav/TopNav";
import ClientConfig from "../../components/admin/ClientConfig";
import MapConfig from "../../components/admin/MapConfig";
import ConfigList from "../../components/misc/ConfigList";
import LazyImage from "../../components/misc/LazyImage";

import { ClientsAPI, MapsAPI } from "../../firebase/API";
import { listFiles, uploadFile, GcsFile } from "../../firebase/Storage";
import {
  ClientDoc,
  isClientDoc,
  MapDoc,
  isMapDoc,
  Loading,
} from "../../misc/Types";

import {
  sortedValues,
  stringSortFactory,
  capitalizeFirstLetter,
} from "../../misc/Utils";

import { isPlainObject } from "lodash";


function MapSourceInfo(props: { mapDoc: MapDoc }): JSX.Element {
  const mapDoc = useMemo(() => props.mapDoc, [props.mapDoc]);

  const sources = useMemo((): true | {name: string, perms: string}[] => {
    if (mapDoc.sources === true) {
      return true;
    }

    const srcInfos: {name: string, perms: string}[] = [];

    for (const [name, srcPerm] of Object.entries(mapDoc.sources)) {
      let perms: string;
      if (typeof srcPerm === "boolean" || srcPerm.length === 0) {
        perms = srcPerm ? "All Data (Sightings, Stations, etc)" : "No Data";
      } else {
        srcPerm.sort(stringSortFactory({ preproc: d => d }));
        // we check for a 0 length array in the if statement, so pop will return
        // at least 1 value.
        const last = capitalizeFirstLetter(srcPerm.pop() as string);

        if (srcPerm.length === 0) {
          perms = `${last} Only`;
        } else {
          perms = `${srcPerm.map(capitalizeFirstLetter).join(", ")} and ${last}`;
        }
      }

      srcInfos.push({ name, perms });
    }

    srcInfos.sort(stringSortFactory({ preproc: d => d.name }));

    return srcInfos;
  }, [mapDoc]);

  if (sources === true) {
    return (
      <div className="mx-auto h-100 align-middle">
        <b>Admin map, data pulled from every source</b>
      </div>
    );
  }

  return (
    <>
      {
        sources.map((srcInfo, idx) => (
          <div className="ml-4 w-auto" key={idx}>
            <div>
              <b>- {srcInfo.name}:</b> {srcInfo.perms}
            </div>
            {
              idx !== sources.length - 1
                ? <hr/>
                : null
            }
          </div>
        ))
      }
    </>
  );
}


function isObject<D extends Record<string, unknown>>(
  obj: unknown
): obj is D {
  return isPlainObject(obj);
}


function updateClientDoc(
  clientDoc: ClientDoc,
  newFile?: File,
): Promise<ClientDoc> {
  if (newFile) {
    const iconPath = `clientIcons/${newFile.name}`;
    return uploadFile(iconPath, newFile)
      .then(() => updateClientDoc({ ...clientDoc, iconPath }));
  }

  // Firestore doesn't like getting undefined values
  if (clientDoc.iconPath === undefined) {
    delete clientDoc.iconPath;
  }

  return ClientsAPI.set(clientDoc);
}

function updateMapDoc(mapDoc: MapDoc, deleteDoc?: boolean): Promise<unknown> {
  return deleteDoc
    ? MapsAPI.delete(mapDoc.id)
    : MapsAPI.set(mapDoc);
}


type MapRecord = Record<string, MapDoc>;
type ClientRecord = Record<string, ClientDoc>;


type ConfigData = {
  clients: Loading | ClientRecord;
  maps: Loading | MapRecord;
  icons: Loading | GcsFile[];
};

type ConfigError = {
  error: string;
};

type ConfigState = ConfigData | ConfigError;

type SortedData = {
  maps: Loading | MapDoc[];
  clients: Loading | ClientDoc[];
};

function MapConfiguration(): JSX.Element {
  const [configState, setConfigState] = useState<ConfigState>({
    clients: Loading,
    maps: Loading,
    icons: Loading,
  });

  const sortedData = useMemo<SortedData>(() => {
    if ("maps" in configState) {
      const maps = configState.maps !== Loading
        ? sortedValues(configState.maps, doc => doc.id[0].toLowerCase())
        : Loading;

      const clients = configState.clients !== Loading
        ? sortedValues(configState.clients)
        : Loading;

      return { maps, clients };
    } else {
      return { maps: Loading, clients: Loading };
    }
  }, [configState]);

  const [activeKey, setActiveKey] = useState<"clients" | "maps">("maps");

  const [mode, setMode] = useState<null | "edit" | "new">(null);

  const [editDoc, setEditDoc] = useState<null | MapDoc | ClientDoc>(null);

  const updateConfigs = useCallback((configs: Partial<ConfigData>): void => {
    setConfigState(current => {
      if ("error" in current) {
        return current;
      }

      let clients = current.clients;
      if (isObject(configs.clients)) {
        clients = current.clients === Loading
          ? configs.clients
          : { ...current.clients, ...configs.clients };
      }

      let maps = current.maps;
      if (isObject(configs.maps)) {
        maps = current.maps === Loading
          ? configs.maps
          : { ...current.maps, ...configs.maps };
      }

      let icons = current.icons;
      if (Array.isArray(configs.icons)) {
        icons = Array.isArray(current.icons)
          ? [...current.icons, ...configs.icons]
          : configs.icons;
      }

      return { clients, maps, icons };
    });
  }, []);


  useEffect(() => {
    document.title = "Mysticetus - Map Config";

    Promise.all([MapsAPI.getAll(), ClientsAPI.getAll(), listFiles("clientIcons/")])
      .then(([maps, clients, icons]) => setConfigState({ maps, clients, icons }))
      .catch(error => setConfigState({ error: error.toString() }));

    const mapUnsub = MapsAPI
      .listenToCollection(maps => updateConfigs({ maps }), undefined);

    const clientUnsub = ClientsAPI
      .listenToCollection(clients => updateConfigs({ clients }), undefined);

    return function() {
      mapUnsub();
      clientUnsub();
    };
  }, []);

  const closeModal = useCallback(() => {
    setEditDoc(null);
    setMode(null);
  }, []);

  return (
    <>
      <TopNav title='Map Configuration'/>
      <Container className="mt-2">
        <Tab.Container defaultActiveKey="maps" onSelect={key => {
          switch (key) {
            case "clients":
              setActiveKey("clients");
              break;
            case "maps":
              setActiveKey("maps");
              break;
            default:
              console.warn(`unknown tab key: '${key}'`);
              break;
          }
        }}>
          <div className="d-flex justify-content-between">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="maps">Maps</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="clients">Clients</Nav.Link>
              </Nav.Item>
            </Nav>
            <Button className="text-capitalize" onClick={() => setMode("new")}>
              <span className="mx-2">
                Add {activeKey.slice(0, activeKey.length - 1)}
              </span>
            </Button>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="clients">
              {
                "error" in configState
                  ? `Error loading clients: ${configState.error}`
                  : <ConfigList items={sortedData?.clients ?? Loading}
                    titleSelector={doc => doc.clientDisplayName}
                    openEditor={(doc) => {
                      setMode("edit");
                      setEditDoc(doc);
                    }}
                    contentFactory={(doc) => {
                      return (
                        <div className="w-100 h-100 d-flex justify-content-stretch">
                          <div className="d-flex flex-column justify-content-start w-auto mx-2">
                            <div className="d-flex w-100 justify-content-between">
                              <span className="mr-2"><b>Mysticetus Domain:</b></span>
                              <span>{doc.id}</span>
                            </div>
                            <hr/>
                            <div className="d-flex w-100 justify-content-between">
                              <span className="mr-2"><b>Display Name:</b></span>
                              <span>{doc.clientDisplayName}</span>
                            </div>
                            <hr/>
                            <div className="d-flex w-100 justify-content-between">
                              <span className="mr-2"><b>Associated Color:</b></span>
                              <div className="inline-block border border-dark rounded"
                                style={{ background: doc.clientColor, height: "1.5rem", width: "5rem" }}/>
                            </div>
                          </div>
                          {
                            typeof doc.iconPath === "string"
                              ? <div className="h-100 w-25 border-left mx-2 px-2 d-flex flex-column justify-content-center">
                                <LazyImage fluid gcsPath={doc.iconPath}/>
                              </div>
                              : null
                          }
                        </div>
                      );
                    }}
                  />
              }
            </Tab.Pane>
            <Tab.Pane eventKey="maps">
              {
                "error" in configState
                  ? `Error loading maps: ${configState.error}`
                  : <ConfigList items={sortedData?.maps ?? Loading}
                    titleSelector={doc => doc.displayName}
                    openEditor={(doc) => {
                      setMode("edit");
                      setEditDoc(doc);
                    }}
                    disableDeleteButton={(doc) => doc.id === "admin"}
                    disableEditorButton={(doc) => doc.id === "admin"}
                    onDelete={(doc) => console.log("delete clicked", doc)}
                    contentFactory={(doc) => {
                      return (
                        <div className="w-100 h-100 d-flex justify-content-stretch">
                          <div className="d-flex flex-column justify-content-start w-50 mx-2 border-right px-2">
                            <div className="d-flex w-100 justify-content-between">
                              <span className="mr-2"><b>Map Id:</b></span>
                              <span>{doc.id}</span>
                            </div>
                            <hr/>
                            <div className="d-flex w-100 justify-content-between">
                              <span className="mr-2"><b>Map Display Name:</b></span>
                              <span>{doc.displayName}</span>
                            </div>
                            <hr/>
                            <div className="d-flex w-100 justify-content-between">
                              <span className="mr-2"><b>Lease Area Polygons:</b></span>
                              {
                                doc.leaseAreas === true
                                  ? <span>All Polygons Visible</span>
                                  : Array.isArray(doc.leaseAreas)
                                    ? <span>{doc.leaseAreas.length} Polygon(s) Visible</span>
                                    : <span>N/A</span>
                              }
                            </div>
                          </div>
                          <div className="d-flex flex-column justify-content-start w-50 mx-2">
                            <div className="mb-2"><b>Data Sources:</b></div>
                            <MapSourceInfo mapDoc={doc}/>
                          </div>
                        </div>
                      );
                    }}
                  />
              }
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        {
          ((isClientDoc(editDoc) && mode === "edit") ||
            (!isMapDoc(editDoc) && mode === "new"))
          && "icons" in configState
            ? <ClientConfig clientDoc={editDoc}
              mode={mode}
              icons={Array.isArray(configState.icons) ? configState.icons : []}
              show={mode !== null && activeKey === "clients"}
              close={closeModal}
              onConfirm={updateClientDoc}
              onCancel={() => setEditDoc(null)}/>
            : null
        }
        {
          ((isMapDoc(editDoc) && mode === "edit") ||
            (!isClientDoc(editDoc) && mode === "new"))
          && "clients" in configState
            ? <MapConfig mapDoc={editDoc}
              mode={mode}
              show={mode !== null && activeKey === "maps"}
              close={closeModal}
              clientDocs={configState.clients}
              onConfirm={updateMapDoc}
              onCancel={() => setEditDoc(null)}/>
            : null
        }
      </Container>
    </>
  );
}

export default MapConfiguration;
